import {Action} from '@ngrx/store';
import {
  EngineDetail,
  factoryRecommendedServices,
  IAvailability,
  IAvailabilityOptions,
  IOpcodeRequest,
  IOpcodeResponse,
  IServiceAppointment,
  IVehicle
} from '@signal/asp-data-commons';
import {IToyotaLexusModelDetails} from './service-appointment.reducer';

export enum ServiceAppointmentActionTypes {
  CREATE_SERVICE_APPOINTMENT = '[Dashboard page] Create new service appointment',
  EDIT_SERVICE_APPOINTMENT_FROM_DASHBOARD = '[Dashboard page] Edit a service appointment from dashboard',
  EDIT_SERVICE_APPOINTMENT_FROM_CONFIRM = '[Confirm page] Edit a service appointment from confirm page',
  EDIT_SERVICE_APPOINTMENT = '[Service page] Edit a service appointment',
  UPDATE_CURRENT_STEP = '[Service page] Update current step',
  UPDATE_IS_REDIRECTED = '[Service page] Update Redirected flag',
  UPDATE_SERVICE_FORM = '[Service page] Update a form value in the service appointment',
  LOAD_MODEL_YEAR_AND_MODELS = '[Service page] Load model year and models data',
  LOAD_MODEL_YEAR_AND_MODELS_SUCCESS = '[DCS API] Load model year and models data success',
  LOAD_MODEL_YEAR_AND_MODELS_FAIL = '[DCS API] Load model year and models data fail',
  LOAD_MODEL_YEAR_AND_MODELS_NO_OP = '[Service effects] Load model year and models no operation done',
  LOAD_SERVICE_REQUESTS = '[Service page] Load service requests data',
  LOAD_SERVICE_REQUESTS_SUCCESS = '[ASP API] Load service requests data success',
  LOAD_SERVICE_REQUESTS_FAIL = '[ASP API] Load service requests data fail',
  LOAD_AVAILABLE_MILEAGES = '[Service page] Load mileages data',
  LOAD_AVAILABLE_MILEAGES_SUCCESS = '[ASP API] Load mileages data success',
  LOAD_AVAILABLE_MILEAGES_FAIL = '[ASP API] Load mileages data fail',
  LOAD_AVAILABILITY_OPTIONS = '[Service page] Load availability options data',
  LOAD_AVAILABILITY_OPTIONS_SUCCESS = '[ASP API] Load availability options data success',
  LOAD_AVAILABILITY_OPTIONS_FAIL = '[ASP API] Load availability options data fail',
  LOAD_AVAILABILITY_OPTIONS_NO_OP = '[Service effects] Load availability options no operation done',
  LOAD_AVAILABILITY = '[Service page] Load availability data',
  LOAD_AVAILABILITY_SUCCESS = '[ASP API] Load availability data success',
  LOAD_AVAILABILITY_FAIL = '[ASP API] Load availability data fail',
  RESET_SERVICE_APPOINTMENT_STATE = '[Service page] Reset the service appointment state',
  RESET_SERVICE_APPOINTMENT_FORM = '[Service page] Reset the service appointment form',
  LOAD_STATES_CITIES='[Service page] Load states and cities data based on zipcode',
  LOAD_STATES_CITIES_SUCCESS='[ASP API] Load states and cities data based on zipcode success',
  LOAD_STATES_CITIES_FAILURE='[ASP API] Load states and cities data based on zipcode failure',
  UPDATE_RECALL_CODES = '[Service page] Update recall codes',
  LOAD_RECOMMENDED_SERVICE = '[ASP API] Load recommended service for opcoderequest',
  LOAD_RECOMMENDED_SERVICE_SUCCESS = '[ASP API] Load recommended service for opcoderequest success',
  LOAD_RECOMMENDED_SERVICE_FAIL = '[ASP API] Load recommended service for opcoderequest fail',
  TRIGGER_LOADING = '[ASP API] Trigger Service Requests Loader',
  TRIGGER_LOADING_FAIL = '[ASP API] Stop Trigger Service Requests Loader',
  SET_MAKEMODELYR_PAGE_DEFAULT = '[Service page] Set Make Model Year Page as Default'
}


export class CreateServiceAppointment implements Action {
  readonly type: string = ServiceAppointmentActionTypes.CREATE_SERVICE_APPOINTMENT;

  constructor() {
  }
}

/*This is a no-op action and just for tracking */
export class EditServiceAppointmentFromDashboard implements Action {
  readonly type: string = ServiceAppointmentActionTypes.EDIT_SERVICE_APPOINTMENT_FROM_DASHBOARD;

  constructor() {
  }
}

/*This is a no-op action and just for tracking */
export class EditServiceAppointmentFromConfirm  implements Action {
  readonly type: string = ServiceAppointmentActionTypes.EDIT_SERVICE_APPOINTMENT_FROM_CONFIRM;

  constructor() {
  }
}

export class EditServiceAppointment {
  readonly type: string = ServiceAppointmentActionTypes.EDIT_SERVICE_APPOINTMENT;

  constructor(public payload: { email: string, appointmentId: string, appointment: IServiceAppointment }) {
  }
}

export class UpdateServiceForm implements Action {
  readonly type: string = ServiceAppointmentActionTypes.UPDATE_SERVICE_FORM;

  constructor(public payload: { path: string, value: IVehicle | any }) {
  }
}

export class UpdateCurrentStep implements Action {
  readonly type: string = ServiceAppointmentActionTypes.UPDATE_CURRENT_STEP;

  constructor(public payload: { step: number }) {
  }
}

export class UpdateIsRedirected implements Action {
  readonly type: string = ServiceAppointmentActionTypes.UPDATE_IS_REDIRECTED;

  constructor(public payload: { isRedirect: boolean }) {
  }
}

export class UpdateRecallCodes implements Action {
  readonly type: string = ServiceAppointmentActionTypes.UPDATE_RECALL_CODES;

  constructor(public payload: { recallCodes: string[] }) {
  }
}

export class LoadModelYearAndModels implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS;

  constructor(public payload: {forceReload: boolean} = {forceReload: false}) {
  }
}

export class LoadModelYearAndModelsSuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS_SUCCESS;

  constructor(public payload: { vehicleModelYearList: IToyotaLexusModelDetails}) {
  }
}

export class LoadModelYearAndModelsFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS_FAIL;

  constructor(public payload: { error: string }) {
  }
}

export class LoadModelYearAndModelsNoOp implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS_NO_OP;

  constructor() {
  }
}

export class LoadServiceRequests implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS;

  constructor(public payload: {opcodeRequest?: IOpcodeRequest, countryCode?:string, languageCode?:string} = {opcodeRequest: {}, countryCode:'US', languageCode:'en'}) {
  }
}

export class LoadServiceRequestsSuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS_SUCCESS;

  constructor(public payload: { opcodeResponse: IOpcodeResponse }) {
  }
}

export class LoadServiceRequestsFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS_FAIL;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAvailableMileages implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES;

  constructor() {
  }
}

export class LoadAvailableMileagesSuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES_SUCCESS;

  constructor(public payload: { availableMileages: number[] }) {
  }
}

export class LoadAvailableMileagesFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES_FAIL;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAvailabilityOptions implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS;

  constructor(public payload: {forceReload: boolean} = {forceReload: false}) {
  }
}

export class LoadAvailabilityOptionsSuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_SUCCESS;

  constructor(public payload: { availabilityOptions: IAvailabilityOptions }) {
  }
}

export class LoadAvailabilityOptionsFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_FAIL;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAvailabilityOptionsNoOp implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_NO_OP;

  constructor() {
  }
}

export class LoadAvailability implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY;

  constructor(public payload: {date: string, advisorId: string, transport: string,enddt?:string,vin?: string,appointmentId?: string} = {date: '', advisorId: '-1', transport: undefined,enddt:'',vin: '', appointmentId: ''}) {
  }
}

export class LoadAvailabilitySuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY_SUCCESS;

  constructor(public payload: { availability: IAvailability, loadPayload: {date: string, advisorId: string, transport: string}}) {
  }
}

export class LoadAvailabilityFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_AVAILABILITY_FAIL;

  constructor(public payload: { error: string }) {
  }
}

export class ResetServiceAppointmentState implements Action {
  readonly type: string = ServiceAppointmentActionTypes.RESET_SERVICE_APPOINTMENT_STATE;

  constructor() {
  }
}

export class ResetServiceAppointmentForm implements Action {
  readonly type: string = ServiceAppointmentActionTypes.RESET_SERVICE_APPOINTMENT_FORM;

  constructor() {
  }
}
export class LoadStatesAndCities implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_STATES_CITIES;

  constructor(public payload: { zipPinCode: string }) {
  }
}
export class LoadStatesAndCitiesSuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_STATES_CITIES_SUCCESS;

  constructor(public payload: {data:any }) {
  }
}

export class LoadStatesAndCitiesFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_STATES_CITIES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}
export class LoadRecommendedService implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE;

  constructor(public payload: {opcodeRequest?: IOpcodeRequest, engineDetail?: EngineDetail, isLexus?:boolean}) {
  }
}
export class LoadRecommendedServiceSuccess implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE_SUCCESS;

  constructor(public payload: {data:factoryRecommendedServices[] }) {
  }
}

export class LoadRecommendedServiceFail implements Action {
  readonly type: string = ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE_FAIL;

  constructor(public payload: { error: string }) {
  }
}

export class TriggerLoader implements Action{
  readonly type: string = ServiceAppointmentActionTypes.TRIGGER_LOADING;

  constructor() {
  }
}

export class TriggerLoaderFail implements Action{
  readonly type: string = ServiceAppointmentActionTypes.TRIGGER_LOADING_FAIL;

  constructor() {
  }
}
export class SetMakeModelYrPageDefault implements Action{
  readonly type: string = ServiceAppointmentActionTypes.SET_MAKEMODELYR_PAGE_DEFAULT;

  constructor() {
  }
}

export type ServiceAppointmentType =
  | CreateServiceAppointment
  | EditServiceAppointmentFromDashboard
  | UpdateServiceForm
  | UpdateRecallCodes
  | LoadModelYearAndModels
  | LoadModelYearAndModelsSuccess
  | LoadModelYearAndModelsFail
  | LoadModelYearAndModelsNoOp
  | LoadServiceRequests
  | LoadServiceRequestsSuccess
  | LoadServiceRequestsFail
  | LoadAvailabilityOptions
  | LoadAvailabilityOptionsSuccess
  | LoadAvailabilityOptionsFail
  | LoadAvailabilityOptionsNoOp
  | LoadAvailability
  | LoadAvailabilitySuccess
  | LoadAvailabilityFail
  | ResetServiceAppointmentState
  | ResetServiceAppointmentForm
  | LoadStatesAndCities
  | LoadStatesAndCitiesSuccess
  | LoadStatesAndCitiesFail
  | LoadRecommendedService
  | LoadRecommendedServiceSuccess
  | LoadRecommendedServiceFail
  ;
