import { Component, OnDestroy, OnInit } from '@angular/core';
import {DateTime} from 'luxon';
import { AppointmentService } from '../../shared/services/asp/appointment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionList } from '../../shared/models/asp.types';
import { unsubscribeSubscriptions } from '../../shared/services/util.service';
import { AppointmentType, IResponse, IDeliveryAppointment, LocationType, ISalesAppointment } from '@signal/asp-data-commons';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import * as util from '../../shared/services/util.service'
import { TranslateService } from '@ngx-translate/core';
import { ILearningAppointment } from '@signal/asp-data-commons/lib/interfaces/appointment';
@Component({
  selector: 'app-confirm-info-delivery',
  templateUrl: './confirm-info.component.html',
  styleUrls: ['./confirm-info.component.scss']
})
export class ConfirmInfoComponent implements OnInit, OnDestroy {
  view = 'confirm';
  appointment: IDeliveryAppointment;
  makeModelYear: string;
  detailMap: { [key: string]: string };
  id: string;
  subscriptions: SubscriptionList = {};

  constructor(
    private readonly appointmentService: AppointmentService,
    private readonly route: ActivatedRoute,
    private readonly bannerService:AspBannerService,
    private readonly translateService:TranslateService,
    private readonly router: Router) {

      const urlState = this.router.getCurrentNavigation()?.extras?.state as ISalesAppointment;
      if(urlState?.appointmentId) {
        this.appointment = urlState;
        this.setDetailMap(urlState);
      } else {
        this.route.queryParams.subscribe(params => {
          this.id = params.id;
          if (this.id) {
            this.appointmentService.get(AppointmentType.LEARNING, this.id).subscribe((res: IResponse<ILearningAppointment>) => {
              if (res && res.data) {
                this.appointment = res.data;
                this.setDetailMap(res.data);
              }
            });
          }
        });
      }
  }

  ngOnInit(): void {
    this.bannerService.hide();
  }

  setDetailMap(appointmentDetails) {
    const isRemoteLocation = appointmentDetails.transportation.category === LocationType.REMOTE;

    const date = `${DateTime.fromISO(appointmentDetails.appointmentStartDate).toFormat('MMMM dd')}`;
    const time = appointmentDetails.timeSlotId;

    const dateTime = `${date} at ${time}`;

    let vehicle = appointmentDetails.vehicle;
    const advisorName = appointmentDetails.advisor.displayName;
    const apptComment = appointmentDetails.appointmentComments;
    const customer = appointmentDetails.customer;

    if(!vehicle.description){
      vehicle = Object.assign({description : ""}, vehicle);
    }

    let customerAddress = '';
    if (isRemoteLocation) {
      const custAdd = customer.otherAddress ? customer.otherAddress : customer.address;
      customerAddress = `otherTexts.weWillBringTheCarTo:\n${custAdd.addressLine1}, ${custAdd.addressLine2 ? custAdd.addressLine2 + ', ' : ''} ${custAdd.city}, ${custAdd.state} - ${custAdd.zip}`;
    }
    
    this.makeModelYear =`${vehicle.year || ''} ${util.titleCase(vehicle.make) || ''} ${vehicle.model || ''}`;


    const dealershipAddress = `${appointmentDetails.dealer.address.addressLine1} ${appointmentDetails.dealer.address.city}, ${appointmentDetails.dealer.address.state} ${appointmentDetails.dealer.address.zip}`;

    this.detailMap = {
      'selectService.time': dateTime,
      'selectService.vehicle': this.makeModelYear,
      'transportation.consultant': advisorName,
      'testDrive.comments': apptComment,
      'transportation.location': dealershipAddress
    };
    if(!apptComment) {
      delete this.detailMap['testDrive.comments'];
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

}
