import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Validators } from '@angular/forms'
import { AnalyticsService } from './../../../shared/services/analytics.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-shared-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() form;
  @Input() zipCodes;
  @Input() formStates;
  @Input() formcities;
  @Input() remotelocation:boolean;
  @Input() isCustomerAddress: boolean;
  @Output() addressChange = new EventEmitter();
  @Output() zipChange = new EventEmitter();
  @Input() isZipValid:boolean;
  filteredStates;
  filteredCities;

  constructor(
    private readonly analyticsService : AnalyticsService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit(): void {
    if(this.formcities && this.formcities.length===1 && this.form.controls.zip.value.length === 5){
      this.filteredCities=this.formcities[0].cities;
    } 
    const valuechange=  this.form?.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if(this.formcities && this.formcities.length===1)
      {
        this.filteredCities=this.formcities[0].cities;
        
      }
        this.addressChange.emit(value);
      });
     
  }

  onEnter(form, event, control) {
    if (event.keyCode === 13) {
      const value = event.target.value;
      if (control.includes("state")) {
        form.statename = value;
        for(const city of this.formcities){
          if(value===city.state){
            this.filteredCities=city.cities;
          }
        }
      }
      else {
        form.cityname = value;
      }
    }
  }
  onblur(form, event, control) {
    if (event.relatedTarget === null) {
      let inputTrackingData :any = {
        content_section : 'confirm tab',
        link_module : 'contact information'
      }
      if (control.includes("state")){
        form.controls['state'].setValue(form.statename);
        inputTrackingData = {
          ...inputTrackingData,
          link_text : 'state',
          link_input_field :form.statename
        };
      }
      else{
        form.controls['city'].setValue(form.cityname);
        inputTrackingData = {
          ...inputTrackingData,
          link_text : 'city',
          link_input_field : form.cityname
        };
      }
      this.analyticsService.trackLink(inputTrackingData,true);
    }

  }
  blurHandler(fieldName,input){
    const inputTrackingData = {
      content_section : 'confirm tab',
      link_module : 'contact information',
      link_text : fieldName,
      link_input_field : input
    }
    this.analyticsService.trackLink(inputTrackingData,true);
  }

  selectedOption(form, option, control) {
    if (option && control.includes("state"))
      {
        form.statename = option;
        for(const city of this.formcities){
          if(option===city.state){
            this.filteredCities=city.cities;
          }
        }
      }
    else {
      form.cityname = option;
    }

  }
  inputZipChange(event) {
    if(this.remotelocation && !this.isCustomerAddress)
    {
      if (event.target.value && event.target.value.length === 5) {
      this.form.controls['isZipValid'].patchValue(this.checkZipCodes(event.target.value));
      if (this.checkZipCodes(event.target.value)) {
        this.zipChange.emit(event.target.value);
        this.form.controls['zip'].clearValidators();
        this.form.controls['zip'].updateValueAndValidity();
        return;
      }
    }
    this.form.controls['isZipValid'].patchValue(false);
   this.form.controls['zip'].setErrors(
     { remoteRequired: this.translate.instant('appointmentInformation.pleaseEnterValidRemoteZipcode') });
    this.form.controls['zip'].setValidators(Validators.required);
  }
   else{
    if (event.target.value && event.target.value.length === 5) {
     this.zipChange.emit(event.target.value);
     this.form.controls['isZipValid'].patchValue(true);
     return;
    }
    this.form.controls['isZipValid'].patchValue(false);
   }
  }
  private checkZipCodes(value): boolean {
    if (this.zipCodes.isRemote) {
      return this.zipCodes.pmaZipCodes.findIndex(x => x === value) !== -1;
    }
    return false;
  }

}
