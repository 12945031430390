<div id="service-request-container" class="container-fluid">
  <div class="title-container">
    <span class="step-title">{{'selectService.chooseYourServices'| translate}}</span>
    <div class="mileage-container">
      <app-loading *ngIf="(availableMileagesLoading$ | async) && (selectedStep$ | async) === 1"
                   class="telematics-loading"
                   loadingSymbol="dots"
                   text="Loading mileages"
                   color="var(--dashboard-primary-color)">
      </app-loading>
      <ng-container *ngIf="!(availableMileagesLoading$ | async) && (availableMileagesLoaded$ | async)">
          <span class="mileage-title">{{'selectService.mileage'| translate}}</span>
          <app-mileage-carousel class="app-mileage-carousel"
                                [mileages]="availableMileages$ | async"
                                [isVisible]="(selectedStep$ | async) === 1"
                                [selectedMileage]="recommendedServicesByMileage$ | async"
                                (mileageChange)="onMileageChange($event)"></app-mileage-carousel>
      </ng-container>
    </div>
  </div>

  <div class='own-vehicle-loader' *ngIf="(serviceOpcodesLoading$ | async) && (selectedStep$ | async) === 1">
    <app-loading size=".5"></app-loading>
  </div>
  <div class="row" *ngIf="!(serviceOpcodesLoading$ | async) && (selectedStep$ | async) === 1 && !isTelematicsLoading">
    <div class="col-md-12 mb-2">
      <form [formGroup]="step" appConnectStore="serviceForm">
        <div class="d-flex mb-4 w-100"
        *ngIf="recommendedServicesAvailable$ | async">
          <span class="step-subtitle">{{'selectService.recommended'| translate}}</span>
          <div class="service-counter ml-3">{{recommendedServicesSelectedCount}}</div>
        </div>
        <div formArrayName="recommendedServices"
             *ngIf="recommendedServicesAvailable$ | async">
          <mat-expansion-panel *ngFor="let service of recommendedServicesArr?.controls, let i = index"
            [expanded]="service.value.expanded" (opened)="accordianOpened(service)" (closed)="accordianClosed(service)"
            [formGroupName]="i" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="service-title-panel my-4 w-100">
                  <mat-checkbox class="service-checkbox d-flex mt-1 pt-2" disableRipple (click)="checkEvent($event)"
                    (change)="selectionChange($event,true,i)" formControlName="selected">
                  </mat-checkbox>
                  <div class="service-info d-flex">
                    <span class="service-name ml-4">{{service.value.serviceInfo.displayName}}</span>
                    <span *ngIf="service.value.serviceInfo.factoryServices && service.value.serviceInfo.factoryServices.length > 0 &&
                      service.value.serviceInfo?.factoryServices[0].factoryServicesDetails && service.value.serviceInfo?.factoryServices[0].factoryServicesDetails.length > 0"
                    class="service-tip-factory-details ml-4" (click)="viewFactoryDetails($event, service.value.serviceInfo)"><ng-container *ngIf="service.value.serviceInfo?.factoryServices[0].vehicleDetails.make?.toLowerCase() !== 'scion'">{{'selectService.seePackageDetails' | translate}}</ng-container></span>
                    <span *ngIf="service.value.serviceInfo.pricing.show
                                  && service.value.serviceInfo?.pricing?.discount?.isAvailable"
                          class="service-tip-secondary ml-4">{{'selectService.special'| translate}}</span>
                    <span *ngIf="service.value.serviceInfo.recommended?.isCoveredByToyotaCare"
                          class="service-covered ml-4 ml-md-auto">
                          <span *ngIf="!service.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.coveredByToyotaCare' | translate}}</span>
                        <span *ngIf="service.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.lexusComplimentaryMaintenance' | translate}}</span>
                    </span>
                    <span class="ask-advisor ml-md-auto"
                          *ngIf="!service.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                  && !((showPricing$ | async) && service.value.serviceInfo.pricing.show)">
                          <ng-template [ngTemplateOutlet]="askAdvisor"></ng-template>
                    </span>
                    <span *ngIf="!service.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                    && (showPricing$ | async)
                                    && service.value.serviceInfo.pricing.show"
                          class="service-pricing ml-4 ml-md-auto">
                        <span class="service-pricing-text"
                              *ngIf="getPricingText(service.value.serviceInfo)"
                              [ngClass]="{'crossed-out-price mr-1': service.value.serviceInfo.pricing.show
                                                                    && service.value.serviceInfo?.pricing?.discount?.isAvailable}">
                              {{getPricingText(service.value.serviceInfo)}}
                        </span>
                        <span class="service-pricing-value"
                        [ngClass]="{'crossed-out-price mr-1': service.value.serviceInfo.pricing.show
                        && service.value.serviceInfo?.pricing?.discount?.isAvailable && service.value.serviceInfo?.pricing?.discount.price !== 0}">
                           <ng-container [ngClass]="getPricing(service.value.serviceInfo).toString().includes('NaN')
                              ?'ask-advisor ml-md-auto normal-text-weight':''">
                              <span *ngIf="!getPricing(service.value.serviceInfo).toString().includes('NaN');
                              else showAskAdvisor">{{getPricing(service.value.serviceInfo)}}</span>
                            </ng-container>
                        </span>
                        <span *ngIf="service.value.serviceInfo?.pricing?.discount?.isAvailable &&  service.value.serviceInfo?.pricing?.discount.price !== 0"
                              class="ml-1 text-decoration-none">
                        {{service.value.serviceInfo?.pricing?.discount.price | currency: ('GLOBAL.CURRENCY' | translate)}}
                      </span>
                      </span>
                  </div>
                </div>
                <mat-icon class="mat-icon-arrow-indicator mx-3">keyboard_arrow_right</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="service-details">
              <p>{{service.value.serviceInfo.description}}</p>
              <textarea *ngIf="service.value.serviceInfo.commentsEnabled"  rows="3" [ngClass]="{'invalid-border': service.invalid}"
                class="service-comments form-control mb-0" placeholder="{{'selectService.enterCommentsHere'| translate}}"
                formControlName="comments" (blur)="commentsTracking(service)"></textarea>
              <span *ngIf="service.invalid" class="comments-warning">{{'selectService.commentsAreRequired' | translate}}</span>
            </div>
            <div  formArrayName = 'addOns'  class="addon-service" *ngIf='service.value.selected && service.value.serviceInfo.addOnOpcodes && service.value.serviceInfo.addOnOpcodes?.checked && service.value.serviceInfo.addOnOpcodes?.opcodes'>
              <span class="addon-text" *ngIf="service.value.serviceInfo.addOnOpcodes?.description!== undefined &&  service.value.serviceInfo.addOnOpcodes?.description !== ''; else upselltext">{{service.value.serviceInfo.addOnOpcodes?.description}}</span>
              <ng-template #upselltext>{{upsellText$ | async}}</ng-template>
              <div class="row addon-list" *ngFor="let addon of getAddOns(i, true), let ind = index">
                <div class="service-info d-flex" [formGroupName]="ind">
                  <mat-checkbox class="addon-checkbox d-flex mt-1 pt-2" formControlName="selected" disableRipple (click)="checkEvent($event)"
                (change)="addOnSelectionChange($event,addon.value.serviceInfo,i,true)">
                </mat-checkbox>
                  <span class="service-name ml-4">{{addon.value.serviceInfo.displayName? addon.value.serviceInfo.displayName :addon.value.serviceInfo.dmsDescription }}</span>
                  <span *ngIf="addon.value.serviceInfo.pricing.show
                              && addon.value.serviceInfo?.pricing?.discount?.isAvailable"
                        class="service-tip-secondary ml-4">{{'selectService.special'| translate}}</span>
                  <span *ngIf="addon.value.serviceInfo.recommended?.isCoveredByToyotaCare"
                        class="service-covered ml-4 ml-md-auto">
                        <span *ngIf="!addon.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.coveredByToyotaCare' | translate}}</span>
                        <span *ngIf="addon.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.lexusComplimentaryMaintenance' | translate}}</span>
                </span>
                  <span class="ask-advisor ml-md-auto"
                        *ngIf="!addon.value.serviceInfo.recommended?.isCoveredByToyotaCare
                              && !((showPricing$ | async) && addon.value.serviceInfo.pricing.show)">
                      <ng-template [ngTemplateOutlet]="askAdvisor"></ng-template>
                </span>
                  <span *ngIf="!addon.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                && (showPricing$ | async)
                                && addon.value.serviceInfo.pricing.show"
                        class="service-pricing ml-4 ml-md-auto">
                    <span class="service-pricing-text"
                          *ngIf="getPricingText(addon.value.serviceInfo)"
                          [ngClass]="{'crossed-out-price mr-1': addon.value.serviceInfo.pricing.show
                                                                && addon.value.serviceInfo?.pricing?.discount?.isAvailable}">
                          {{getPricingText(addon.value.serviceInfo)}}
                    </span>
                    <span class="service-pricing-value"
                    [ngClass]="{'crossed-out-price mr-1':addon.value.serviceInfo?.pricing?.show
                                                                && addon.value.serviceInfo?.pricing?.discount?.isAvailable && service.value.serviceInfo?.pricing?.discount.price !== 0}">
                        <ng-container [ngClass]="getPricing(addon.value.serviceInfo).toString().includes('NaN') ?
                         'ask-advisor ml-md-auto normal-text-weight':''" >
                      <span
                      *ngIf="!getPricing(addon.value.serviceInfo).toString().includes('NaN');
                      else showAskAdvisor"> {{getPricing(addon.value.serviceInfo)}}</span>
                      </ng-container>
                    </span>
                    <span *ngIf="addon.value.serviceInfo?.pricing?.discount?.isAvailable &&  service.value.serviceInfo?.pricing?.discount.price !== 0"
                          class="ml-1 text-decoration-none">
                    {{addon.value.serviceInfo?.pricing?.discount?.price | currency: ('GLOBAL.CURRENCY' | translate)}}
                  </span>
                  </span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
        <div class="counter-section d-flex my-4 w-100"
             *ngIf="otherServicesAvailable$ | async">
          <div class="step-subtitle">{{'selectService.allServices'| translate}}</div>
          <div class="service-counter ml-3">{{regularServicesSelectedCount}}</div>
          <div class="step-deselect ml-4" (click)="clearSelections()">{{'selectService.deselectAll'| translate}}</div>
          <div class="d-flex d-md-none w-100"></div>
          <div class="d-flex ml-md-auto mt-4 mt-md-0 search-text">
            <mat-form-field appearance="outline">
              <mat-label>{{'selectService.search'| translate}}</mat-label>
              <mat-icon matPrefix>search</mat-icon>
              <input matInput #clearFilter (keyup)="filterServices($event.target.value)" (focusout)="trackSearchTerm($event.target.value)"
                [placeholder]="'selectService.searchForServices' | translate">
              <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearServiceFilter()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="allServices"
             *ngIf="otherServicesAvailable$ | async">
          <ng-container *ngFor="let service of allServicesArr?.controls, let i = index" [formGroupName]="i">
            <mat-expansion-panel *ngIf="!service.value.serviceInfo.isMiscellaneous && !service.value.hidden" [expanded]="service.value.expanded"
            (opened)="accordianOpened(service)" (closed)="accordianClosed(service)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="service-title-panel my-4 w-100">
                    <mat-checkbox class="service-checkbox d-flex mt-1 pt-2" disableRipple (click)="checkEvent($event)"
                                  (change)="selectionChange($event,false,i)" formControlName="selected">
                    </mat-checkbox>
                    <div class="service-info d-flex">
                      <span class="service-name ml-4">{{service.value.serviceInfo.displayName? service.value.serviceInfo.displayName : service.value.serviceInfo.dmsDescription}}</span>
                      <span *ngIf="service.value.serviceInfo.pricing.show
                                  && service.value.serviceInfo?.pricing?.discount?.isAvailable"
                            class="service-tip-secondary ml-4">{{'selectService.special'| translate}}</span>
                      <span *ngIf="service.value.serviceInfo.recommended?.isCoveredByToyotaCare"
                            class="service-covered ml-4 ml-md-auto">
                            <span *ngIf="!service.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.coveredByToyotaCare' | translate}}</span>
                            <span *ngIf="service.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.lexusComplimentaryMaintenance' | translate}}</span>
                    </span>
                      <span class="ask-advisor ml-md-auto"
                            *ngIf="!service.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                  && !((showPricing$ | async) && service.value.serviceInfo.pricing.show)">
                          <ng-template [ngTemplateOutlet]="askAdvisor"></ng-template>
                    </span>
                      <span *ngIf="!service.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                    && (showPricing$ | async)
                                    && service.value.serviceInfo.pricing.show"
                            class="service-pricing ml-4 ml-md-auto">
                        <span class="service-pricing-text"
                              *ngIf="getPricingText(service.value.serviceInfo)"
                              [ngClass]="{'crossed-out-price mr-1': service.value.serviceInfo.pricing.show
                                                                    && service.value.serviceInfo?.pricing?.discount?.isAvailable}">
                              {{getPricingText(service.value.serviceInfo)}}
                        </span>
                        <span class="service-pricing-value"
                        [ngClass]="{'crossed-out-price mr-1': service.value.serviceInfo?.pricing?.show
                                                                    && service.value.serviceInfo?.pricing?.discount?.isAvailable &&  service.value.serviceInfo?.pricing?.discount.price !== 0}">
                              <ng-container [ngClass]="getPricing(service.value.serviceInfo).toString().includes('NaN') ?
                               'ask-advisor ml-md-auto normal-text-weight':''" >
                          <span
                          *ngIf="!getPricing(service.value.serviceInfo).toString().includes('NaN');
                          else showAskAdvisor">{{getPricing(service.value.serviceInfo)}}</span>
                          </ng-container>
                        </span>
                        <span *ngIf="service.value.serviceInfo?.pricing?.discount?.isAvailable &&  service.value.serviceInfo?.pricing?.discount.price !== 0"
                              class="ml-1 text-decoration-none">
                        {{service.value.serviceInfo?.pricing?.discount?.price | currency: ('GLOBAL.CURRENCY' | translate)}}
                      </span>
                      </span>
                    </div>
                  </div>
                  <mat-icon class="mat-icon-arrow-indicator mx-3">keyboard_arrow_right</mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="service-details">
                <p>{{service.value.serviceInfo.description}}</p>
                <textarea *ngIf="service.value.serviceInfo.commentsEnabled || service.value.serviceInfo.isDmsApptOpcode" rows="3" [ngClass]="{'invalid-border': service.invalid}" class="service-comments form-control"
                  placeholder="{{'selectService.enterCommentsHere'| translate}}" formControlName="comments"  (blur)="commentsTracking(service)"></textarea>
                <span *ngIf="service.invalid" class="comments-warning">{{'selectService.commentsAreRequired' | translate}}</span>
              </div>
              <div formArrayName='addOns' class="addon-service"  
                *ngIf='service.value.selected && service.value.serviceInfo.isVisible && service.value.serviceInfo?.subComplaints && service.value.serviceInfo.subComplaints?.checked && service.value.serviceInfo.subComplaints?.complaintCodes'>
                  <div class="row addon-list" *ngFor="let code of getSubComplaints(service.value.serviceInfo),let i = index" >
                    <div class="service-info d-flex"  [formGroupName]="i">
                      <mat-checkbox class="addon-checkbox d-flex mt-1 pt-2"  disableRipple (click)="checkEvent($event)"
                      (change)="addOnSelectionChange($event,code,i,false)" 
                        formControlName="selected" >
                      </mat-checkbox>
                      <span class="service-name ml-4">{{code.displayName}}</span>
                    </div>
                  </div>
              </div>
              <div formArrayName = 'addOns' class="addon-service" *ngIf='service.value.selected && service.value.serviceInfo.addOnOpcodes && service.value.serviceInfo.addOnOpcodes?.checked && service.value.serviceInfo.addOnOpcodes?.opcodes'>
                <span class="addon-text" *ngIf="service.value.serviceInfo.addOnOpcodes?.description!== undefined && service.value.serviceInfo.addOnOpcodes?.description !== ''; else upselltext">{{service.value.serviceInfo.addOnOpcodes?.description}}</span>
                <ng-template #upselltext>{{upsellText$ | async}}</ng-template>
                <div class="row addon-list" *ngFor="let addon of getAddOns(i, false), let ind = index">
                  <div class="service-info d-flex" [formGroupName]="ind">
                    <mat-checkbox class="addon-checkbox d-flex mt-1 pt-2" formControlName="selected" disableRipple (click)="checkEvent($event)"
                  (change)="addOnSelectionChange($event,addon.value.serviceInfo,i,false)">
                  </mat-checkbox>
                    <span class="service-name ml-4">{{addon.value.serviceInfo.displayName? addon.value.serviceInfo.displayName : addon.value.serviceInfo.dmsDescription }}</span>
                
                    <span *ngIf="addon.value.serviceInfo.pricing.show
                                && addon.value.serviceInfo?.pricing?.discount?.isAvailable"
                          class="service-tip-secondary ml-4">{{'selectService.special'| translate}}</span>
                    <span *ngIf="addon.value.serviceInfo.recommended?.isCoveredByToyotaCare"
                          class="service-covered ml-4 ml-md-auto">
                          <span *ngIf="!addon.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.coveredByToyotaCare' | translate}}</span>
                          <span *ngIf="addon.value.serviceInfo.dealerCode.startsWith('6')">{{ 'selectService.lexusComplimentaryMaintenance' | translate}}</span>
                  </span>
                    <span class="ask-advisor ml-md-auto"
                          *ngIf="!addon.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                && !((showPricing$ | async) && addon.value.serviceInfo.pricing.show)">
                        <ng-template [ngTemplateOutlet]="askAdvisor"></ng-template>
                  </span>
                    <span *ngIf="!addon.value.serviceInfo.recommended?.isCoveredByToyotaCare
                                  && (showPricing$ | async)
                                  && addon.value.serviceInfo.pricing.show"
                          class="service-pricing ml-4 ml-md-auto">
                      <span class="service-pricing-text"
                            *ngIf="getPricingText(addon.value.serviceInfo)"
                            [ngClass]="{'crossed-out-price mr-1': addon.value.serviceInfo.pricing.show
                                                                  && addon.value.serviceInfo?.pricing?.discount?.isAvailable}">
                            {{getPricingText(addon.value.serviceInfo)}}
                      </span>
                      <span class="service-pricing-value"
                      [ngClass]="{'crossed-out-price mr-1':addon.value.serviceInfo?.pricing?.show
                                                                  && addon.value.serviceInfo?.pricing?.discount?.isAvailable &&  service.value.serviceInfo?.pricing?.discount.price !== 0}">
                            <ng-container [ngClass]="getPricing(addon.value.serviceInfo).toString().includes('NaN') ?
                               'ask-advisor ml-md-auto normal-text-weight':''">
                        <span
                         *ngIf="!getPricing(addon.value.serviceInfo).toString().includes('NaN');
                         else showAskAdvisor">{{getPricing(addon.value.serviceInfo)}}</span>
                         </ng-container>
                      </span>
                      <span *ngIf="addon.value.serviceInfo?.pricing?.discount?.isAvailable &&  service.value.serviceInfo?.pricing?.discount.price !== 0"
                            class="ml-1 text-decoration-none">
                      {{addon.value.serviceInfo?.pricing?.discount?.price | currency: ('GLOBAL.CURRENCY' | translate)}}
                    </span>
                    </span>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngFor="let miscservice of allServicesArr?.controls, let j = index" [formGroupName]="j">
            <div *ngIf="miscservice.value.serviceInfo.isMiscellaneous" class="misc-details">
              <p>{{miscservice.value.serviceInfo.description}}</p>
              <textarea rows="3" (keyup)="setMiscSelected(miscservice)"
                class="service-comments form-control mb-0"
                formControlName="comments" (blur)="commentsTracking(miscservice)"></textarea>
            </div>
            </ng-container>
        </div>
       
      </form>
    </div>
  </div>
</div>

<ng-template #askAdvisor>
  {{'selectService.askAdvisor'| translate}}
  <mat-icon [title]="'selectService.pleaseContactTheDealerToKnowThePriceDetails' | translate"
  class="mat-info-icon">info_outline</mat-icon>
</ng-template>
<ng-template class="ask-advisor ml-md-auto" #showAskAdvisor><ng-template [ngTemplateOutlet]="askAdvisor"></ng-template></ng-template>
