import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';
// tslint:disable-next-line:no-duplicate-imports
import { DateTime } from 'luxon';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input, OnChanges,
  OnInit,
  Optional,
  Output, SimpleChanges,
  SkipSelf,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS} from '@angular/material/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { LuxonDateAdapter } from '../../services/asp/luxon-date-adapter';
import { AnalyticsService } from './../../../shared/services/analytics.service';

@Component({
  selector: 'app-asp-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AspCalendarComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class AspCalendarComponent implements ControlValueAccessor, OnInit, OnChanges {
  @ViewChild('tdcalendar', { static: false }) calendar: MatCalendar<DateTime>;

  @Input() isAvailabilityLoading: boolean;
  @Input() availableDates: string[]; // = ['2020-07-21', '2020-07-22', '2020-08-01', '2020-08-05'];
  @Input() startAt; // = '2020-08-20';
  @Input() minDate; // = '2020-07-20';
  @Input() maxDate; // = '2020-09-24';
  @Input() selectedDate: DateTime;
  @Output() dateChanged: EventEmitter<DateTime> = new EventEmitter();
  @Output() monthChanged: EventEmitter<DateTime> = new EventEmitter();
  customHeader = CalendarHeaderComponent;

  @Input() formControlName: string;

  dateFilter = (d: DateTime): boolean => {
    const currentDate = d.toFormat('yyyy-MM-dd');
    if(DateTime.now().toFormat('yyyy-MM-dd')===currentDate)
    {
      return this.availableDates?.filter(t => t === currentDate).length > 0;
    }
    return d.diffNow('days').days >= 0
      && this.availableDates?.filter(t => t === currentDate).length > 0;
  };

  private control: AbstractControl;


  onChange: any = (value) => { };
  onTouched: any = () => { };

  constructor(@Optional() @Host() @SkipSelf()
  private readonly controlContainer: ControlContainer,
  private readonly analyticsService : AnalyticsService) {

  }

  ngOnInit() {
    this.control = this.controlContainer.control.get(this.formControlName);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.control.reset();
    }
  }

  registerOnTouched(fn) {
  }

  get value(): DateTime {
    return this.control.value;
  }

  set value(value) {
    this.selectedDate = DateTime.fromFormat(value.toString(), 'yyyy-MM-dd');
    this.startAt = DateTime.fromFormat(value.toString(), 'yyyy-MM-dd');
    if (this.calendar) {
      this.calendar.activeDate = this.selectedDate;
      this.calendar.startAt = this.selectedDate;
    }
    this.onChange(value);
    this.onTouched();
  }

  dateFilterInput = this.dateFilter;

  setDateClass() {
    return;
  }

  dateChange(e) {
    const linkTrackingData = {
      content_section : 'appointment tab',
      link_module : 'appointment availability',
      link_text : 'date changed',
      link_input_field : e.toFormat('yyyy-MM-dd')
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    this.control.setValue(e.toFormat('yyyy-MM-dd'));
    this.dateChanged.emit(e.toFormat('yyyy-MM-dd'));
  }

  validate(_: FormControl) {
    return this.control.valid ? null : { valid: false };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty(('availableDates'))){
      this.dateFilterInput = undefined;
      setTimeout( () => {
        this.dateFilterInput = this.dateFilter;
      }, 0);
    }
  }

  monthChange(date: DateTime) {
    this.monthChanged.emit(date.startOf('month'));
  }
}
