import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionList } from '../../../shared/models/asp.types';
import { SalesAppointmentState } from '../../../store/sales-appointment/sales-appointment.reducer';
import { debounceTime } from 'rxjs/operators';
import {
  selectAppointmentFormAndLookUpData, selectAppointmentFormDetailMap,
  selectVehicleFormImageUrl,
  selectVehicleFormMakeModelYearText
} from '../../../store/sales-appointment/sales-appointment.selectors';
import { Store } from '@ngrx/store';
import { unsubscribeSubscriptions } from '../../../shared/services/util.service';

@Component({
  selector: 'app-confirm-summary',
  templateUrl: './confirm-summary.component.html',
  styleUrls: ['./confirm-summary.component.scss']
})
export class ConfirmSummaryComponent implements OnInit, OnDestroy {

  view = 'summary';
  detailMap: { [key: string]: string };
  imageUrl$: Observable<string>;
  makeModelYear$: Observable<string>;
  detailMap$: Observable<{ [key: string]: string }>;
  private subs: SubscriptionList = {};

  constructor(private readonly salesAppointmentState: Store<SalesAppointmentState>) { }

  ngOnInit(): void {
    this.imageUrl$ = this.salesAppointmentState.select(selectVehicleFormImageUrl);
    this.makeModelYear$ = this.salesAppointmentState.select(selectVehicleFormMakeModelYearText);
    this.subs.detailMapSub = this.salesAppointmentState.select(selectAppointmentFormAndLookUpData)
      .pipe(debounceTime(300))
      .subscribe(d => this.detailMap$ = this.salesAppointmentState.select(selectAppointmentFormDetailMap));
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}


