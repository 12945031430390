import { AuthService } from './../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import * as util from '../shared/services/util.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  inDealerSite = false;
  showBanner = false;

  constructor(private readonly authService: AuthService) {
  }

  ngOnInit() {
    this.inDealerSite = util.inDealerSite();
    this.showBanner = (window as any).ASPHub?.displayBanner;
  }
  logout() {
    this.authService.logoutB2c();
  }
}
