import { AspSideNavService } from './../asp-side-nav/asp-side-nav.service';
import { SubscriptionList } from './../../models/asp.types';
import { Observable } from 'rxjs';
import { inDealerSite, unsubscribeSubscriptions, countryCd } from './../../services/util.service';
import { AuthService } from './../../services/auth.service';

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { assetUrl, environment, navigateToRoute } from '../../../../modules/shared/services/util.service';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { Store } from '@ngrx/store';
import { DealerState } from '../../../../modules/store/dealer/dealer.reducer';
import * as fromSelectors from '../../../../modules/store/dealer/dealer.selectors';

@Component({
  selector: 'app-asp-header',
  templateUrl: './asp-header.component.html',
  styleUrls: ['./asp-header.component.scss']
})
export class AspHeaderComponent implements OnInit, OnDestroy {
  @Input() hideLogoAndText = false;
  account: any;
  user: any;
  brand;
  logoPath: string;
  registerUrl = '';
  inDealerSite = false;
  dealerName$: Observable<string>;
  dealerWebsiteLink: string;
  hamburgerPath: string;
  private subs: SubscriptionList = {};
  isLoginVisible: boolean = true;
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly dealerState: Store<DealerState>,
    private readonly analyticsService: AnalyticsService,
    private readonly aspSideNavService: AspSideNavService) {
    this.registerUrl = environment.registerUrl;
    this.subs.user = this.authService.user.subscribe(user => {
      if (user) {
        this.user = user;
        this.account = user;
      }
    });
    this.isLoginVisible = (countryCd === 'US')?true:false;
  }

  ngOnInit(): void {
    this.inDealerSite = inDealerSite();
    this.brand = environment.brand;
    if (this.brand === 'lexus') {
      this.logoPath = assetUrl('layout/logo/lexus_logo.png');
    } else {
      this.logoPath = assetUrl('layout/Toyota-Logo-Header.svg');
    }
    this.hamburgerPath = assetUrl('icons/svg/hamburger.svg');
    this.dealerName$ = this.dealerState.select(fromSelectors.selectDealerName);
    this.subs.webSiteLinkSub = this.dealerState.select(fromSelectors.selectDealerWebsiteLink).subscribe(websiteLink => {
      this.dealerWebsiteLink = websiteLink;
    });
  }
  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
  }

  login() {
    const linkTrackingData = {
      content_section: 'navigation',
      link_module: 'nav',
      link_text: 'login clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true)
    this.authService.loginB2c();
  }

  signUp() {
    const linkTrackingData = {
      content_section: 'navigation',
      link_module: 'nav',
      link_text: 'sign up clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true)
  }

  logout() {
    const linkTrackingData = {
      content_section: 'navigation',
      link_module: 'nav',
      link_text: 'logout clicked'
    }
    this.analyticsService.trackLink(linkTrackingData)
    this.authService.logoutB2c();
  }

  isMobile() {
    return window.innerWidth <= 600;
  }

  redirectToHome() {
    navigateToRoute('dashboard', '/dashboard', this.router);
  }

  redirectToT3() {
    if (!!this.dealerWebsiteLink) {
      window.location.href = this.dealerWebsiteLink;
    }
  }

  toggleSideNav() {
    this.aspSideNavService.toggle();
  }
}
