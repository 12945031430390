import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AspBannerService {

  hideBanner = new Subject<boolean>();
  showBanner = new Subject<boolean>();
  showCarImage$ = new Subject<boolean>();
  updateBanner = new Subject<string>();

  constructor() {
  }

  hide() {
    this.showBanner.next(false);
  }

  show() {
    this.showBanner.next(true);
  }

  hideCarImage() {
    this.showCarImage$.next(false);
  }

  showCarImage() {
    this.showCarImage$.next(true);
  }

  update(data) {
    this.updateBanner.next(data);
  }
}
