import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IChecklist, IResponse } from '@signal/asp-data-commons';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { selectDealerCode } from '../../store/dealer/dealer.selectors';
import { environment } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  private apiRoot: string;

  constructor(private readonly http: HttpClient,
    private readonly dealerState: Store<DealerState>) {
    this.apiRoot = environment.aspB2cGuestApi;
  }

  getAllChecklist(): Observable<IChecklist[]> {
    return this.dealerState.select(selectDealerCode).pipe(switchMap(dealerCode => {
      return this.http.get(`${this.apiRoot}checklist/${dealerCode}/allChecklist`).pipe(
        map((data: IResponse<IChecklist[]>) => {
          return data.data;
        })
      )
    }));
  }

//   updateChecklist(checklist: IChecklist): Observable<IResponse<IChecklist>> {
//     return this.dealerState.select(selectDealerCode).pipe(switchMap(dealerCode => {
//       return this.http.put(`${this.apiRoot}checklist/${dealerCode}/update`, checklist).pipe(
//         map((data: IResponse<IChecklist>) => {
//           return data;
//         })
//       )
//     }));
//   }

}
