import { ISettingsResponse } from '../../shared/models/settings/settings.model';
import { of } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';

export const dayOfWeek = () => {
  const weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const date = new Date();
  const day = date.getDay();
  return weekdays[day];
}


export class SettingsAdapter {
    static toSettings(response: ISettingsResponse) {
        if (`${response.status.code}` === '200') {
            return of(response.data);
        } else {
            return throwError(`${response.status.code} - ${response.status.messages.join(', ')}`);
        }
    }
}





