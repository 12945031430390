import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf} from '@angular/core';
import {AspBannerService} from '../asp-banner/asp-banner.service';

@Component({
  selector: 'asp-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() exteriorColors = [];

  selectedExtColor = '';

  private control: AbstractControl;

  onChange: any = (value) => { };
  onTouched: any = () => { };

  constructor(@Optional() @Host() @SkipSelf()
  private readonly controlContainer: ControlContainer,
              private readonly aspBanner: AspBannerService) { }
  ngOnInit(): void {
    this.control = this.controlContainer.control.get(this.formControlName);
  }

  onSelectExteriorColor(colorSelected) {
    this.selectedExtColor = colorSelected;
    this.aspBanner.update(this.exteriorColors?.find(x => x.id === colorSelected)?.carImage);
    this.control.setValue(colorSelected);
    this.onChange(colorSelected);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      if (this.exteriorColors && this.exteriorColors.length !== 0){
        this.aspBanner.update(this.exteriorColors.find(x => x.id === value)?.carImage);
      }
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): string {
    return this.control.value;
  }

  set value(value: string) {
    this.selectedExtColor = value;
    this.onChange(value);
    this.onTouched();
  }

  getContrastYIQ(hexcolor) {
    if(hexcolor){
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
    }else{
      return 'white';
    }
  }

  validate(_: FormControl) {
    return this.control.valid ? null : { valid: false };
  }

}
