<form [formGroup]='form' class='location-info'>
    <mat-form-field appearance="outline" class="address1">
        <mat-label>{{ 'appointmentInformation.addressLine' | translate }}1</mat-label>
        <input matInput formControlName='addressLine1' required (blur) = "blurHandler('add_line_1',$event)">
        <mat-error *ngIf="form?.controls?.addressLine1?.invalid">
            {{ 'appointmentInformation.pleaseEnterYourAddress' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="address2">
        <mat-label>{{ 'appointmentInformation.addressLine' | translate }}2</mat-label>
        <input matInput formControlName='addressLine2' (blur) = "blurHandler('add_line_2',$event)">
    </mat-form-field>

    <mat-form-field appearance="outline" class="state">
        <mat-label>{{ 'appointmentInformation.state' | translate }}</mat-label>
        <mat-select formControlName='state' required [disabled]="!form?.value?.isZipValid && (!isZipValid)">
            <mat-option [value]='option' *ngFor="let option of formStates" (click)="selectedOption(form, option,'state') "
            (keydown)="onEnter(form, $event,'state')">
                {{option}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.state?.invalid">
            {{ 'appointmentInformation.pleaseSelectAState' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="city">
        <mat-label>{{ 'appointmentInformation.city' | translate }}</mat-label>
        <mat-select formControlName='city' required [disabled]="!form?.value?.isZipValid && !isZipValid">
            <mat-option [value]='option' *ngFor="let option of filteredCities" (keydown)="onEnter(form, $event,'city')"
            (click)="selectedOption(form, option,'city') ">
                {{ option }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.city?.invalid">
            {{ 'appointmentInformation.pleaseSelectACity' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class='pincode'>
        <mat-label>{{ 'transportation.zipcode' | translate }}</mat-label>
        <input matInput formControlName='zip' (input)="inputZipChange($event)" aspNumberOnly minlength="5" maxlength="5" required (blur) = "blurHandler('zip',$event)">
        <div class = "tick" *ngIf="form?.value?.isZipValid && !isCustomerAddress" [ngClass]="{'selected' : form?.value?.isZipValid}" ></div>
        <mat-error *ngIf="form.controls['zip']?.errors?.remoteRequired ">{{ 'appointmentInformation.pleaseEnterValidRemoteZipcode' | translate }}</mat-error>
        <mat-error *ngIf="form.controls['zip']?.errors?.required ">{{ 'appointmentInformation.pleaseEnterValidZipcode' | translate }}</mat-error>
    </mat-form-field>
</form>