import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechAppointmentComponent } from './tech-appointment.component';
import { TechAppointmentRoutingModule } from './tech-appointment-routing.module';
import {SharedModule} from '../shared/shared.module';
import { ConfirmInfoComponent } from './confirm-info/confirm-info.component';



@NgModule({
  declarations: [TechAppointmentComponent, ConfirmInfoComponent],
  imports: [
    CommonModule,
    TechAppointmentRoutingModule,
    SharedModule
  ]
})
export class TechAppointmentModule { }
