import { Injectable } from '@angular/core';
import {  Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResolveGuard implements Resolve <any>{
  constructor(private readonly translate : TranslateService){

  }
 resolve(){
return  forkJoin([this.translate.get('COUNTRYTRANSLATION.estimatedMiles'),this.translate.get('welcome.estimatedMiles') ])

 }
  
}
