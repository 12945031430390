<div class="row w-100 no-gutters px-4">
    <div class="col-12 justify-content-end">
        <div class="close-wrapper" (click)="closedSideNav()">
            <img alt="close icon" [src]="closeImagePath" class="close-btn-img">
        </div>
    </div>
    <div class="col-12 px-3 mt-1">
        <img class="logo-image" [src]="logoPath" alt="Logo image">
    </div>
    <div class="col-12 px-3 mt-3 mb-2 menu-item dealer-name">
        <span>{{dealerName$ | async}}</span>
    </div>
    <div class="col-12 px-3 mt-2 menu-item">
        <span class="menu-underline link" (click)="redirectToT3()">{{'navigation.dealerHome' | translate}}</span>
    </div>
    <app-splitter class="w-100 p-2 px-3" [config]="'horizontal 100% 0px 0px 0px'"></app-splitter>
    <div class="col-12 px-3 mt-2 menu-item">
        <span class="menu-underline link" (click)="redirectToHome();closedSideNav()">{{'navigation.home' | translate}}</span>
    </div>
    <app-splitter class="w-100 p-2 px-3" [config]="'horizontal 100% 0px 0px 0px'"></app-splitter>
    <div class="col-12 px-3 my-3 menu-item">
        <span *ngIf="dealerAddress$ | async" class="font-book">
            {{ (dealerAddress$ | async).addressLine1 + ',' }}
            <br />
            {{ (dealerAddress$ | async).city + ', ' +
            (dealerAddress$ | async).state + ' ' + (dealerAddress$ | async).zip}}
        </span>
    </div>
    <div class="col-12 px-3 my-2 menu-item">
        <span class="menu-underline link" (click)="onNavigateToGmap()">{{'navigation.getDirections' | translate}}</span>
    </div>
    <div class="col-12 px-3 my-2 menu-item">
        <span class="menu-underline font-book link" (click)="callPhone()">{{dealerPhone | phone}}</span>
    </div>

</div>