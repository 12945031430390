import { Observable } from 'rxjs';
import { DealerState } from './../../store/dealer/dealer.reducer';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import * as fromSelectors from '../../store/dealer/dealer.selectors';

@Component({
  selector: 'app-delivery-checklist',
  templateUrl: './delivery-checklist.component.html',
  styleUrls: ['./delivery-checklist.component.scss']
})
export class DeliveryChecklistComponent implements OnInit {

  dealerPdiChecklist$: Observable<string[]>;

  constructor(private readonly dealerState: Store<DealerState>) { }

  ngOnInit(): void {
    this.dealerPdiChecklist$ = this.dealerState.select(fromSelectors.selectPdiChecklist);
  }

}
