import * as fromReducer from './service-appointment.reducer';
import {
  IEditDetails,
  IFooterSummary,
  IServiceAppointmentForm,
  IServiceRequestForm,
  IToyotaLexusModelDetails,
  IVehicleForm
} from './service-appointment.reducer';
import * as fromAdapter from './service-appointment.adapter';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FeatureKeys} from '../app.store';
import {IAsyncData, IAvailability, IAvailabilityOptions, IOpcodeResponse, IServiceAppointment, ITransport, IServiceRequest, IDealerInfo} from '@signal/asp-data-commons';
import {dealerDetailsData, selectDealerAddress, selectServiceShowDuration, selectServiceShowPricing, selectSlotDuration} from '../dealer/dealer.selectors';


/* NgRx Selectors  */

/* To select the service appointment state from the app state */
export const selectServiceAppointmentFeature = createFeatureSelector<fromReducer.ServiceAppointmentState>(
  FeatureKeys.SERVICE_APPOINTMENT
);

/* To select the appointmentForm from the service appointment state */
export const selectAppointmentForm = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.appointmentForm
);

/* To select the currentStep from the service appointment state */
export const selectCurrentStep = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.appointmentForm.currentStep
);

/* To select the isEditMode flag from the service appointment state */
export const selectIsEditMode = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.appointmentForm.isEditMode
);

/* To select the isRedirected flag from the service appointment state */
export const selectIsRedirected = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.appointmentForm.isRedirected
);

/* To select the edit mode details from the service appointment state */
export const selectEditDetails = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.appointmentForm.editDetails
);

/* To select the 'appointment' from the slice 'editDetails' */
export const selectAppointmentFromEditDetails = createSelector(
  selectEditDetails,
  (editDetails: fromReducer.IEditDetails) => editDetails.appointment
);

/* To select the 'vehicle' as array from the slice 'editDetails.appointment' */
export const selectAppointmentVehicleAsArray = createSelector(
  selectAppointmentFromEditDetails,
  (appointment: IServiceAppointment) => [appointment.vehicle]
);

export const selectVehicleFormAndOriginalAppointment = createSelector(
  selectAppointmentForm,
  selectEditDetails,
  (appointment: fromReducer.IServiceAppointmentForm, editDetails: IEditDetails) =>
    ({vehicleForm: appointment.vehicleForm, originalAppt: editDetails?.appointment})
);
/* ---------- Vehicle form selectors ------------------------- */
/* To select the vehicleForm from the service appointment form */
export const selectVehicleForm = createSelector(
  selectAppointmentForm,
  (appointment: fromReducer.IServiceAppointmentForm) => appointment.vehicleForm
);

/* To select the isOwnVehicle from the vehicle form */
export const selectVehicleFormIsOwnVehicle = createSelector(
  selectVehicleForm,
  (vehicleForm: fromReducer.IVehicleForm) => vehicleForm.isOwnVehicle
);

/* To select the imageUrl from the vehicle form */
export const selectVehicleFormImageUrl = createSelector(
  selectVehicleForm,
  (vehicleForm: fromReducer.IVehicleForm) => vehicleForm.imageUrl
);

/* To select the vin from the vehicle form */
export const selectVehicleFormVin = createSelector(
  selectVehicleForm,
  (vehicleForm: fromReducer.IVehicleForm) => vehicleForm.vin
);

/* To select the make model year text from the vehicle form */
export const selectVehicleFormMakeModelYearText = createSelector(
  selectVehicleForm,
  (vehicleForm: fromReducer.IVehicleForm) => fromAdapter.vehicleToMakeModelYearTextProjector(vehicleForm)
);

/* To select the mileage from the vehicle form */
export const selectVehicleFormMileage = createSelector(
  selectVehicleForm,
  (vehicleForm: fromReducer.IVehicleForm) => vehicleForm.mileage
);

/* ---------- DATA: Model year and models selectors ------------------------- */
/* To select the model year and models slice from the service appointment state */
export const selectModelYearAndModels = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.modelYearAndModels
);

/* To select the model year and models 'data' from the slice 'modelYearAndModels' */
export const selectModelYearAndModelsData = createSelector(
  selectModelYearAndModels,
  (modelYearAndModels: IAsyncData<IToyotaLexusModelDetails>) => modelYearAndModels.data
);

/* To select the model year and models 'Loading' from the slice 'modelYearAndModels' */
export const selectModelYearAndModelsLoading = createSelector(
  selectModelYearAndModels,
  (modelYearAndModels: IAsyncData<IToyotaLexusModelDetails>) => modelYearAndModels.loading
);

/* ---------- DATA: Service requests selectors ------------------------- */
/* To select the service requests slice from the service appointment state */
export const selectServiceRequests = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.serviceRequests
);

/* To select the service requests 'data' from the slice 'serviceRequests' */
export const selectServiceRequestsData = createSelector(
  selectServiceRequests,
  (serviceRequests: IAsyncData<IOpcodeResponse>) => serviceRequests.data
);

/* To select the 'appointmentForm.recallCodes' from the slice 'appointmentForm' */
export const selectRecallCodes = createSelector(
  selectAppointmentForm,
  (appointmentForm: fromReducer.IServiceAppointmentForm) => {
    if (appointmentForm.serviceForm.selectedServices.filter(t => t.details?.recommended?.isSSCRecall).length > 0) {
      return appointmentForm.recallCodes
    }
    return undefined;
  }
);

/* To select the recommended service requests from the slice 'serviceRequests' */
export const selectRecommendedServiceOpcodes = createSelector(
  selectServiceRequestsData,
  (opcodeResponse: IOpcodeResponse) => opcodeResponse.recommended
);

/* To select the recommended service requests available or not from the slice 'serviceRequests' */
export const selectRecommendedServiceOpcodesAvailable = createSelector(
  selectServiceRequestsData,
  (opcodeResponse: IOpcodeResponse) => !!opcodeResponse.recommended?.length
);

/* To select the other service requests from the slice 'serviceRequests' */
export const selectOtherServiceOpcodes = createSelector(
  selectServiceRequestsData,
  (opcodeResponse: IOpcodeResponse) => [...(opcodeResponse.maintenance || []), ...(opcodeResponse.complaint || []), ...(opcodeResponse.dms || [] )]
);

/* To select the other service requests from the slice 'serviceRequests' */
export const selectOtherServiceOpcodesAvailable = createSelector(
  selectServiceRequestsData,
  (opcodeResponse: IOpcodeResponse) => !!(opcodeResponse.maintenance?.length || opcodeResponse.complaint?.length || opcodeResponse.dms?.length)
);

/* To select the service requests 'Loading' from the slice 'serviceRequests' */
export const selectServiceOpcodesLoading = createSelector(
  selectServiceRequests,
  (serviceRequests: IAsyncData<IOpcodeResponse>) => serviceRequests.loading
);

/* To select the service requests 'Loading' from the slice 'serviceRequests' */
export const selectServiceRequestsLoaded = createSelector(
  selectServiceRequests,
  (serviceRequests: IAsyncData<IOpcodeResponse>) => serviceRequests.loaded
);

/* To select the service requests 'recommendationBasedOn mileage' from the slice 'serviceRequests' */
export const selectServiceOpcodesRecommendedMileage = createSelector(
  selectServiceRequestsData,
  (opcodeResponse: IOpcodeResponse) => opcodeResponse?.recommendationBasedOn?.roundedOffMileage || 0
);

/* ---------- Available mileages selectors ------------------------- */
/* To select the available mileages slice */
export const selectAvailableMileages = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.availableMileages
);

/* To select 'loading' from the available mileages slice */
export const selectAvailableMileagesLoading = createSelector(
  selectAvailableMileages,
  (availableMileages: IAsyncData<number[]>) => availableMileages.loading
);

/* To select 'loaded' from the available mileages slice */
export const selectAvailableMileagesLoaded = createSelector(
  selectAvailableMileages,
  (availableMileages: IAsyncData<number[]>) => availableMileages.loaded
);


/* To select 'data' from the available mileages slice */
export const selectAvailableMileagesData = createSelector(
  selectAvailableMileages,
  (availableMileages: IAsyncData<number[]>) => availableMileages.data
);

/* ---------- Service form selectors ------------------------- */
/* To select the 'serviceForm' from the slice 'appointmentForm' */
export const selectServiceForm = createSelector(
  selectAppointmentForm,
  (appointmentForm: fromReducer.IServiceAppointmentForm) => appointmentForm.serviceForm
);

/* To select the 'serviceForm.selectedServices' from the slice 'appointmentForm' */
export const selectServiceFormSelectedServices = createSelector(
  selectServiceForm,
  (serviceForm: fromReducer.IServiceRequestForm) => serviceForm.selectedServices
);

/* To select the opcode array from 'serviceForm.selectedServices' from the slice 'appointmentForm' */
export const selectServiceOpcodes = createSelector(
  selectServiceFormSelectedServices,
  (selectedService: IServiceRequest[]) => selectedService.map(x =>  x.opcode)
);

/* To select the 'serviceForm' & 'serviceRequests.data' from the state */
export const selectServiceFormAndServiceRequestsData = createSelector(
  selectServiceForm,
  selectServiceRequestsData,
  (serviceForm: fromReducer.IServiceRequestForm, serviceRequestData: IOpcodeResponse) => ({ serviceForm, serviceRequestData })
);

/* ---------- Availability form selectors ------------------------- */
/* To select the 'availabilityForm' from the slice 'appointmentForm' */
export const selectAvailabilityForm = createSelector(
  selectAppointmentForm,
  (appointmentForm: fromReducer.IServiceAppointmentForm) => appointmentForm.availabilityForm
);


export const selectedTransport = createSelector(
  selectAppointmentForm,
  (appointmentForm: fromReducer.IServiceAppointmentForm) => appointmentForm.availabilityForm.transport
);

/* To select the 'availabilityForm.isRemoteLocation' from the slice 'appointmentForm' */
export const selectAvailabilityFormIsRemoteLocation = createSelector(
  selectAvailabilityForm,
  (availabilityForm: fromReducer.IAvailabilityForm) => fromAdapter.transportToIsRemoteLocationProjector(availabilityForm)
);

export const selectAvailabilityFormAdvisorId = createSelector(
  selectAvailabilityForm,
  (availabilityForm: fromReducer.IAvailabilityForm) => availabilityForm.availability.advisorId
);

export const selectAvailabilityFormRemoteZipCode = createSelector(
  selectAvailabilityForm,
  (availabilityForm: fromReducer.IAvailabilityForm) => availabilityForm.remoteZipCode
);

/* ---------- DATA: Availability options selectors ------------------------- */
/* To select the availability options slice from the service appointment state */
export const selectAvailabilityOptions = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.availabilityOptions
);

/* To select the availability options data slice from the service appointment state */
export const selectAvailabilityOptionsLoading = createSelector(
  selectAvailabilityOptions,
  (availabilityOptions: IAsyncData<IAvailabilityOptions>) => availabilityOptions.loading
);

/* To select the availability options data slice from the service appointment state */
export const selectAvailabilityOptionsData = createSelector(
  selectAvailabilityOptions,
  (availabilityOptions: IAsyncData<IAvailabilityOptions>) => availabilityOptions.data
);

/* To select the appointmentForm & availability look up from the service appointment state */
export const selectAppointmentFormAndLookUpData = createSelector(
  selectAppointmentForm,
  selectAvailabilityOptionsData,
  (appointmentForm: IServiceAppointmentForm, availabilityOptions: IAvailabilityOptions) =>
    ({ appointmentForm, availabilityOptions })
);

/* To select appointment form and derive detailMap */
export const selectAppointmentFormDetailMap = createSelector(
  selectAppointmentFormAndLookUpData,
  selectServiceShowPricing,
  dealerDetailsData,
  (data: { appointmentForm: IServiceAppointmentForm, availabilityOptions: IAvailabilityOptions },
   showPricing: boolean,
   dealerDetails: IDealerInfo) =>
    fromAdapter.appointmentFormToDetailMap(data, showPricing, dealerDetails)
);


/* ---------- DATA: Availability selectors ------------------------- */
/* To select the availability options slice from the service appointment state */
export const selectAvailability = createSelector(
  selectServiceAppointmentFeature,
  (state: fromReducer.ServiceAppointmentState) => state.availability
);

/* To select the availability loading from the service appointment state */
export const selectAvailabilityLoading = createSelector(
  selectAvailability,
  (availability: IAsyncData<IAvailability>) => availability.loading
);

/* To select the availability options data slice from the service appointment state */
export const selectAvailabilityData = createSelector(
  selectAvailability,
  (availability: IAsyncData<IAvailability>) => availability.data
);


/* ---------- DATA: Transport options selectors ------------------------- */
/* To select the transport options slice from the service appointment state */
export const selectTransport = createSelector(
  selectAvailabilityOptions,
  (availabilityOptions: IAsyncData<IAvailabilityOptions>) => availabilityOptions.data.transport
);

export const selectTransportOptions = createSelector(
  selectTransport,
  (transport: ITransport) => transport?.transportOptions
);

export const selectPMAZipCodes = createSelector(
  selectTransport,
  (transport: ITransport) => transport?.includedRemoteZipCodes
);

export const selectPMAZipCodesAndRemoteIndicator = createSelector(
  selectPMAZipCodes,
  selectAvailabilityFormIsRemoteLocation,
  (pmaZipCodes: string[], isRemote: boolean ) => ({pmaZipCodes, isRemote})
);

export const selectAvailabilityOptionsAndAddress = createSelector(
  selectAvailabilityOptions,
  selectDealerAddress,
  (availabilityOptions: IAsyncData<IAvailabilityOptions>, address: string) => ({ availabilityOptions, address })
);

/* --------------- Footer summary --------------------------------- */
/* To select the appointment vehicle details and form vehicle summary */
export const selectAppointmentVehicleSummary = createSelector(
  selectVehicleForm,
  (vehicleForm: IVehicleForm) => fromAdapter.appointmentVehicleFormToSummaryProjector(vehicleForm)
);

/* To select the appointment service requests and form service request summary */
export const selectAppointmentServiceRequestSummary = createSelector(
  selectServiceForm,
  selectServiceShowPricing,
  selectServiceShowDuration,
  selectAvailabilityFormIsRemoteLocation,
  selectAvailability,
  selectAppointmentForm,
  selectSlotDuration,
  (serviceForm: IServiceRequestForm, showPricing: boolean, showTime: boolean, transportation: boolean,availability: IAsyncData<IAvailability>,appointmentForm: IServiceAppointmentForm, duration: number) =>
    fromAdapter.appointmentServiceRequestFormToSummaryProjector(serviceForm, showPricing, showTime, transportation,availability,appointmentForm, duration)
);
export const selectStatesAndCities = createSelector(
  selectServiceAppointmentFeature,
  (state:fromReducer.ServiceAppointmentState)=>state.stateCitiesFromZip.data
);
export const selectStatesAndCitiesBasedOnZip = createSelector(
  selectStatesAndCities,
  (zipCodeData, props: { zipCode: string })=>zipCodeData?.find(x=>x.postalCode===props.zipCode)
);
/* To select the appointment details and form footer summary array */
export const selectServiceAppointmentFooterSummaries = createSelector(
  selectAppointmentVehicleSummary,
  selectAppointmentServiceRequestSummary,
  (vehicleSummary: IFooterSummary[], serviceRequestSummary: IFooterSummary[]) => [...vehicleSummary, ...serviceRequestSummary]
);
export const selectFactoryRecommendedService = createSelector(
  selectServiceAppointmentFeature,
  (state:fromReducer.ServiceAppointmentState)=>state.factoryRecommendedService.data
);
