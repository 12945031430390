import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {ICalendarEvent, NgAddToCalendarService} from '@trademe/ng-add-to-calendar';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.scss']
})
export class AddToCalendarComponent implements OnInit {
  public appleCalendarEventUrl: SafeUrl;
  public googleCalendarEventUrl: SafeUrl;
  public office365CalendarEventUrl: SafeUrl;
  public outlookCalendarEventUrl: SafeUrl;
  public yahooCalendarEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) private readonly data: any,
              private readonly addToCalendar: MatBottomSheetRef<AddToCalendarComponent>,
              private readonly addToCalendarService: NgAddToCalendarService,
              private readonly sanitizer: DomSanitizer) {
    this.newEvent = this.data;
  }

  ngOnInit(): void {
    this.appleCalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.iCalendar, this.newEvent)
    );
    this.googleCalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.google, this.newEvent)
    );
    this.office365CalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.outlookLive, this.newEvent)
    );
    this.outlookCalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.outlook, this.newEvent)
    );
    this.yahooCalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.yahoo, this.newEvent)
    );
  }

}
