export const environment = {
  production: true,
  brand: "toyota",
  clientId: "8c2022a0-279c-4837-9206-d9d013087175",
  redirectUri: "https://qa.scheduling.toyota.com/",
  spaHost: "https://qa.scheduling.toyota.com/",
  postLogoutRedirectUri: "https://qa.scheduling.toyota.com/",
  b2cSignInPolicy: "B2C_1_SignupOrSigninToyota",
  authority: "https://qa.driverslogin.com",
  tenant: "tmnab2cqa.onmicrosoft.com",
  registerUrl: "https://www.toyota.com/owners/register",
  lexusRegisterUrl: "https://drivers.lexus.com/lexusdrivers/account/register",
  toyotaRegisterUrl: "https://www.toyota.com/owners/register",
  efcApi: "https://asp-catalog-api.qa.dealer.toyota.com/proxy-external/efc/vehiclecontent/v1/",
  dcsb2oApi: "https://asp-catalog-api.qa.dealer.toyota.com/proxy-external/dcs-b2o/",
  dcsb2cApi: "https://asp-appointment-api.qa.dealer.toyota.com/proxy-external/dcs-b2c/",
  aspAppointmentApi: "https://asp-b2c-api.qa.dealer.toyota.com/public/",
  aspAvailabilityApi: "https://asp-b2c-api.qa.dealer.toyota.com/public/",
  aspCatalogApi: "https://asp-b2c-api.qa.dealer.toyota.com/public/",
  aspB2cGuestApi: "https://asp-b2c-guest-api.qa.dealer.toyota.com/",
  xBrand: "T",
  xClient: "TO-WEB",
  xVersion: "1.0",
  frClientId: "appointment-portal",
  frDgId: "7ac6cfd9-dc3d-4a79-8eea-9bae4857a687",
  frLoginUrl: "https://api.stage.dg.toyota.com/garage/v2/auth/login/start",
  frLogoutUrl: "https://api.stage.dg.toyota.com/garage/v2/auth/logout/start",
  loginEnvironment: "tmnab2cqa",
  frEnvironment: "stage",
  analyticsTaggingUrl: "https://nexus.toyota.com/toyotanational/t-scheduler-stage/Bootstrap.js",
  useEOSForDCS: true,
  showDelivery: true
};
