import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { assetUrl } from './util.service';

@Injectable()
export class CustomTranslateFactoryService implements TranslateLoader  {
    response = {};

    constructor(private readonly httpClient: HttpClient) {}
    getTranslation(localization: string): Observable<any> {
      if (localization.indexOf('-') === -1) {
        console.error('Invalid localization');
        return of(null);
      } else {
        const localizationTemp = localization.split('-');
        const lang = localizationTemp[0];
        const locale = localizationTemp[1];
        return new Observable((observer) => {
            this.downloadTranslation(lang, locale).subscribe(
              (data) => {
                observer.next(data);
              }
            );
          
  
        });
      }
    }
    private downloadTranslation(lang: string, locale: string) {
      return combineLatest([
        this.getLanguageFile(lang),
        this.getLocaleFile(locale),
      ]).pipe(
        catchError(error => {
          console.error('Unable to load language files:', lang, locale);
          console.error(error);
          return of(null);
        }),
        map(
          (data: any[]) => {
            if (data && data.length > 0) {
              const out = { ...data[0], ...data[1] };
              return out;
            }
            return null;
          })
      );
    }
    private getLanguageFile(lang: string): Observable<any> {
      if (this.response[lang]) {
        return of(this.response[lang]);
      }
      const ts = new Date().getTime().toString();
      return this.httpClient.get(assetUrl(`i18n/${lang}.json`)).pipe(
        map((data) => {
          this.response[lang] = data;
          return data;
        })
      );
    }
    private getLocaleFile(locale: string) {
      if (this.response[locale]) {
        return of(this.response[locale]);
      }
      const ts = new Date().getTime().toString();
      return this.httpClient.get(assetUrl(`i18n/${locale}.json`)).pipe(
        map((data) => {
          this.response[locale] = data;
          return data;
        })
      );
    }
}
