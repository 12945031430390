<div class="your-container">
  <div class="form-header">
    <div class="step-title">{{'appointmentInformation.contactInformation'| translate}}
    </div>
    <div *ngIf='!signedIn && isLoginVisible' class='already-customer'>{{'appointmentInformation.alreadyACustomer'| translate}}
      <span class='login' (click)='login()'>{{'welcome.logIn'| translate}}</span>
    </div>
  </div>
  <div class='your-info'>
    <div class='contact-form'>
      <form [formGroup]='step' class='contact-info' appConnectStore="customerForm">
        <mat-form-field appearance="outline" class="firstname">
          <mat-label>{{'appointmentInformation.firstName'| translate}}</mat-label>
          <input matInput formControlName='firstName' required (blur) = "blurHandler('firstName',$event)">
          <mat-error *ngIf="f.firstName?.invalid">{{'appointmentInformation.pleaseEnterFirstName'| translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="lastname">
          <mat-label>{{'appointmentInformation.lastName'| translate}}</mat-label>
          <input matInput formControlName='lastName' required (blur) = "blurHandler('lastName',$event)">
          <mat-error *ngIf="f.lastName?.invalid">{{'appointmentInformation.pleaseEnterLastName'| translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mob-num">
          <mat-label>{{'appointmentInformation.mobileNumber'| translate}}</mat-label>
          <ngx-mat-intl-tel-input [onlyCountries]="['us', 'mx', 'ca']" [preferredCountries]="[preferredCountries]"
            [enablePlaceholder]="true" [enableSearch]="false" format="national" formControlName="phoneNumber" required (blur) = "blurHandler('contact',$event)">
          </ngx-mat-intl-tel-input>
          <mat-error *ngIf="f.phoneNumber?.invalid">{{'appointmentInformation.pleaseEnterValidMobileNumber'| translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="email">
          <mat-label>{{'appointmentInformation.email'| translate}}</mat-label>
          <input matInput formControlName='email' (blur) = "blurHandler('email',$event)">
          <mat-error *ngIf="f.email?.invalid">{{'appointmentInformation.pleaseEnterAValidEmail'| translate}}</mat-error>
        </mat-form-field>
      </form>
      <app-splitter *ngIf="(transport === 'REMOTE' || transport === 'PICKUP')" class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
      <section >
        <div *ngIf="(transport === 'REMOTE' || transport === 'PICKUP')" class='location-header'>
          {{'appointmentInformation.locationForVehiclePickup'| translate}}
        </div>


          <!-- pickup address details-->
          <app-shared-address *ngIf="(transport === 'REMOTE' || transport === 'PICKUP')" [form]="step.controls.pickUpAddress"
          [zipCodes]="remoteValidZipcodes"
          (addressChange)="updateAddressFields($event,'pickUpAddress')"
          (zipChange)="zipChange($event,'pickUpAddress')"
          [formStates]="formStates['pickUpAddress'].states"
          [formcities]="formcities['pickUpAddress'].cities.statecities"
          [remotelocation]="this.step.controls.isRemoteLocation.value">
          </app-shared-address>

        <app-splitter *ngIf= "(transport === 'DELIVERY' || transport === 'REMOTE')" class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

        <div *ngIf= "(transport === 'REMOTE' || transport === 'DELIVERY')" class='location-header'>
          {{'appointmentInformation.locationForVehicleDrop-Off'| translate}}
        </div>
        <div *ngIf= "(transport === 'REMOTE')" class='pickup-header'>
          <div class="fltLeft">{{'appointmentInformation.sameAsPickupLocation'| translate}}</div>
          <div class="fltRight">
            <app-onofswitch [switchInput]='isPickUpLocationSame'
              (switchChange)="updateSwitchValues($event, 'pickuplocationsame')" [customText]="'dashboardAppointments.yes/dashboardAppointments.no'">
            </app-onofswitch>
          </div>
        </div>
          <!-- dropoff address details -->
          <div *ngIf="(transport === 'DELIVERY')" >
          <app-shared-address [form]="step.controls.pickUpAddress"
          [zipCodes]="remoteValidZipcodes"
          (addressChange)="updateAddressFields($event,'pickUpAddress')"
          [formStates]="formStates['pickUpAddress'].states"
          [formcities]="formcities['pickUpAddress'].cities.statecities"
          (zipChange)="zipChange($event,'pickUpAddress')"
          [remotelocation]="this.step.controls.isRemoteLocation.value">
          </app-shared-address>
         </div>
           <!--this is for remote pickup and dropoff scenario-->
           <div *ngIf="!isPickUpLocationSame && (transport === 'REMOTE') ">
            <app-shared-address  [form]="step.controls.dropOffAddress" 
            [zipCodes]="remoteValidZipcodes" 
            (addressChange)="updateAddressFields($event,'dropOffAddress')"

            [formStates]="formStates['dropOffAddress'].states"
            [formcities]="formcities['dropOffAddress'].cities.statecities"
            (zipChange)="zipChange($event,'dropOffAddress')"
            [remotelocation]="this.step.controls.isRemoteLocation.value">
            </app-shared-address>
           </div>
        <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>


      </section>
  <!-- customer address details-->
  <ng-container *ngIf="isCustomerAddressMandate">
    <section>
      <div class='location-header'>
        {{'appointmentInformation.homeLocation'| translate}}
      </div>
      <div *ngIf="isValidRemoteZip">
        <div *ngIf="isRemoteLocation">
          <div class='pickup-header'>
            <div class="fltLeft">{{'appointmentInformation.sameAsPickupLocation'| translate}}</div>
          <div class="fltRight">
          <app-onofswitch [switchInput]='isHomeLocationSame'
            (switchChange)="updateSwitchValues($event, 'homelocationsame')" [customText]="'dashboardAppointments.yes/dashboardAppointments.no'">
          </app-onofswitch>
          </div>
        </div>
        </div>
      </div>
      <ng-container *ngIf=" !isHomeLocationSame">
      <app-shared-address [form]="step.controls.customerAddress"
      [zipCodes]="remoteValidZipcodes"
      [isCustomerAddress]="isCustomerAddressMandate"
      (zipChange)="zipChange($event,'customerAddress')"
      [formStates]="formStates['customerAddress'].states"
      [formcities]="formcities['customerAddress'].cities.statecities"
      [remotelocation]="this.step.controls.isRemoteLocation.value">
      </app-shared-address>
      </ng-container>
    </section>
  </ng-container>
      <div class='comments-header'>
        {{'appointmentInformation.additionalComments'| translate}}
      </div>
      <form [formGroup]='step' class='comments-info'>
        <mat-form-field appearance="outline">
          <textarea matInput rows='5' formControlName='appointmentComments' (blur) = "blurHandler('comments',$event)"></textarea>
        </mat-form-field>
      </form>
      <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

      <div *ngIf="(amenities$ | async)?.length > 0">
        <div class='comments-header'>{{'appointmentInformation.amenities'| translate}}</div>
        <div class='content-size'>
          <ol class="pl-4">
            <li *ngFor="let amenity of amenities$ | async">{{amenity}}</li>
          </ol>
        </div>
      </div>

    </div>
    <div class='summary'>
      <app-confirmation [view]="view" [detailMap]="detailMap$ | async" [carImage]="imageUrl$ | async"
        [makeModelYear]="makeModelYear$ | async">
      </app-confirmation>
    </div>
  </div>
</div>
