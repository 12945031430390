import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DeliveryFormRoutingModule} from './delivery-form-routing.module';
import {DeliveryFormComponent} from './delivery-form/delivery-form.component';
import {DeliveryAppointmentComponent} from './delivery-appointment/delivery-appointment.component';
import {DeliveryChecklistComponent} from './delivery-checklist/delivery-checklist.component';
import {VehicleDeliveryComponent} from './vehicle-delivery/vehicle-delivery.component';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {DeliveryConfirmComponent} from './delivery-confirm/delivery-confirm.component';


@NgModule({
  declarations: [DeliveryFormComponent, DeliveryAppointmentComponent, DeliveryChecklistComponent, VehicleDeliveryComponent, DeliveryConfirmComponent],
  providers:[],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DeliveryFormRoutingModule
  ]
})
export class DeliveryFormModule { }
