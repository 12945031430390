import {Component, OnInit} from '@angular/core';
import {AspBannerService} from '../../shared/components/asp-banner/asp-banner.service';
import {DateTime} from 'luxon';
import {TimeSlot} from '../../shared/models/sales-appointments/time-slot';
import {AvailabilityOptionsData} from '../../../assets/sampledata/appt-availability-options';
import {AppointmentService} from '../../shared/services/asp/appointment.service';
import {ActivatedRoute} from '@angular/router';
import {AppointmentType, IDeliveryAppointment} from '@signal/asp-data-commons';
import {TranslateService} from '@ngx-translate/core';
import * as util from '../../shared/services/util.service'

@Component({
  selector: 'app-delivery-confirm',
  templateUrl: './delivery-confirm.component.html',
  styleUrls: ['./delivery-confirm.component.scss']
})
export class DeliveryConfirmComponent implements OnInit {
  view = 'confirm';
  appointmentType = AppointmentType.DELIVERY;
  appointment: IDeliveryAppointment;
  makeModelYear: string;
  detailMap: { [key: string]: string };
  email: string;
  id: string;
  response: string;
  timeSlots: TimeSlot[] = AvailabilityOptionsData.timeSlots;

  constructor(private readonly bannerService: AspBannerService,
              private readonly appointmentService: AppointmentService,
              private readonly route: ActivatedRoute,
              private readonly translate: TranslateService) {

    this.route.queryParams.subscribe(params => {
      this.email = params.email;
      this.id = params.id;
      if (this.id) {
        this.appointmentService.get(AppointmentType.DELIVERY, this.id).subscribe(res => {
          if (res && res.data) {
            this.appointment = res.data;
            this.response = res.data.appointmentStatus;
            this.setDetailMap(res.data);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.bannerService.hide();
  }

  setDetailMap(appointmentDetails) {
    const vehicle = appointmentDetails.vehicle;
    const customer = appointmentDetails.customer;

    let address = '';
    let displayText = `${appointmentDetails.dealer.address.addressLine1} ${appointmentDetails.dealer.address.city}, ${appointmentDetails.dealer.address.state} ${appointmentDetails.dealer.address.zip}`;

    if (appointmentDetails.transportationTypeCode === 'REMOTE') {
      const custAdd = customer.otherAddress ? customer.otherAddress : customer.address;
      address = custAdd.addressLine1 + ', ' + (custAdd.addressLine2 ? custAdd.addressLine2 + ', ' : '') + custAdd.city + ', ' + custAdd.state + ' - ' + custAdd.zip;
      displayText = `${this.translate.instant('testDrive.weWillBringTheVehicleTo')} \n ${address}`
    }
    const advisorName = appointmentDetails.advisor.displayName;
    this.makeModelYear =`${vehicle.year || ''} ${util.titleCase(vehicle.make) || ''} ${vehicle.model || ''}`;
    this.detailMap = {
      'selectService.time': DateTime.fromISO(appointmentDetails.appointmentStartDate).toFormat('MMM dd') + ' at ' + appointmentDetails.timeSlotId,
      'selectService.vehicle': this.makeModelYear,  // + "\n1230ZESAD91191",
      'transportation.consultant': advisorName,
      'transportation.location' : displayText
    };
  }

}
