import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _  from 'lodash';
@Component({
  selector: 'app-onofswitch',
  templateUrl: './onofswitch.component.html',
  styleUrls: ['./onofswitch.component.scss']
})
export class OnofswitchComponent implements OnInit {

  @Input() switchInput:boolean; 
  @Input() customText:string;
  uniqueId:any
  @Output() switchChange: EventEmitter<any> = new EventEmitter();
  onText : string = 'common.ON';
  offText: string = 'common.OFF';
  status:boolean = false;
  currentText:string; 
  
  constructor() {
    this.uniqueId =  _.uniqueId('onoffswitch_');
    
   }

  ngOnInit(): void {
    if(this.customText){
      this.decodeCustomText();
      this.status=this.switchInput;
      this.currentText=this.onText;
    }
    else
   { 
     this.currentText = this.offText;
    }
  }

  ngOnChanges(changes) {
    this.switchInput = changes.switchInput.currentValue;
  }

  decodeCustomText(){
    const customTextArray = this.customText.split('/');
    this.onText = customTextArray[0];
    this.offText = customTextArray[1];
  }

  valueChange(event:boolean){
    this.currentText = this.offText;
    if(event){
      this.currentText = this.onText;
    }
    this.status = event;
    this.switchChange.emit(event); 
  }

}
