import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style} from '@angular/animations';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { Store } from '@ngrx/store';
import { ServiceAppointmentState } from '../../../store/service-appointment/service-appointment.reducer';
import {  TriggerLoaderFail } from '../../../store/service-appointment/service-appointment.actions';

@Component({
  selector: 'app-mileage-carousel',
  templateUrl: './mileage-carousel.component.html',
  styleUrls: ['./mileage-carousel.component.scss']
})
export class MileageCarouselComponent implements OnChanges {
  @Input() mileages: number[];
  @Input() selectedMileage: number;
  @Input() isVisible: boolean;
  @Output() mileageChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('mileageDiv', {static: false}) mileageDiv: ElementRef;
  @ViewChild('carousel') private readonly carousel: ElementRef;

  currentSlide = 0;
  private player: AnimationPlayer;

  constructor(private readonly builder: AnimationBuilder,
              private readonly renderer: Renderer2,
              private readonly analyticsService: AnalyticsService,
              private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
             ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (!this.carousel && !this.isVisible) {
      return;
    }

    if (changes.hasOwnProperty('selectedMileage')) {
      const selectedMileageIndex = this.mileages.indexOf(this.selectedMileage);
      this.currentSlide = selectedMileageIndex === -1 ? 0 : selectedMileageIndex;
      this.analyticsService.serviceSchedulerToolData = {
        ...this.analyticsService.serviceSchedulerToolData,
        category : this.getTransformed(this.mileages[this.currentSlide])
      };
      this.serviceAppointmentState.dispatch(new TriggerLoaderFail());

      setTimeout(() => {
        this.slideAnimation();
      });
    }

    if (changes.hasOwnProperty('isVisible')) {
      setTimeout(() => {
        this.slideAnimation();
      });
    }
  }

  onPrev() {
    if (this.currentSlide === 0 || !this.carousel) {
      return;
    }

    this.currentSlide = ((this.currentSlide - 1) + this.mileages.length) % this.mileages.length;
    this.triggerMileageChangeTracker(this.currentSlide);
    this.slideAnimation();
    this.mileageChange.emit(this.mileages[this.currentSlide]);
  }

  onNext() {
    if (this.currentSlide + 1 === this.mileages.length || !this.carousel) {
      return;
    }
    this.currentSlide = (this.currentSlide + 1) % this.mileages.length;
    this.triggerMileageChangeTracker(this.currentSlide)
    this.slideAnimation();
    this.mileageChange.emit(this.mileages[this.currentSlide]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.slideAnimation();
    });
  }

  private slideAnimation() {
    if (!this.isVisible || !this.mileageDiv) {
      return;
    }
    const offset = +getComputedStyle(this.mileageDiv?.nativeElement)
      ?.width?.replace('px', '') || 70;
    const marginRight = +getComputedStyle(this.mileageDiv?.nativeElement)
      .marginRight?.replace('px', '');
    const offsetCalc = this.currentSlide * (offset + marginRight) * -1;
    const offsetPx = offsetCalc === 0 ? '0px' : offsetCalc + 'px';
    const myAnimation: AnimationFactory = this.builder.build([
      animate('250ms ease-in', style({transform: `translateX(${offsetPx})`}))
    ]);

    this.player = myAnimation.create(this.carousel?.nativeElement);
    try {
      this.player.play();
    } catch (e) {
      console.warn('Error when animating: ', e.message);
    }
  }

  getTransformed(mileage: number) {
    // @ts-ignore
    const kConverted = (Math.sign(mileage) * ((Math.abs(mileage) / 1000).toFixed(1))) + 'K';
    const normal = Math.sign(mileage) * Math.abs(mileage);
    return Math.abs(mileage) > 999 ? kConverted : normal;
  }
  selectMileage(i: number) {
    if (i === this.currentSlide) {
      return;
    }
    this.triggerMileageChangeTracker(i);
    this.currentSlide = i;
    this.slideAnimation();
    this.mileageChange.emit(this.mileages[this.currentSlide]);
  }

  triggerMileageChangeTracker(index : number){
    const mileageLinkTrackingData = {
      content_section : 'services tab',
      link_module : 'choose your services',
      link_text : 'mileage change',
      link_input_text : this.getTransformed(this.mileages[index])
    }
    this.analyticsService.serviceSchedulerToolData = {
      ...this.analyticsService.serviceSchedulerToolData,
      category : this.getTransformed(this.mileages[index])
    };
    this.analyticsService.trackLink(mileageLinkTrackingData,true);
  }
}
