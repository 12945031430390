<div class='trade-in-container'>
  <form [formGroup]='tradeInForm'>
    <div class='trade-in-header'>
      {{'testDrive.trade-InDetails' | translate}}
    </div>
    <div class='trade-in-vehicles'>
      <mat-expansion-panel #tradeIn [disabled]='!tradeInCheckbox.checked' [expanded]='tradeInCheckbox.checked'>
        <mat-expansion-panel-header [ngClass]="{'show-cursor': !tradeInCheckbox.checked }">
          <mat-panel-title>
            <mat-checkbox #tradeInCheckbox disableRipple formControlName='tradeInCheckbox'>
            </mat-checkbox>
            <span class='textArea-text'>{{'testDrive.doYouWantToTrade-InYourCurrentVehicles' | translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <div class='select-from-existing' *ngIf='signedIn'>
            <div class='header'>{{'testDrive.selectYourTrade-InVehicle' | translate}}</div>
            <div class='vehicles' *ngIf='signedIn'>
              <div class='vehicle-card' *ngFor="let vehicle of vehicles;let i=index;">
                <label class='vehicle-label'>
                  <mat-checkbox [checked]="vehicle.isChecked" [hidden]="true" formGroupName="vehicle" [value]='vehicle'
                    name='vehicle' (change)="setVehicle($event, vehicle, i)"></mat-checkbox>
                  <div class='vehicle'>
                    <img [src]="vehicle.carImage" (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="Vehicle image" />
                    <div class='nickname'>{{vehicle.nickName}}</div>
                    <div class='make-model-year'>{{vehicle.make + ' ' +  vehicle.model + ' ' + vehicle.year}}</div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div id="or" *ngIf="signedIn">{{ 'welcome.or' | translate }}</div>

          <div class='choose-new'>
            <div class='header-login'>
              <div class='choose-new-header'>
                <div class='header'>{{'testDrive.enterYourTrade-InVehicleDetails' | translate}}</div>
              </div>

              <div *ngIf='!signedIn' class='login'>{{'testDrive.chooseFromGarage' | translate}}
                <span (click)='login()'>{{ 'welcome.logIn' | translate }}</span>
              </div>
            </div>

            <div formArrayName='vehicle'>
              <div class='dropdown' *ngFor="let other of vehicleArray?.controls, let i = index, first as isFirst"
                  [formGroupName]="i">
                  <div  *ngIf="signedIn ? !other.value.isOwnVehicle : true">
                    <app-splitter *ngIf="signedIn ? !isFirstmanualVehicle(i) : !isFirst" class="app-splitter mx-3" [config]="'horizontal 100% 0 -0.5rem'"></app-splitter>
                    <div class="choose-new-content">

            <mat-form-field appearance="outline">
              <mat-label>{{'testDrive.year' | translate}}</mat-label>
              <input matInput formControlName='year' required aspNumberOnly maxlength="4">
              <mat-error *ngIf="other.controls['year'].invalid">
                {{'testDrive.pleaseEnterAValidYear' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field  appearance="outline">
              <mat-label>{{'welcome.make' | translate}}</mat-label>
              <input matInput formControlName='make' required>
              <mat-error *ngIf="other.controls['make'].invalid">
                {{'testDrive.pleaseEnterAValidMake' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{'testDrive.model' | translate}}</mat-label>
              <input matInput formControlName='model' required>
              <mat-error *ngIf="other.controls['model'].invalid">
                {{'testDrive.pleaseEnterAValidModel' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{'testDrive.trim' | translate}}</mat-label>
              <input matInput formControlName='trim'>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>VIN</mat-label>
              <input matInput formControlName='vin' maxlength="17">
            </mat-form-field>

            <div style="flex-direction: row;">
            <mat-form-field appearance="outline">
              <mat-label>{{'testDrive.estimatedMileage' | translate}}</mat-label>
              <input matInput formControlName='mileage' aspNumberOnly>
            </mat-form-field>
            <div  *ngIf="!(!signedIn && i===0 && vehicleArray?.length === 1)" class="mt-4 delete-icon"
                [title]="'delivery.deleteVehicle' | translate">
                <app-icon class='app-icon' height='1rem' width='1rem' name='ic-20-utility-delete-trash.svg'
                        color="rgba(14,19,25,0.8)" (click)='onDeleteVehicle(i)'>
                </app-icon>
              </div>
            </div>
                    </div>
                  </div>
              </div>
            </div>

            <div class="addVehicleBtn">
              <div  class='action' (click)='onAddVehicle()' [ngClass]="{'disabled': manualEntryVehicles()?.length >= maxManualTradeInVehicle}">
                    <div class="box">
                        <div class="plus">+</div>
                    </div>
                    <div class="btnText" >{{('delivery.addVehicle' | translate) }}</div>
             </div>
            </div>
          </div>

            <ng-container *ngIf="from === 'delivery'">
              <div class="delivery-tradein">
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="mat-input-container full-width">
                      <mat-label>{{'testDrive.title' | translate}}</mat-label>
                      <input type="text" matInput placeholder="{{'testDrive.title' | translate}}" formControlName='title'>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="mat-input-container full-width">
                      <mat-label>{{'testDrive.authorizedRepresentativeInfo' | translate}}</mat-label>
                      <input type="text" matInput placeholder="{{'testDrive.authorizedRepresentativeInfo' | translate}}"
                        formControlName='trepresentativeInfo'>
                    </mat-form-field>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="mat-input-container full-width">
                      <mat-label>{{'testDrive.comments' | translate}}</mat-label>
                      <input type="text" matInput placeholder="{{'testDrive.comments' | translate}}" formControlName='tradeInComments'>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
      </mat-expansion-panel>
    </div>
    <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

    <div class='trade-in-header'>
      {{'delivery.additionalComments' | translate}}
    </div>
    <mat-expansion-panel style="margin-top: 2rem; margin-bottom: 2rem;">
      <mat-expansion-panel-header>
      <mat-panel-title>
        <span class='textArea-text'>{{'delivery.additionalComments' | translate}}</span>
      </mat-panel-title>
      </mat-expansion-panel-header>
          <mat-form-field class="no-fixed-height comment-width" appearance="outline">
          <textarea matInput #additionalCommentsTextArea rows='5' formControlName='additionalComments' maxlength="250" placeholder="{{ 'delivery.placeHolderComments' | translate}}"></textarea>
          </mat-form-field>
          <mat-hint style="display: flex; flex-direction: row-reverse; font-weight: bold;">{{additionalCommentsTextArea.value?.length || 0}}/250 {{ 'delivery.characters' | translate}}</mat-hint>
  </mat-expansion-panel>

  <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

  </form>
</div>
