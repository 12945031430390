import {TestDriveModule} from './../test-drive/test-drive.module';
import {DashboardModule} from './../dashboard/dashboard.module';
import {LayoutRoutingModule} from './layout-routing.module';
import {NgModule} from '@angular/core';
import {LayoutComponent} from './layout.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ServiceAppointmentModule} from '../service-appointment/service-appointment.module';
import {DeliveryFormModule} from '../delivery-form/delivery-form.module';
import { TechAppointmentModule } from '../tech-appointment/tech-appointment.module';
import {TranslateModule} from '@ngx-translate/core';
import { DeliveryModule } from '../delivery/delivery.module';


@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutRoutingModule,
    DashboardModule,
    CommonModule,
    TestDriveModule,
    ServiceAppointmentModule,
    DeliveryFormModule,
    TechAppointmentModule,
    TranslateModule,
    DeliveryModule
  ],
  exports: [TranslateModule],
  providers: [],
})
export class LayoutModule { }
