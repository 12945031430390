import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TechAppointmentComponent } from './tech-appointment.component';
import { ConfirmInfoComponent } from './confirm-info/confirm-info.component';

const routes: Routes = [
  {
      path: '',
      component: TechAppointmentComponent,
  },
  {
    path: 'confirm',
    component: ConfirmInfoComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TechAppointmentRoutingModule { }
