import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { environment } from './util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly _signedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isSignedIn() {
        return this._signedIn.asObservable();
    }

    private readonly _expired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // get isExpired() {
    //     return this._expired.asObservable();
    // }

    private readonly _user: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    get user() {
        return this._user.asObservable();
    }

    constructor(private readonly cookieService: CookieService,
        private readonly http: HttpClient) { }

    loginB2c() {
        // we need to send the request body as type 'multipart/form-data' or 'application/x-www-form-urlencoded'
        let formData = new URLSearchParams();
        formData.append('redirectUrl', location.href);
        formData.append('dgid', environment.frDgId);
        formData.append('client_id', environment.frClientId);
        formData.append('brand', environment.brand);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        this.http.post(`${environment.frLoginUrl}`, formData.toString(), httpOptions).subscribe((data) => {
            console.log(data, typeof (data));
        }, errors => {
            if (errors?.status === 200) {
                const url = `${errors?.error?.text}`.replace(' ', '');
                window.location.href = url;
            }
            else {
                console.log('login error', errors?.message);
            }
        })
    }

    getTokenName() {
        // undefined environment
        return environment.loginEnvironment?.length > 0 ? `id_token_${environment.loginEnvironment}` : 'id_token';
    }

    getToken() {
        return this.cookieService.get(this.getTokenName());
    }

    setSignedInUser() {
        const rawToken = this.cookieService.get(this.getTokenName());
        if (!!rawToken) {
            if (!this.isExpired()) {
                const user = jwt_decode(rawToken);
                this._user.next(user);
                this._signedIn.next(!!rawToken);
            }
            else {
                this.logoutB2c();
            }
        }
    }

    setExpired() {
        const rawToken = this.cookieService.get(this.getTokenName());
        if (!!rawToken) {
            const token: any = jwt_decode(rawToken);
            const now = Date.now().valueOf() / 1000
            this._expired.next(typeof token.exp !== 'undefined' && token.exp < now);
        }
        else {
            this._expired.next(true);
        }
    }

    isExpired() {
        const rawToken = this.cookieService.get(this.getTokenName());
        if (!!rawToken) {
            const token: any = jwt_decode(rawToken);
            const now = Date.now().valueOf() / 1000
            return typeof token.exp !== 'undefined' && token.exp < now;
        }
        else {
            return true;
        }
    }

    logoutB2c() {
        var tokenName = environment.loginEnvironment?.length > 0 ? `id_token_${environment.loginEnvironment}` : 'id_token';
        // we need to send the request body as type 'multipart/form-data' or 'application/x-www-form-urlencoded'
        let formData = new URLSearchParams();
        formData.append('redirectUrl', location.href);
        formData.append('client_id', environment.frClientId);
        formData.append('loginDataParserRoute', (window as any).ASPHub?.pageRoutes?.loginDataParserRoute);
        formData.append('idToken', this.cookieService.get(tokenName));
        formData.append('brand', environment.brand);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        this.cookieService.delete(tokenName);
        // force flush all the cookie since user is logged out. 
        this.cookieService.deleteAll('/', 'toyota.com');

        this.http.post(`${environment.frLogoutUrl}`, formData.toString(), httpOptions).subscribe((data) => {
            console.log(data, typeof (data));
        }, errors => {
            if (errors?.status === 200) {
                const url = `${errors?.error?.text}`.replace(' ', '');
                window.location.href = url;
            }
            else {
                console.log('logout error', errors?.message);
            }
        })
    }

}
