<div class='exterior-colors' *ngIf="exteriorColors?.length>0">
    <div class='desc'>{{ 'testDrive.preferredExteriorColor' | translate}}
      <span style="color:var(--primary-color)">*</span>
    </div>
    <div class='colors' >
      <div class='color' 
      *ngFor='let item of exteriorColors' 
      [style.--color]="item.colorCode"
      [style.--tcolor]="getContrastYIQ(item.colorCode)"
      [ngClass]="{'selected' : item.id==selectedExtColor}"
      [matTooltip]="item.colorName"
      matTooltipClass="asp-custom-tooltip"
      (click)="onSelectExteriorColor(item.id)">
      </div>
    </div>
  </div>