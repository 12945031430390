<div class='add-to-calendar'>
    <div class='choose-desc'>{{'confirm.chooseWhichCalendarText' | translate}}</div>
    <a [href]="appleCalendarEventUrl" target="_blank">
        <div>
            <app-icon class='app-icon' name='apple.svg'></app-icon>
            {{'confirm.apple' | translate}}
        </div>
    </a>

    <a [href]="googleCalendarEventUrl" target="_blank">
        <div>
            <app-icon class='app-icon' name='google.svg'></app-icon>
            {{'confirm.google' | translate}}
        </div>
    </a>


    <a [href]="office365CalendarEventUrl" target="_blank">
        <div>
            <app-icon class='app-icon' name='microsoft.svg'></app-icon>
            {{'confirm.office365' | translate}}
        </div>
    </a>


    <a [href]="outlookCalendarEventUrl" target="_blank">
        <div>
            <app-icon class='app-icon' name='outlook.svg'></app-icon>
            {{'confirm.outlook' | translate}}
        </div>
    </a>


    <a [href]="yahooCalendarEventUrl" target="_blank">
        <div>
            <app-icon class='app-icon' name='yahoo.svg'></app-icon>
            {{'confirm.yahoo' | translate}}
        </div>
    </a>

</div>
