import { IAddress } from '@signal/asp-data-commons';
import { Router } from '@angular/router';
import { SubscriptionList } from './../../models/asp.types';
import { navigateToRoutePromise, unsubscribeSubscriptions } from './../../services/util.service';
import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { assetUrl, environment } from '../../../../modules/shared/services/util.service';
import { Store } from '@ngrx/store';
import { DealerState } from '../../../../modules/store/dealer/dealer.reducer';
import * as fromSelectors from '../../../../modules/store/dealer/dealer.selectors';

@Component({
  selector: 'app-asp-footer',
  templateUrl: './asp-footer.component.html',
  styleUrls: ['./asp-footer.component.scss']
})
export class AspFooterComponent implements OnInit, OnDestroy {

  brand;
  logoPath: string;
  currentYear: number;
  serviceHoursOfOperation$: Observable<string>;
  salesHoursOfOperation$: Observable<string>;
  dealerName$: Observable<string>;
  dealerPhone: string;
  dealerAddress$: Observable<IAddress>;
  dealerLatitude: number;
  dealerLongitude: number;
  dealerWebLink: string;
  private subs: SubscriptionList = {};

  constructor(
    private readonly router: Router,
    private readonly dealerState: Store<DealerState>) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.dealerName$ = this.dealerState.select(fromSelectors.selectDealerName);
    this.dealerAddress$ = this.dealerState.select(fromSelectors.selectDealerAddressDetails);
    this.serviceHoursOfOperation$ = this.dealerState.select(fromSelectors.selectServiceOperationalHours);
    this.salesHoursOfOperation$ = this.dealerState.select(fromSelectors.selectSalesOperationalHours);
    this.brand = environment.brand;
    if (this.brand === 'lexus') {
      this.logoPath = assetUrl('layout/logo/lexus_small_logo.png');
    } else {
      this.logoPath = assetUrl('layout/Toyota-square.png');
    }
    this.subs.webSiteLinkSub = this.dealerState.select(fromSelectors.selectDealerWebsiteLink).subscribe(websiteLink => {
      this.dealerWebLink = websiteLink;
    });
    this.subs.latitude = this.dealerState.select(fromSelectors.selectLatitude).subscribe(latitude => {
      this.dealerLatitude = latitude;
    });
    this.subs.longitude = this.dealerState.select(fromSelectors.selectLongitude).subscribe(longitude => {
      this.dealerLongitude = longitude;
    });
    this.subs.phone = this.dealerState.select(fromSelectors.selectDealerPhoneNumber).subscribe(phone => {
      this.dealerPhone = phone;
    });
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
  }

  redirectToT3() {
    if (!!this.dealerWebLink) {
      window.location.href = this.dealerWebLink;
    }
  }

  onNavigateToGmap() {
    const link = `https://www.google.com/maps?z=12&t=m&q=loc:${this.dealerLatitude}+${this.dealerLongitude}`;
    navigateToRoutePromise(undefined, this.router)
      .then(() => {
        window.open(link, '_blank');
      });
  }

  callPhone() {
    if (this.dealerPhone) {
      window.open('tel:' + this.dealerPhone, '_self');
    }
  }

}
