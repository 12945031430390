import { VehicleStatus } from './vehicle-health-report';
import { ITelematics } from '../dashboard.model';
import { VehicleAlertList, VehicleAlerts } from '@signal/asp-data-commons/lib/interfaces/vehicle';

export class Alert {
  type: string;
  title: string;
  message: string;
}

export class Telematics implements ITelematics {

  constructor(engineOilLevelStatus: string,
    engineOilImageURL: any,
    engineOilLevelStatusHover: string = '',
    milesToEmptyDescription: string,
    milesToEmptyImageURL: string,
    milesToEmptyDescriptionHover: string = '',
    odometerReading: string,
    odometerIcon: string,
    odometerReadingHover: string = '',
    smartKeyBatteryDegradationStatus: string,
    smartKeyImageURL: any,
    smartKeyBatteryDegradationStatusHover: string = '',
    alertsList: VehicleAlerts,
    mileage:string='',
    tirePressureStatus:string = '',
    tirePressureStatusHover:string = '',
    fuelHover:string = '',
    fuel:string = ''
    ) {

    this.engineOilLevelStatus = engineOilLevelStatus;
    this.engineOilLevelStatusToShow = Telematics.getHoverTextFromStatus(this.engineOilLevelStatus);
    this.engineOilImageURL = engineOilImageURL;
    this.engineOilLevelStatusHover += engineOilLevelStatusHover;

    const milesText = milesToEmptyDescription ? `${milesToEmptyDescription}`.trim() : '--';
    this.milesToEmptyDescription = milesText === ''? '--' : milesText;
    const milesDescriptionHover = milesToEmptyDescriptionHover ? `${milesToEmptyDescriptionHover}`.trim() : '--';
    this.milesToEmptyImageURL = milesToEmptyImageURL;
    this.milesToEmptyDescriptionHover += milesDescriptionHover;

    const find = 'undefined';
    const re = new RegExp(find, 'g');
    // mileage received from health-report api is in number format thats why converted to string here
    mileage=''+mileage;
    this.mileage = mileage?.replace(re, '')?.trim() || '';
    const odometerText = odometerReading ? `${odometerReading}`.trim() : '--';
    this.odometerReading = odometerText === ''? '--' : odometerText;
    this.odometerIcon = odometerIcon;
    const odometerTextHover = odometerReadingHover ? `${odometerReadingHover}`.trim() : '--';
    this.odometerReadingHover += odometerTextHover;

    this.smartKeyBatteryDegradationStatus = smartKeyBatteryDegradationStatus;
    this.smartKeyBatteryDegradationStatusToShow = Telematics.getHoverTextFromStatus(this.smartKeyBatteryDegradationStatus);
    this.smartKeyImageURL = smartKeyImageURL;
    this.smartKeyBatteryDegradationStatusHover += smartKeyBatteryDegradationStatusHover;
    this.alerts = alertsList?.vehicleAlertList.map(val => <Alert>{ message: val.WNGDesc });

    const tirePressureDetails = getTirePressureStatus(alertsList?.vehicleAlertList)
    this.tirePressureStatus = tirePressureDetails.status;
    this.tirePressureStatusHover += tirePressureDetails.message;
  }
  engineOilLevelStatus: string;
  engineOilLevelStatusToShow: string;
  engineOilLevelStatusHover = 'dashboardAppointments.engineOilLevel:';
  engineOilImageURL: any;
  milesToEmptyDescription: string;
  milesToEmptyDescriptionHover = 'dashboardAppointments.fuelLevel:';
  milesToEmptyImageURL: string;
  odometerReading: string;
  odometerReadingHover = 'dashboardAppointments.currentMileage:';
  odometerIcon: string;
  smartKeyBatteryDegradationStatus: string;
  smartKeyBatteryDegradationStatusToShow: string;
  smartKeyImageURL: any;
  smartKeyBatteryDegradationStatusHover = 'dashboardAppointments.smartKeyBatteryDegradationStatus:';
  mileage:string=''
  alerts: Alert[] = [];
  tirePressureStatus:string = '';
  tirePressureStatusHover = 'dashboardAppointments.tirePressureStatus:';

  static getHoverTextFromStatusImage(status: string) {
    if (status && status?.toUpperCase().includes('GREEN')) {
      return 'Ok';
    } else if (status && status?.toUpperCase().includes('RED')) {
      return 'Bad';
    } else {
      return '--';
    }
  }
  static getHoverTextFromStatus(status: string) {
    if (status && status === '3') {
      return 'Ok';
    } else if (status && status === '2') {
      return 'Bad';
    } else {
      return '--';
    }
  }

  static getTelematics(vehicleStatus: VehicleStatus, vehicleAlerts: any) {
    const tirePressureStatus = getTirePressureStatus(vehicleAlerts.vehicleAlertList)
    return new Telematics(
      vehicleStatus.engineOilLevelStatus,
      vehicleStatus.engineOilImageURL,
      Telematics.getHoverTextFromStatus(vehicleStatus.engineOilLevelStatus),
      vehicleStatus.milesToEmptyDescription,
      vehicleStatus.milesToEmptyImageURL,
      vehicleStatus.milesToEmptyDateTime,
      vehicleStatus.odometerReading,
      vehicleStatus.odometerIcon,
      vehicleStatus.odometerReading,
      vehicleStatus.smartKeyBatteryDegradationStatus,
      vehicleStatus.smartKeyImageURL,
      Telematics.getHoverTextFromStatus(vehicleStatus.smartKeyBatteryDegradationStatus),
      vehicleAlerts,
      vehicleStatus.odometerReading,
      tirePressureStatus.status,
      tirePressureStatus.message
    );


  }

  static getDefaultTelematics() {
    return new Telematics(
      '--',
      undefined,
      '(Not available)',
      '--',
      undefined,
      '(Not available)',
      '--',
      undefined,
      '(Not available)',
      '--',
      undefined,
      '(Not available)',
      undefined,
      '',
      '--',
      '(Not available)'
    );
  }

  getIssuesCount(): number {
    return this.alerts ? this.alerts.length : 0;
  }

  issueExists(): boolean {
    return this.getIssuesCount() > 0;
  }

  noIssueExists(): boolean {
    return this.getIssuesCount() === 0;
  }

  getIssueCountDesc(): string {
    const issueCount = this.getIssuesCount();
    const issueDescStr = issueCount === 0 ? 'No' : issueCount;
    return issueDescStr + ' Alert' + (issueCount === 1 ? '' : 's');
  }
}
function getTirePressureStatus(warningsList:VehicleAlertList[]):{status:string,message:string}{
  if(warningsList === undefined ||(warningsList && warningsList?.length=== 0)){
    return {status:'--',message:'(Not available)'};
  }
  else{
    const warninglist=warningsList?.find(warning =>{ return warning.WNGName?.trim().toLowerCase() === 'tire pressure warning system' ||  warning.WNGName?.trim().toLowerCase() === 'low tire pressure'})
    if((warninglist))
    {
      return {status:'Bad',message:'Bad'};
    }else{
      return {status: 'Ok',message: 'Good'};
    }
  }
}
