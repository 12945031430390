import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardMenuComponent implements OnInit {
  @Input() vehicle: any;
  @Input() vOffset = '10px';
  @Input() hOffset = '10px';
  @Output() cancelEvent: EventEmitter<string> = new EventEmitter();
  @Output() copyEvent: EventEmitter<string> = new EventEmitter();

  appointmentExists: boolean;
  showCancelItem : boolean = false;
  
  constructor(
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.appointmentExists = !!this.vehicle.appointment;
    if(this.vehicle.appointment && this.vehicle.appointment.length === 1){
      this.showCancelItem = true;
    }
  }

  onCancelClick() {
    this.cancelEvent.emit();
  }

  onCopyClick() {
    this.copyEvent.emit();
  }
  getCopyDetailstext(){
    return this.translate.instant('dashboardAppointments.copyDetails');
  }
}
