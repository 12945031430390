<div id="dialog-container">
    <header>
      <div class="title">
        {{title}}
      </div>
  
      <div class="close-icon" mat-dialog-close >
        <span class="icon" [inlineSVG]="iconSource" [setSVGAttributes]="{ width: width, height: height }">
        </span>
      </div>
    </header>
   <div class="data-message">
    {{data.message}}
   </div>

</div>