<div class="vehicle-card" [class.flip-card]="isCardFlipped">
  <ng-container *ngFor="let eachvehicle of newvehicle">
    <div *ngIf="eachvehicle.vin===vehicle.vin">
      <div class="front" (click)="emitVehicleDetails(eachvehicle)">
  <div class="car-image">
    <img [src]="carImageAbsoluteURL" (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="Vehicle image"/>
  </div>
  <div class="car-name">
    {{ vehicle.make | titlecase }} {{vehicle.model}}
    <span
      *ngIf="telematics?.issueExists(); else noIssues"
      class="issues-exists"
      matRipple
      (click)="flipTheCard()"
    >
    <span [title]="getAlert(telematics?.getIssueCountDesc())">
      <app-icon
        name="ic-20-alert-error.svg"
        color="var(--dashboard-telematics-alert-icon-clr)"
      ></app-icon>
    </span>
    </span>
    <ng-template #noIssues>
      <span [title]="getAlert(telematics?.getIssueCountDesc())">
        <app-icon name="ic-20-alert-tick.svg"></app-icon>
      </span>
    </ng-template>
  </div>
  <div class="car-info">
    <span>{{ makeModelYearText }}</span>
    <span>{{ vinDisplayText }}</span>
  </div>
  <ng-container *ngIf="!isTelematicsLoading; else loading">
    <div class="telematics">
    <span [title]="getTranslation(telematics?.smartKeyBatteryDegradationStatusHover)">
      <app-icon name="icon-battery.svg"
                color="inherit"
                width="20px"
                height="15px"></app-icon>
      <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow === '--';">
        {{ telematics?.smartKeyBatteryDegradationStatusToShow }}
      </span>
      <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow.toLowerCase() === 'ok'">
        <ng-template [ngTemplateOutlet]="ok"></ng-template>
      </span>
      <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow?.toLowerCase() === 'bad'">
        <ng-template [ngTemplateOutlet]="bad"></ng-template>
      </span>
    </span>
      <span [title]="getTranslation(telematics?.milesToEmptyDescriptionHover)">
      <app-icon name="ic-20-vin-fuel.svg"
                color="inherit"
                width="20px"
                height="15px"></app-icon>
      <span>{{ telematics?.milesToEmptyDescription }}</span>
    </span>
      <span [title]="getTranslation(telematics?.odometerReadingHover)">
      <app-icon name="ic-20-vin-odometer.svg"
                color="inherit"
                width="20px"
                height="15px"></app-icon>
      <span>{{ telematics?.odometerReading }}</span>
    </span>
      <span [title]="getTranslation(telematics?.engineOilLevelStatusHover)">
      <app-icon name="icon-engine-oil.svg"
                color="inherit"
                width="20px"
                height="15px"></app-icon>
      <span *ngIf="telematics?.engineOilLevelStatus === '--'">
        {{ telematics?.engineOilLevelStatus }}
      </span>
      <span *ngIf="telematics?.engineOilLevelStatus === '3'">
        <ng-template [ngTemplateOutlet]="ok"></ng-template>
      </span>
      <span *ngIf="telematics?.engineOilLevelStatus === '2'">
        <ng-template [ngTemplateOutlet]="bad"></ng-template>
      </span>
    </span>
    <span [title]="getTranslation(telematics?.tirePressureStatusHover)">
      <app-icon name="ic-20-vin-tire.svg" path="svg/"  color="inherit"></app-icon>
      <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === '--';">
        {{ telematics?.tirePressureStatus }}
      </span>
      <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === 'ok';">
        <ng-template [ngTemplateOutlet]="ok"></ng-template>
      </span>
      <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === 'bad';">
        <ng-template [ngTemplateOutlet]="bad"></ng-template>
      </span>
    </span>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-loading class="telematics-loading"
                 loadingSymbol="dots"
                 text="{{ 'dashboardAppointments.loadingTelematics' | translate}}"
                 color="var(--dashboard-primary-color)"
                 size=".5">
    </app-loading>
  </ng-template>
  <div class="telematic-alerts mileage-input" [ngClass]="eachvehicle.appointment!=undefined && !editmode ?'setdisabledbackground':''">
    <input
      name="vehicle-mileage"
      placeholder="{{'dashboardAppointments.enterMileage' | translate }}"
      title="{{'dashboardAppointments.enterMileage' | translate }}"
      [ngModel]="setVehicleMileage(eachvehicle,editmode)"
      (ngModelChange)="onMileageChange($event)"
      (blur)="mileageBlurHandler($event)"
      aspNumberOnly
    />
  </div>
  <div class="link-button" (click)="onHistoryClicked()">{{ 'dashboardAppointments.serviceHistory' | translate }}</div>
    </div>
    </div>
  </ng-container>



  <div class="back issue-card">
    <div class="telematic-alerts issues-exists" matRipple (click)="flipTheCard()">
      <span>
        <app-icon name="arrow_left.svg"></app-icon>
        <span class="count"> {{ getAlert(telematics?.getIssueCountDesc()) }}</span>
      </span>
      <span id="issue-closer" class="view">
        {{ 'selectService.back' | translate}}
      </span>
    </div>
    <div class="issue-desc">
      <swiper class="swiper-container" [config]="config">
        <div
          *ngFor="let alert of getTelematicsAlerts()"
          class="swiper-slide alert-msg"
        >
          <div>
            {{ alert.message }}
          </div>
        </div>
      </swiper>

      <div class="swiper-pagination"></div>
    </div>
    <div class="disclaimer">
      <span class="heading">{{ 'welcome.disclaimer' | translate }}</span> {{disclaimerText | translate}}
    </div>
  </div>
</div>
<ng-template #bad>
  <span class="icon-button">
    <app-icon name="ic-red-cross.svg"
    path="svg/"
    class="app-icon"
    color="inherit"
    width="1rem"
    height="1rem"></app-icon>
  </span>
</ng-template>
<ng-template #ok>
  <span class="icon-button">
    <app-icon name="ic-green-tick.svg"
    path="svg/"
    class="app-icon"
    color="inherit"
    width="1rem"
    height="1rem"></app-icon>
  </span>
</ng-template>
