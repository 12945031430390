import { Component, OnDestroy, OnInit } from '@angular/core';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import { DateTime } from 'luxon';
import { AppointmentService } from '../../shared/services/asp/appointment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionList } from '../../shared/models/asp.types';
import { unsubscribeSubscriptions } from '../../shared/services/util.service';
import { AppointmentType, IResponse, ISalesAppointment, ITestDriveAppointment, LocationType } from '@signal/asp-data-commons';
import * as util from './../../shared/services/util.service';
import {TranslateService} from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { DealerState } from '../../store/dealer/dealer.reducer';
import * as fromDealerSelector from '../../store/dealer/dealer.selectors';

@Component({
  selector: 'app-confirm-info',
  templateUrl: './confirm-info.component.html',
  styleUrls: ['./confirm-info.component.scss']
})
export class ConfirmInfoComponent implements OnInit, OnDestroy {
  view = 'confirm';
  appointmentType = AppointmentType.TEST_DRIVE;
  appointment: ITestDriveAppointment;
  makeModelYear: string;
  detailMap: { [key: string]: string };
  id: string;
  subscriptions: SubscriptionList = {};
  hideSalesConsultant=false;
  
  constructor(
    private readonly bannerService: AspBannerService,
    private readonly appointmentService: AppointmentService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly dealerState: Store<DealerState>) {

      const urlState = this.router.getCurrentNavigation()?.extras?.state as ISalesAppointment;
      if(urlState?.appointmentId) {
        this.appointment = urlState;
        this.setDetailMap(urlState);
      } else {
        this.route.queryParams.subscribe(params => {
          this.id = params.id;
          if (this.id) {
            this.appointmentService.get(AppointmentType.TEST_DRIVE, this.id).subscribe((res: IResponse<ITestDriveAppointment>) => {
              if (res && res.data) {
                this.appointment = res.data;
                this.setDetailMap(res.data);
              }
            });
          }
        });
      }
  }

  ngOnInit(): void {
    this.bannerService.hide();
    
  }

  setDetailMap(appointmentDetails) {
    const isRemoteLocation = appointmentDetails.transportation.category === LocationType.REMOTE;

    const date = `${DateTime.fromISO(appointmentDetails.appointmentStartDate).toFormat('MMMM dd')}`;
    const time = appointmentDetails.timeSlotId;

    const dateTime = `${date} at ${time}`;

    const vehicle = appointmentDetails.vehicle;
    const advisorName = appointmentDetails.advisor.displayName;

    // const customer = appointmentDetails.customer;
    const address = appointmentDetails.dropOffAddress;

    let customerAddress = '';
    if (isRemoteLocation) {
      customerAddress = `${this.translate.instant('testDrive.weWillBringTheVehicleTo')} \n${address.addressLine1}, ${address.addressLine2 ? address.addressLine2 + ', ' : ''} ${address.city}, ${address.state} - ${address.zip}`;
    }

    // let index = vehicle.description.indexOf(" ");
    const model = vehicle.model;
    this.makeModelYear = vehicle.year + ' ' +util.titleCase(vehicle.make) + ' ' + model;


    const dealershipAddress = `${appointmentDetails.dealer.address.addressLine1} ${appointmentDetails.dealer.address.city}, ${appointmentDetails.dealer.address.state} ${appointmentDetails.dealer.address.zip}`;
    const dealerComment = appointmentDetails.appointmentComments ? this.getComment(appointmentDetails.appointmentComments, 'Comments-From-Dealer') : '';
    const additionalComments = appointmentDetails.appointmentComments ? this.getComment(appointmentDetails.appointmentComments, 'Customer-Additional-Comments') : '';

    this.detailMap = {
      'selectService.time': dateTime,
      'selectService.vehicle': this.makeModelYear,
      'transportation.consultant': advisorName,
      'delivery.dealerComments': dealerComment,
      'delivery.additionalComments': additionalComments,
      'transportation.location': `${(isRemoteLocation ? customerAddress : dealershipAddress)}`
    };

    /* fetch hideSalesConsultant from state */
    this.dealerState.select(fromDealerSelector.selectSalesDetails).subscribe((data:any) =>{
      this.hideSalesConsultant = data?.hideSalesConsultant || false;
    });

    if(this.hideSalesConsultant)
    {
      delete this.detailMap['transportation.consultant'];
    }
    if(!dealerComment) {
      delete this.detailMap['delivery.dealerComments'];
    }
    if(!additionalComments) {
      delete this.detailMap['delivery.additionalComments'];
    }
  }

  getComment(data, field) {
    let returnComment = '';
    data.split(':::').forEach(comment=> {
      if(comment.includes(field)) {
        returnComment = comment.replace(`${field} - `, '').trim();
      }
    })
    return returnComment;
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

}
