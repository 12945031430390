<div id="asp-header-con">
  <div id="header-top" [ngClass]="inDealerSite ? 'light-shadow' : ''">
    <div class="container header-container">
      <div class="row mb15">
        <div class="col-md-12">
          <div class="logo-con">
            <div class="sidenav" (click)="toggleSideNav()">
              <img class="hamburger-icon" [src]="hamburgerPath" alt="Hamburger">
            </div>
            <div *ngIf="!hideLogoAndText" (click)="redirectToHome()" class="com-logo"
              [ngClass]="brand === 'lexus' ? 'lexus':''">
              <span>
                <img class="logo-image" [src]="logoPath" alt="Logo image">
              </span>
            </div>
            <app-splitter class='app-splitter d-none d-md-inline' [config]="'vertical 40px 0px -20px 550px'">
            </app-splitter>
            <div *ngIf="!hideLogoAndText" (click)="redirectToT3()" class="logo-text d-none d-md-inline">
              <div class="logo-title">
                <span>{{ dealerName$ | async}}</span>
              </div>
            </div>
            <span class="spacer"></span>
            <div class="button-view {{brand}}">
              <a class="signin-options menu-underline mr-2" target="blank" href="{{registerUrl}}" (click)="signUp()"
                *ngIf="!account && isLoginVisible">
               {{ 'welcome.createAccount'| translate}}
              </a>
              <app-splitter class="app-splitter d-none d-md-inline" [config]="'vertical 22px 0px 0px 550px'"
                *ngIf="!account && isLoginVisible"></app-splitter>
              <a class="signin-options menu-underline ml-2 d-none d-md-inline" *ngIf="!account && isLoginVisible" (click)="login()">
                {{ 'welcome.signIn'| translate}}
              </a>
              <div class="user-info" *ngIf="account">
                <span class="user-name desktop">{{account.name}}</span>
                <span>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon class="menu-open-icon desktop">keyboard_arrow_down</mat-icon>
                    <mat-icon class="menu-open-icon mobile">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="userMenu">
                    <span *ngIf="isMobile()">
                      <button mat-menu-item>
                        <mat-icon>account_circle</mat-icon>
                        <span>{{account.name}}</span>
                      </button>
                    </span>

                    <button mat-menu-item (click)="logout()">
                      <mat-icon>exit_to_app </mat-icon>
                      <span>{{ 'testDrive.logout'| translate}}</span>
                    </button>
                  </mat-menu>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>