import * as fromReducer from './service-appointment.reducer';
import { IAvailabilityForm, ICustomerForm, IServiceAppointmentForm, IServiceRequestForm, IVehicleForm } from './service-appointment.reducer';
import { DateTime } from 'luxon';
import * as util from '../../shared/services/util.service';
import { IAvailabilityOptions, IAsyncData, IAvailability, IServiceAppointment, IServiceRequest, IVehicle, IDealerInfo } from '@signal/asp-data-commons';
let totalServiceCost: number = 0;
let totalLaborHoursOfService: string | number = "";
/* Projector function */

export function vehicleFormToVehicleProjector(vehicleForm: IVehicleForm): IVehicle {
  const vehicleInfo: IVehicle = {} as IVehicle;

  if (vehicleForm.isOwnVehicle) {
    vehicleInfo.isOwnVehicle = true;
    vehicleInfo.imageUrl = vehicleForm.imageUrl;
    vehicleInfo.description = vehicleForm.description;
  } else {
    vehicleInfo.isOwnVehicle = false;
    vehicleInfo.description = `${vehicleForm.make} ${vehicleForm.model}`;
    vehicleInfo.imageUrl = vehicleForm.imageUrl;
  }

  vehicleInfo.vin = vehicleForm.vin;
  vehicleInfo.make = vehicleForm.make?.toLowerCase() === 'other' ? vehicleForm.otherMake : vehicleForm.make;
  vehicleInfo.year = vehicleForm.year?.toString() || null;
  vehicleInfo.model = vehicleForm.model;
  vehicleInfo.mileage = +vehicleForm.mileage;
  return vehicleInfo;
}

export const appointmentFormToDetailMap =
  (data: { appointmentForm: IServiceAppointmentForm; availabilityOptions: IAvailabilityOptions },
    showPricing: boolean,
    dealerDetails: IDealerInfo) => {
    const appointmentForm: IServiceAppointmentForm = data.appointmentForm;
    const isRemoteLocation = appointmentForm.availabilityForm.transport === 'REMOTE' ||appointmentForm.availabilityForm.transport === 'DELIVERY'||appointmentForm.availabilityForm.transport === 'PICKUP';
    const availabilityOptions = data.availabilityOptions;
    if (appointmentForm) {
      const date = `${DateTime.fromISO(appointmentForm.availabilityForm.availability.appointmentStartDate).toFormat('MMMM dd')}`;
      const time = appointmentForm.availabilityForm.availability.timeSlotId;

      const dateTime = `${date} at ${time}`;
      const vehicle = getSelectedVehicleSummary(appointmentForm.vehicleForm);
      const serviceFormData = buildSelectedServicesInfo(appointmentForm.serviceForm);

      let services = '';
      let cost = 0;
      let showPricingDisabledForAny = false;
      if (serviceFormData && serviceFormData.length > 0) {
        serviceFormData.map(s => s.details).forEach(t => {
          const serviceRequestPrice = +t.pricing.final;
          const coveredByToyotaCare = t?.recommended?.isCoveredByToyotaCare;
          const displayName = t.displayName ? t.displayName : t.dmsDescription;
         
          if (showPricing && t?.pricing?.show) {
            services += `${displayName} - ${coveredByToyotaCare ? dealerDetails?.dealerCode?.startsWith('6') ? 'Lexus Complimentary Maintenance':'Covered by Toyota Care' : util.getOpcodeDisplayPricing(t)}\n`;
            cost = cost + (!isNaN(serviceRequestPrice) ? serviceRequestPrice : 0);
          } else {
            services += `${displayName}${coveredByToyotaCare ?dealerDetails?.dealerCode?.startsWith('6') ? ' - Lexus Complimentary Maintenance\n': ' - Covered by Toyota Care\n' : ' - Ask Advisor\n'}`;
          }
          /* Checking whether any opcode is having show pricing as false or not */
          if (!t?.pricing?.show) {
            showPricingDisabledForAny = true;
          }
        });
      }

      let customerAddress = '';
      let fields = [];
      let zip = '';
      const customer = appointmentForm.customerForm;
      if (isRemoteLocation) {
        fields = [customer.pickUpAddress.addressLine1, customer.pickUpAddress.addressLine2, customer.pickUpAddress.city, customer.pickUpAddress.state];
        zip = customer.pickUpAddress.zip;
        const addressFields = fields?.filter(f => !!f)?.join(', ');
        if (appointmentForm.availabilityForm.transport === 'REMOTE' || appointmentForm.availabilityForm.transport === 'PICKUP') {
        customerAddress = `confirm.weWillPickYourCarAt: \n${addressFields ? addressFields + ' - ' : ''}${zip}`;
        }
        const OtherFields = [customer.dropOffAddress.addressLine1, customer.dropOffAddress.addressLine2, customer.dropOffAddress.city, customer.dropOffAddress.state];
        const otherZip = customer.dropOffAddress.zip;
        const OtherAddressFields = OtherFields?.filter(f => !!f)?.join(', ');
        
        if(appointmentForm.availabilityForm.transport === 'REMOTE' ||appointmentForm.availabilityForm.transport === 'DELIVERY' )
        {
        customerAddress += `\n\nconfirm.weWillDropOffYourCarAt: \n${OtherAddressFields ? OtherAddressFields + ' - ' : ''}${otherZip}`;
        }

        if (customer.isDropOffAddress) {
          customerAddress = `confirm.weWillPickAndDropOffYourCarAt: \n${addressFields ? addressFields + ' - ' : ''}${zip}`;
        }
        if (isRemoteLocation) {
          customerAddress += '\n\nconfirm.dealershipWillContactYouOnceTheServiceIsCompleted';
        }
      }

      const dealershipAddress = `confirm.serviceTheCarAt: ${dealerDetails?.dealerName}\n ${dealerDetails?.appointmentAddresses?.serviceAppointmentAddress?.addressShort.length > 0 ? dealerDetails?.appointmentAddresses?.serviceAppointmentAddress?.addressShort : dealerDetails?.addressShort}`;

      const advisorName = availabilityOptions?.advisors
        ?.find(advisor => advisor.id === appointmentForm.availabilityForm.availability.advisorId)
        ?.displayName;

        const transportLocation = availabilityOptions?.transport?.transportOptions?.find(opt=> opt.id === appointmentForm.availabilityForm.transport);

      const detailMap = {
        'selectService.time': dateTime,
        'selectService.pickupTimes': dateTime,
        'selectService.vehicle': vehicle,
        'transportation.serviceAdvisor': advisorName,
        'selectService.services': services,
        'appointmentInformation.estimatedCost': util.toUsDollar(cost),
        // 'Coupons': 'Alignment Check',
        'transportation.transportation': (transportLocation?.label)?`transportation.${transportLocation.label}`:'',
        "transportation.location": `${(isRemoteLocation ? customerAddress : dealershipAddress)}`
      };
      if (!showPricing || showPricingDisabledForAny) {
        delete detailMap['appointmentInformation.estimatedCost'];
      }
      if (isRemoteLocation) {
        delete detailMap['selectService.time'];
      }
      if (!isRemoteLocation) {
        delete detailMap['selectService.pickupTimes'];
      }
      return detailMap as { [key: string]: string };
    }
  };

export const buildSelectedServicesInfo = (serviceForm: IServiceRequestForm): IServiceRequest[] => {
  return serviceForm.selectedServices.map(t => {
    return { ...t } as IServiceRequest;
  });
};

export const getSelectedVehicleSummary = (vehicleForm: IVehicleForm) => {
  const selectedVehicle = vehicleFormToVehicleProjector(vehicleForm);
  let vehicleInfo =selectedVehicle.year ? `${selectedVehicle.year} ${selectedVehicle.model}` : '';
  vehicleInfo = vehicleInfo ? `${vehicleInfo} \n` : vehicleInfo;
  return selectedVehicle.vin ? `${vehicleInfo}VIN ${selectedVehicle.vin}` : vehicleInfo;
};

export const transportToIsRemoteLocationProjector = (availabilityForm: fromReducer.IAvailabilityForm) => {
  return (availabilityForm.transport === 'REMOTE'||availabilityForm.transport === 'DELIVERY'||availabilityForm.transport === 'PICKUP' );
};


export const vehicleToMakeModelYearTextProjector = (vehicleForm: fromReducer.IVehicleForm) => {
  return vehicleForm?.model ? `${vehicleForm.year || ''} ${vehicleForm.model || ''}` : vehicleForm?.vin ? `VIN ${vehicleForm.vin}` : '';
};

export const appointmentToAppointmentFormProjector =
  (a: IServiceAppointment): IServiceAppointmentForm => {
    const appointmentVehicle: IVehicle = a.vehicle;
    const isOwnVehicle = appointmentVehicle.isOwnVehicle;
    let vehicleForm: IVehicleForm;
    const appointmentId = (a.appointmentId) ? (a.appointmentId) : '';
    const isInputVinBased = !!(!isOwnVehicle || appointmentVehicle.vin);
    const isMakeOther = appointmentVehicle.make?.toLowerCase() !== 'toyota' && appointmentVehicle.make?.toLowerCase() !== 'lexus' && appointmentVehicle.make?.toLowerCase() !== 'scion';
    const make = isMakeOther ? 'Other' : appointmentVehicle.make;
    vehicleForm = {
      isOwnVehicle,
      isInputVinBased,
      description: appointmentVehicle.description,
      imageUrl: appointmentVehicle.imageUrl,
      make,
      otherMake: isMakeOther && appointmentVehicle.make,
      year: appointmentVehicle.year,
      model: appointmentVehicle.model,
      vin: appointmentVehicle.vin,
      mileage: appointmentVehicle.mileage,
    };

    const selectedServices: IServiceRequest[] = a.serviceRequests;

    const availabilityForm: IAvailabilityForm = {
      transport: a.transportationTypeCode,
      remoteZipCode: a.pickUpAddress?.zip,
      availability: {
        languageId: a.languageId,
        advisorId: a.advisorId,
        timeSlotId: a.timeSlotId,
        appointmentStartDate: DateTime.fromISO(a.appointmentStartDate).toFormat('yyyy-MM-dd')
      }
    };

    const isRemoteLocation = !!(a.dropOffAddress);
    const isDropOffAddress = !!(a.customer.isDropOffAddress);
    let comments = a.appointmentComments?.replace('| Eligible for Complimentary Rental - YES', '')?.replace('| Eligible for Protection Products - YES', '') || a.appointmentComments;
    comments = comments && a.vehicle.protectionProducts ? comments.replace(` | Valid Protection Plans -  ${a.vehicle.protectionProducts}`, '') : comments;
    let customerForm: ICustomerForm = {
      firstName: a.customer.firstName,
      lastName: a.customer.lastName,
      phoneNumber: a.customer.phoneNumber,
      email: a.customer.emailAddress,
      isRemoteLocation,
      pickUpAddress: {
        addressLine1: isRemoteLocation ? a.pickUpAddress.addressLine1 : '',
        addressLine2: isRemoteLocation ? a.pickUpAddress.addressLine2 : '',
        city: (isRemoteLocation) ? a.pickUpAddress.city : '',
        state: (isRemoteLocation) ? a.pickUpAddress.state : '',
        zip: (isRemoteLocation) ? a.pickUpAddress.zip : '',
      },
      dropOffAddress: {
        addressLine1: (isRemoteLocation) ? a.dropOffAddress.addressLine1 : '',
        addressLine2: (isRemoteLocation) ? a.dropOffAddress.addressLine2 : '',
        city: (isRemoteLocation) ? a.dropOffAddress.city : '',
        state: (isRemoteLocation) ? a.dropOffAddress.state : '',
        zip: (isRemoteLocation) ? a.dropOffAddress.zip : '',
      },
      isDropOffAddress,
      appointmentComments: comments
    };
    if (a.customer.address && a.customer.address.addressLine1) {
      customerForm = {
        ...customerForm,
        customerAddress: {
          addressLine1: a.customer.address.addressLine1,
          addressLine2: a.customer.address.addressLine2,
          city: a.customer.address.city,
          state: a.customer.address.state,
          zip: a.customer.address.zip
        },
      }
    }
    return {
      appointmentStatus: a.appointmentStatus,
      vehicleForm,
      recallCodes: [],
      serviceForm: {
        selectedServices,
        isInitialState: false
      },
      availabilityForm,
      customerForm,
      appointmentId
    };
  };

export function appointmentVehicleFormToSummaryProjector(vehicleForm: IVehicleForm) {
  /* Vehicle summary */
  const chosenVehicle = (vehicleForm.year && vehicleForm.model) ? `${vehicleForm.year} ${vehicleForm.model}` : '';
  return [{
    key: 'selectService.vehicle',
    value: chosenVehicle
  }];
}

export function appointmentServiceRequestFormToSummaryProjector(
  serviceForm: IServiceRequestForm,
  showPricing: boolean,
  showTime: boolean,
  transportation: boolean,
  availability: IAsyncData<IAvailability>,
  appointmentForm: IServiceAppointmentForm,
  duration: number) {
  /* Services summary */
  const availabilitySlots = availability.data.availableDates;
  const selectedServices = serviceForm.selectedServices;
  let selectedServicesCost = 0;
  let selectedServicesHours = 0;
  let showPricingDisabledForAny = false;
  let showEstimatedCost = false;
  if (selectedServices && selectedServices.length > 0) {
    selectedServices.map(s => s.details).forEach(t => {
      const coveredByToyotaCare = t?.recommended?.isCoveredByToyotaCare;
      const serviceRequestPrice = +t?.pricing?.final;
      if (showPricing && t?.pricing?.show) {
        selectedServicesCost += (!isNaN(+serviceRequestPrice) && !coveredByToyotaCare ? +serviceRequestPrice : 0);
        showEstimatedCost = true;
      } else {
        showPricingDisabledForAny = true;
      }
      selectedServicesHours += t.labourHours;
    });
  }
  const totalCost = selectedServices.length && !isNaN(selectedServicesCost) ? util.toUsDollar(selectedServicesCost) : '';
  totalServiceCost = selectedServices.length && !isNaN(selectedServicesCost) ? selectedServicesCost : 0;
  const priceDisplayText = showPricingDisabledForAny && !showEstimatedCost ? 'selectService.askAdvisor' : totalCost;
  const defaultDisclaimerText = 'selectService.estimatedPriceDisclaimerText';
  const priceNotKnownDisclaimerText = 'selectService.priceNotKnownDisclaimerText';
  const priceDisclaimerText = showPricingDisabledForAny && !showEstimatedCost ? priceNotKnownDisclaimerText : defaultDisclaimerText;
  const servicePriceSummary = {
    key: 'selectService.estimatedPrice',
    value: priceDisplayText,
    disclaimer: priceDisclaimerText
  };
  const NORemote = `${selectedServices.length && selectedServicesHours ? selectedServicesHours.toFixed(2) + ' hrs' : ''}`
  let Remote = 'NA';
  if (transportation) {
    for (let i = 0; i < availability.data.availableDates?.length; i++) {
      if (availability.data.availableDates[i]?.date === appointmentForm.availabilityForm.availability.appointmentStartDate) {
        for (let j = 0; j < availability.data.availableDates[i]?.timeSlots.length; j++) {
          if (availability.data.availableDates[i]?.timeSlots[j].timeSlotId === appointmentForm.availabilityForm.availability.timeSlotId) {
            if (availability.data.availableDates[i]?.timeSlots[j].remoteEstimatedCompletionTime) {
              Remote = `${DateTime.fromISO(availability.data.availableDates[i]?.timeSlots[j].remoteEstimatedCompletionTime, { zone: 'America/New_York' }).toFormat("LLL dd' at 'hh:mm a")}`;
            }
          }
        }
      }
    }
  }
  totalLaborHoursOfService = transportation ? DateTime.fromFormat(Remote, "LLL dd' at 'hh:mm a").toFormat("hh:mm") : selectedServicesHours;
  const serviceHoursSummary = {
    key: transportation ? 'selectService.estimatedTimeForServiceCompletion' : 'selectService.estimatedTime',
    value: transportation ? getRoundOffTime(Remote, duration) : NORemote,
    disclaimer: 'selectService.estimatedTimeDisclaimerText'
  };
  const footerSummary = [];
  if(showPricing) {
    footerSummary.push(servicePriceSummary);
  }
  if(showTime) {
    footerSummary.push(serviceHoursSummary);
  }

  return footerSummary;
}

function getRoundOffTime(remoteTime: string, duration: number) {
  if (remoteTime === 'NA') {
    return remoteTime;
  }
  const daySplitStrings = remoteTime.split(' at ');
  const timeSplitStrings = daySplitStrings[1].split(' ');
  const time = timeSplitStrings[0];

  const hoursAndMinutes = time.split(':');
  const hours = parseInt(hoursAndMinutes[0]);
  const minutes = parseInt(hoursAndMinutes[1]);

  const timeInMinutes = (hours * 60) + minutes;

  const rounded = Math.round(Math.ceil(timeInMinutes / duration)) * duration;
  const rHr = '' + Math.floor(rounded / 60);
  const rMin = '' + rounded % 60;

  const roundOffTime = rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0');

  return `${daySplitStrings[0]} at ${roundOffTime} ${timeSplitStrings[1]}`;
}

export const getSelectedServices = (services: any[]) => {
  return (services)
    .filter(t => t.selected)
    .map(t => {
      let subComplaints = [];
      if (t.serviceInfo?.isInternalCode && t?.addOns && t?.addOns.length) {
        subComplaints = t?.addOns.filter(c => c.selected === true).map(t => t.serviceInfo);
      }
      const internalCode = t.serviceInfo?.isInternalCode ? t.serviceInfo?.opcode : "";
      return { opcode: t.serviceInfo.opcode, comments: t.comments, details: t.serviceInfo, internalCode, subComplaints };
    });
};
export const getTotalCostAndLabourHrs = () => {
  const cost = isNaN(parseInt(totalServiceCost.toString())) ? 0 : parseInt(totalServiceCost.toString());
  const hrs = isNaN(parseInt(totalLaborHoursOfService.toString())) ? 0 : parseInt(totalLaborHoursOfService.toString());
  return { cost, hrs };
}

export const editAppointmentDetails = (appt: IServiceAppointment): IServiceAppointment => {
  if (appt && appt.vehicle && appt.vehicle.make?.toLowerCase() !== 'toyota' && appt.vehicle.make?.toLowerCase() !== 'lexus' && appt.vehicle.make?.toLowerCase() !== 'scion') {
    const vehicle = { ...appt.vehicle, make: 'other' }
    appt = { ...appt, vehicle }
  }
  return appt;
}
