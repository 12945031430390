<div *ngIf="dur && appointmentType==='Delivery'" class='container'>
    <app-confirmation
    [view]="view"
    [detailMap]="detailMap"
    [appointment]="appointment"
    [makeModelYear]="makeModelYear">
    </app-confirmation>
</div>
<div *ngIf="appointmentType!=='Delivery'" class='container'>
    <app-confirmation
    [view]="view"
    [detailMap]="detailMap"
    [appointment]="appointment"
    [makeModelYear]="makeModelYear">
    </app-confirmation>
</div>