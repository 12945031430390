import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-vehicle-delivery',
  templateUrl: './vehicle-delivery.component.html',
  styleUrls: ['./vehicle-delivery.component.scss']
})
export class VehicleDeliveryComponent implements OnInit {

  @Input() vehicleDetails;
  constructor() { }

  ngOnInit(): void {
  }

}
