import {AvailabilityOptions} from '../../modules/shared/models/sales-appointments/appointment-options';

export const AvailabilityOptionsData: AvailabilityOptions = {
  timeSlots: [
    { timeSlotId: '1', time: '8.00 am', partOftheDay: 'Morning', isOpen: true },
    { timeSlotId: '2', time: '9.00 am', partOftheDay: 'Morning', isOpen: true },
    { timeSlotId: '3', time: '10.00 am', partOftheDay: 'Morning', isOpen: false },
    { timeSlotId: '4', time: '11.00 am', partOftheDay: 'Morning', isOpen: true },
    { timeSlotId: '5', time: '12.00 pm', partOftheDay: 'Afternoon', isOpen: true },
    { timeSlotId: '6', time: '1.00 pm', partOftheDay: 'Afternoon', isOpen: true },
    { timeSlotId: '7', time: '2.00 pm', partOftheDay: 'Afternoon', isOpen: false },
    { timeSlotId: '8', time: '3.00 pm', partOftheDay: 'Afternoon', isOpen: true },
    { timeSlotId: '9', time: '4.00 pm', partOftheDay: 'Afternoon', isOpen: false },
    { timeSlotId: '10', time: '5.00 pm', partOftheDay: 'Evening', isOpen: true },
    { timeSlotId: '11', time: '6.00 pm', partOftheDay: 'Evening', isOpen: true },
    { timeSlotId: '12', time: '7.00 pm', partOftheDay: 'Evening', isOpen: true },
    { timeSlotId: '13', time: '8.00 pm', partOftheDay: 'Evening', isOpen: true },
  ],
  availableDates: ['2020-08-27', '2020-08-28', '2020-08-29', '2020-08-31'],
  suggestedTimes: [{
    date: '2020-08-27',
    timeSlot: { timeSlotId: '1', time: '8.00 am', partOftheDay: 'Morning', isOpen: true }
  },
  {
    date: '2020-08-27',
    timeSlot: { timeSlotId: '2', time: '9.00 am', partOftheDay: 'Morning', isOpen: true }
  },
  {
    date: '2020-08-27',
    timeSlot: { timeSlotId: '4', time: '11.00 am', partOftheDay: 'Morning', isOpen: true }
  }],
  advisors: [
    { advisorId: '0', name: 'Any Advisor', languagId: '1' },
    { advisorId: '1', name: 'Jane Smith', languagId: '1' },
    { advisorId: '2', name: 'Jacob King', languagId: '1' }],
  languages: [
    { languageId: '1', language: 'English' },
    { languageId: '2', language: 'Spanish' },
    { languageId: '3', language: 'French' }],
  advisorLabel: 'Sales',
  dayStartAt: '2020-08-27',
  minDate: '2020-08-24',
  maxDate: '2020-08-31'
};
