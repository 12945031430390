import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { assetUrl, environment } from '../../../../modules/shared/services/util.service';

@Component({
  selector: 'asp-brand-switcher',
  templateUrl: './brand-switcher.component.html',
  styleUrls: ['./brand-switcher.component.scss'],
})
export class BrandSwitcherComponent implements OnInit {
  brand = environment.brand;
  brandlogo = assetUrl('icons/png/' + this.brand + '-logo.png');
  @Output() brandChange: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void { }

  toggleBrand() {
    this.brand = this.brand === 'toyota' ? 'lexus' : 'toyota';
    this.brandlogo = assetUrl('icons/png/' + this.brand + '-logo.png');
    this.brandChange.emit(this.brand);
  }

}
