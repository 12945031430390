export const languages = [
    {
        languageId: 'en',
        language: 'English'
    },
    {
        language: 'French',
        languageId: 'fr'
    }, {
        language: 'Japanese',
        languageId: 'ja'
    }, {
        language: 'Hindi',
        languageId: 'hi'

    }, {
        language: 'Chinese',
        languageId: 'zh'
    }, {
        language: 'Tagalog',
        languageId: 'tl'
    }, {
        language: 'Vietnamese',
        languageId: 'vi'
    }, {
        language: 'Arabic',
        languageId: 'ar'
    }, {
        language: 'Korean',
        languageId: 'ko'
    }, {
        language: 'Russian',
        languageId: 'ru'
    }, {
        language: 'German',
        languageId: 'de'
    }, {
        language: 'Haitian Creole',
        languageId: 'ht'
    },
    {
        language: 'Portuguese',
        languageId: 'pt'
    }, {
        language: 'Italian',
        languageId: 'it'

    }, {
        language: 'Polish',
        languageId: 'pl'
    },
    {
        language: 'Spanish',
        languageId: 'es'

    }
];