import produce from 'immer';

import * as fromActions from './service-appointment.actions';
import * as fromAdapter from './service-appointment.adapter';
import {VehicleModelYearList} from '../../shared/models/service-appointments/series-data';
import {
  AppointmentStatus,
  factoryRecommendedServices,
  IAsyncData,
  IAvailability,
  IAvailabilityOptions,
  IServiceAppointment,
  IServiceRequest,
  IZipCodeResponse,
  IOpcodeResponse
} from '@signal/asp-data-commons';
import {fromAvailabilityOptions, getDateTime} from '../app.adapter';


export function serviceAppointmentReducer(
  state: ServiceAppointmentState = initialState,
  action: fromActions.ServiceAppointmentType): ServiceAppointmentState {

  switch (action.type) {
    case fromActions.ServiceAppointmentActionTypes.CREATE_SERVICE_APPOINTMENT: {
      return produce(state, draftState => {
        draftState.appointmentForm.isEditMode = false;
        draftState.appointmentForm.editDetails = undefined;
        draftState.appointmentForm = initialAppointment;
      });
    }

    case fromActions.ServiceAppointmentActionTypes.EDIT_SERVICE_APPOINTMENT: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.EditServiceAppointment ? action.payload : undefined;
        draftState.appointmentForm = fromAdapter.appointmentToAppointmentFormProjector(payload.appointment);
        draftState.appointmentForm.currentStep = 0;
        draftState.appointmentForm.isEditMode = true;
        draftState.appointmentForm.editDetails = {...payload,appointment:fromAdapter.editAppointmentDetails(payload.appointment)};
      });
    }

    case fromActions.ServiceAppointmentActionTypes.UPDATE_SERVICE_FORM: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.UpdateServiceForm ? action.payload : undefined;
        draftState.appointmentForm[payload.path] = payload.value;
      });
    }

    case fromActions.ServiceAppointmentActionTypes.UPDATE_CURRENT_STEP: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.UpdateCurrentStep ? action.payload : undefined;
        draftState.appointmentForm.currentStep = payload.step;
      });
    }

    case fromActions.ServiceAppointmentActionTypes.UPDATE_IS_REDIRECTED: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.UpdateIsRedirected ? action.payload : undefined;
        draftState.appointmentForm.isRedirected = payload.isRedirect;
      });
    }

    case fromActions.ServiceAppointmentActionTypes.UPDATE_RECALL_CODES: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.UpdateRecallCodes ? action.payload : undefined;
        draftState.appointmentForm.recallCodes = payload.recallCodes;
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS: {
      const payload = action instanceof fromActions.LoadModelYearAndModels ? action.payload : undefined;
      /* If no need to force reload AND if service requests data is already loaded, return the current state */
      if (!payload.forceReload && state.modelYearAndModels.loaded) {
        return state;
      }
      return produce(state, draftState => {
        draftState.modelYearAndModels = {
          data: {} as IToyotaLexusModelDetails,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadModelYearAndModelsSuccess ? action.payload : undefined;
        draftState.modelYearAndModels = {
          data: payload.vehicleModelYearList,
          loading: false,
          loaded: true,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_MODEL_YEAR_AND_MODELS_FAIL: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadModelYearAndModelsFail ? action.payload : undefined;
        draftState.modelYearAndModels = {
          data: {} as IToyotaLexusModelDetails,
          loading: false,
          loaded: false,
          error: payload.error
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS: {
      const payload = action instanceof fromActions.LoadServiceRequests ? action.payload : undefined;
      return produce(state, draftState => {
        draftState.serviceRequests = {
          data: state.serviceRequests.data,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadServiceRequestsSuccess ? action.payload : undefined;
        draftState.serviceRequests = {
          data: payload.opcodeResponse,
          loading: false,
          loaded: true,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_SERVICE_REQUESTS_FAIL: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadServiceRequestsFail ? action.payload : undefined;
        draftState.serviceRequests = {
          data: {recommended: [], maintenance: [], complaint: [], dms: []},
          loading: false,
          loaded: false,
          error: payload.error
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES: {
      return produce(state, draftState => {
        draftState.availableMileages = {
          data: [],
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadAvailableMileagesSuccess ? action.payload : undefined;
        draftState.availableMileages = {
          data: payload.availableMileages,
          loading: false,
          loaded: true,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABLE_MILEAGES_FAIL: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadServiceRequestsFail ? action.payload : undefined;
        draftState.availableMileages = {
          data: [],
          loading: false,
          loaded: false,
          error: payload?.error
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS: {
      const payload = action instanceof fromActions.LoadAvailabilityOptions ? action.payload : undefined;
      /* If no need to force reload AND if service requests data is already loaded, return the current state */
      if (!payload.forceReload && state.availabilityOptions.loaded) {
        return state;
      }
      return produce(state, draftState => {
        draftState.availabilityOptions = {
          data: {} as IAvailabilityOptions,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadAvailabilityOptionsSuccess ? action.payload : undefined;
        draftState.availabilityOptions = {
          data: payload.availabilityOptions,
          loading: false,
          loaded: true,
          error: ''
        };

        if (!state.appointmentForm.isEditMode) {
          draftState.appointmentForm.availabilityForm = fromAvailabilityOptions(payload.availabilityOptions);
        }
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_FAIL: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadAvailabilityOptionsFail ? action.payload : undefined;
        draftState.availabilityOptions = {
          data: {} as IAvailabilityOptions,
          loading: false,
          loaded: false,
          error: payload.error
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY: {
      return produce(state, draftState => {
        draftState.availability = {
          data: state.availability.data,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadAvailabilitySuccess ? action.payload : undefined;
        /* Only for date change we should not change the already selected date*/
        const availability = state.appointmentForm?.availabilityForm?.availability;
        if (!(availability?.timeSlotId && availability?.appointmentStartDate)) {
          let dateTime;
          if (!availability.appointmentStartDate){
            dateTime = getDateTime(payload.availability);
          }else{
            dateTime = {
              appointmentStartDate: availability.appointmentStartDate,
              timeSlotId: availability.timeSlotId
            };
          }
          draftState.appointmentForm.availabilityForm = {
            transport: state.appointmentForm.availabilityForm.transport,
            remoteZipCode: '',
            availability: {
              ...availability,
              advisorId: payload.loadPayload.advisorId || availability.advisorId,
              ...dateTime,
            }
          };
        }
        draftState.availability = {
          data: payload.availability,
          loading: false,
          loaded: true,
          error: ''
        };

      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_AVAILABILITY_FAIL: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadAvailabilityFail ? action.payload : undefined;
        draftState.availability = {
          data: {} as IAvailability,
          loading: false,
          loaded: false,
          error: payload.error
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.RESET_SERVICE_APPOINTMENT_STATE: {
      return initialState;
    }

    case fromActions.ServiceAppointmentActionTypes.RESET_SERVICE_APPOINTMENT_FORM: {
      return produce(state, draftState => {
        draftState.appointmentForm.isEditMode = false;
        draftState.appointmentForm.editDetails = undefined;
        draftState.appointmentForm = initialAppointment;
      });
    }
    case fromActions.ServiceAppointmentActionTypes.LOAD_STATES_CITIES:{
      return produce(state,draftState=>{
        draftState.stateCitiesFromZip = {
          data: [] as IZipCodeResponse[],
          loading: true,
          loaded: false,
          error: ''
        };
      })
    }
    case fromActions.ServiceAppointmentActionTypes.LOAD_STATES_CITIES_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadStatesAndCitiesSuccess ? action.payload : undefined;
        draftState.stateCitiesFromZip = {
          data: payload.data,
          loading: false,
          loaded: true,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_STATES_CITIES_FAILURE: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadStatesAndCitiesFail ? action.payload : undefined;
        draftState.stateCitiesFromZip = {
          data: [] as IZipCodeResponse[],
          loading: false,
          loaded: false,
          error: payload.error
        };
      });
    }
    case fromActions.ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE:{
      return produce(state,draftState=>{
        draftState.factoryRecommendedService = {
          data: [] as factoryRecommendedServices[],
          loading: true,
          loaded: false,
          error: ''
        };
      })
    }
    case fromActions.ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadRecommendedServiceSuccess ? action.payload : undefined;
        draftState.factoryRecommendedService = {
          data: payload.data,
          loading: false,
          loaded: true,
          error: ''
        };
      });
    }

    case fromActions.ServiceAppointmentActionTypes.LOAD_RECOMMENDED_SERVICE_FAIL: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadRecommendedServiceFail ? action.payload : undefined;
        draftState.factoryRecommendedService = {
          data: [] as factoryRecommendedServices[],
          loading: false,
          loaded: false,
          error: payload.error
        };
      });
    }
    case fromActions.ServiceAppointmentActionTypes.TRIGGER_LOADING: {
      return produce(state, draftState => {
        draftState.serviceRequests.loading = true;
      })
    }
    case fromActions.ServiceAppointmentActionTypes.TRIGGER_LOADING_FAIL: {
      return produce(state, draftState => {
        draftState.serviceRequests.loading = false;
      })
    }
    case fromActions.ServiceAppointmentActionTypes.SET_MAKEMODELYR_PAGE_DEFAULT: {
      return produce(state, draftState => {
        draftState.appointmentForm.vehicleForm.isOwnVehicle = false;
      })
    }

    default:
      return state;
  }
}

/* Initial state */

const initialAppointment: IServiceAppointmentForm = {
  isEditMode: false,
  editDetails: undefined,
  currentStep: 0,
  isRedirected: false,
  appointmentStatus: AppointmentStatus.SCHEDULED,
  recallCodes: [],
  vehicleForm: {
    isOwnVehicle: true,
    isInputVinBased: false,
    vin: '',
    make: '',
    otherMake: '',
    vehicleId: '',
    year: undefined,
    model: '',
    mileage: undefined,
  },
  serviceForm: {
    selectedServices: [],
    isInitialState: true
  },
  availabilityForm: {
    transport: '',
    remoteZipCode: '',
    availability: {
      timeSlotId: '',
      advisorId: '',
      languageId: '',
      appointmentStartDate: ''
    }
  },
  customerForm: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    isRemoteLocation: false,
   customerAddress:{
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
   },
   pickUpAddress:{
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
   },
   dropOffAddress:{
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
   },
   isDropOffAddress: false,
    appointmentComments: ''
  }
};

const initialState = {
  appointmentForm: initialAppointment,
  modelYearAndModels: {
    data: {} as { toyota: VehicleModelYearList, lexus: VehicleModelYearList },
    loading: false,
    loaded: false,
    error: ''
  },
  serviceRequests: {
    data: {recommended: [], maintenance: [], complaint: [], dms: []},
    loading: false,
    loaded: false,
    error: ''
  },
  availableMileages: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  availabilityOptions: {
    data: {} as IAvailabilityOptions,
    loading: false,
    loaded: false,
    error: ''
  },
  availability: {
    data: {} as IAvailability,
    loading: false,
    loaded: false,
    error: ''
  },
  stateCitiesFromZip:{
    data: [] as IZipCodeResponse[],
    loading: false,
    loaded: false,
    error: ''
 },
  factoryRecommendedService:{
    data:[] as factoryRecommendedServices[],
    loading:false,
    loaded:false,
    error:''
  }
};

/* Interfaces */

export interface IFooterSummary {
  key: string;
  value: string | number;
  disclaimer?: string;
}

export interface ServiceAppointmentState {
  appointmentForm: IServiceAppointmentForm;
  modelYearAndModels: IAsyncData<IToyotaLexusModelDetails>;
  serviceRequests: IAsyncData<IOpcodeResponse>;
  availableMileages: IAsyncData<number[]>;
  availabilityOptions: IAsyncData<IAvailabilityOptions>;
  availability: IAsyncData<IAvailability>;
  stateCitiesFromZip:IAsyncData<IZipCodeResponse[]>;
  factoryRecommendedService:IAsyncData<factoryRecommendedServices[]>
}

export interface IEditDetails {
  email: string;
  appointmentId: string;
  appointment: IServiceAppointment;
}

export interface IVehicleForm {
  isOwnVehicle: boolean;
  isInputVinBased: boolean;
  description?: string;
  imageUrl?: string;
  vehicleId?: string;
  vin: string;
  make: string;
  otherMake: string;
  year: string;
  model: string;
  mileage: number;
}

export interface IServiceRequestForm {
  selectedServices: IServiceRequest[];
  isInitialState?: boolean;
}

export interface IAvailabilityForm {
  transport: string;
  remoteZipCode: string;
  availability: {
    languageId: string;
    advisorId: string;
    appointmentStartDate: string;
    timeSlotId: string;
  };
}

export interface ICustomerForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isRemoteLocation: boolean;
 customerAddress?:IAddress;
 pickUpAddress:IAddress;
 dropOffAddress:IAddress,
 isDropOffAddress: boolean;
  appointmentComments: string;
}

export interface IServiceAppointmentForm {
  currentStep?: number;
  isEditMode?: boolean;
  isRedirected?: boolean;
  editDetails?: IEditDetails;
  recallCodes: string[];
  appointmentStatus: AppointmentStatus;
  vehicleForm: IVehicleForm;
  serviceForm: IServiceRequestForm;
  availabilityForm: IAvailabilityForm;
  customerForm: ICustomerForm;
  appointmentId?: string;
}

export interface IToyotaLexusModelDetails {
  toyota: VehicleModelYearList;
  lexus: VehicleModelYearList;
}
 export interface IAddress{
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
 }
