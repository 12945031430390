import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, } from '@angular/core';
import {Linear, Power3, TimelineMax, TweenMax} from 'gsap/all';
import {Brand} from '@signal/asp-data-commons';
import { environment } from './../../../../modules/shared/services/util.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, AfterViewInit {
  @Input() size = 1;
  @Input() text: string;
  @Input() loadingSymbol: string;
  @Input() color: string;
  @Input() asOverlay: boolean;

  /* Toyota vector's paths */
  @ViewChild('pathCenter') pathCenter: ElementRef;
  @ViewChild('pathTop') pathTop: ElementRef;
  @ViewChild('pathOuter') pathOuter: ElementRef;

  /* Lexus vector's path */
  @ViewChild('pathLexus') pathLexus: ElementRef;

  constructor(private readonly hostElement: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.loadingSymbol) {
      this.loadingSymbol = environment.brand === 'lexus' ? Brand.LEXUS : Brand.TOYOTA;
    }
  }

  ngAfterViewInit(): void {
    if (this.loadingSymbol === Brand.TOYOTA) {
      setTimeout(()=>{
        this.animateToyota();
      })
    } else if (this.loadingSymbol === Brand.LEXUS) {
      setTimeout(()=>{
      this.animateLexus();
      });
    }
  }

  animateToyota() {
    const lenOuter = this.pathOuter.nativeElement.getTotalLength();
    const lenTop = this.pathTop.nativeElement.getTotalLength();
    const lenCenter = this.pathCenter.nativeElement.getTotalLength();

    TweenMax.set('#loading-toyota-stroke-outer', {strokeDasharray: lenOuter});
    TweenMax.set('#loading-toyota-stroke-top', {strokeDasharray: lenTop});
    TweenMax.set('#loading-toyota-stroke-center', {strokeDasharray: lenCenter});
    TweenMax.set('#loding-animation', {autoAlpha: 0});

    const tl = new TimelineMax({
      repeat: -1,
      repeatDelay: 0.5,
      ease: Power3.easeOut,
    });

    tl.addLabel('i', 6.5)
      .set('#loading-toyota-stroke-outer', {strokeDashoffset: lenOuter})
      .set('#loading-toyota-stroke-top', {strokeDashoffset: lenTop})
      .set('#loading-toyota-stroke-center', {strokeDashoffset: lenCenter})
      .set('#loding-animation', {autoAlpha: 1})
      .set('#loading-toyota-stroke-center', {autoAlpha: 0})
      .set('#loading-toyota-stroke-outer', {autoAlpha: 0.3})
      .to('#loading-toyota-stroke-outer', 0.6, {
        autoAlpha: 1,
        strokeDashoffset: 0,
        ease: Linear.easeOut,
      })
      .to('#loading-toyota-stroke-top', 0.35, {strokeDashoffset: 0, ease: Linear.easeOut}, '-=0.05')
      .to(
        '#loading-toyota-stroke-center',
        0.35,
        {autoAlpha: 1, strokeDashoffset: 0, ease: Linear.easeIn},
        '-=0.05'
      )
      .to(
        '#loading-toyota-logo',
        0.5,
        {autoAlpha: 0, scale: this.size - 0.02, ease: Linear.easeIn},
        '+=.3'
      );
  }

  animateLexus() {
    const pathLength = this.pathLexus.nativeElement.getTotalLength();

    TweenMax.set('#loading-lexus-stroke', {strokeDasharray: pathLength, autoAlpha: 0.7});
    const tl = new TimelineMax({
      repeat: -1,
      repeatDelay: 0.5,
      ease: Power3.easeOut,
    });

    tl.addLabel('i', 6.5)
      .set('#loading-lexus-stroke', {strokeDashoffset: pathLength})
      .to('#loading-lexus-stroke', 1.2, {
        autoAlpha: 1,
        strokeDashoffset: 0,
        ease: Power3.easeOut,
      })
      .to(
        '#loading-lexus-logo',
        0.4,
        {
          autoAlpha: 0,
          scale: this.size - 0.03,
        },
        '+=.2'
      );
  }
}
