import {AppointmentStatus, AppointmentType, IAppointment, IVehicle} from '@signal/asp-data-commons';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FeatureKeys} from '../app.store';
import {DashboardState} from './dashboard.reducer';
import * as fromAdapter from './dashboard.adapter';

/* NgRx Selectors */

/* To select the Dashboard feature state from the app state */
export const selectDashboardFeature = createFeatureSelector<DashboardState>(FeatureKeys.DASHBOARD);

/* ----------------- User Vehicles -------------------------- */
/* To select the user vehicles loading flag in the dashboard state */
export const selectUserVehiclesLoading = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.userVehiclesLoading
);

export const selectUserVehiclesAndAppointmentsLoading = createSelector(
  selectDashboardFeature,
  (state : DashboardState) => { return {vechilesStatus : state.userVehiclesLoading , appointmentStatus : state.appointmentsLoading}}
)


/* To select user vehicles in the dashboard state */
export const selectUserVehicles = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.userVehicles
);

/* To select user vehicles and copy from the dashboard state */
export const selectUserVehiclesCopy = createSelector(
  selectUserVehicles,
  (userVehicles: IVehicle[]) => fromAdapter.copyUserVehiclesProjector
);

/* ----------------- Appointments -------------------------- */
/* To select the appointments loading flag in the dashboard state */
export const selectAppointmentsLoading = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.appointmentsLoading
);

/* To select the appointments loaded flag in the dashboard state */
export const selectAppointmentsLoaded = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.appointmentsLoaded
);

/* To select all the appointments in the dashboard state */
export const selectAppointments = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.appointments
);

export const selectAppointmentsForVin = createSelector(
  selectDashboardFeature,
  (state: DashboardState) => state.appointmentsforvin
);

/* To select only the 'TEST_DRIVE' appointments in the dashboard state */
export const selectTestDriveAppointments = createSelector(
  selectAppointments,
  (appointments: IAppointment[]) =>
    appointments.filter(a => a.appointmentType === AppointmentType.TEST_DRIVE)
);

/* To select only the 'TEST_DRIVE' appointments count in the dashboard state */
export const selectTestDriveAppointmentsCount = createSelector(
  selectTestDriveAppointments,
  (appointments: IAppointment[]) => appointments.length
);

/* To select only the 'DELIVERY' appointments in the dashboard state */
/* saved for later appointment to be filtered out */
export const selectDeliveryAppointments = createSelector(
  selectAppointments,
  (appointments: IAppointment[]) =>
    appointments.filter(a => a.appointmentType === AppointmentType.DELIVERY && a.appointmentStatus !== AppointmentStatus.SAVED)
);
/* To select only the 'LEARNING' appointments in the dashboard state */
export const selectLearningAppointments = createSelector(
  selectAppointments,
  (appointments: IAppointment[]) =>
    appointments.filter(a => a.appointmentType === AppointmentType.LEARNING)
);

/* To select only the 'DELIVERY' appointments count in the dashboard state */
export const selectDeliveryAppointmentsCount = createSelector(
  selectDeliveryAppointments,
  (appointments: IAppointment[]) => appointments.length
);

/* To select only the 'LEARNING' appointments count in the dashboard state */
export const selectLearningAppointmentsCount = createSelector(
  selectLearningAppointments,
  (appointments: IAppointment[]) => appointments.length
);
/* To select only the 'SERVICE' appointments in the dashboard state */
export const selectServiceAppointments = createSelector(
  selectAppointments,
  (appointments: IAppointment[]) =>
    appointments.filter(a => a.appointmentType === AppointmentType.SERVICE)
);

/* To select only the 'SERVICE' appointments count in the dashboard state */
export const selectServiceAppointmentsCount = createSelector(
  selectServiceAppointments,
  (appointments: IAppointment[]) => appointments.length
);

/* To select only the 'TEST_DRIVE' appointments in the dashboard state and
* convert it into IDashboardVehicle[] to display in the dashboard */
export const selectTestDriveApptForDashboard = createSelector(
  selectTestDriveAppointments,
  fromAdapter.apptToDashboardVehicleProjector
);

/* To select only the 'DELIVERY' appointments in the dashboard state and
* convert it into IDashboardVehicle[] to display in the dashboard */
export const selectDeliveryApptForDashboard = createSelector(
  selectDeliveryAppointments,
  fromAdapter.apptToDashboardVehicleProjector
);

/*To select only the 'LEARNING appointments in the dashboard state and
*convert it into IDashboardVechicle[] to display in the dashboard */
export const selectLearningApptForDashboard = createSelector(
  selectLearningAppointments,
  fromAdapter.apptToDashboardVehicleProjector
);
/* To select only the 'LEARNING' appointments in the dashboard state and
* convert it into IDashboardVehicle[] to display in the dashboard */

/* To select only the 'Learning' appointments in the dashboard state and
* convert it into IDashboardVehicle[] to display in the dashboard */
export const selectLearningAppointmentsForDashboard = createSelector(
  selectUserVehicles,
  selectLearningAppointments,
  fromAdapter.mergeUserVehiclesAndAppointmentsProjector
);

/* To select only the 'SERVICE' appointments in the dashboard state and
* convert it into IDashboardVehicle[] to display in the dashboard */
export const selectServiceApptForDashboard = createSelector(
  selectUserVehicles,
  selectServiceAppointments,
  fromAdapter.mergeUserVehiclesAndAppointmentsProjector
);
export const selectServiceAppointmentsForDashboard = createSelector(
  selectUserVehicles,
  selectServiceAppointments,
  fromAdapter.getAllVehiclesAppointments
);

/* To select an appointment by appointmentId */
export const selectAppointmentById = createSelector(
  selectAppointments,
  (appointments: IAppointment[], props: { appointmentId: string }) =>
    appointments.find(a => a.appointmentId === props.appointmentId)
);

/* To select the whether an appointment is loaded in the dashboard state by its id */
export const selectAppointmentsLoadedById = createSelector(
  selectAppointmentById,
  (appointment: IAppointment, props: { appointmentId: string }) => !!appointment
);

/* To select whether an appointment is being cancelled by appointmentId */
export const selectIsBeingCancelledByAppointmentId = createSelector(
  selectAppointments,
  (appointments: IAppointment[], props: { appointmentId: string }) =>
    appointments.find(a => a.appointmentId === props.appointmentId)?.appointmentStatus === AppointmentStatus.CANCELLING
);

export const selectMyUserVehicles = createSelector(
  selectUserVehicles,
  fromAdapter.vehiclesToDashboardVehiclesProjector
);
export const selectMyUserVehiclesCount = createSelector(
  selectMyUserVehicles,
  (vehicles:any[]) => vehicles.length
);
