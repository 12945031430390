import {Component, forwardRef, Input, OnDestroy, OnInit, OnChanges, ChangeDetectorRef} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import {countryCd} from '../../shared/services/util.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'asp-vehicle-configurator',
  templateUrl: './vehicle-configurator.component.html',
  styleUrls: ['./vehicle-configurator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VehicleConfiguratorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VehicleConfiguratorComponent),
      multi: true
    }
  ]
})
export class VehicleConfiguratorComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() vehicleDetails;
  @Input() isEditMode : boolean;
  @Input() vin;
  @Input() withoutFleetVehicle;
  @Input() appt;

  vehicle: FormGroup;
  otherVehicles: FormArray;
  subscription: Subscription;
  disabled: boolean;
  modelList$: any;
  vehicleList$: any;
  vehicleFromList$: any;
  vehicleListColor$: any;
  countryCode: string = 'USA';
  anyTrimMarketingTitle = "anytrim$%$marketingTitle";
  isUpdated = false;
  vehicleNotFoundReset: boolean = false;

  constructor(private readonly formBuilder: FormBuilder,
    private bannerService:AspBannerService,
    private cdr: ChangeDetectorRef,
    private readonly translate : TranslateService) {
    this.vehicle = this.formBuilder.group({
      year: [''],
      model: [''],
      trim: [''],
      exteriorColor: [''],
      vin:[''],
      imageUrl:[''],
      make:['']
    });

    this.subscription = this.vehicle.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
    });

    this.countryCode = countryCd;
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
    if(this.isUpdated) {
      this.vehicle.patchValue({description:""});
    }
     /* *this condition is used to deal with if appointment vehicle is deleted or status is false then reset vehicle section*/
     if(this.vehicleNotFoundReset) {
      this.vehicleNotFoundReset = false;   
      this.vehicle.reset();
    }
  }

  ngOnChanges(change){
    /* *this condition is used to deal with if appointment vehicle is deleted or status is false */
    if(!change?.appt?.currentValue && !this.isUpdated) {
      this.isUpdated = true;
      this.vehicleNotFoundReset = true;   
    }
    if(change?.appt?.currentValue && change?.appt?.currentValue !== change?.appt?.previousValue && !this.isUpdated){
      this.vehiclesForThatYear(String(change.appt.currentValue.year))
      this.vehiclesForMoodel(change.appt.currentValue.model)
      this.vehiclesForTrim(this.vehicleList$[0].trimCode,this.vehicleList$[0].marketing_title)
      this.isUpdated = true
    }
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get f() { return this.vehicle.controls; }

  compareWith(o1: any, o2: any) {
    return o1 === o2;
  }

  get value(): any {
    return this.vehicle.value;
  }

  set value(value: any) {
    if (this.disabled) { return; }
    if(!value.year && !value.make) { return; }
    this.vehicle.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.vehicle.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.vehicle.valid ? null : { vehicle: { valid: false } };
  }

  vehiclesForThatYear(year: any){
    this.vehicle.patchValue({year, model:"", trim:"", exteriorColor:"", vin:"", imageUrl:""});
    this.vehicleList$ = [];
    this.vehicleFromList$ = [];
    this.vehicleListColor$ = [];
    this.bannerService.update('');

    this.modelList$ = this.vehicleDetails.find(d => d.year === year);
  }

  vehiclesForMoodel(model: any){
    /* *To handle if vehicle selction is changed after selecting one value */
    this.vehicle.patchValue({model, trim:"", exteriorColor:"", vin:"", imageUrl:""});
    
    this.vehicleList$ = [];
    this.vehicleFromList$ = [];
    this.vehicleListColor$ = [];

    let modelData = this.modelList$?.series_List.find(d => d.model === model);
    this.vehicleList$ = this.convertObjectToArray({...modelData?.trims}, "marketing_title", "trimCode", "vehicle_List");
    let testArr=[]
    this.vehicleList$.forEach(d=>{
      testArr = [...testArr,...d.vehicle_List];
    })
    const testObj = {
      "marketing_title": this.anyTrimMarketingTitle,
      "trimCode": "anytrim",
      "vehicle_List": testArr
    }
    this.vehicleList$.push(testObj);
    this.vehicleList$ = this.vehicleList$.filter(d=>!d.marketing_title.includes('$%$undefined'));
    this.vehiclesForTrim("anytrim", this.anyTrimMarketingTitle);
  }

  convertObjectToArray(data: any, key1: string, key2: string, key3: string){
    return Object.entries(data).map(([key, value]) => ({ [key1]: key, [key2]: value[0].trim, [key3]: value }));
  }

  convertObjectToArrayOfColor(data: any, key1: string, key2: string, key3: string,key4:string = null){
    return Object.entries(data).map(([key, value])=>{
      let obj = {
        [key1] : value[0].exteriorColorCode,
        [key2] : value[0].exteriorColorHexCode,
        [key3] : value[0].imageUrl,
      }
      if(key4){
        obj[key4] = value[0].exteriorColorName;
      }
      return obj
    })
  }

  vehiclesForTrim(trimCode: any, marketing_title: any){
    let result = this.vehicleList$.find(d => (d.trimCode === trimCode && d.marketing_title === marketing_title));
    this.vehicleListColor$ = [];

    if(trimCode === "anytrim" && marketing_title === this.anyTrimMarketingTitle) {
      this.autoSelectTrim(result);
      return;
    }

    /* *LMEX vehicles we are not able to find colorHexcode so, we are not able to group these vehicle properly */
    if(this.countryCode.toUpperCase().includes('US')) {
      /* grouping vehicle with different vin under hexcode, same hexcode will be group together*/
      this.vehicleFromList$ = result?.vehicle_List.reduce((r, a)=> {
        r[a.exteriorColorHexCode] = r[a.exteriorColorHexCode] || [];
        r[a.exteriorColorHexCode].push(a);
        return r;
      }, Object.create(null));

      this.vehicleListColor$ = (this.vehicleFromList$)? this.convertObjectToArrayOfColor(this.vehicleFromList$, "id", "colorCode", "carImage","colorName"): [];
      // ByDefault color selection for first color from vehicleListColor$ list array.
      if(this.vehicleListColor$.length>0){
        this.vehicle.get('exteriorColor').patchValue(this.vehicleListColor$[0].id);
        this.vehicleColorSelected();
      }
    } else {
      this.vehicleSelectionWithoutColor(result);
    }
  }

  autoSelectTrim(data: any){
    const {imageUrl, make} = data?.vehicle_List[0];  
    const vinList = data?.vehicle_List.map(d=>d.vin).join(',');
    this.vehicle.patchValue({make, vin: vinList, imageUrl, trim: "anytrim", exteriorColor: ""});
  }

  vehicleColorSelected() {  
    const hexCodeValues = this.vehicleListColor$.find(d => d.id === this.vehicle.controls.exteriorColor.value)
    let vinList = this.vehicleFromList$[hexCodeValues.colorCode].map(data=>data.vin).join(',');
    let oneVehicle = this.vehicleFromList$[hexCodeValues.colorCode][0];
    let {make, imageUrl} = oneVehicle;
    if(!imageUrl) {
      imageUrl = "";
    }
    this.vehicle.patchValue({make, vin: vinList, imageUrl});
    this.bannerService.update(imageUrl.substring(1));
    console.log("color and vehicles Selected",oneVehicle);
  }

  vehicleSelectionWithoutColor(data: any){
    let {make, imageUrl} = data?.vehicle_List[0];  
    let vinList = data?.vehicle_List.map(d=>d.vin).join(',');
    this.vehicle.patchValue({make, vin: vinList, imageUrl});
    console.log("vehicle without color selected", this.vehicle.value);
  }

  trimTranslate(tTrim){
    const temp = tTrim.marketing_title.split('$%$')[1];
    if (temp === 'undefined' || temp === 'marketingTitle') {
      return this.translate.instant('testDrive.anyTrim');
    }
    return temp; 
  }
}
