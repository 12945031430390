import {IAsyncData, IDealerInfo} from '@signal/asp-data-commons';
import * as fromActions from './dealer.actions';
import produce from 'immer';
import { StateDetails } from './../../shared/models/state';


export interface DealerState {
  settings: any;
  loading: boolean;
  loaded: boolean;
  error: string;
  dealerInfo: IAsyncData<IDealerInfo>;
  states: IAsyncData<StateDetails[]>;
  cities: IAsyncData<{ [key: string]: string[] }>;
  dealerCode: string;
  department: string;
}

const initialState = {
  settings: {},
  loading: false,
  loaded: false,
  error: '',
  dealerInfo: {
    data: null,
    loading: false,
    loaded: false,
    error: ''
  },
  states: {
    data: null,
    loading: false,
    loaded: false,
    error: ''
  },
  cities: {
    data: null,
    loading: false,
    loaded: false,
    error: ''
  },
  dealerCode: '',
  department: '',
};

export function dealerReducer(
  state: DealerState = initialState,
  action: fromActions.DealerType): DealerState {

  switch (action.type) {
    case fromActions.DealerTypes.LOAD_DEALER_DETAILS: {
      return produce(state, draftState => {
        draftState.dealerInfo = {
          data: undefined,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.DealerTypes.LOAD_DEALER_DETAILS_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadDealerDetailsSuccess ? action.payload : undefined;
        draftState.dealerInfo = {
          data: payload.dealerInfo,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.DealerTypes.LOAD_DEALER_DETAILS_FAILURE: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadDealerDetailsFail ? action.payload : undefined;
        draftState.dealerInfo = {
          data: {} as IDealerInfo,
          loading: true,
          loaded: false,
          error: payload.error
        };
      });
    }

    case fromActions.DealerTypes.LOAD_SETTINGS: {
        return produce(state, draftState => {
            draftState.settings = null;
            draftState.loaded = false;
            draftState.loading = true;
            draftState.error = '';
        });
    }
    case fromActions.DealerTypes.LOAD_SETTINGS_SUCCESS: {
        return produce(state, draftState => {
            draftState.settings = action instanceof fromActions.LoadSettingsSuccess ? action.payload.settings : {};
            draftState.loaded = true;
            draftState.loading = false;
            draftState.error = '';
        });
    }
    case fromActions.DealerTypes.LOAD_SETTINGS_FAILURE: {
        return produce(state, draftState => {
            draftState.settings = {};
            draftState.loaded = true;
            draftState.loading = false;
            draftState.error = action instanceof fromActions.LoadSettingsFailure ? action.payload.error : '';
        });
    }

    case fromActions.DealerTypes.LOAD_STATES: {
      return produce(state, draftState => {
        draftState.states = {
          data: [],
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.DealerTypes.LOAD_STATES_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadStatesDetailsSuccess ? action.payload : undefined;
        draftState.states = {
          data: payload.states,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.DealerTypes.LOAD_STATES_FAILURE: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadStatesDetailsFail ? action.payload : undefined;
        draftState.states = {
          data: [],
          loading: true,
          loaded: false,
          error: payload.error
        };
      });
    }

    case fromActions.DealerTypes.LOAD_CITIES: {
      return produce(state, draftState => {
        draftState.cities = {
          data: {},
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.DealerTypes.LOAD_CITIES_SUCCESS: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadCityDetailsSuccess ? action.payload : undefined;
        draftState.cities = {
          data: payload.cities,
          loading: true,
          loaded: false,
          error: ''
        };
      });
    }

    case fromActions.DealerTypes.LOAD_CITIES_FAILURE: {
      return produce(state, draftState => {
        const payload = action instanceof fromActions.LoadCityDetailsFail ? action.payload : undefined;
        draftState.cities = {
          data: {},
          loading: true,
          loaded: false,
          error: payload.error
        };
      });
    }

    default:
      return state;
  }

}
