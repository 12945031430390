import { ISalesAppointmentForm, IAvail } from './../store/sales-appointment/sales-appointment.reducer';
import { AnalyticsService } from './../shared/services/analytics.service';
import { AuthService } from './../shared/services/auth.service';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { AspBannerService } from '../shared/components/asp-banner/asp-banner.service';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Model, Trim, VehicleDetails } from '../shared/models/sales-appointments/series-data';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, combineLatest } from 'rxjs';
import { SalesAppointmentState } from '../store/sales-appointment/sales-appointment.reducer';
import { DashboardState } from '../store/dashboard/dashboard.reducer';
import * as fromActions from '../store/sales-appointment/sales-appointment.actions';
import * as fromSelectors from '../store/sales-appointment/sales-appointment.selectors';
import { UpsertAppointment } from '../store/dashboard/dashboard.actions';
import { SubscriptionList } from '../shared/models/asp.types';
import { getRouteFromWindow, GLOBAL_CONSTANT, navigateToRoutePromise, unsubscribeSubscriptions } from '../shared/services/util.service';
import _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { ICustomer, IAvailabilityOptions, IAvailability, LocationType, IDateAvailability, AppointmentType, IVehicle, IAppointment, ITestDriveVehicleInventory, Department } from '@signal/asp-data-commons';
import { ITransportOption } from '@signal/asp-data-commons/lib/interfaces/general-setttings';
import { DateTime } from 'luxon';
import uuidv4 from 'uuidv4';
import { ServiceAppointmentState } from '../store/service-appointment/service-appointment.reducer';
import { TranslateService } from '@ngx-translate/core';
import { DealerState } from '../store/dealer/dealer.reducer';
import * as fromDealerSelector from '../store/dealer/dealer.selectors';

@Component({
  selector: 'app-test-drive',
  templateUrl: './test-drive.component.html',
  styleUrls: ['./test-drive.component.scss']
})
export class TestDriveComponent implements OnInit, OnDestroy {
  testDriveForm: FormGroup;
  signedIn: boolean;
  appointmentId: string;
  isSubmitting: boolean;
  isUpdate = false;
  isStoreUpdate = false;
  isValidRemoteZip = false;
  hasRemoteLocation = false;
  validatedFields = ['year', 'model', 'trim'];
  otherVehicleChecked = false;
  private readonly analyticsSessionId: string;
  remoteValidZipcodes: { pmaZipCodes: string[], isRemote: boolean };
  /* All 4 steps are sub form - Accessible only through the main form */
  private readonly vehicleForm: FormControl;
  private otherVehiclesForm: FormArray;
  private readonly tradeInForm: FormControl;
  availability: FormControl;
  private readonly customerForm: FormControl;
  private readonly contactEmailInfoForm: FormControl;

  availabilityOptions$: Observable<IAvailabilityOptions>;
  availabilityOptionsLoading$: Observable<boolean>;
  availability$: Observable<IAvailability>;
  availabilityLoading$: Observable<boolean>;
  transportOptions$: Observable<ITransportOption[]>;
  remoteZipcodes$: Observable<string[]>;
  yearList$: Observable<number[]>;
  modelList$: Array<Observable<Model[]>> = [];
  trimList$: Array<Observable<Trim[]>> = [];
  vehicleDetails$: Observable<VehicleDetails>;
  fullDataWithYear$: Observable<any>;
  withoutFleetVehicle: boolean = false;
  vinTestDrive: string = '';
  testDriveVins: string = '';
  editBool:boolean = false;
  fullDataWithYear;
  appointmentVehicle;
  isLoaded$: Observable<boolean>;
  fullDataWithYearSchedule$: Observable<any>;

  subscriptions: SubscriptionList = {};

  profile: any;

  private lastValue: any;
  private storeValue: any;
  private signedInUserDetails: ICustomer;
  department = Department.SALES;
  apptType:string=AppointmentType.TEST_DRIVE;
  languageId='';
  vehicleDetails;
  bookingFromDirectVin:boolean = false;
  hideSalesConsultant=false;

  constructor(
    private readonly viewportScroller: ViewportScroller,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly bannerService: AspBannerService,
    private readonly formBuilder: FormBuilder,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly dashboardState: Store<DashboardState>,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly dealerState: Store<DealerState>
    ) {
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.authService.user.subscribe(user => {
      this.profile = user;
    });

    this.initializeTestDriveForm();

    this.route.queryParams.subscribe(params => {
      if (params && params.vin && params.vin.length === 17) {
        this.vinTestDrive = params.vin;
        const vehicle = {} as IVehicle;
        vehicle.vin = this.vinTestDrive
        // this.bookingFromDirectVin = true;
        // this.salesAppointmentState.dispatch(new fromActions.LoadTestDriveVinVehicle
        //   ({dealerCode: params.dealerCd, vehicle} ));
      } else {
        this.vinTestDrive = '';
        this.appointmentId = params.id;
        if (this.appointmentId) {
          this.salesAppointmentState.dispatch(new fromActions.LoadInventoryVehicle());
          this.salesAppointmentState.dispatch(new fromActions.GetEditData
            ({ appointmentId: this.appointmentId, appointmentType: AppointmentType.TEST_DRIVE }));
          //this.isLoaded$ = this.salesAppointmentState.select(fromSelectors.selectVehiclesLoaded)
          combineLatest([
            this.salesAppointmentState.select(fromSelectors.getAllVehicleInventoryList),
            this.salesAppointmentState.select(fromSelectors.appointmentDetails)
          ]).subscribe(([fullData, appointmentVehicle]) =>{
             if(fullData?.length > 0 && appointmentVehicle?.vehicle?.year){
              this.fullDataWithYear = fullData;
              this.appointmentVehicle = this.findNestedObj(fullData, 'vin', appointmentVehicle.vehicle.vin);
              this.isUpdate = true;
        }});
        }
      }

    });
    this.analyticsSessionId = uuidv4();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  /* *this function is used to deal with if appointment vehicle is deleted or status is false */
  findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });

    if(!foundObj) {
        return null;
    }

    return foundObj;
  };

  ngOnInit() {
    const pageLoadTrackingData = {
      content_section: 'schedule test drive'
    };
    this.analyticsService.customTrackLink('km-satd-shown', pageLoadTrackingData, true)
    this.viewportScroller.scrollToPosition([0, 0]);
    this.bannerService.update('');
    this.bannerService.show();
    this.setupStoreValue();
    // this.subscriptions.route = this.route.queryParams.subscribe(params => {
    //   this.appointmentId = params.id;
    //   if (this.appointmentId) {
    //     this.editBool = true;   
    //     this.salesAppointmentState.dispatch(new fromActions.GetEditData
    //       ({appointmentType: AppointmentType.TEST_DRIVE, appointmentId: this.appointmentId }));
       
    //       this.subscriptions.appointmentDetails = this.salesAppointmentState.select(fromSelectors.appointmentDetails).subscribe((value) =>{
          
    //         if (_.isEqual(this.lastValue, value)) {
    //           return;
    //         }
    //         this.lastValue = value;
    //         console.log('vaa')
    //         console.log(value)
    //         this.testDriveForm.patchValue(value);
    //       })

    //   }
    // }); 
    this.dealerState.select(fromDealerSelector.selectSalesDetails).subscribe((data:any) =>{
      this.hideSalesConsultant = data?.hideSalesConsultant || false;
    });
  }

  initializeTestDriveForm() {
    this.initializeOtherVehicleForm();

    /* Initialize main form group with sub forms */
    this.testDriveForm = this.formBuilder.group({
      vehicle: this.vehicleForm,
      otherVehicles: this.otherVehiclesForm,
      tradeIn: this.tradeInForm,
      transportationTypeCode: ['', [Validators.required]],
      remoteZipCode: [''],
      availability: this.availability,
      customer: this.contactEmailInfoForm,
      address: this.customerForm
    });

    this.testDriveForm.controls.remoteZipCode.setValidators(this.zipValidator());
    this.onValueChange();
  }

  initializeOtherVehicleForm() {
    this.otherVehiclesForm = new FormArray([new FormGroup({
      year: new FormControl(''),
      model: new FormControl(''),
      trim: new FormControl('')
    })]);
  }

  zipValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.testDriveForm.value.transportationTypeCode === 'REMOTE' && !this.isValidRemoteZip) {
        return { remoteZipCode: this.translate.instant('appointmentInformation.invalidRemotezipcode') };
      } else {
        return null;
      }
    };
  }


  setSignedInUserDetails() {
    if (this.signedIn) {
      this.signedInUserDetails = {
        firstName: this.profile.given_name,
        lastName: this.profile.family_name,
        emailAddress: this.profile.email,
        phoneNumber: ''
      };
      this.salesAppointmentState.dispatch(new fromActions.LoadCustomerDetails({ signedInUserDetails: this.signedInUserDetails }));
    }
  }

  checkZipCodes(value) {
    this.testDriveForm.controls.remoteZipCode.clearValidators();
    if (this.testDriveForm.value.transportationTypeCode === 'REMOTE') {
      this.remoteZipcodes$.pipe(
        filter(zip => !!zip?.length),
        take(1))
        .subscribe(zip => {
          this.remoteValidZipcodes = { pmaZipCodes: zip, isRemote: true };
          this.isValidRemoteZip = zip?.findIndex(x => x === value) !== -1;
          this.testDriveForm.controls.remoteZipCode.setValidators(this.zipValidator());
          this.testDriveForm.controls.remoteZipCode.updateValueAndValidity();
        });
    }
  }

  setupStoreValue() {
    this.setSignedInUserDetails();
    // this.salesAppointmentState.dispatch(new fromActions.LoadSeriesAndTrim());
    this.salesAppointmentState.dispatch(new fromActions.LoadAvailabilityOptions({appointmentType: AppointmentType.TEST_DRIVE}));

    // this.salesAppointmentState.select(fromSelectors.getAvailabilityDetails)
    //   .pipe(take(1))
    //   .subscribe(details => {
    //     if (this.isUpdate && details.appointmentStartDate !== "") {
    //       const date = DateTime.fromFormat(details.appointmentStartDate, 'yyyy-MM-dd')
    //         .startOf('month')
    //         .toFormat('yyyy-MM-dd');
    //       this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
    //         { date, advisorId: details.advisorId, transport: details.transport }));
    //     }
    //   });

    this.salesAppointmentState.dispatch(new fromActions.LoadInventoryVehicle());
    if(this.isUpdate || !!this.vinTestDrive) { //for reschedule flow
      //this.fullDataWithYear$ = this.salesAppointmentState.select(fromSelectors.getAllVehicleInventoryList);
    }
    else if(!(this.isUpdate || !!this.vinTestDrive)) { //for schedule flow
      this.fullDataWithYearSchedule$ = this.salesAppointmentState.select(fromSelectors.getValidVehicleInventoryList);
    }

    if (this.signedIn) {
      this.salesAppointmentState.dispatch(new fromActions.LoadCustomerDetails({ signedInUserDetails: this.signedInUserDetails }));
    }

    this.availabilityOptions$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptions);
    this.availabilityOptionsLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptionsLoading);
    this.availability$ = this.salesAppointmentState.select(fromSelectors.getAvailability);
    this.availabilityLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityLoading);
    this.transportOptions$ = this.salesAppointmentState.select(fromSelectors.getTransportOptions);
    this.remoteZipcodes$ = this.salesAppointmentState.select(fromSelectors.getPMAZipCodes);
    this.yearList$ = this.salesAppointmentState.select(fromSelectors.getYearList);

    this.availabilityOptions$.subscribe(options => {
      this.hasRemoteLocation = !!options?.transport?.transportOptions?.find(x => x.category === LocationType.REMOTE && x.isActive && x.enableTestDriveType);
    });

    this.subscriptions.formStateValueSub = this.salesAppointmentState.select(fromSelectors.appointmentDetails).subscribe((data) => {
      let value = {...data};
      delete value.checklistRequest;
      delete value.appointmentComments;

      if (_.isEqual(this.lastValue, value)) {
        return;
      }

      if (value?.vehicle?.model && !value?.vehicle?.vin &&  this.lastValue?.vehicle?.model !== value?.vehicle?.model) {
        this.vehicleDetails$ = this.salesAppointmentState.select(fromSelectors.getVehicleDetails,
          { model: value.vehicle?.model, year: parseInt(value.vehicle?.year) || 0 });
      }
      if (value?.vehicle?.vin  && this.lastValue?.vehicle?.vin !== value?.vehicle?.vin) {
        const vehicle = { vin: value.vehicle.vin } as IVehicle;
        // this.salesAppointmentState.dispatch(new fromActions.LoadTestDriveVinVehicle
        //   ({ vehicle }));
      }
      if (value?.vehicle?.trim && this.lastValue?.vehicle?.trim !== value?.vehicle?.trim) {
        if (this.isUpdate || this.vinTestDrive) {
          this.bannerService.update(value.vehicle.imageUrl);
        } 
        // else {
          // this.salesAppointmentState.dispatch(
          //   new fromActions.LoadExteriorColors({ year: value.vehicle.year?.toString(), model: value.vehicle.model, trim: value.vehicle.trim }));
        // }

      }
      if(this.bookingFromDirectVin && value.vehicle?.make === "vinIsOffline") {
        alert(this.translate.instant('testDrive.thisVehicleIsNotAvailableForTheTestDrive'));
      }
      /** this is commented for vinbased 

      this.vehicleDetails = value.vehicle;
      if(this.vehicleDetails?.imageUrl) {
        this.bannerService.update(this.vehicleDetails.imageUrl);
      }
      
      if(this.bookingFromDirectVin && value.vehicle?.make === "vinIsOffline") {
        alert(this.translate.instant('testDrive.thisVehicleIsNotAvailableForTheTestDrive'));
      }

      this.vehicleDetails = value.vehicle;
      if(this.vehicleDetails?.imageUrl) {
        this.bannerService.update(this.vehicleDetails.imageUrl);
      }
    */
      this.languageId=value?.availability?.languageId;
      if(!this.bookingFromDirectVin) {
        this.lastValue = value;
      }
      this.isStoreUpdate = true;

      /*isUpdate -> During edit mode we need to store the state value thats coming in,
       so that we can check the incoming form changes against storeValue
       isStoreUpdate -> Phase 2 (basically used when changes are invoked from state during create mode)*/
      if (this.isUpdate || this.isStoreUpdate) {
        const obj = { ...value };
        delete obj.appointmentComments;
        this.storeValue = { ...obj };
      }
      const obj = { ...value };
      delete obj.appointmentComments;
      this.testDriveForm.patchValue(obj);
      this.checkZipCodes(value.remoteZipCode);
      this.setOtherVehicles(value);
    });

  }

  setOtherVehicles(data) {
    if (this.isUpdate && data.otherVehicles) {
      this.otherVehicleChecked = data.otherVehicles[0] && !!data.otherVehicles[0].year;
      data.otherVehicles.forEach((element, i) => {
        if (!this.otherVehiclesArray.controls[i]) {
          this.otherVehiclesArray.push(new FormGroup({
            year: new FormControl(element.year),
            model: new FormControl(element.model),
            trim: new FormControl(element.trim)
          }));
        } else {
          this.otherVehiclesArray.controls[i].patchValue(new FormGroup({
            year: new FormControl(element.year),
            model: new FormControl(element.model),
            trim: new FormControl(element.trim)
          }));
        }
        this.otherVehiclesArray.controls[i].setValidators(this.setOtherVehicleValidator());
      });
    }
  }

  onValueChange() {
    const vehicleTrackingData = {
      content_section: 'schedule test drive',
      link_module: ': choose your vehicle'
    }

    this.subscriptions.testDriveSub = this.testDriveForm.valueChanges.subscribe(value => {
      if (value.vehicle && value.vehicle.year && this.lastValue && this.lastValue.vehicle.year !== value.vehicle.year) {
        const yearTrackingData = {
          ...vehicleTrackingData,
          link_text: 'year',
          link_input_field: value.vehicle.year
        };
        this.analyticsService.customTrackLink('km-satd-start', yearTrackingData, true)
      }
      // reset color and car image on changing the model
      // if (value.vehicle && value.vehicle.model && this.lastValue && this.lastValue.vehicle.model !== value.vehicle.model) {
      //   const modelTrackingData = {
      //     ...vehicleTrackingData,
      //     link_text: 'model',
      //     link_input_field: value.vehicle.model
      //   };
      //   this.analyticsService.trackLink(modelTrackingData, true)
      //   this.salesAppointmentState.dispatch(new fromActions.ResetExteriorColors());
      //   this.bannerService.update('');
      // }

      // to hit the EFC API for color and carImage only when trim is changed
      // if (value.vehicle && value.vehicle.trim && this.lastValue && this.lastValue.vehicle.trim !== value.vehicle.trim) {
      //   const trimTrackingData = {
      //     ...vehicleTrackingData,
      //     link_text: 'trim',
      //     link_input_field: value.vehicle.trim
      //   };
      //   this.analyticsService.trackLink(trimTrackingData, true)
      //   this.salesAppointmentState.dispatch(
      //     new fromActions.LoadExteriorColors({ year: value.vehicle.year, model: value.vehicle.model, trim: value.vehicle.trim }));
      // }

      // if (value.vehicle && value.vehicle.exteriorColor && this.lastValue && this.lastValue.vehicle.exteriorColor !== value.vehicle.exteriorColor) {
      //   const exteriorColorTrackingData = {
      //     ...vehicleTrackingData,
      //     link_text: 'exteriorColor',
      //     link_input_field: value.vehicle.exteriorColor
      //   };
      //   this.analyticsService.trackLink(exteriorColorTrackingData, true)
      // }

      //  initialize array of observables from otherVehicle FormArray
      this.setObservablesForOtherVehicles(value);
      if (value.vehicle?.vin && (this.vinTestDrive || this.isUpdate)) {
        // this.vehicleDetails$ = this.salesAppointmentState.select(fromSelectors.getVehicleDetails,
        this.vehicleDetails$ = this.salesAppointmentState.select(fromSelectors.getVehicleDetailsForVin,
          { model: value.vehicle?.model, year: value.vehicle?.year });

      } else {
        // initializing vehicle-configurators selector
        this.vehicleDetails$ = this.salesAppointmentState.select(fromSelectors.getVehicleDetails,
          { model: value.vehicle?.model, year: value.vehicle?.year });
      }

      // to disable vehicle in Edit Mode
      if (this.subscriptions.vehicleDetailSub) {
        this.subscriptions.vehicleDetailSub.unsubscribe();
      }
      this.subscriptions.vehicleDetailSub = this.vehicleDetails$.subscribe(item => {
        if (item.modelList.length !== 0 && item.exteriorColors.length !== 0 && this.isUpdate) {
          if (value.vehicle.exteriorColor) {
            this.bannerService.update(item.exteriorColors.find(x => x.id === value.vehicle.exteriorColor)?.carImage);
          }
        }
      });

      /*Updating storeValue = undefined everytime the form is updated to the initial changes from the state */
      if (this.storeValue && _.isEqual(this.storeValue, value)) {
        this.storeValue = undefined;
        this.isStoreUpdate = false;
        /* for the very first time lastValue, storeValue and value will be equal,
         hence the storeValue will be undefined. To handle this scenario we are
         reassigning the same value from the store*/
        if (_.isEqual(this.lastValue, value)) {
          this.storeValue = this.lastValue;
        }
        return;
      }

      if (_.isEqual(this.lastValue, value)) {
        return;
      }

      if ((value.availability.advisorId && this.lastValue.availability.advisorId !== value.availability.advisorId)
        || (value.transportationTypeCode && this.lastValue.transportationTypeCode !== value.transportationTypeCode)
        || (value.vehicle.vin && this.lastValue.vehicle.vin !== value.vehicle.vin)) {
        this.isStoreUpdate = true;
        this.testDriveVins = value.vehicle.vin;
        if(this.testDriveVins && value.availability.advisorId){
        this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
          { date: '', advisorId: value.availability.advisorId, transport: value.transportationTypeCode, vin: value.vehicle.vin, 
          appointmentType: AppointmentType.TEST_DRIVE, appointmentId: this.appointmentId }));
        }
      } else {
        this.isStoreUpdate = false;
      }

      /*lastValue keeps changing everytime the form value changes
       (even when the changes are from the initialstate or EditState) */
      this.lastValue = value;

      /*dispatch happens only when the storeValue = undefined which means
      that all the initial changes (initialState and EditState) from store are
      populated in the form and now we are dispatching the changes done by the user*/

      if (!this.storeValue) {
        // if (value && value.address?.stateAbbr)
        //   value.address.state = value.address.stateAbbr;
        this.salesAppointmentState.dispatch(new fromActions.UpdateSalesFormData({ value }));
      }
    });
  }

  setObservablesForOtherVehicles(value) {
    if (value.otherVehicles.length && this.lastValue) {
      value?.otherVehicles?.forEach((element, i) => {

        // reset dropdown values of model and trim if the year is changed
        if (this.lastValue.otherVehicles[i] && value.otherVehicles[i].year !== this.lastValue.otherVehicles[i].year) {
          this.modelList$.splice(i, 1);
          this.trimList$.splice(i, 1);
        }

        // reset dropdown values of trim if the model is changed
        if (this.lastValue.otherVehicles[i] && value.otherVehicles[i].model !== this.lastValue.otherVehicles[i].model) {
          this.trimList$.splice(i, 1);
        }

        // adding observables to the array only when 'Add Vehicle' is clicked
        if (i === this.modelList$.length && value.otherVehicles[i].year && this.modelList$.length !== value.otherVehicles.length) {
          this.modelList$.push(this.salesAppointmentState.select(fromSelectors.getOtherVehiclesSeriesList,
            { year: value.otherVehicles[i]?.year }));
        }

        if (i === this.trimList$.length && value.otherVehicles[i].year &&
          value.otherVehicles[i].model && this.trimList$.length !== value.otherVehicles.length) {
          this.trimList$.push(this.salesAppointmentState.select(fromSelectors.getOtherVehiclesTrimList,
            { model: value.otherVehicles[i]?.model, year: value.otherVehicles[i]?.year }));
        }
      });
    }
  }

  get otherVehiclesArray() {
    return this.testDriveForm.get('otherVehicles') as FormArray;
  }

  onAddVehicle() {
    this.otherVehiclesArray.push(new FormGroup({
      year: new FormControl(''),
      model: new FormControl(''),
      trim: new FormControl('')
    }));
    const index = this.otherVehiclesArray.length - 1;
    (this.otherVehiclesArray.controls[index] as FormGroup).setValidators(this.setOtherVehicleValidator());
  }

  onDeleteVehicle(index: number) {
    this.otherVehiclesArray.removeAt(index);
    this.modelList$.splice(index, 1);
    this.trimList$.splice(index, 1);
  }

  isValidForm() {
    return this.testDriveForm.valid && this.checkIfEmailOrPhoneValid();
  }

   checkIfEmailOrPhoneValid()
  {
    /* check if email or phone entered */
    if(this.testDriveForm.controls.customer.value.emailAddress || this.testDriveForm.controls.customer.value.phoneNumber)
    {
      return this.testDriveForm.controls['customer'].valid;
    }
    /*return false if none is entered,either has to be entered*/
    return false;
  }

  onSubmit() {
    this.trackSubmit();
    this.isSubmitting = true;
    if (this.isUpdate) {
      if (this.updateStoreBeforeSubmitting()) {
        this.salesAppointmentState.dispatch(new fromActions.EditSalesAppointment({ appointmentType: AppointmentType.TEST_DRIVE, appointmentId: null }));
      }
    } else {
      this.salesAppointmentState.dispatch(new fromActions.CreateSalesAppointment({ appointmentType: AppointmentType.TEST_DRIVE }));
    }
    this.subscriptions.editDetailsSub = this.salesAppointmentState.select(fromSelectors.editDetails)
      .subscribe((editDetail) => {
        this.onSubmitting(editDetail);
      });
  }

  trackSubmit() {
    forkJoin([
      this.salesAppointmentState.select(fromSelectors.appointmentDetails).pipe(take(1)),
      this.salesAppointmentState.select(fromSelectors.availability).pipe(take(1))
    ]).pipe(take(1)).subscribe(([appointment, availabilityForm]) => {
      const testDriveTrackingData: any = {
        testscheduler_session_id: this.analyticsSessionId,
        content_section: 'Test Drive Appointment',
        link_text: 'Appointment Submit',
        testscheduler_advisor_preference: appointment.availability.advisorId !== '-1',
        testscheduler_language_preference: appointment.availability.languageId !== '-1',
        testscheduler_is_suggested_timeslot: this.isSuggestedTimeslot(availabilityForm.data.suggestedTimes, appointment.availability)
      };
      this.analyticsService.trackLink(testDriveTrackingData);
    });
  }

  private isSuggestedTimeslot(suggestedTimes: IDateAvailability[], availability: IAvail) {
    return suggestedTimes.some(t =>
      t.date === availability.appointmentStartDate &&
      t.timeSlots.some(r => r.timeSlotId.indexOf(availability.timeSlotId)));
  }

  isMultiVehicleTestDrive(appointment: ISalesAppointmentForm) {
    return appointment.otherVehicles.some(t => t.year
      && t.model && t.model.trim().length > 0
      && t.trim && t.trim.trim().length > 0)
  }

  onSubmitting(data) {
    if (data) {
      this.isSubmitting = false;
      if (data.isSubmitted) {
        this.appointmentId = data.appointmentId;
        const appointment: IAppointment = data.appointment;
        this.dashboardState.dispatch(new UpsertAppointment({ appointment: appointment }));
        const urlState = data.appointment;   
        const navigationExtras: NavigationExtras = {
          queryParams: {
            id: this.appointmentId
          },
          state: urlState
        };

        navigateToRoutePromise(getRouteFromWindow('testdrive') ? `${getRouteFromWindow('testdrive')}/confirm` : '/testdrive/confirm', this.router, navigationExtras);
      }
    }
  }

  updateStoreBeforeSubmitting() {
    const value = this.testDriveForm.value;
    const storeValue = _.cloneDeep(this.storeValue);
    if (
      storeValue &&
      value &&
      value.address?.stateAbbr &&
      storeValue?.address
    ) {
      storeValue.address.stateAbbr = value.address.stateAbbr;
    }
    if (_.isEqual(this.storeValue, value)) {
      return false;
    }
    if (value && value.address?.stateAbbr) value.address.state = value.address.stateAbbr;
    this.salesAppointmentState.dispatch(new fromActions.UpdateSalesFormData({ value }));
    return true;
  }

  onMonthChange($event: DateTime) {
    const startdate = $event.toFormat('yyyy-MM-dd');
    const newenddate: string = DateTime.local().plus({ days: 40 }).toFormat('yyyy-MM-dd');
    const monthEndDate = this.getEndOfMonth(startdate).toFormat('yyyy-MM-dd');
    const diffdates = (new Date(startdate) < new Date(newenddate)) && (new Date(newenddate) < new Date(monthEndDate));

    this.salesAppointmentState.select(fromSelectors.getAvailabilityDetails)
      .pipe(take(1))
      .subscribe(details => {
        const date = $event.toFormat('yyyy-MM-dd');
        if (diffdates) {
          this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date, advisorId: details.advisorId, transport: details.transport, enddt: newenddate, vin: this.testDriveVins,
               appointmentType: AppointmentType.TEST_DRIVE, appointmentId: this.appointmentId }));
        }
        else if ((new Date(startdate) > new Date(newenddate))) {
          return;
        }
        else {
          this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date, advisorId: details.advisorId, transport: details.transport, vin: this.testDriveVins,
               appointmentType: AppointmentType.TEST_DRIVE, appointmentId: this.appointmentId }));
        }
      });
  }

  onOtherVehicleChecked() {
    this.otherVehicleChecked = !this.otherVehicleChecked;
    if (this.otherVehiclesArray.length) {
      this.otherVehiclesArray.controls.forEach((a, i) => {
        if (this.otherVehicleChecked) {
          a.setValidators(this.setOtherVehicleValidator());
          a.updateValueAndValidity();
        } else {
          this.removeOtherVehicleValidator(i);
          this.otherVehiclesArray.reset();
        }
      });
    }
  }

  setOtherVehicleValidator(): ValidatorFn {
    return (vehicle: FormGroup): ValidationErrors => {

      if (this.otherVehicleChecked) {
        this.validatedFields.forEach(fields => {
          if (!vehicle.controls[fields].value) {
            vehicle.controls[fields].setErrors({ required: `${fields} ${this.translate.instant('testDrive.isRequired')}` });
          }
        });
      }
      return;
    };
  }

  removeOtherVehicleValidator(index: number) {
    const vehicle = this.otherVehiclesArray.controls[index] as FormGroup;
    this.validatedFields.forEach(field => vehicle.controls[field].setErrors(null, { emitEvent: false }));
    if (index !== 0) {
      this.otherVehiclesArray.removeAt(index);
    }
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
    this.salesAppointmentState.dispatch(new fromActions.ResetSalesAppointmentState());
    this.bannerService.update('');
  }

  get getDisclaimerText() {
    return GLOBAL_CONSTANT.schedulingDisclaimerText;
  }
  private getEndOfMonth(date: string) {
    const parseDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
    const endOfMonth = DateTime.fromFormat(`${parseDate.toFormat('yyyy-MM')}-01`, 'yyyy-MM-dd').plus({ month: 1, day: -1 });
    return endOfMonth;
  }

  getTranslation(value) {
    return value ? `${this.translate.instant(`common.${value}`)}` : value;
  }
}
