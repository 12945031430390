<app-loading
  class="dashboard-loading"
  size=".5"
  *ngIf="isAppointmentsLoading$ | async; else dashboard"
></app-loading>
<ng-template #dashboard>
  <div class="container">
    <div class="row">
      <div class="dashboard-container col-md-auto">
        <app-appointment-section *ngIf="signedIn" [appointmentType]="default" [signedIn]="signedIn"></app-appointment-section>
        <app-appointment-section [appointmentType]="serviceType" [signedIn]="signedIn"></app-appointment-section>

        <app-appointment-section [appointmentType]="testDriveType" [signedIn]="signedIn"></app-appointment-section>

        <ng-container *ngIf="environment.showDelivery">
          <app-appointment-section [appointmentType]="deliveryType"
          *ngIf="signedIn" [signedIn]="signedIn"></app-appointment-section>
        </ng-container>
        <app-appointment-section [appointmentType]="learningType" [signedIn]="signedIn"></app-appointment-section>
      </div>
    </div>
  </div>
</ng-template>
