<!-- Slider main container -->
<button mat-icon-button id="mileage-button-prev" (click)="onPrev()">
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<div class="mileages-wrapper">
  <div class="mileages" #carousel>
    <div class="mileage" #mileageDiv
         *ngFor="let mileage of mileages; let i = index"
         (click)="selectMileage(i)"
        [ngClass]="{'selected-mileage': currentSlide === i }">
      {{ getTransformed(mileage) }}
    </div>
  </div>
</div>
<button mat-icon-button id="mileage-button-next" (click)="onNext()">
  <mat-icon>keyboard_arrow_right</mat-icon>
</button>
