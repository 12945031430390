import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {UpdateServiceForm} from '../../store/service-appointment/service-appointment.actions';
import {FormArray, FormGroupDirective} from '@angular/forms';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {debounceTime} from 'rxjs/operators';
import {AppState} from '../../store/app.store';
import {SubscriptionList} from '../../shared/models/asp.types';
import * as util from '../../shared/services/util.service';
import {getSelectedServices} from '../../store/service-appointment/service-appointment.adapter';
import {IServiceRequest} from '@signal/asp-data-commons';

@Directive({
  selector: '[appConnectStore]'
})
export class ConnectStoreDirective implements OnInit, OnDestroy {
  @Input('appConnectStore') path: string;
  @Input() debounce = 500;
  private lastValue: any;
  private subs: SubscriptionList = {};

  constructor(private readonly formGroupDirective: FormGroupDirective,
              private readonly actions$: Actions,
              private readonly store: Store<AppState>) {
  }

  ngOnInit() {
    this.reactToStoreChanges();
    this.reactToFormChanges();
  }

  isValueOld(newValue) {
    const lastValueString = JSON.stringify(this.lastValue);
    const newValueString = JSON.stringify(newValue);
    return lastValueString === newValueString;
  }

  ngOnDestroy() {
    util.unsubscribeSubscriptions(this.subs);
  }

  private reactToFormChanges() {
    this.subs.formChangeSub = this.formGroupDirective?.form?.valueChanges
      .pipe(debounceTime(this.debounce))
      .subscribe(value => {

        if (this.path === 'vehicleForm') {
          value = this.formGroupDirective.form.getRawValue();
        } else if (this.path === 'serviceForm') {
          value = this.getSelectedServicesAsValue();
        }

        /* This check is for breaking the infinite loop */
        if (this.isValueOld(value)) {
          return;
        }
      if(value.pickUpAddress && value.pickUpAddress.stateAbbr && value.pickUpAddress.state!==value.pickUpAddress.stateAbbr)
      {
      value.pickUpAddress.state=value.pickUpAddress.stateAbbr;
      }
      if(value.dropOffAddress && value.dropOffAddress.stateAbbr && value.dropOffAddress.stateAbbr!==value.dropOffAddress.state)
      value.dropOffAddress.state=value.dropOffAddress.stateAbbr;
        this.lastValue = value;

        this.store.dispatch(new UpdateServiceForm({path: this.path, value}));
      });
  }

  private reactToStoreChanges() {
    this.subs.storeChangeSub = this.store
      .select(state => state.serviceAppointmentState.appointmentForm[this.path])
      .subscribe(value => {

        /* For serviceFom alone we need to reset the form state manually. since it uses formArray,
        it's initial value in the store is not actual value (which is computed after API call) */
        if (this.path === 'serviceForm') {
          if (value.isInitialState) {
            this.resetFormArray();
          } else {
            this.setFormValuesToSelectedServices(value.selectedServices);
          }
          this.lastValue = this.getSelectedServicesAsValue();
          if (this.lastValue?.selectedServices && value.selectedServices && this.lastValue?.selectedServices?.length !== value.selectedServices?.length){
            this.store.dispatch(new UpdateServiceForm({path: this.path, value: this.lastValue}));
          }
          return;
        }

        /* This check is for breaking the infinite loop */
        if (this.isValueOld(value)) {
          return;
        }
        this.lastValue = value;

        this.formGroupDirective.form.patchValue(value);
      });
  }

  private resetFormArray() {
    (this.formGroupDirective.form.controls.allServices as FormArray).controls.forEach(c => {
      c.get('selected').setValue(false);
      c.get('expanded').setValue(false);
      c.get('comments').setValue('');
    });

    (this.formGroupDirective.form.controls.recommendedServices as FormArray).controls.forEach(c => {
      c.get('selected').setValue(false);
      c.get('expanded').setValue(false);
      c.get('comments').setValue('');
    });
  }

  private getSelectedServicesAsValue() {
    if(this.formGroupDirective.form.value.allServices.length || this.formGroupDirective.form.value.recommendedServices.length || this.formGroupDirective.form.value.addOnServices.length){
    const selectedAllServices = getSelectedServices(this.formGroupDirective.form.value.allServices);
    const selectedRecommendedServices = getSelectedServices(this.formGroupDirective.form.value.recommendedServices);
    const selectedAddOnServices = getSelectedServices(this.formGroupDirective.form.value.addOnServices);
    const selectedServicesArr = [...selectedRecommendedServices, ...selectedAllServices, ...selectedAddOnServices];
    const uniqSelectionList = [];
    selectedServicesArr.forEach(x => {
      if(uniqSelectionList.findIndex(y => y.opcode === x.opcode) === -1){
        uniqSelectionList.push(x);
      }
    });
    return {selectedServices: uniqSelectionList};
    }
  }

  private setFormValuesToSelectedServices(selectedServices: IServiceRequest[]) {
    this.setSelectedServicesValues(selectedServices, 'recommendedServices');
    this.setSelectedServicesValues(selectedServices, 'allServices');
  }

  private setSelectedServicesValues(selectedServices: IServiceRequest[], controlName: string) {
    const formControl = this.formGroupDirective.form.controls[controlName] as FormArray;
    if (formControl && formControl.value.length ) {
      formControl.controls.forEach(c => {
        const selectedService = this.findSelectedService(selectedServices, c.value.serviceInfo);
        c.get('selected').setValue(!!selectedService);
        c.get('comments').setValue(selectedService && selectedService.comments);
      });
    }
  }

  private findSelectedService(selectedServices, s) {
    return selectedServices.find(md =>(md?.internalCode ) ? ( (md?.internalCode !=="") ? (md.internalCode === s.opcode) : (md.opcode === s.opcode)) : (md.opcode === s.opcode) );
  }
}
