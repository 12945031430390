import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from './util.service';

@Injectable({ providedIn: 'root' })
export class DcsBaseHttpService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private readonly http: HttpClient) {
    try {

      this.httpOptions.headers = this.httpOptions.headers
        .set('X-BRAND', environment.xBrand);

    } catch (error) {
      console.log('token failed', error);
    }
  }

  get(path: string, version: string = '1.0', brand?: { xBrand: string, xClient: string }): Observable<any> {
    const options = this.httpOptions;
    if (brand) {
      options.headers = options.headers.set('X-BRAND', brand.xBrand);
    }
    return this.http.get(`${path}`, options)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${path}`, body, this.httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${path}`, body, this.httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }

  delete(path: string): Observable<any> {
    return this.http.delete(`${path}`, this.httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }


  private log(data) {
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
