<div class="choose-vehicle-con">
  <div class="row">
      <div class="col-md-12">
          <span class="choose-vehicle-header">{{ 'delivery.selectedVehicle' | translate }}</span>
      </div>
  </div>
  <div class="row d-flex">
    <div class="col-md-8">
      <div class="selected-vehicle">
        <div class="yearMakeModel vehicle-label">
          <span>{{vehicleDetails?.yearMakeModel}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-4 display-grid" *ngIf="isUVI">
      <div>
        <h5 class="isUsed">{{isUsed | translate}}</h5>
      </div>
      <div>
        <h5 class="nontoyota">{{message | translate}}</h5>
      </div>
    </div>
    
  </div>
  
  <app-splitter class='app-splitter'  [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
</div>

