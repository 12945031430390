import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { SubscriptionList } from '../../shared/models/asp.types';

@Component({
  selector: 'app-vehicle-delivery',
  templateUrl: './vehicle-delivery.component.html',
  styleUrls: ['./vehicle-delivery.component.scss']
})
export class VehicleDeliveryComponent implements OnInit, OnChanges {

  @Input() vehicleDetails;
  @Input() dealerCode:string;
  isUsed:string;
  isNonToyota:boolean;
  isNonLexus:boolean;
  isUVI:boolean;
  message:string;
  subscriptions: SubscriptionList = {};
  isLexusDealer = false;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hasOwnProperty(('dealerCode'))) {
           this.isLexusDealer = this.dealerCode?.startsWith('6') ? true : false;
    }
    if (changes.hasOwnProperty(('vehicleDetails'))) {
    const vehicleData = changes.vehicleDetails?.currentValue;
    if (!!vehicleData) {
    this.isUsed = this.isLexusDealer ? 
    (this.vehicleDetails.certificationStatus==='CERTIFIED' ? 
    'delivery.lexusCertified' : 'delivery.preOwned') :
    (this.vehicleDetails.certificationStatus==='CERTIFIED' ? 
    'delivery.toyotaCertified' : 'delivery.isUsed');

    this.isUVI  = this.vehicleDetails.source === "UVI";
    this.isNonToyota = !(this.vehicleDetails.make?.toUpperCase() === "TOYOTA");
    this.isNonLexus = !(this.vehicleDetails.make?.toUpperCase() === "LEXUS");

    this.message = this.isLexusDealer ?
    (this.isNonLexus ? 'delivery.nonLexus' : '') : (this.isNonToyota ? 'delivery.nonToyota' : '');
    }
  }

  }

}
