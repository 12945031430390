<div class="asp-app-root">
  <link rel="stylesheet" type="text/css" [href]="dynamicCSSUrl" />
  <mat-drawer-container class="example-container" hasBackdrop="true">
    <mat-drawer #drawer mode="over">
      <app-asp-side-nav></app-asp-side-nav>
    </mat-drawer>
    <mat-drawer-content>
      <asp-brand-switcher (brandChange)="brandChange($event)" *ngIf="!isProdEnv"></asp-brand-switcher>
      <router-outlet *ngIf="analyticsScriptLoaded"></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>