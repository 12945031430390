import { AuthService } from './../../shared/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../../shared/services/asp/appointment.service';
import { filter, take } from 'rxjs/operators';
import { DateTime } from 'luxon';
import {  Observable } from 'rxjs';
import { SubscriptionList } from '../../shared/models/asp.types';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TimeSlot } from '../../shared/models/sales-appointments/time-slot';
import { AvailabilityOptionsData } from '../../../assets/sampledata/appt-availability-options';
import { ViewportScroller } from '@angular/common';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import { AppointmentType, IAddress,  IAppointment,  IAvailability, IAvailabilityOptions, ICustomer, 
  IDeliveryAppointment, ITransportOption, LocationType } from '@signal/asp-data-commons';
import { getRouteFromWindow, navigateToRoutePromise, unsubscribeSubscriptions } from '../../shared/services/util.service';
import {  SalesAppointmentState } from '../../store/sales-appointment/sales-appointment.reducer';
import { Store } from '@ngrx/store';
import * as fromActions from '../../store/sales-appointment/sales-appointment.actions';
import * as fromSelectors from '../../store/sales-appointment/sales-appointment.selectors';

import _ from 'lodash';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { selectDealerAddressDetails, selectDealerCode } from '../../store/dealer/dealer.selectors';
import uuidv4 from 'uuidv4';
import { SalesDataShareService } from '../../shared/services/sales-data-share.service';
import {TranslateService} from '@ngx-translate/core';
import { UpsertAppointment } from '../../store/dashboard/dashboard.actions';
import { DashboardState } from '../../store/dashboard/dashboard.reducer';

@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit,OnDestroy {

  signedIn: boolean;
  appointmentId: string;
  location: string;
  availability: FormControl;

  dealerAddress:IAddress;
  profile: any;
  dealerCode:string = '';
  preferredDate: string;
  preferredTime: string;
  lastName = '';
  email = '';
  firstName = '';
  isSubmitting = false;
  private signedInUserEmail: string;
  private lastValue: any;
  private storeValue: any;
  isStoreUpdate = false;

  vehicleDetails: any = {
    yearMakeModel: '2020 Yaris Hatchback LE',
    vin: '1230ZESAD91191',
    stockno: 'P6392',
    trim: 'XE'
  };

  timeSlots: TimeSlot[] = AvailabilityOptionsData.timeSlots;

  isUpdate: boolean;
  hasRemoteLocation = false;
  availabilityOptionsLoading$: Observable<boolean>;
  subscriptions:SubscriptionList = {};
  deliveryForm: FormGroup;
  availabilityLoading$: Observable<boolean>;
  // private vehicleForm: FormControl;
  availabilityOptions$: Observable<IAvailabilityOptions>;
  private readonly tradeInForm: FormControl;
  private readonly appointmentForm: FormControl;
  private readonly customerForm: FormControl;
  private readonly contactEmailInfoForm: FormControl;


  availability$: Observable<IAvailability>;

  transportOptions$: Observable<ITransportOption[]>;
  remoteZipcodes$: Observable<string[]>;
  yearList$: Observable<number[]>;
  
  private signedInUserDetails: ICustomer;
  isValidRemoteZip = false;
  remoteValidZipcodes: { pmaZipCodes: string[], isRemote: boolean };
 
  private readonly analyticsSessionId: string;
  apptType : string= AppointmentType.TEST_DRIVE;
  

  constructor(
    private readonly viewportScroller: ViewportScroller,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private  readonly bannerService: AspBannerService,
    private readonly formBuilder: FormBuilder,
    private readonly appointmentService: AppointmentService,
    private readonly authService: AuthService,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly analyticsService: AnalyticsService,
    private readonly dealerState: Store<DealerState>,
    private readonly salesDataShareService : SalesDataShareService,
    private readonly translate : TranslateService,
    private readonly dashboardState: Store<DashboardState>
  ) {
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.authService.user.subscribe(user => {
      this.profile = user;
    });

    this.location = 'DEALER';

    this.initializeDeliveryForm();

    this.route.queryParams.subscribe(params => {
      this.appointmentId = params.id;
      if ( this.appointmentId) {
          this.salesAppointmentState.dispatch(new fromActions.GetEditData
          ({ appointmentId: this.appointmentId, appointmentType: AppointmentType.DELIVERY }));
        this.isUpdate = true;
        // this.appointmentService.get(AppointmentType.DELIVERY, this.appointmentId).subscribe(res => {
        //   if (res && res.data) {
        //     this.isUpdate = true;
        //     this.setFormOnEdit(res.data);
        //   }
        // });
      }
    });
    this.analyticsSessionId = uuidv4();

  }

    initializeDeliveryForm() {
      this.deliveryForm = this.formBuilder.group({
        tradeIn: this.tradeInForm,
        availability: this.availability,
        transportationTypeCode: ['', [Validators.required]],
        remoteZipCode: [''],
        customer: this.contactEmailInfoForm,
        address: this.customerForm
      });
      this.deliveryForm.controls.remoteZipCode.setValidators(this.zipValidator());

      const vehicleTrackingData = {
        content_section: 'schedule test drive',
        link_module: ': choose your vehicle'
      }
      this.deliveryForm.valueChanges.subscribe((value) =>  
      {
      if(value.transportationTypeCode === 'REMOTE'){
        this.location = value.transportationTypeCode;
      }
    
      // reset color and car image on changing the model
      if (value.vehicle && value.vehicle.model && this.lastValue && this.lastValue.vehicle.model !== value.vehicle.model) {
        const modelTrackingData = {
          ...vehicleTrackingData,
          link_text: 'model',
          link_input_field: value.vehicle.model
        };
        this.analyticsService.trackLink(modelTrackingData, true)
        this.salesAppointmentState.dispatch(new fromActions.ResetExteriorColors());
        this.bannerService.update('');
      }

      // to hit the EFC API for color and carImage only when trim is changed
      if (value.vehicle && value.vehicle.trim && this.lastValue && this.lastValue.vehicle.trim !== value.vehicle.trim) {
        const trimTrackingData = {
          ...vehicleTrackingData,
          link_text: 'trim',
          link_input_field: value.vehicle.trim
        };
        this.analyticsService.trackLink(trimTrackingData, true)
        this.salesAppointmentState.dispatch(
          new fromActions.LoadExteriorColors({ year: value.vehicle.year, model: value.vehicle.model, trim: value.vehicle.trim }));
      }

      if (value.vehicle && value.vehicle.exteriorColor && this.lastValue && this.lastValue.vehicle.exteriorColor !== value.vehicle.exteriorColor) {
        const exteriorColorTrackingData = {
          ...vehicleTrackingData,
          link_text: 'exteriorColor',
          link_input_field: value.vehicle.exteriorColor
        };
        this.analyticsService.trackLink(exteriorColorTrackingData, true)
      }
      /* duplicate store value and delete othervehicles and vehicle*/
      const storeValue = {...this.storeValue,otherVehicles:{}}
      delete storeValue.otherVehicles;
      delete storeValue.vehicle;
      delete storeValue.appointmentComments

      /*Updating storeValue = undefined everytime the form is updated to the initial changes from the state */
      if (storeValue && _.isEqual(storeValue, value)) {
        this.storeValue = undefined;
        this.isStoreUpdate = false;
        /* for the very first time lastValue, storeValue and value will be equal,
        hence the storeValue will be undefined. To handle this scenario we are
        reassigning the same value from the store*/
        if (_.isEqual(this.lastValue, value)) {
          this.storeValue = this.lastValue;
        }
        return;
      }

      if (_.isEqual(this.lastValue, value)) {
        return;
      }

      if ((value.availability.advisorId && this.lastValue.availability.advisorId !== value.availability.advisorId)
        || (value.transportationTypeCode && this.lastValue.transportationTypeCode !== value.transportationTypeCode)) {
        this.isStoreUpdate = true;
        this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
          { date: '', advisorId: value.availability.advisorId, transport: value.transportationTypeCode, 
          appointmentId: this.appointmentId  }));
      } else {
        this.isStoreUpdate = false;
      }

      /*lastValue keeps changing everytime the form value changes
      (even when the changes are from the initialstate or EditState) */
      this.lastValue = value;

      /*dispatch happens only when the storeValue = undefined which means
      that all the initial changes (initialState and EditState) from store are
      populated in the form and now we are dispatching the changes done by the user*/
      if (!this.storeValue) {
        if (value && value.customer?.address?.stateAbbr)
        value.address.state = value.address.stateAbbr;
        this.salesAppointmentState.dispatch(new fromActions.UpdateSalesFormData({ value }));
      }

    });
    }

    getTimeSlot(timeSlotId: string) {
      return this.timeSlots.find(x => x.timeSlotId === timeSlotId)?.time;
    }

    ngOnInit() {
      const pageLoadTrackingData = {
        content_section: 'schedule test drive'
      };
      this.analyticsService.customTrackLink('km-satd-shown', pageLoadTrackingData, true)
      this.viewportScroller.scrollToPosition([0, 0]);
      this.bannerService.show();
      this.setupStoreValue()
    }
    setupStoreValue() {
      this.setSignedInUserDetails();
      this.dispatchActions();
      this.subscriptions.address = this.dealerState.select(selectDealerAddressDetails).subscribe(dealerAddress=> this.dealerAddress = dealerAddress);
      this.subscriptions.dealercode = this.dealerState.select(selectDealerCode).subscribe(dealercode => this.dealerCode = dealercode);
      this.salesAppointmentState.select(fromSelectors.getAvailabilityDetails)
      .pipe(take(1))
      .subscribe(details => {
        if (this.isUpdate && details.appointmentStartDate !== "") {
          const date = DateTime.fromFormat(details.appointmentStartDate, 'yyyy-MM-dd')
            .startOf('month')
            .toFormat('yyyy-MM-dd');
          this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date, advisorId: details.advisorId, transport: details.transport, appointmentId: this.appointmentId }));
        }else{
          const advisor =details.advisorId ? details.advisorId : '-1'
          this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(

            { date: '', advisorId: advisor, transport: details.transport, appointmentId: this.appointmentId }));
        }
      });
      if (this.signedIn) {
        this.salesAppointmentState.dispatch(new fromActions.LoadCustomerDetails({ signedInUserDetails: this.signedInUserDetails }));
      }
      this.availabilityOptions$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptions);
      this.availabilityOptionsLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptionsLoading);
      this.availability$ = this.salesAppointmentState.select(fromSelectors.getAvailability);
      this.availabilityLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityLoading);
      this.transportOptions$ = this.salesAppointmentState.select(fromSelectors.getTransportOptions);
      this.remoteZipcodes$ = this.salesAppointmentState.select(fromSelectors.getPMAZipCodes);
      this.yearList$ = this.salesAppointmentState.select(fromSelectors.getYearList);

      this.availabilityOptions$.subscribe(options => {
        this.hasRemoteLocation = !!options?.transport?.transportOptions?.find(x => x.category === LocationType.REMOTE && x.isActive && x.enableDeliveryType);
      });

      this.subscriptions.formStateValueSub = this.salesAppointmentState.select(fromSelectors.appointmentDetails).subscribe((value) => {
        if (_.isEqual(this.lastValue, value)) {
          return;
        }
        this.lastValue = value;
        this.isStoreUpdate = true;

        /*isUpdate -> During edit mode we need to store the state value thats coming in,
        so that we can check the incoming form changes against storeValue
        isStoreUpdate -> Phase 2 (basically used when changes are invoked from state during create mode)*/
        if (this.isUpdate || this.isStoreUpdate) {
          this.storeValue = { ...value };
        }

        this.deliveryForm.patchValue(value);
        this.checkZipCodes(value.remoteZipCode);
      });
    }

    setFormOnEdit(data) {
      const apptData = this.salesDataShareService.setDataFormOnEdit(data);
      if(apptData.customer){
        apptData.customer.otherAddressChecked = !!data.customer.otherAddress;
        delete apptData.customer.otherAddressChecked;
        delete apptData.customer.otherAddress;
        this.setAddress(data, apptData);
      }      
      this.deliveryForm.patchValue(apptData);
      this.deliveryForm.updateValueAndValidity();
      this.setSummaryDetails(data);
    }

    setAddress(data, apptData) {
      if (!data.customer.address) {
        apptData.customer.address = {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zip: '',
          country: ''
        };
      }
    }
  
    setSummaryDetails(data) {
      this.location = data.transportationTypeCode;
      this.preferredDate = data.appointmentStartDate;
      this.preferredTime = this.getTimeSlot(data.timeSlotId);
    }

    isValidForm() {
      return this.deliveryForm.valid;
    }

    updateRequestData() {
      const appointment = this.createRequestData();
      appointment.appointmentId = this.appointmentId;
      return appointment;
    }

    onSubmit() {
      this.salesDataShareService.trackSubmit(this.analyticsSessionId);
      this.isSubmitting = true;
      if (this.isUpdate) {
        this.appointmentService.update(this.updateRequestData()).subscribe(data => {
          this.onSubmitting(data);
        });
      } else {
        this.appointmentService.create(this.createRequestData()).subscribe(data => {
          this.onSubmitting(data);
        });
      }
    }

    onSubmitting(data) {
      this.appointmentId = data.data.appointmentId;
      this.signedInUserEmail = data.data.emailAddress;
      this.isSubmitting = false;
      const appointment:IAppointment = data.data.appointment;
       this.dashboardState.dispatch(new UpsertAppointment({ appointment: appointment }));
        
      navigateToRoutePromise(getRouteFromWindow('delivery') ? `${getRouteFromWindow('delivery')}/confirm` : '/delivery/confirm', this.router, {
        queryParams: {
          id: this.appointmentId,
          email: this.signedInUserEmail
        }
      });
    }

    createRequestData() {
      const vehicle = {
        year: "2020",
        model: 'Yaris Hatchback',
        trim: 'LE',
        make: 'Toyota'
      };
      const availability = this.deliveryForm.get('availability') as FormGroup;
      const customer = this.deliveryForm.get('customer').value;
      const tradeIn = this.deliveryForm.get('tradeIn').value;
      const transportationTypeCode = this.deliveryForm.get('transportationTypeCode').value;
      let deliveryAddress:IAddress = {
        addressLine1:'',
        addressLine2:'',
        state:'',
        city:'',
        zip:'',
        country:'USA'
      }
      if(customer.address){
      deliveryAddress= transportationTypeCode === 'DEALER' ? this.dealerAddress : {...customer.address,country : 'USA'};
      }
      const address = transportationTypeCode === 'DEALER' ? {} : {...customer.address, country : 'USA'}
      const customerDetails =  {...customer,address} 
      const apptEmail = customer.emailAddress;
      if(transportationTypeCode === 'DEALER' && !customer.address.addressLine1){
        delete customerDetails.address;
      }
      
      const appt: IDeliveryAppointment = {
        appointmentType: AppointmentType.DELIVERY,
        dealerCode: this.dealerCode,
        emailAddress: apptEmail,
        languageId: availability.value.languageId,
        advisorId: availability.value.advisorId,
        timeSlotId: availability.value.timeSlotId,
        transportationTypeCode,
        appointmentStartDate: availability.value.appointmentStartDate,
        vehicle,
        customer: customerDetails,
        stockNumber: this.vehicleDetails.stockno,
        deliveryAddress
      };

      if (tradeIn && tradeIn.tradeInCheckbox) {
        delete tradeIn.tradeInCheckbox;
        appt.tradeInVehicle = tradeIn.vehicle;
    
        let tradeInVehicle = [];
        tradeIn.vehicle.forEach((vehicle, index)=> {
          if(vehicle.vin || (vehicle.make && vehicle.model && vehicle.year)) {
            tradeInVehicle.push({...vehicle, mileage: vehicle.mileage  ? +vehicle.mileage : 0});
          }
        })
        if(tradeInVehicle.length > 0) {
          appt.tradeInVehicle = tradeInVehicle;
        }
        if (tradeIn.title && tradeIn.title !== '') {
          appt.title = tradeIn.title;
        }
        if (tradeIn.trepresentativeInfo && tradeIn.trepresentativeInfo !== '') {
          appt.representative = tradeIn.trepresentativeInfo;
        }
        if (tradeIn.dealerComments || tradeIn.tradeInComments) {
          let apptComments = '';
          apptComments = tradeIn.dealerComments ? `Dealership - ${tradeIn.dealerComments}` : '';
          apptComments += tradeIn.tradeInComments ? tradeIn.dealerComments ? ` : Trade-in - ${tradeIn.tradeInComments}` : `Trade-in - ${tradeIn.tradeInComments}` : '';  
          appt.appointmentComments = apptComments;
        }
      }
      return JSON.parse(JSON.stringify(appt).replace(/null/g, '""'));
    }
    onMonthChange($event: DateTime) {
     this.salesDataShareService.onMonthChange($event, this.appointmentId);
    }
    checkZipCodes(value) {
      this.deliveryForm.controls.remoteZipCode.clearValidators();
      if (this.deliveryForm.value.transportationTypeCode === 'REMOTE') {
        this.remoteZipcodes$.pipe(filter(zip => !!zip?.length),take(1)).subscribe(zip => {
            this.remoteValidZipcodes = 
            { 
              pmaZipCodes: zip, 
              isRemote: true 
            };
            this.isValidRemoteZip = zip?.findIndex(x => x === value) !== -1;
            this.deliveryForm.controls.remoteZipCode.setValidators(this.zipValidator());
            this.deliveryForm.controls.remoteZipCode.updateValueAndValidity();
          });
      }
    }
    zipValidator(): ValidatorFn {
      return (control: FormControl): ValidationErrors => {
        if (this.deliveryForm.value.transportationTypeCode === 'REMOTE' && !this.isValidRemoteZip) {
          return { remoteZipCode: this.translate.instant('appointmentInformation.invalidRemotezipcode') };
        } else {
          return null;
        }
      };
    }
    setSignedInUserDetails() {
      if (this.signedIn) {
        this.signedInUserDetails = {
          firstName: this.profile.given_name,
          lastName: this.profile.family_name,
          emailAddress: this.profile.email,
          phoneNumber: ''
        };
      }
    }
    ngOnDestroy(){
      unsubscribeSubscriptions(this.subscriptions);
      this.salesAppointmentState.dispatch(new fromActions.ResetSalesAppointmentState());
    } 
    dispatchActions(){
      this.salesAppointmentState.dispatch(new fromActions.LoadSeriesAndTrim());
      this.salesAppointmentState.dispatch(new fromActions.LoadAvailabilityOptions({appointmentType: AppointmentType.DELIVERY}));
    }

    getTranslation(value){
      return value ? `${this.translate.instant(`common.${value}`)}` : value;
    }
}
