<div class="section-container" *ngIf="enableDrive || appointnmentCount">
  <div class="header">
    <span class="title">
      <span class="name">{{ getTitle() }}</span>
      <app-splitter class='app-splitter' [config]="'vertical 15px 20px -2px 550px'"></app-splitter>
      <span class="notification" *ngIf="!showLoader ;else loading">{{
          getNotificationTxt(salesOrServiceAppointmentCount$ | async)
        }}</span>
    </span>

    <span class="new-appointment-btn" *ngIf="enableDrive">
      <asp-info-button
        [name]="getNewAppointmentTxt()"
        type='small'
        (click)="onNewAppointmentClick()"
      ></asp-info-button>
    </span>
  </div>

  <div class="vehicle-card-list" *ngIf="!showLoader">
    <ng-container *ngIf="isTestDrive">
      <app-sales-card
        *ngFor="let vehicle of (salesOrServiceAppointmentVehicles$ | async)"
        class="service-card-container"
        [vehicle]="vehicle"
        (appointmentCancel)="onSalesAppointmentCancel(vehicle)"
      ></app-sales-card>
    </ng-container>
    <ng-container *ngIf="isDelivery">
      <app-sales-card
        *ngFor="let vehicle of (salesOrServiceAppointmentVehicles$ | async)"
        class="service-card-container"
        [vehicle]="vehicle"
        (appointmentCancel)="onSalesAppointmentCancel(vehicle)"
      ></app-sales-card>
    </ng-container>
    <ng-container *ngIf="isLearning">
      <app-sales-card
        *ngFor="let vehicle of (salesOrServiceAppointmentVehicles$ | async)"
        class="service-card-container"
        [vehicle]="vehicle"
        (appointmentCancel)="onSalesAppointmentCancel(vehicle)"
      ></app-sales-card>
    </ng-container>
    <ng-container *ngIf="isService">
      <app-service-card
        *ngFor="let vehicle of (appointments$ | async)"
        class="service-card-container"
        [vehicle]="vehicle"
        (appointmentCancel)="onServiceAppointmentCancel(vehicle,$event)"
        [ownedVehicle]=false
      ></app-service-card>
    </ng-container>
    
    <ng-container *ngIf="isMyVehicle">
      <app-service-card
        *ngFor="let vehicle of (userVehicles$ | async)"
        class="service-card-container"
        [vehicle]="vehicle"
        (appointmentCancel)="onServiceAppointmentCancel(vehicle,$event)"
        [ownedVehicle]=true
      ></app-service-card>
    </ng-container>
  </div>

</div>

<app-splitter class='app-splitter'
  *ngIf="!isThisLastSection"
  [config]="'horizontal 100% 0 -4px'"
></app-splitter>

<ng-template #loading>
  <app-loading class="dashboard-loader"
               loadingSymbol="dots"
               text="{{ getLoadingTxt() | translate }}"
               color="var(--dashboard-primary-color)"
               size="1">
  </app-loading>
</ng-template>
