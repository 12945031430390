<div >
    <ng-container *ngFor="let eachvehicle of newvehicle">
      <ng-container *ngIf="eachvehicle.vin===vehicle.vin">
        <div class="vehicle-card" [class.flip-card]="isCardFlipped">
          <div [ngClass]="'front'" (click)="emitVehicleDetails(eachvehicle,telematics?.mileage)">
      <div class="car-image">
        <img [src]="carImageAbsoluteURL" (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="Vehicle image"/>
      </div>
      <div class="car-name">
        {{ vehicle.make | titlecase }} {{vehicle.model}}
        <span
          *ngIf="telematics?.issueExists(); else noIssues"
          class="telematic-alerts issues-exists"
          matRipple
          (click)="flipTheCard()"
        >
        <span class='alert-icon'[title]="getAlert(telematics?.getIssueCountDesc())">
          <app-icon
            class="telematics-alert"
            name="ic-20-alert-error.svg"
            color="var(--dashboard-telematics-alert-icon-clr)"
            width=".9rem"
            height=".9rem"
          ></app-icon>
        </span>
        </span>
        <ng-template #noIssues>
          <span [title]="getAlert(telematics?.getIssueCountDesc())">
            <app-icon name="ic-20-alert-tick.svg"></app-icon>
          </span>
        </ng-template>
      </div>
      <div class="car-info">
        <span>{{ makeModelYearText }}</span>
        <span>{{ vinDisplayText }}</span>
      </div>
      <ng-container *ngIf="!isTelematicsLoading; else loading">
        
        <div >
          <div class="telematics">
            <span [title]="getTelematicsTranslation(telematics?.smartKeyBatteryDegradationStatusHover)">
              <app-icon name="icon-battery.svg"
                        class="app-icon"
                        color="inherit"
                        width="1rem"
                        height="1rem"></app-icon>
              <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow === '--';">
                {{ telematics?.smartKeyBatteryDegradationStatusToShow }}
              </span>
              <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow.toUpperCase() === 'OK'">
                <ng-template [ngTemplateOutlet]="ok"></ng-template>
              </span>
              <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow?.toUpperCase() === 'BAD'">
                <ng-template [ngTemplateOutlet]="bad"></ng-template>
              </span>
            </span>
        
              <app-icon name="ic-20-vin-fuel.svg"
                        class="app-icon"
                        color="inherit"
                        width="1rem"
                        height="1rem"></app-icon>
           
              <span [title]="getTelematicsTranslation(telematics?.milesToEmptyDescriptionHover)">
              <app-icon name="ic-20-vin-odometer.svg"
                        class="app-icon"
                        color="inherit"
                        width="1rem"
                        height="1rem"></app-icon>
              <span>{{ telematics?.milesToEmptyDescription }}</span>
            </span>
              <span [title]="getTelematicsTranslation(telematics?.engineOilLevelStatusHover)">
              <app-icon name="icon-engine-oil.svg"
                        class="app-icon"
                        color="inherit"
                        width="1.2rem"
                        height="1.2rem"></app-icon>
              <span *ngIf="telematics?.engineOilLevelStatus === '--';">
                {{ telematics?.engineOilLevelStatus }}
              </span>
              <span *ngIf="telematics?.engineOilLevelStatus === '3';">
                <ng-template [ngTemplateOutlet]="ok"></ng-template>
              </span>
              <span *ngIf="telematics?.engineOilLevelStatus === '2';">
                <ng-template [ngTemplateOutlet]="bad"></ng-template>
              </span>
            </span>
            <span [title]="getTelematicsTranslation(telematics?.tirePressureStatusHover)">
              <app-icon name="ic-20-vin-tire.svg"
                        path="svg/"
                        class="app-icon"
                        color="inherit"
                        width="1rem"
                        height="1rem"></app-icon>
              <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === '--';">
                {{ telematics?.tirePressureStatus }}
              </span>
              <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === 'ok';">
                <ng-template [ngTemplateOutlet]="ok"></ng-template>
              </span>
              <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === 'bad';">
                <ng-template [ngTemplateOutlet]="bad"></ng-template>
              </span>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-loading class="telematics-loading"
                     loadingSymbol="dots"
                     [text]="'dashboardAppointments.loadingTelematics' | translate"
                     color="var(--dashboard-primary-color)"
                     size=".5">
        </app-loading>
      </ng-template>
      <div class="telematic-alerts mileage-input">
        <input
          name="vehicle-mileage"
          placeholder="{{ 'dashboardAppointments.enterMileage' | translate }}"
          title="{{ 'dashboardAppointments.enterMileage' | translate }}"
          [ngModel]="setVehicleMileage(eachvehicle,editmode)"
          (ngModelChange)="onMileageChange($event)"
          aspNumberOnly
        />
      </div>
      <div class="link-button" (click)="onHistoryClicked()">{{ 'dashboardAppointments.serviceHistory' | translate}}</div>
    </div>
    <div class="back issue-card">
      <div class="telematic-alerts issues-exists back-side" matRipple (click)="flipTheCard()">
        <span>
          <app-icon name="arrow_left.svg"></app-icon>
          <span class="count"> {{ getAlert(telematics?.getIssueCountDesc()) }}</span>
        </span>
        <span id="issue-closer" class="view">
          {{ 'service.back' | translate}}
        </span>
      </div>
      <div class="issue-desc">
        <swiper class="swiper-container" [config]="config">
          <div
            *ngFor="let alert of getTelematicsAlerts()"
            class="swiper-slide alert-msg"
          >
            <div class="heading">{{ alert.title }}</div>
            <div class="desc">{{ alert.message }}</div>
          </div>
        </swiper>
  
        <div class="swiper-pagination"></div>
     
      </div>
      <div class="disclaimer">
        <span class="heading">{{ 'testDrive.disclaimer' | translate}}</span> {{disclaimerText | translate}}
      </div>
     
    </div>
  </div>
  </ng-container>   
</ng-container>
</div>


<ng-template #bad>
  <span>
    <app-icon name="ic-red-cross.svg"
    path="svg/"
    class="app-icon"
    color="inherit"
    width="1rem"
    height="1rem"></app-icon>
  </span>
</ng-template>
<ng-template #ok>
  <span>
    <app-icon name="ic-green-tick.svg"
    path="svg/"
    class="app-icon"
    color="inherit"
    width="1rem"
    height="1rem"></app-icon>
  </span>
</ng-template>
