import {TestDriveComponent} from './test-drive.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ConfirmInfoComponent} from './confirm-info/confirm-info.component';


const routes: Routes = [
    {
        path: '',
        component: TestDriveComponent
    },
    {
        path: 'confirm',
        component: ConfirmInfoComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TestDriveRoutingModule { }
