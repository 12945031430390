import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {DealerState} from '../../../store/dealer/dealer.reducer';
import {IOpcodeRequest, IOpcodeResponse, IResponse,IZipCodeRequest, EngineDetail, factoryRecommendedServices} from '@signal/asp-data-commons';
import {map, switchMap} from 'rxjs/operators';
import {selectDealerCode} from '../../../store/dealer/dealer.selectors';
import {Observable} from 'rxjs';
import { environment, countryCd } from '../util.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private readonly aspCatalogApi: string;
  private readonly aspB2cGuestApi: string;

  constructor(private readonly http: HttpClient,
              private readonly dealerState: Store<DealerState>) {
    this.aspCatalogApi = environment.aspCatalogApi;
    this.aspB2cGuestApi = environment.aspB2cGuestApi;
  }

  getServiceOpcodes(opcodeRequest: IOpcodeRequest, countryCd: string, languageCd: string): Observable<IResponse<IOpcodeResponse>>{
    return this.dealerState.select(selectDealerCode).pipe(
      //revert this dealerCode hardcode change later
      switchMap(dealerCode => this.http.post(`${this.aspB2cGuestApi}service-opcode/${dealerCode}/online/consumer?countryCode=${countryCd}&languageCode=${languageCd}`, opcodeRequest).pipe(
        map((data: IResponse<IOpcodeResponse>) => {
            return data;
          }
        )
      )));
  }

  getAvailableMileages() {
    return this.dealerState.select(selectDealerCode).pipe(
      switchMap(dealerCode => this.http.get(`${(this.aspB2cGuestApi)}service-opcode/${dealerCode}/mileages?countryCode=${countryCd}`).pipe(
        map((data: IResponse<number[]>) => {
            return data;
          }
        )
      )));
  }
  getStateCitiesForZipcode(ZipCode){
    const countryAbbr=countryCd;
    const zipcodearray=ZipCode.split(',');
    const zipCodeRequest:IZipCodeRequest[]=[];
    const requestbody= {"zipCodeRequest":[]}
    if(zipcodearray && zipcodearray.length){
      for(const zipcode of zipcodearray){
       this.setZipCodeRequest(zipcode,countryAbbr,zipCodeRequest)
      }
      requestbody.zipCodeRequest=zipCodeRequest;
    }
    return this.dealerState.select(selectDealerCode).pipe(
      switchMap(dealerCode => this.http.post(`${(this.aspCatalogApi)}api/catalog/${dealerCode}/lookup`,requestbody).pipe(
        map((data:IResponse<any>) => {
         return data;
        }
      ))));
  }
  private setZipCodeRequest(zipcode:string,CountryAbbr:string,zipCodeRequest:IZipCodeRequest[]){
    const zipcodeRequest:IZipCodeRequest={postalCode: zipcode,countryName: CountryAbbr}
    zipCodeRequest.push(zipcodeRequest);
  }
  getBase64ImageData(url){
    return this.dealerState.select(selectDealerCode).pipe(
      switchMap(dealerCode => this.http.get(`${this.aspB2cGuestApi}catalog/${dealerCode}/images?url=${url}`).pipe(
        map((data: IResponse<string>) => {
            return data;
          }
        )
      )));
  }
  getRecommendedService(opcodeRequest: IOpcodeRequest,engineDetail:EngineDetail,isLexus:boolean): Observable<IResponse<factoryRecommendedServices[]>>{
    return this.dealerState.select(selectDealerCode).pipe(
      //revert this dealerCode hardcode change later
      switchMap(dealerCode => this.http.post(`${this.aspB2cGuestApi}service-opcode/${dealerCode}/online/factoryrecommendedservice?engine=${engineDetail.engine}&transmission=${engineDetail.transmission}&driveType=${engineDetail.driveType}&isLexus=${isLexus}&countryCode=${countryCd}`, opcodeRequest).pipe(
        map((data: IResponse<factoryRecommendedServices[]>) => {
            return data;
          }
        )
      )));
  }
}
