import {UserVehicle, Vehicle as DcsVehicle} from '../../shared/models/service-appointments/myvehicle';
import {of, throwError} from 'rxjs';
import {IDashboardVehicle} from '../../shared/models/dashboard.model';
import * as fromActions from './dashboard.actions';
import {deepCopy} from '../../shared/services/util.service';
import {AppointmentStatus, IAppointment, IResponse, IVehicle} from '@signal/asp-data-commons';


/* Projector functions */

/**
 * Converts the UserVehicle response from the DCS API to simplified Vehicle interface for ASP Application
 * @param userVehicle raw response from the DCS API
 */
export const toVehiclesProjector = (userVehicle: UserVehicle) => {
  /* DCS Response gives status code as string, hence converting it to number using '+' operator */
  if (+userVehicle.status.code === 200) {
    return userVehicle.vehicles.map(v => getVehicle(v));
  } else {
    return throwError(`${userVehicle.status.code} - ${userVehicle.status.messages.join(', ')}`);
  }
};

export const toAppointmentsProjector = (appointments: IResponse<IAppointment[]>) => {
  if (appointments.status.code === 200) {
    return of(appointments.data);
  } else {
    return throwError(`${appointments.status.code} - ${appointments.status.messages.join(', ')}`);
  }
};

export const appointmentsToDashboardVehiclesProjector = (appointments: IAppointment[]): IDashboardVehicle[] => {
  return appointments.map(appointment => ({
    ...appointment.vehicle,
    appointment
  }));
};

export const ownVehicleAppointmentsToDashboardVehiclesProjector =
  (appointments: IAppointment[], userVehicles: IVehicle[]): IDashboardVehicle[] => {
  return appointments.map(appointment => ({
    ...appointment.vehicle,
    vehicleId : userVehicles.find(x => x.vin === appointment.vehicle.vin)?.vehicleId,
    appointment
  }));
};

export const vehiclesToDashboardVehiclesProjector = (vehicles: IVehicle[]): IDashboardVehicle[] => {
  return vehicles.map(vehicle => ({
    ...vehicle,
    isOwnVehicle: true,
    appointment: undefined
  }));
};

export const apptToDashboardVehicleProjector =
  (appointments: IAppointment[]) =>
    appointmentsToDashboardVehiclesProjector(appointments);

export const mergeUserVehiclesAndAppointmentsProjector =
  (userVehicles: IVehicle[], serviceAppointments: IAppointment[]) =>
    mergeUserVehiclesAndAppointments(userVehicles, serviceAppointments);


export const copyUserVehiclesProjector = (userVehicles: IVehicle[]) => {
  return userVehicles.map(deepCopy(userVehicles));
};



export const updateAppointmentAsCancelling = (action: fromActions.DashboardActions) => a => {
  const payloadAppointmentId = action instanceof fromActions.CancelAppointment ? action.payload.appointment.appointmentId : '';
  a.appointmentStatus = a.appointmentId === payloadAppointmentId ? AppointmentStatus.CANCELLING : a.appointmentStatus;
  return a;
};

export const getVehicle = (dcsVehicle: DcsVehicle): IVehicle => {
  return {
    vehicleId: dcsVehicle.vehicleId,
    description: dcsVehicle.description,
    make: dcsVehicle.make,
    model: dcsVehicle.model,
    year: dcsVehicle.year?.toString(),
    vin: dcsVehicle.vin,
    imageUrl: dcsVehicle.imageUrl,
    mileage: dcsVehicle.currentVehicleMileage
  } as IVehicle;
};

/* Reducer helper functions */

export const mergeUserVehiclesAndAppointments = (userVehicles: IVehicle[], appointments: IAppointment[]): IDashboardVehicle[] => {
  const ownVehicleAppointments = appointments?.filter(a => a.vehicle.isOwnVehicle);
  const otherVehicleAppointments = appointments?.filter(a => !a.vehicle.isOwnVehicle);

  /* Find vehicles without appointments and convert them into IDashboardVehicle */
  const ownVehicleApptVINs = ownVehicleAppointments?.map(o => o.vehicle.vin);
  const ownVehiclesWithoutAppointments = Array.isArray(userVehicles) ? userVehicles?.filter(v => !ownVehicleApptVINs.includes(v.vin)) : [];
  return [
    ...ownVehicleAppointmentsToDashboardVehiclesProjector(ownVehicleAppointments, userVehicles),
    ...appointmentsToDashboardVehiclesProjector(otherVehicleAppointments),
    ...vehiclesToDashboardVehiclesProjector(ownVehiclesWithoutAppointments),
  ];
};
export const getAllVehiclesAppointments = (userVehicles: IVehicle[], appointments: IAppointment[]): IDashboardVehicle[] => {
  const ownVehicleAppointments = appointments?.filter(a => a.vehicle.isOwnVehicle);
  const otherVehicleAppointments = appointments?.filter(a => !a.vehicle.isOwnVehicle);
  return [
    ...ownVehicleAppointmentsToDashboardVehiclesProjector(ownVehicleAppointments, userVehicles),
    ...appointmentsToDashboardVehiclesProjector(otherVehicleAppointments)
  ]
}
