<div  class="vehicle-card"   [class.flip-card]="flipCancelCard" [class.toggle-height] ="!enableDrive" >
  <div  class="front"  >
    <div class="car-image">
      <img [src]="carImageAbsoluteURL"  (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="Vehicle Image"/>
      <app-card-menu [vehicle]="vehicle" hOffset='-14px'
                     (cancelEvent)="onCancelFlip()"
                     (copyEvent)="onCopyDetails()"></app-card-menu>
    </div>
    <div class="car-name">{{ carNameDisplayText | titlecase}}</div>
    <div class="car-info" *ngIf="vehicle.appointment.appointmentType!=='Test Drive'">
      <span class="vin">{{ vinDisplayText }}</span>
    </div>
    <div class="location-info">
      <ng-container *ngIf="appointmentLocationType && appointmentLocationAddress">
        <div class="type"
        title="{{isAtDealership ? ('dashboardAppointments.openInMaps' | translate) : ''}}"
        [ngStyle]="{'cursor': isAtDealership ? 'pointer' : 'auto'}"
        (click)="onNavigateToGmap()">
          <app-icon class='map-icon' name="location_on-24px.svg" color="var(--primary-color)"></app-icon>
          {{ (appointmentLocationType === 'At Dealership' ? ('dashboardAppointments.atDealership') : ('dashboardAppointments.atRemote')) | translate}}
        </div>
        <div class="address" [title]="appointmentLocationAddress">
          {{ appointmentLocationAddress }}
        </div>
        <div *ngIf="!enableDrive" class="datetime"  [title]="appointmentLocationAddress">
          {{ appointmentDateTime }}
        </div>
        <div *ngIf="!enableDrive"  class="message" >
         {{'dashboardAppointments.contactMessage' | translate }} 
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="appointmentExists && enableDrive">
      <asp-info-button [name]="buttonText | translate" [info]="appointmentDateTime"
                       type="secondary" (click)="onEditAppointment()"></asp-info-button>
    </ng-container>
    <ng-container *ngIf="appointmentExists && enableDrive">
      <asp-info-button [name]="buttonTextCancel | translate" [info]="appointmentDateTime"
                        (click)="onCancelFlip()"></asp-info-button>
    </ng-container>
    <ng-container *ngIf="!appointmentExists">
      <asp-info-button name="{{ 'dashboardAppointments.bookAppointment' | translate }}"></asp-info-button>
    </ng-container>
    <div class="call-dealer" (mouseenter)="onHoverDialer()" (click)="callDealer()">
      <a [href]="'tel:' + (dealerPhone$ | async)">
        <app-icon name="phone-dialer-solid.svg" width="12px"></app-icon>
        <span class="dealer-info">{{ (dealerName$ | async) + ' • ' + ((dealerPhone$ | async) | phone) }}</span>
      </a>
    </div>
  </div>

  <div class="back cancel-card">
    <div *ngIf="enableDrive">
   <div class="cancel-loading" *ngIf="isBeingCancelled$ | async; else cancelConfirmation">
      <app-loading loadingSymbol="dots"
                   text="{{ 'dashboardAppointments.cancelling' | translate }}"
                   color="var(--dashboard-primary-color)"
                   size="1.6">
      </app-loading>
    </div>
  </div>
    <ng-template #cancelConfirmation>
      <div class="cancel-desc">{{ 'dashboardAppointments.areYouSureYouWantToCancel' | translate }} </div>
      <asp-info-button name="{{ 'dashboardAppointments.yes' | translate }}" type="primary" (click)="onCancel()"></asp-info-button>
      <asp-info-button name="{{ 'dashboardAppointments.no' | translate }}" type="secondary" (click)="onCancelFlip()"></asp-info-button>
    </ng-template>
  </div>
</div>
