import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
  SwiperScrollbarInterface,
} from 'ngx-swiper-wrapper';
import * as util from '../../services/util.service';
import { IVehicle } from '@signal/asp-data-commons';
import { IDashboardVehicle } from '../../models/dashboard.model';
import { Router } from '@angular/router';
import { Telematics, Alert } from '../../models/dashboard/telematics.model';
import { VehicleService } from '../../services/vehicle.service';
import { TranslateService } from '@ngx-translate/core';
import { UserVehicleService } from '../../services/user-vehicle.service';

@Component({
  selector: 'app-learning-vehicle-card',
  templateUrl: './learning-vehicle-card.component.html',
  styleUrls: ['./learning-vehicle-card.component.scss'],
})
export class LearningVehicleCardComponent implements OnInit {
  constructor(private readonly vehicleService: VehicleService,
    private readonly router: Router,
    private readonly userVehicleService: UserVehicleService,
    private readonly translate: TranslateService) {
    this.disclaimerText = util.GLOBAL_CONSTANT.telematicsDisclaimerText;
  }
  /* vehicle info to be displayed in the card */
  @Input() vehicle: IVehicle;
  @Input() editmode: boolean;
  @Input() newvehicle: IDashboardVehicle[];
  @Input() preSelectedVin: string;
  @Output() toShowFooterDetails: EventEmitter<boolean> = new EventEmitter();
  @Output() vehicleChange: EventEmitter<IVehicle> = new EventEmitter();
  @Output() fieldChange: EventEmitter<{
    field: string;
    value: string;
  }> = new EventEmitter();

  setDefaultImg = util.setDefaultImg;
  isCardFlipped = false;
  isTelematicsLoading = true;
  disclaimerText: string;
  telematics: Telematics;
  vinDisplayText: string;
  model: string;
  makeModelYearText: string;
  carImageAbsoluteURL: string;
  inputMileage: number;
  vehicleMileage: string;



  private readonly pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    // dynamicBullets: true
  };

  private readonly scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  };

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
  };
  ngOnInit(): void {
    this.vinDisplayText = `VIN ${this.vehicle.vin || this.translate.instant('service.notAvailable')}`
    this.makeModelYearText = util.getMakeModelYearText(this.vehicle);
    this.carImageAbsoluteURL = util.getCarImageAbsoluteURL(this.vehicle.imageUrl);
    this.isTelematicsLoading = true;
    this.fetchTelematics();
    if (this.newvehicle.length>0) {
      this.setMileage(this.newvehicle)
    }
  }

  private fetchTelematics() {
    this.userVehicleService.getVehicleHealthReport(undefined, this.vehicle.vin, this.vehicle.vin)
      .subscribe(data => {
        if (data.status.code === '200') {
          const vehicleStatus = data.vhr.vehicleStatus;
          const vehicleAlerts = data.vhr.vehicleAlerts;
          this.telematics = Telematics.getTelematics(vehicleStatus, vehicleAlerts);
          if (this.preSelectedVin && this.preSelectedVin === this.vehicle.vin && this.telematics.mileage) {
            this.onMileageChange(this.telematics?.mileage)
          }
        } else {
          this.fetchSafetyCampaigns()
        }
        this.isTelematicsLoading = false;
      },
        err => {
          this.isTelematicsLoading = false;
          this.fetchSafetyCampaigns()
        });
  }

  private fetchSafetyCampaigns() {
    this.telematics = Telematics.getDefaultTelematics();
    this.userVehicleService.getSpecialServiceCampaign(this.vehicle.vin).subscribe(sscData => {
      if (sscData.status.code === '200') {
        this.telematics.alerts = sscData.vehicleCampaignList.map(val => <Alert>{ message: val.campaignTitle });
      }
      this.isTelematicsLoading = false;
    });
  }

  setMileage(vehicles) {
    vehicles.forEach(vehicle => {
      if (vehicle.vin === this.vehicle.vin && this.preSelectedVin === vehicle.vin) {
        if (this.editmode) {
          this.onMileageChange(this.telematics.mileage ? this.telematics.mileage : vehicle.appointment.vehicle.mileage)
        } else {
          this.onMileageChange(this.telematics.mileage ? this.telematics.mileage : '')
        }
      }
    })
  }

  onMileageChange(input) {
    this.inputMileage = input;
    this.fieldChange.emit({

      field: 'mileage',
      value: input + '',
    });
  }

  triggerOnMileageChange() {
    this.onMileageChange(this.inputMileage);
  }

  getTelematicsAlerts() {
    return this.telematics?.alerts;
  }

  flipTheCard() {
    this.isCardFlipped = !this.isCardFlipped;
  }

  onHistoryClicked() {
    this.router.navigate(['asp/bdc/service/servicehistory'], {
      queryParams: {
        vin: this.vehicle.vin
      }
    });
  }
  emitVehicleDetails(vehicle, mileage) {
    if (mileage) {
      this.inputMileage = mileage;
    }
    vehicle.mileage = this.inputMileage;
    this.toShowFooterDetails.emit(true);
  }
  setVehicleMileage(vehicle, editmode) {
    if (editmode) {
      return this.telematics?.mileage ? this.telematics?.mileage : (vehicle.appointment?.vehicle?.mileage ? vehicle.appointment.vehicle?.mileage : '');
    }
    return this.telematics?.mileage ? this.telematics?.mileage : '';
  }
  getAlert(alert) {
    const alertArray = alert?.split(' ');
    if (alertArray && alertArray.length > 0 && alertArray[0].match(/^[A-Za-z]+$/)) {
      const alertValue = `${alertArray[0].toLowerCase()}${alertArray[1]}`;
      return this.translate.instant(`service.${alertValue}`);
    } else {
      return alertArray && alertArray.length > 0 ? `${alertArray[0]} ${this.translate.instant(`service.${alertArray[1].toLowerCase()}`)}` : alert;
    }
  }
  getTelematicsTranslation(value) {
    if (value) {
      const translatedTxt = value?.split(':');
      return this.translate.instant(`${translatedTxt[0]}`) + (translatedTxt[1] === '(Not available)' ? `(${this.translate.instant(`dashboardAppointments.notAvailable`)})` : translatedTxt[1]);
    }
  }
}
