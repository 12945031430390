import { Injectable } from '@angular/core';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public dialog: MatDialog,private readonly translate:TranslateService) { }

  openDialog(message): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '500px',
      data: this.translate.instant(message)
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
