import { AuthService } from './auth.service';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from './util.service';
import { Store } from '@ngrx/store';
import { DealerState } from './../../../modules/store/dealer/dealer.reducer';
import * as fromSelectors from './../../../modules/store/dealer/dealer.selectors';
import { DashboardState } from './../../store/dashboard/dashboard.reducer';
import { selectUserVehicles } from './../../store/dashboard/dashboard.selectors';
import { DOCUMENT } from '@angular/common';

declare global {
  interface Window { fireTag(pageload, data): any; }
}
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private _globalData: any = {};
  private _userData: any = {};
  private _dealerData: any = {};
  private _userInputErrorData: any = {};
  private _serviceSchedulerData: any = {};
  private _vehicleData: any = {};
  private readonly renderer: Renderer2;
  scriptLoadSuccess: boolean = false;

  constructor(private readonly authService: AuthService,
    private readonly dealerState: Store<DealerState>,
    private readonly dashboardState: Store<DashboardState>,
    private readonly rendererFactory: RendererFactory2, @Inject(DOCUMENT) private readonly document) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.authService.isSignedIn.subscribe(loggedin => {
      this._userData.isSignedIn = loggedin;
      if (loggedin) {
        this.dashboardState.select(selectUserVehicles).subscribe(data => {
          if (data) {
            this.userData = {
              ...this.userData,
              vehicleCount: data.length
            }
          }
        });
      }
    });
    this.dealerState.select(fromSelectors.selectDealerCode).subscribe(t => this._dealerData = { ...this._dealerData, dealerCode: t });
    this.dealerState.select(fromSelectors.selectDealerName).subscribe(t => this._dealerData = { ...this._dealerData, dealerName: t });
    this.dealerState.select(fromSelectors.selectDealerAddressDetails).subscribe(address => {
      this._dealerData = { ...this._dealerData, dealerAddress: address }
    });
  }

  setupAnalytics(): HTMLScriptElement {
    const analyticsScript = document.createElement('script');
    analyticsScript.type = 'text/javascript';
    analyticsScript.src = environment.analyticsTaggingUrl;
    analyticsScript.async = true;
    this.renderer.appendChild(document.head, analyticsScript);
    return analyticsScript;
  }

  trackLink(val: any, sendAllBlocks: boolean = false) {
    if (window && this.scriptLoadSuccess) {
      if (sendAllBlocks) {
        window.fireTag('aa-link', {
          ...this.globalBlock,
          ...this.dealerBlock,
          ...this.userBlock,
          ...this.serviceSchedulerVehicleBlock,
          ...this.serviceSchedulerToolBlock,
          ...val
        });
      } else {
        window.fireTag('aa-link', {
          ...this.globalBlock,
          ...this.dealerBlock,
          ...this.userBlock,
          ...val
        });
      }
    }
  }

  trackPageLoad(val: any, sendAllBlocks: boolean = false) {
    if (window && this.scriptLoadSuccess) {
      if (sendAllBlocks) {
        window.fireTag('aa-pageload', {
          ...this.globalBlock,
          ...this.dealerBlock,
          ...this.userBlock,
          ...this.serviceSchedulerVehicleBlock,
          ...this.serviceSchedulerToolBlock,
          ...val
        });
      } else {
        window.fireTag('aa-pageload', {
          ...this.globalBlock,
          ...this.dealerBlock,
          ...this.userBlock,
          ...val
        });
      }
    }
  }

  customTrackLink(trackingText: string, val: any, sendAllBlocks: boolean = false) {
    if (window && this.scriptLoadSuccess) {
      if (sendAllBlocks) {
        window.fireTag(trackingText, {
          ...this.globalBlock,
          ...this.dealerBlock,
          ...this.userBlock,
          ...this.serviceSchedulerVehicleBlock,
          ...this.serviceSchedulerToolBlock,
          ...val
        });
      } else {
        window.fireTag(trackingText, {
          ...this.globalBlock,
          ...this.dealerBlock,
          ...this.userBlock,
          ...val
        });
      }
    }
  }

  get globalBlock() {
    return {
      gxp_tier: '3',
      //platform: '',
      tool_name: 'asp',
      device_type: 'desktop',
      // tda_code : '',
      // form-campaign_code : ''
    }
  }

  set globalData(globalValues: any) {
    this._globalData = globalValues;
  }

  get globalData() {
    return this._globalData;
  }

  get userBlock() {
    return {
      user_logged_status: this._userData.isSignedIn ? 'logged in' : 'guest',
      user_vehicles_count: this._userData.vehicleCount ? this._userData.vehicleCount : '',
      // user_digital_garage_id : ''
    };
  }

  set userData(userInfo: any) {
    this._userData = userInfo;
  }

  get userData() {
    return this._userData
  }

  get dealerBlock() {
    return {
      dealer_brand: environment.brand,
      dealer_code: this._dealerData.dealerCode,
      dealer_name: this._dealerData.dealerName,
      dealer_zipcode: this._dealerData.dealerAddress?.zip,
      dealer_city: this._dealerData.dealerAddress?.city,
      dealer_state: this._dealerData.dealerAddress?.state,
      dealer_employee_id: this._dealerData.dealer_employee_id ?? '',
      dealer_contact_dept_type: this._dealerData.isRemote ? "we come to you" : "you come to us"
    };
  }

  get dealerData() {
    return this._dealerData
  }

  set dealerData(dealerInfo: any) {
    this._dealerData = dealerInfo;
  }

  get userInputErrorBlock() {
    return {
      displayed_error_message: this._userInputErrorData.message ?? '',
    }
  }

  set userInputError(error: any) {
    this._userInputErrorData = {
      message: error
    }
  }

  set serviceSchedulerToolData(servSchedVal: any) {
    this._serviceSchedulerData = servSchedVal;
  }

  get serviceSchedulerToolData() {
    return this._serviceSchedulerData;
  }

  get serviceSchedulerToolBlock() {
    return {
      servscheduler_code: this._serviceSchedulerData.code,
      servscheduler_title: this._serviceSchedulerData.title,
      servscheduler_catgry: this._serviceSchedulerData.category,
      servscheduler_msrp: this._serviceSchedulerData.msrp
    }
  }

  set serviceSchedulerVehicleData(vehicleBlock: any) {
    this._vehicleData = vehicleBlock;
  }

  get serviceSchedulerVehicleData() {
    return this._vehicleData;
  }

  get serviceSchedulerVehicleBlock() {
    return {
      servscheduler_vehyear: this._vehicleData.year,
      servscheduler_vehmake: this._vehicleData.make,
      servscheduler_vehmodel: this._vehicleData.model,
      servscheduler_vehvin: this._vehicleData.vin,
      servscheduler_vehmilage: this._vehicleData.mileage
    }
  }
}
