import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environmentConst } from './environments/environment';

import { AppModule } from './modules/app.module';

import * as e6p from 'es6-promise';
(e6p as any).polyfill();
import 'isomorphic-fetch';
import { loadEnvironmentVariables, loadFonts } from './modules/shared/services/util.service';

if (environmentConst.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

loadEnvironmentVariables(window);
loadFonts();
