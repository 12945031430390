import { AuthService } from './../shared/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GsapAnimationService } from '../shared/services/gsap-animation.service';
import { DashboardState } from '../store/dashboard/dashboard.reducer';
import { Store } from '@ngrx/store';
import { selectAppointmentsLoading } from '../store/dashboard/dashboard.selectors';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AspBannerService } from '../shared/components/asp-banner/asp-banner.service';
import { AppointmentType } from '@signal/asp-data-commons';
import { navigateToRoute,environment } from '../shared/services/util.service';
import { AnalyticsService } from './../shared/services/analytics.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy, OnInit {
  signedIn: boolean;
  testDriveType = AppointmentType.TEST_DRIVE;
  deliveryType = AppointmentType.DELIVERY;
  serviceType = AppointmentType.SERVICE;
  learningType = AppointmentType.LEARNING;
  default = "default";
  environment;
  isAppointmentsLoading$: Observable<boolean>;
  constructor(
    private readonly gsap: GsapAnimationService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly bannerService: AspBannerService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.environment = environment;
    this.authService.user.subscribe(user => {
      this.signedIn = user && !this.authService.isExpired();
      if (!this.signedIn)
        this.activatedRoute.queryParams.subscribe(params => {
          const queryText = params.searchText;
          if (!queryText) {
            navigateToRoute('welcome', '/welcome', this.router);
          }
        });

    });
    this.isAppointmentsLoading$ = this.dashboardState.select(selectAppointmentsLoading);
    this.bannerService.hideCarImage();
  }

  ngOnInit(): void {
    const pageLoadTrackingData = {
      content_section: 'scheduled services and test drives'
    }
    setTimeout(() => {
      this.analyticsService.trackPageLoad(pageLoadTrackingData);
    }, 3000);
  }

  ngOnDestroy(): void {
    this.bannerService.showCarImage();
  }
}
