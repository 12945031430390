import { Component, OnDestroy, OnInit } from '@angular/core';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import { SubscriptionList } from '../../shared/models/asp.types';
import * as util from '../../shared/services/util.service';
import { AppointmentService } from '../../shared/services/asp/appointment.service';
import { DateTime } from 'luxon';
import { CurrencyPipe } from '@angular/common';
import { AppointmentType, IResponse, IServiceAppointment, LocationType, OpcodeType } from '@signal/asp-data-commons';
import { selectServiceShowPricing } from '../../store/dealer/dealer.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-appointment',
  templateUrl: './confirm-appointment.component.html',
  styleUrls: ['./confirm-appointment.component.scss']
})
export class ConfirmAppointmentComponent implements OnInit, OnDestroy {
  view = 'confirm';
  appointment: IServiceAppointment;
  makeModelYear = '2020 YARIS HATCHBACK';
  detailMap: { [key: string]: string } = {
    'selectService.time': 'June 16 at 9:00 am',
    'selectService.vehicle': '2020 YARIS HATCHBACK LE\n1230ZESAD91191',
    'transportation.advisor': 'Dave Rowold',
    'selectService.services': '10,000 Miles Checkup - Covered by Toyota Care\nState Inspection - $84.24\nWinshield - $49.94',
    'appointmentInformation.estimatedCost': '$109.23',
    'confirm.coupons': 'Alignment Check',
    "transportation.location": `appointmentInformation.pickupYourVehicleAt: \n1234 Cherry Lane, Irving, TX 75039`
  };

  appointmentId: string;
  private readonly subs: SubscriptionList = {};

  constructor(
    private readonly bannerService: AspBannerService,
    private readonly appointmentService: AppointmentService,
    private readonly route: ActivatedRoute,
    private readonly currencyPipe: CurrencyPipe,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly translate: TranslateService,
    private router: Router
  ) {


    const urlState = this.router.getCurrentNavigation()?.extras?.state as IServiceAppointment;
    if(urlState?.appointmentId) {
      this.appointment = urlState;
      this.setDetailMap(urlState);
    } else {
        this.subs.query = this.route.queryParams.subscribe(params => {
          this.appointmentId = params.id;

          if (this.appointmentId) {
            this.subs.getAppt = this.appointmentService.get(AppointmentType.SERVICE, this.appointmentId).subscribe((res: IResponse<IServiceAppointment>) => {
              if (res && res.data) {
                this.appointment = res.data;
                this.setDetailMap(res.data);
              }
            });
          }
        });
    }
  }

  setDetailMap(appointmentDetails: IServiceAppointment) {
    this.serviceAppointmentState.select(selectServiceShowPricing)
      .subscribe((showPricing: boolean) => {
        const isRemoteLocation = appointmentDetails.transportation.category === LocationType.REMOTE;

        const date = `${DateTime.fromISO(appointmentDetails.appointmentStartDate).toFormat('MMMM dd')}`;
        let time = appointmentDetails.timeSlotId;
        if (appointmentDetails.remotePickupTimeSlotId) {
          time = appointmentDetails.remotePickupTimeSlotId;
        }
        const dateTime = `${date} at ${time}`;

        const vehicle = appointmentDetails.vehicle;
        const advisorName = appointmentDetails.advisor.displayName;

        const customer = appointmentDetails.customer;

        let services = '';
        let cost = 0;
        let isShowPricingDisabledForAny = false;

        if (appointmentDetails.serviceRequests && appointmentDetails.serviceRequests.length > 0) {
          appointmentDetails.serviceRequests.map(s => s.details).forEach(t => {
            const coveredByToyotaCare = t?.recommended?.isCoveredByToyotaCare;
            const serviceRequestPrice = t.pricing.final;
            const displayName = t.displayName ? t.displayName : t.dmsDescription;
            if (t.opcodeType && t.opcodeType !== OpcodeType.OTHER) {
              services += `${displayName}\n`;
            } else if (t?.pricing?.show && showPricing) {
              services += `${displayName} - ${coveredByToyotaCare ? 'Covered by Toyota Care' : this.opCodePricingTranslation(util.getOpcodeDisplayPricing(t))}\n`;
              cost = cost + (!isNaN(serviceRequestPrice) && !coveredByToyotaCare ? serviceRequestPrice : 0);
            } else {
              services += `${displayName}${coveredByToyotaCare ? ' - ' + 'Covered by Toyota Care' + '\n' : ' - ' + (this.translate.instant('selectService.askAdvisor')) + '\n'}`;
            }

            /* Checking whether any opcode is having show pricing as false or not */
            if (!t?.pricing?.show) {
              isShowPricingDisabledForAny = true;
            }
          });
        }
        let customerAddress = '';
        if (isRemoteLocation) {
          const custAdd = appointmentDetails.pickUpAddress ? appointmentDetails.pickUpAddress : customer.address;
          customerAddress = `confirm.weWillPickYourCarAt: \n${custAdd.addressLine1}, ${custAdd.addressLine2 ? custAdd.addressLine2 + ', ' : ''} ${custAdd.city}, ${custAdd.state} - ${custAdd.zip}`;
        }
        this.makeModelYear = vehicle.year + ' ' + vehicle.model;
        this.makeModelYear = vehicle.year ? this.makeModelYear : `VIN ${vehicle.vin}`;


        const dealershipAddress = `${this.translate.instant('confirm.serviceYourVehicleAt:')} ${appointmentDetails.dealer.dealerName} \n${appointmentDetails.dealer.addressShort}`;

        let vehicleInfo = vehicle.year ? `${vehicle.year} ${vehicle.model}` : '';
        vehicleInfo = vehicleInfo ? `${vehicleInfo} \n` : vehicleInfo;
        vehicleInfo = vehicle.vin ? `${vehicleInfo}VIN ${vehicle.vin}` : vehicleInfo;
        const transportLocation = appointmentDetails.transportation;
        this.detailMap = {
          'selectService.time': dateTime,
          'transportation.advisor': advisorName,
          'selectService.vehicle': vehicleInfo,
          'selectService.services': services,
          'appointmentInformation.estimatedCost': `${util.countryCd === 'US' ? '' : 'MX'}${this.currencyPipe.transform(cost, 'USD', 'symbol')}`,
          'transportation.transportation': transportLocation?.label? this.translate.instant(`transportation.${transportLocation.label}`): '',
          "transportation.location": `${(isRemoteLocation ? customerAddress : dealershipAddress)}`
        };
        if (!showPricing || isShowPricingDisabledForAny) {
          delete this.detailMap['appointmentInformation.estimatedCost'];
        }
      });
  }

  opCodePricingTranslation(text) {
    if (text) {
      text = text.replace('Starts at', this.translate.instant('selectService.startsAt'));
      text = text.replace('Ranges from', this.translate.instant('selectService.rangesFrom'));
    }
    return text;
  }

  ngOnInit(): void {
    this.bannerService.hide();
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }

}
