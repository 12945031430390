import {Pipe, PipeTransform} from '@angular/core';
import { ParseError, parsePhoneNumber, parsePhoneNumberWithError  } from 'libphonenumber-js';
@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(phoneValue:string) {
        try {
            const phoneNumber = parsePhoneNumberWithError(phoneValue + '');  
            const countryCode = (!!phoneNumber.countryCallingCode) ? `+${phoneNumber.countryCallingCode}` : null;
            let formattedPhoneNumber;
            
            if(!!countryCode){
                formattedPhoneNumber = `${countryCode} ${phoneNumber.formatNational()}`;
            } else {
                formattedPhoneNumber = phoneNumber.formatNational();
            }

            return formattedPhoneNumber;
        } catch(error) {
            let phoneNumber = phoneValue;
            if(error instanceof ParseError){
                if(error.message === 'INVALID_COUNTRY') {
                    // if no country code found, set US as default country code
                    phoneNumber = parsePhoneNumber(phoneValue,'US').formatNational();
                }
            }

            return phoneNumber;
        }
    }
}
