<div id="loding-animation" [style.--size]="size">
  <ng-container *ngIf="loadingSymbol === 'Toyota'">
    <svg id="loading-toyota-logo"
         class="logo"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="30 0 100 100"
         height="47mm"
         width="80mm"
    >
      <clipPath id="loading-toyota-clippath-outer">
        <path
          d="M85.414 28.57A39.55 25.145 0 0045.88 53.717 39.55 25.145 0 0085.432 78.86a39.55 25.145 0 0039.55-25.144 39.55 25.145 0 00-39.55-25.145 39.55 25.145 0 00-.018 0zm-.114 3.887a33.698 21.986 0 01.193 0 33.698 21.986 0 0133.698 21.986A33.698 21.986 0 0185.493 76.43a33.698 21.986 0 01-33.698-21.986A33.698 21.986 0 0185.3 32.457z"
        />
      </clipPath>
      <clipPath id="loading-toyota-clippath-top">
        <path
          d="M85.432 28.57a29.78 12.857 0 00-29.78 12.858 29.78 12.857 0 0029.78 12.857 29.78 12.857 0 0029.779-12.857 29.78 12.857 0 00-29.779-12.857zm.06 3.897a22.92 7.587 0 01.011 0 22.92 7.587 0 0122.92 7.587 22.92 7.587 0 01-22.92 7.587 22.92 7.587 0 01-22.92-7.587 22.92 7.587 0 0122.908-7.587z"
        />
      </clipPath>
      <clipPath id="loading-toyota-clippath-center">
        <path
          d="M85.493 31.07a9.505 23.11 0 00-9.505 23.11 9.505 23.11 0 009.505 23.11 9.505 23.11 0 009.505-23.11 9.505 23.11 0 00-9.505-23.11zm0 5.642a5.528 15.772 0 015.528 15.772 5.528 15.772 0 01-5.528 15.771 5.528 15.772 0 01-5.528-15.771 5.528 15.772 0 015.528-15.772z"
        />
      </clipPath>

      <g fill="none" stroke="#EB0A1E" stroke-linecap="round">
        <path
            #pathCenter
            id='loading-toyota-stroke-center'
            clip-path="url(#loading-toyota-clippath-center)"
            class="loading-toyota-path center"
            d="M83.912 36.002a5.772 19.077 0 017.178 12.496 5.772 19.077 0 01-3.693 23.875 5.772 19.077 0 01-7.269-11.912 5.772 19.077 0 013.515-24.166"
            opacity="1"
            stroke-width="8.663"
            stroke-linejoin="bevel"
            paint-order="markers fill stroke"
        />
        <path
            #pathTop
            id='loading-toyota-stroke-top'
            clip-path="url(#loading-toyota-clippath-top)"
            class="loading-toyota-path top"
            d="M77.654 33.799a25.432 8.214 0 0131.626 5.38 25.432 8.214 0 01-16.27 10.28 25.432 8.214 0 01-32.025-5.129 25.432 8.214 0 0115.487-10.405"
            opacity="1"
            stroke-width="9.833"
            stroke-linejoin="bevel"
            paint-order="markers fill stroke"
        />
        <path
            #pathOuter
            id='loading-toyota-stroke-outer'
            clip-path="url(#loading-toyota-clippath-outer)"
            class="loading-toyota-path outer"
            d="M74.632 32.914a36.534 21.846 0 0145.433 14.31 36.534 21.846 0 01-23.374 27.34 36.534 21.846 0 01-46.005-13.64 36.534 21.846 0 0122.248-27.675"
            opacity="1"
            stroke-width="8.15"
            stroke-linejoin="round"
            paint-order="fill markers stroke"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="loadingSymbol === 'Lexus'">
    <svg id="loading-lexus-logo" xmlns="http://www.w3.org/2000/svg" width="80mm" height="47mm" viewBox="30 0 100 100"
         class="prefix__logo">
      <clipPath id="loading-lexus-clipath">
        <path display="block"
              d="M101.349 31.082l-1.53 2.298c10.251 4.212 14.392 11.87 14.392 20.07 0 4.1-1.104 7.81-3.269 7.81H84.066c-3.664 0-4.75-1.305-3.108-4.562 2.94-5.8 11.748-16.897 17.179-23.57.79-.976 1.443-2.508-1.406-3.269-2.915-.778-7.63-1.102-11.622-1.102-20.21.003-34.298 10.928-34.298 25.016 0 12.969 12.217 25.077 34.248 25.077 9.74 0 21.676-2.735 28.45-10.411h-5.838c-6.596 5.96-15.204 7.323-22.562 7.323-17.782 0-29.036-9.233-29.036-21.99 0-11.757 12.742-22.314 28.816-22.314 1.922 0 2.552.415.519 2.94C81.302 39.5 73.954 49.61 70.505 55.532c-3.699 6.347.074 11.501 8.378 11.501h33.62c2.076 0 3.257-1.183 4.468-3.328.968-1.72 2.483-5.203 2.483-9.932 0-9.557-7.17-18.338-18.105-22.69z"
              fill="#ff8080"/>
      </clipPath>
      <path #pathLexus id="loading-lexus-stroke" clip-path="url(#loading-lexus-clipath)"
            d="M110.23 73.374s-5.295.367-6.426 1.094c-1.132.728-7.435 2.263-9.94 2.667-2.505.404-8.728 1.536-14.465.404-5.738-1.131-11.233-2.424-16.243-5.818-5.01-3.394-10.829-10.357-9.94-19.718.89-9.36 8.243-14.626 10.83-16.162 2.585-1.535 11.15-6.222 20.929-5.899 9.778.323 9.778 1.778 6.869 4.202-2.91 2.425-16.162 22.304-16.162 22.304s-6.384 8.08 6.95 7.354c13.333-.728 28.768-1.213 28.768-1.213s6.141-2.343 6.707-9.94c.566-7.595-3.152-10.343-7.273-13.899-4.121-3.555-6.167-4.892-6.167-4.892"
            fill="none" stroke="#000000" stroke-width="10.76257125" stroke-linecap="square" stroke-linejoin="bevel"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="loadingSymbol === 'dots'">
    <div class="dots-loading-symbol" [style.--color]="color">
      <div class="loading-text" *ngIf="text">{{text}}</div>
      <div class="dots">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </ng-container>
</div>
