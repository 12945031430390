import {Action} from '@ngrx/store';
import {IAppointment, IVehicle} from '@signal/asp-data-commons';

export enum DashboardActionTypes {
  LOAD_USER_VEHICLES = '[App component] Load user vehicles',
  LOAD_USER_VEHICLES_SUCCESS = '[DCS API] Load user vehicles success',
  LOAD_USER_VEHICLES_FAILURE = '[DCS API] Load user vehicles failure',
  LOAD_APPOINTMENTS = '[Dashboard page] Load user appointments',
  LOAD_APPOINTMENTS_FOR_VIN='[Dashboard page] Load user appointments based on vin',
  LOAD_APPOINTMENTS_SUCCESS = '[ASP API] Load user appointments success',
  LOAD_APPOINTMENTS_SUCCESS_FOR_VIN = '[ASP API] Load user appointments success for vin',
  LOAD_APPOINTMENTS_FAILURE = '[ASP API] Load user appointments failure',
  UPSERT_APPOINTMENT = '[Service appointment] Upsert user appointment',
  CANCEL_APPOINTMENT_FROM_DASHBOARD = '[Dashboard page] Cancel an appointment',
  CANCEL_APPOINTMENT_FROM_CONFIRM = '[Confirm page] Cancel an appointment',
  CANCEL_APPOINTMENT_SUCCESS = '[ASP API] Cancel an appointment success',
  CANCEL_APPOINTMENT_FAILURE = '[ASP API] Cancel an appointment failure',
}

export class LoadUserVehicles implements Action {
  readonly type: string = DashboardActionTypes.LOAD_USER_VEHICLES;

  constructor() {
  }
}

export class LoadUserVehiclesSuccess implements Action {
  readonly type = DashboardActionTypes.LOAD_USER_VEHICLES_SUCCESS;

  constructor(public payload: { vehicles: IVehicle[] }) {
  }
}
export class LoadAppointmentsSuccessForVin implements Action {
  readonly type = DashboardActionTypes.LOAD_APPOINTMENTS_SUCCESS_FOR_VIN;

  constructor(public payload: { appointments: IAppointment[] }) {
  }
}
export class LoadUserVehiclesFail implements Action {
  readonly type = DashboardActionTypes.LOAD_USER_VEHICLES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAppointments implements Action {
  readonly type: string = DashboardActionTypes.LOAD_APPOINTMENTS;

  constructor(public payload: { queryText: string }) {
  }
}

export class LoadAppointmentsForVin implements Action {
  readonly type: string = DashboardActionTypes.LOAD_APPOINTMENTS_FOR_VIN;

  constructor(public payload: { queryText: string }) {
  }
}

export class LoadAppointmentsSuccess implements Action {
  readonly type = DashboardActionTypes.LOAD_APPOINTMENTS_SUCCESS;

  constructor(public payload: { appointments: IAppointment[] }) {
  }
}

export class LoadAppointmentsFail implements Action {
  readonly type = DashboardActionTypes.LOAD_APPOINTMENTS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class UpsertAppointment implements Action {
  readonly type = DashboardActionTypes.UPSERT_APPOINTMENT;

  constructor(public payload: { appointment: IAppointment }) {
  }
}

export class CancelAppointment {

  constructor(public payload: { appointment: IAppointment }) {
  }
}

export class CancelAppointmentFromDashboard extends CancelAppointment implements Action {
  readonly type: string = DashboardActionTypes.CANCEL_APPOINTMENT_FROM_DASHBOARD;

  constructor(public payload: { appointment: IAppointment }) {
    super(payload);
  }
}

export class CancelAppointmentFromConfirm extends CancelAppointment implements Action {
  readonly type: string = DashboardActionTypes.CANCEL_APPOINTMENT_FROM_CONFIRM;

  constructor(public payload: { appointment: IAppointment }) {
    super(payload);
  }
}

export class CancelAppointmentSuccess implements Action {
  readonly type = DashboardActionTypes.CANCEL_APPOINTMENT_SUCCESS;

  constructor(public payload: { appointmentId: string }) {
  }
}

export class CancelAppointmentFail implements Action {
  readonly type = DashboardActionTypes.CANCEL_APPOINTMENT_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export type DashboardActions =
  | LoadUserVehicles
  | LoadUserVehiclesSuccess
  | LoadUserVehiclesFail
  | LoadAppointments
  | LoadAppointmentsForVin
  | LoadAppointmentsSuccess
  | LoadAppointmentsFail
  | UpsertAppointment
  | CancelAppointmentFromDashboard
  | CancelAppointmentSuccess
  | CancelAppointmentFail;
