import { LayoutComponent } from './layout.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';



const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'prefix'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'testdrive',
        loadChildren: () => import('../test-drive/test-drive.module').then(m => m.TestDriveModule)
      },
      {
        path: 'service',
        loadChildren: () => import('../service-appointment/service-appointment.module').then(m => m.ServiceAppointmentModule)
      },
      {
        path: 'delivery',
        loadChildren: () => import('../delivery/delivery.module').then(m => m.DeliveryModule)
      },
      {
        path: 'learning',
        loadChildren: () => import('../tech-appointment/tech-appointment.module').then(m => m.TechAppointmentModule)
      },
      {
        path: 'vehicleinquiry',
        loadChildren: () => import('../inquiry-appointment/inquiry-appointment.module').then(m => m.InquiryAppointmentModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {
}
