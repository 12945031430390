import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SubscriptionList } from '../../shared/models/asp.types';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import {
  AppointmentType, Department, IAddress, IAvailability, IAvailabilityOptions, ICustomer,
  ITransportOption, LocationType
} from '@signal/asp-data-commons';
import { unsubscribeSubscriptions } from '../../shared/services/util.service';
import { SalesAppointmentState } from '../../store/sales-appointment/sales-appointment.reducer';
import { Store } from '@ngrx/store';
import * as fromActions from '../../store/sales-appointment/sales-appointment.actions';
import * as fromSelectors from '../../store/sales-appointment/sales-appointment.selectors';

import _ from 'lodash';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { selectDealerAddressDetails } from '../../store/dealer/dealer.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-customer-info',
  templateUrl: './vehicle-customer-info.component.html',
  styleUrls: ['./vehicle-customer-info.component.scss']
})
export class VehicleCustomerInfoComponent implements OnInit, OnDestroy {
  @Input() isUpdate: boolean;
  @Input() signedIn: boolean;
  @Input() profile: any;
  @Input() appointmentId: string;
  @Input() checklistDuration: { min: number };
  @Input() dealerCode: string;
  @Input() lockSalesConsultant: string;

  private lastValue: any;
  private storeValue: any;
  isStoreUpdate = false;

  vehicleDetails: any = {
    yearMakeModel: '',
    vin: '',
    stockNo: 'P6392',
    trim: 'XE',
    certificationStatus: '',
    source: '',
    make: ''
  };

  subscriptions: SubscriptionList = {};
  deliveryForm: FormGroup;
  private readonly tradeInForm: FormControl;
  private readonly availability: FormControl;
  private readonly customerForm: FormControl;
  private readonly contactEmailInfoForm: FormControl;
  availabilityOptions$: Observable<IAvailabilityOptions>;
  availabilityOptionsLoading$: Observable<boolean>;
  availability$: Observable<IAvailability>;
  availabilityLoading$: Observable<boolean>;
  transportOptions$: Observable<ITransportOption[]>;
  remoteZipcodes$: Observable<string[]>;
  dealerAddress: IAddress;
  private signedInUserDetails: ICustomer;
  hasRemoteLocation = false;
  remoteValidZipcodes: { pmaZipCodes: string[], isRemote: boolean };
  isValidRemoteZip = false;
  apptType = AppointmentType.DELIVERY;
  department = Department.SALES;
  languageId='';

  constructor(
    private readonly viewportScroller: ViewportScroller,
    private readonly bannerService: AspBannerService,
    private readonly formBuilder: FormBuilder,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly dealerState: Store<DealerState>,
    private readonly translate: TranslateService,
  ) {
    this.initializeDeliveryForm();
  }

  initializeDeliveryForm() {
    this.deliveryForm = this.formBuilder.group({
      vehicle: this.formBuilder.group({
        exteriorColor: null,
        model: null,
        trim: null,
        year: null,
        make: null,
        imageUrl: null,
        vin: null,
        certificationStatus: null,
        stockNo: null,
        source: null,
        dealerCategory:null,
        unitId:null,
        dealerCode:null
      }),
      availability: this.availability,
      transportationTypeCode: ['', [Validators.required]],
      remoteZipCode: [''],
      customer: this.contactEmailInfoForm,
      address: this.customerForm,
      tradeIn: this.tradeInForm,
    });

    this.onValueChange()
  }

  onValueChange() {
    this.subscriptions.valueChanges = this.deliveryForm.valueChanges.subscribe((value) => {
      /* *this is to choose selected vehicle during edit mode*/
      if (value?.vehicle?.model && this.isUpdate) {
        const data = { ...this.vehicleDetails };
        data.yearMakeModel = `${value.vehicle.year} ${value.vehicle.make} ${value.vehicle.model}`;
        data.vin = value.vehicle.vin;
        data.certificationStatus = value.vehicle.certificationStatus;
        data.stockNo = value.vehicle.stockNo;
        data.source = value.vehicle.source;
        data.make = value.vehicle.make;
        if (!_.isEqual(data, this.vehicleDetails)) {
          this.vehicleDetails = { ...this.vehicleDetails, ...data };
          this.bannerService.update(value.vehicle.imageUrl);
        }
      }

      const val = { ...value }
      delete val.otherVehicles;
      delete val.vehicle;

      /*Updating storeValue = undefined everytime the form is updated to the initial changes from the state */
      if (this.storeValue && _.isEqual(this.storeValue, val)) {
        this.storeValue = undefined;
        this.isStoreUpdate = false;

        /* for the very first time lastValue, storeValue and value will be equal,
        hence the storeValue will be undefined. To handle this scenario we are
        reassigning the same value from the store*/
        if (_.isEqual(this.lastValue, value) && !this.isUpdate) {
          this.storeValue = this.lastValue;
        }
        return;
      }

      if (_.isEqual(this.lastValue, value)) {
        return;
      }

      if ((value.transportationTypeCode && this.lastValue.transportationTypeCode !== value.transportationTypeCode)) {
        this.isStoreUpdate = true;
        this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
          { date: '', advisorId: value.availability.advisorId, transport: value.transportationTypeCode, checklistDuration: this.checklistDuration,
          appointmentId: this.appointmentId || '' }));
      } else {
        this.isStoreUpdate = false;
      }

      /*lastValue keeps changing everytime the form value changes
      (even when the changes are from the initialstate or EditState) */
      this.lastValue = value;

      /*dispatch happens only when the storeValue = undefined which means
      that all the initial changes (initialState and EditState) from store are
      populated in the form and now we are dispatching the changes done by the user*/
      if (!this.storeValue) {
        if (value && value.customer?.address?.stateAbbr) {
          value.address.state = value.address.stateAbbr;
        }
        this.salesAppointmentState.dispatch(new fromActions.UpdateSalesFormData({ value, appointmentType: AppointmentType.DELIVERY }));
      }

    });
  }

  ngOnInit() {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.bannerService.show();
    this.setupStoreValue()
  };

  setupStoreValue() {
    
    this.setSignedInUserDetails();

    this.subscriptions.address = this.dealerState.select(selectDealerAddressDetails).subscribe(dealerAddress => this.dealerAddress = dealerAddress);

    if (this.signedIn) {
      this.salesAppointmentState.dispatch(new fromActions.LoadCustomerDetails({ signedInUserDetails: this.signedInUserDetails }));
    }

    this.availabilityOptions$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptions);
    this.availabilityOptionsLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityOptionsLoading);
    this.availability$ = this.salesAppointmentState.select(fromSelectors.getAvailability);
    this.availabilityLoading$ = this.salesAppointmentState.select(fromSelectors.getAvailabilityLoading);
    this.transportOptions$ = this.salesAppointmentState.select(fromSelectors.getTransportOptions);
    this.remoteZipcodes$ = this.salesAppointmentState.select(fromSelectors.getPMAZipCodes);

    this.subscriptions.options = this.availabilityOptions$.subscribe(options => {
      this.hasRemoteLocation = !!options?.transport?.transportOptions?.find(x => x.category === LocationType.REMOTE && x.isActive && x.enableDeliveryType);
    });

    this.subscriptions.formStateValueSub = this.salesAppointmentState.select(fromSelectors.appointmentDetails).subscribe((val) => {
      let value = { ...val };
      delete value.checklistRequest;
      delete value.appointmentComments;
      value.availability = { ...value.availability, timeSlotId: '', appointmentStartDate: '' };

      if (_.isEqual(this.lastValue, value)) {
        return;
      }
      this.languageId=val?.availability?.languageId;
      this.lastValue = value;
      this.isStoreUpdate = true;

      /*isUpdate -> During edit mode we need to store the state value thats coming in,
      so that we can check the incoming form changes against storeValue
      isStoreUpdate -> Phase 2 (basically used when changes are invoked from state during create mode)*/
      if (this.isUpdate || this.isStoreUpdate) {
        this.storeValue = { ...value };
        delete this.storeValue.otherVehicles;
        delete this.storeValue.vehicle;
      }

      this.deliveryForm.patchValue(value);
      this.checkZipCodes(value.remoteZipCode);
    });
  }

  checkZipCodes(value) {
    this.deliveryForm.controls.remoteZipCode.clearValidators();
    if (this.deliveryForm.value.transportationTypeCode === 'REMOTE') {
      this.subscriptions.remoteZip = this.remoteZipcodes$.pipe(filter(zip => !!zip?.length), take(1)).subscribe(zip => {
        this.remoteValidZipcodes =
        {
          pmaZipCodes: zip,
          isRemote: true
        };
        this.isValidRemoteZip = zip?.findIndex(x => x === value) !== -1;
        this.deliveryForm.controls.remoteZipCode.setValidators(this.zipValidator());
        this.deliveryForm.controls.remoteZipCode.updateValueAndValidity();
      });
    }
  }
  zipValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.deliveryForm.value.transportationTypeCode === 'REMOTE' && !this.isValidRemoteZip) {
        return { remoteZipCode: this.translate.instant('appointmentInformation.invalidRemotezipcode') };
      } else {
        return null;
      }
    };
  }
  setSignedInUserDetails() {
    if (this.signedIn) {
      this.signedInUserDetails = {
        firstName: this.profile.given_name,
        lastName: this.profile.family_name,
        emailAddress: this.profile.email,
        phoneNumber: ''
      };
    }
  }

  isValidForm() {
    return (this.appointmentId ? true : false) &&
      this.deliveryForm.controls.availability.value.advisorId &&
      this.deliveryForm.controls.availability.value.languageId &&
      this.deliveryForm.controls.transportationTypeCode.valid &&
      this.deliveryForm.controls.address.valid &&
      this.deliveryForm.controls.tradeIn.valid &&
      this.deliveryForm.controls.remoteZipCode.valid &&
      this.deliveryForm.controls.vehicle.valid &&
      this.deliveryForm.controls.customer.valid;
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
  }

  getTranslation(value) {
    return value ? `${this.translate.instant(`common.${value}`)}` : value;
  }
}

