import { AnalyticsService } from './../../shared/services/analytics.service';
import { AuthService } from './../../shared/services/auth.service';
import { FactoryServicesDialogComponent } from './../../shared/components/factory-services-dialog/factory-services-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IVehicleForm } from './../../store/service-appointment/service-appointment.reducer';
import { UpdateRecallCodes, UpdateServiceForm } from './../../store/service-appointment/service-appointment.actions';
import { UserVehicleService } from './../../shared/services/user-vehicle.service';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentFormService } from '../appointment-form.service';
import { ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import { Store } from '@ngrx/store';
import * as fromSelectors from '../../store/service-appointment/service-appointment.selectors';
import * as fromDashboardSelectors from '../../store/dashboard/dashboard.selectors';
import * as util from '../../shared/services/util.service';
import { SubscriptionList } from '../../shared/models/asp.types';
import { getServiceRequestValidator } from '../service-appointment.validator';
import { IOpcodeRequest, IServiceOpcode, OpcodeType, IServiceRequest } from '@signal/asp-data-commons';
import { Observable } from 'rxjs';
import { selectServiceShowPricing, selectUpsellText } from '../../store/dealer/dealer.selectors';
import { debounceTime, take } from 'rxjs/operators';
import { LoadServiceRequests } from '../../store/service-appointment/service-appointment.actions';
import { DashboardState } from '../../store/dashboard/dashboard.reducer';
import _ from 'lodash';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { FactoryPrintService } from '../../shared/components/factory-services-dialog/factory-print.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.component.html',
  styleUrls: ['./select-service.component.scss']
})
export class SelectServiceComponent implements OnInit, OnChanges, OnDestroy {

  allServices: IServiceOpcode[] = [];
  services: IServiceOpcode[] = [];
  recommendedServices: IServiceOpcode[] = [];
  recommendedServicesSelectedCount = 0;
  regularServicesSelectedCount = 0;
  openedRecommendedService;
  openedService;
  telematicsInfo;
  telematicsAlert: string;
  recallAlert: string;
  selectedServicesArr: IServiceRequest[] = [];
  isEditMode: boolean;
  isTelematicsLoading: boolean;
  telematicsLoadedFor: string;
  lastVehicleForm: IVehicleForm;

  @Input() stepIndex = 1;
  @ViewChild('clearFilter') clearFilterInput: ElementRef;
  step: FormGroup;
  service: IServiceOpcode = {} as IServiceOpcode;
  selectedServices: any[] = [];
  private subs: SubscriptionList = {};

  serviceOpcodesLoading$: Observable<boolean>;
  showPricing$: Observable<boolean>;
  upsellText$: Observable<string>;
  recommendedServicesAvailable$: Observable<boolean>;
  otherServicesAvailable$: Observable<boolean>;
  index = 0;
  selectedStep$: Observable<number>;
  availableMileages$: Observable<number[]>;
  recommendedServicesByMileage$: Observable<number>;
  availableMileagesLoading$: Observable<boolean>;
  availableMileagesLoaded$: Observable<boolean>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly vehicleService: UserVehicleService,
    private readonly formService: AppointmentFormService,
    readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly dashboardState: Store<DashboardState>,
    private readonly authService: AuthService,
    private readonly dealerState: Store<DealerState>,
    private readonly analyticsService: AnalyticsService,
    private readonly factoryPrintService: FactoryPrintService,
    private readonly translate : TranslateService
  ) {
    this.isTelematicsLoading = true;
    this.telematicsLoadedFor = '';

    this.ready();
    this.setupStore();
    this.factoryPrintService.getImage();
  }

  get f() {
    return this.step && this.step.controls;
  }

  get servicesFormArr(): FormArray {
    return this.f && this.f.servicesFormArr as FormArray;
  }

  get recommendedServicesArr() {
    return this.step.get('recommendedServices') as FormArray;
  }

  get allServicesArr() {
    return this.step.get('allServices') as FormArray;
  }

  getAddOns(index, isRecommendedSec) {
    if (isRecommendedSec) {
      const recommendedFormControl = this.recommendedServicesArr.controls[index].get('addOns') as FormArray;
      return recommendedFormControl.controls;
    } else {
      const recommendedFormControl = this.allServicesArr.controls[index].get('addOns') as FormArray;
      return recommendedFormControl.controls;
    }
  }

  get addOnServicesArr() {
    return this.step.get('addOnServices') as FormArray;
  }

  mapItems() {
    const servicesSelected: any[] = this.allServicesArr.value?.filter((item) => item.selected).map((item) => item.id);
    const recommendedServicesSelected: any[] = this.recommendedServicesArr.value?.filter((item) => item.selected).map((item) => item.id);
    return servicesSelected.length + recommendedServicesSelected.length ? '1' : null;
  }

  ngOnInit(): void {

  }

  ngOnChanges(change: any) {
    this.stepIndex = change.stepIndex.currentValue;
  }

  collapseOthers(isrecommendedSection: boolean, index) {
    if (isrecommendedSection) {
      this.openedRecommendedService = index;
    } else {
      this.openedService = index;
    }
  }

  checkEvent(e) {
    e.stopPropagation();
  }

  viewFactoryDetails(e, service) {
    this.dialog.open(FactoryServicesDialogComponent, {
      maxWidth: '60%',
      height: '70%',
      data: service,
      width:'71rem'
    });
    e.stopPropagation();
  }


  selectionChange(e, isrecommendedSection: boolean, index) {
    let serv: IServiceOpcode;
    if (isrecommendedSection) {
      this.recommendedServicesArr.controls[index].get('trackingFlag').setValue(false);
      this.recommendedServicesArr.controls[index].get('expanded').setValue(e.checked);
      serv = this.recommendedServicesArr.controls[index].get('serviceInfo').value;
    } else {
      this.allServicesArr.controls[index].get('trackingFlag').setValue(false);
      this.allServicesArr.controls[index].get('expanded').setValue(e.checked);
      serv = this.allServicesArr.controls[index].get('serviceInfo').value;
    }
    const serviceSelectTrackingData = {
      content_section : 'services tab',
      link_module : 'choose your services',
      link_text : 'select',
      link_input_text : serv.displayName??serv.description
    }
    this.analyticsService.serviceSchedulerToolData = {
      ...this.analyticsService.serviceSchedulerToolData,
      code : serv.opcode ,
      title : serv.displayName??serv.description ,
      msrp : serv.pricing?.discount?.price
    };
    this.analyticsService.trackLink(serviceSelectTrackingData,true);
    this.updateAddOnSelection(serv, e.checked);
    if (!e.checked && !serv?.isInternalCode && !serv?.isDmsApptOpcode) {
      if (serv.addOnOpcodes.checked && serv.addOnOpcodes.opcodes && serv.addOnOpcodes.opcodes.length > 0) {
        serv.addOnOpcodes.opcodes.forEach(addOn => {
          this.updateAddOnSelection(addOn.opcodeDetails, e.checked);
        });
      }
    }
  }

  accordianOpened(service : FormGroup){
    service.get('expanded').patchValue(true);
    const serviceSelectTrackingData = {
      content_section : 'services tab',
      link_module : 'choose your services',
      link_text : 'expanded',
      link_input_text : 'true'
    }
    const serv = service.get('serviceInfo').value;
    if(service.get('trackingFlag').value){
      this.analyticsService.serviceSchedulerToolData = {
        ...this.analyticsService.serviceSchedulerToolData,
        code : serv.opcode ,
        title : serv.displayName ?? serv.description ,
        msrp : serv.pricing?.discount?.price
      };
      this.analyticsService.trackLink(serviceSelectTrackingData,true);
    }else{
      service.get('trackingFlag').setValue(true);
    }
  }

  accordianClosed(service : FormGroup){
    service.get('expanded').patchValue(false);
    const serviceSelectTrackingData = {
      content_section : 'services tab',
      link_module : 'choose your services',
      link_text : 'expanded',
      link_input_text : 'false'
    }
    const serv = service.get('serviceInfo').value;
    if(service.get('trackingFlag').value){
      this.analyticsService.serviceSchedulerToolData = {
        ...this.analyticsService.serviceSchedulerToolData,
        code : serv.opcode ,
        title : serv.displayName ,
        msrp : serv.pricing?.discount?.price
      };
      this.analyticsService.trackLink(serviceSelectTrackingData,true);
    }else{
      service.get('trackingFlag').setValue(true);
    }
  }

  updateAddOnSelection(service: IServiceOpcode, isSelected) {
    this.addOnServicesArr.controls.forEach(x => {
      if (x.get('serviceInfo').value.opcode === service.opcode) {
        x.get('selected').patchValue(isSelected);
      }
    });
    this.recommendedServicesArr.controls.forEach(x => {
      if (x.get('serviceInfo').value.opcode === service.opcode) {
        x.get('selected').setValue(isSelected);
      }
    });
    this.allServicesArr.controls.forEach(x => {
      if (x.get('serviceInfo').value.opcode === service.opcode) {
        x.get('selected').setValue(isSelected);
         /* if complaint code and isSelected is false,uncheck all subComplaints*/
         const formData=x.get('serviceInfo').value;
         if(formData?.isInternalCode && !isSelected )
         {
           const formArr = x.get('addOns') as FormArray;
           formArr.controls.forEach(c=>{
             c.get('selected').patchValue(isSelected);
           });
         }
      }
    });
    this.recommendedServicesArr.controls.forEach(c => {
      const formArr = c.get('addOns') as FormArray;
      formArr.controls.forEach(x => {
        if (x.get('serviceInfo').value.opcode === service.opcode) {
          x.get('selected').patchValue(isSelected);
        }
      });
    });
    this.allServicesArr.controls.forEach(c => {
      const formArr = c.get('addOns') as FormArray;
      formArr.controls.forEach(x => {
        if (x.get('serviceInfo').value.opcode === service.opcode) {
          x.get('selected').patchValue(isSelected);
        }
      });
    });
  }

  addOnSelectionChange(e, addOnVal: IServiceOpcode,index,isrecommendedSection) {
    const addOnTrackingData = {
      content_section : 'services tab',
      link_module : 'choose your services',
      link_text : "select",
      link_input_field : addOnVal.displayName ?? addOnVal.description
    }
    let serv: IServiceOpcode;
    if (isrecommendedSection) {
      serv = this.recommendedServicesArr.controls[index].get('serviceInfo').value;
    } else {
      serv = this.allServicesArr.controls[index].get('serviceInfo').value;
    }
    this.analyticsService.serviceSchedulerToolData = {
      ...this.analyticsService.serviceSchedulerToolData,
      code : addOnVal.opcode,
      title : serv.displayName ?? serv.description,
      msrp : addOnVal?.pricing?.discount?.price
    };
    this.analyticsService.trackLink(addOnTrackingData,true);
    this.updateAddOnSelection(addOnVal, e.checked);
  }

  trackSearchTerm(filterValue: string) {
    const val = filterValue?.toLocaleLowerCase();
    /*Analytics Section Begins*/
    if (val && val.length > 2) {
      const searchServiceTrackingData: any = {
        content_section: 'Schedule Service Appointment',
        link_text: 'Services Search',
        search_term: val
      };
      this.analyticsService.trackLink(searchServiceTrackingData,true);
    }
    /*Analytics Section Ends*/
  }

  filterServices(filterValue: string) {
    const val = filterValue?.toLocaleLowerCase();
    this.allServicesArr.controls.forEach(t => {
      if (val && val !== '' && t.get('serviceInfo').value.displayName.toLocaleLowerCase().indexOf(val) < 0
        && t.get('serviceInfo').value.description.toLocaleLowerCase().indexOf(val) < 0) {
        t.get('hidden').patchValue(true);
      } else {
        t.get('hidden').patchValue(false);
      }
    });
  }

  clearServiceFilter() {
    this.clearFilterInput.nativeElement.value = '';
    this.filterServices('');
  }

  clearSelections() {
    this.allServicesArr.controls.forEach(item => {
      item.get('expanded').setValue(false);
      this.updateAddOnSelection(item.get('serviceInfo').value, false);
      if (item.get('serviceInfo').value.addOnOpcodes.checked && item.get('serviceInfo').value.addOnOpcodes.opcodes) {
        item.get('serviceInfo').value.addOnOpcodes.opcodes.forEach(addOn => {
          this.updateAddOnSelection(addOn.opcodeDetails, false);
        });
      }
    });
    this.regularServicesSelectedCount = 0;
  }

  ready() {

    this.serviceOpcodesLoading$ = this.serviceAppointmentState.select(fromSelectors.selectServiceOpcodesLoading);
    this.showPricing$ = this.serviceAppointmentState.select(selectServiceShowPricing);
    this.upsellText$ = this.dealerState.select(selectUpsellText);
    this.recommendedServicesAvailable$ = this.serviceAppointmentState.select(fromSelectors.selectRecommendedServiceOpcodesAvailable);
    this.otherServicesAvailable$ = this.serviceAppointmentState.select(fromSelectors.selectOtherServiceOpcodesAvailable);
    this.selectedStep$ = this.serviceAppointmentState.select(fromSelectors.selectCurrentStep);
    this.availableMileages$ = this.serviceAppointmentState.select(fromSelectors.selectAvailableMileagesData);
    this.recommendedServicesByMileage$ = this.serviceAppointmentState.select(fromSelectors.selectServiceOpcodesRecommendedMileage);
    this.availableMileagesLoading$ = this.serviceAppointmentState.select(fromSelectors.selectAvailableMileagesLoading);
    this.availableMileagesLoaded$ = this.serviceAppointmentState.select(fromSelectors.selectAvailableMileagesLoaded);

    this.step = this.formBuilder.group({
      recommendedServices: this.formBuilder.array([]),
      allServices: this.formBuilder.array([]),
      addOnServices: this.formBuilder.array([]),
      selectedServices: [[], null],
      isInitialState: false
    });

    this.step.setValidators(getServiceRequestValidator());

    this.subs.validateServiceSub = this.formService.validateServices.subscribe(t => {
      this.recommendedServicesArr.controls.forEach(service => {
        if (!service.valid) {
          service.get('expanded').patchValue(true);
        }
      });
      this.allServicesArr.controls.forEach(service => {
        if (!service.valid) {
          service.get('expanded').patchValue(true);
        }
      });
    });

  }

  ngOnDestroy(): void {
    this.unsubscribeSubscriptionsWrapper(this.subs);
  }

  buildRecommendedServicesFormArray() {
    this.recommendedServicesArr.clear();
    this.selectedServicesArr = [];
    this.recommendedServices.forEach(rs => {

      let isSelected = false;
      let comments;
      const serv = { ...rs };
      if (!!rs.recommended && rs.recommended.isTelematics) {
        if (!this.telematicsAlert) {
          return;
        }
        else {
          comments = serv.description = this.telematicsAlert;
          isSelected = true;
        }
      }
      else if (!!rs.recommended && rs.recommended.isSSCRecall) {
        if (!this.recallAlert) {
          return;
        }
        else {
          comments = serv.description = this.recallAlert;
          isSelected = true;
        }
      }
      const selService: IServiceRequest = { opcode: rs.opcode, comments, details: rs };

      const selectedService = this.findSelectedService(rs);

      isSelected = this.isEditMode ? false : isSelected;
      if (isSelected) {
        this.selectedServicesArr.push(selService);
      }
      const addOnArr: FormArray = new FormArray([]);
      if (serv.addOnOpcodes && serv.addOnOpcodes.opcodes) {
        serv.addOnOpcodes.opcodes.forEach(x => {
          let selectedAddOn = false;
          if (selectedService) {
            selectedAddOn = this.findSelectedService(x.opcodeDetails);
          }
          const addOnServiceItem = this.formBuilder.group({
            selected: [!!selectedAddOn],
            serviceInfo: [x.opcodeDetails],
            addOns: this.formBuilder.array([]),
            comments: null,
            hidden: [false],
            expanded: [false]
          });
          addOnArr.push(addOnServiceItem);
        });
      }
      const serviceItem = this.formBuilder.group({
        selected: [!!selectedService],
        serviceInfo: [serv],
        addOns: addOnArr,
        comments: this.formBuilder.control({ value: (comments ? comments :(selectedService && selectedService.comments) || ''), disabled: (!rs.commentsEnabled && !rs.isDmsApptOpcode)
        }),
        expanded: [false],
        trackingFlag : [true]
      });

      if (!!rs.opcodeType || rs.opcodeType === OpcodeType.OTHER) {
        this.subs.serviceSelectionSub = (serviceItem.get('selected') as FormControl).valueChanges
          .subscribe(this.setCommentsValidator(serviceItem, rs));
      }
      this.recommendedServicesArr.push(serviceItem);
      this.buildAddOnServiceArray(rs, isSelected);
    });
    if (this.selectedServicesArr.length > 0) {
      this.serviceAppointmentState.dispatch(new UpdateServiceForm({
        path: 'serviceForm', value: { selectedServices: this.selectedServicesArr }
      }));
    }

    this.subs.recommenedServiceSub = this.recommendedServicesArr.valueChanges.subscribe(t => {
      this.recommendedServicesSelectedCount = this.recommendedServicesArr.value?.filter((item) => item.selected).length;
    });
  }

  commentsTracking(service : FormGroup){
    const serv : IServiceOpcode = service.value.serviceInfo;
    const commentTrackingData = {
      content_section : 'services tab',
      link_module : 'choose your services',
      link_text :  'enter comments here',
      link_input_feild_value : 'user entered comment',
    };
    this.analyticsService.serviceSchedulerToolData = {
      ...this.analyticsService.serviceSchedulerToolData,
      code : serv.opcode,
      title : serv.displayName,
      msrp : serv?.pricing?.discount?.price
    };
    this.analyticsService.trackLink(commentTrackingData,true);
  }

  private buildAddOnServiceArray(service: IServiceOpcode, selectedService: boolean) {
    if (service.addOnOpcodes && service.addOnOpcodes.checked && service.addOnOpcodes.opcodes && service.addOnOpcodes.opcodes.length > 0) {
      service.addOnOpcodes.opcodes.forEach(serv => {
        const index = this.addOnServicesArr.controls.findIndex(x => x.get('serviceInfo').value.opcode === serv.opcodeDetails.opcode);
        if (index === -1) {
          let selectedAddOn = false;
          if (selectedService) {
            selectedAddOn = this.findSelectedService(serv.opcodeDetails);
          }
          const serviceItem = this.formBuilder.group({
            selected: [!!selectedAddOn],
            serviceInfo: [serv.opcodeDetails],
            comments: null,
            hidden: [false],
            expanded: [false]
          });
          this.addOnServicesArr.push(serviceItem);
        }
      })
    }
  }

  private buildAllServicesFormArray() {
    this.allServicesArr.clear();
    this.services.forEach((s,index) => {
      const selectedService = this.findSelectedService(s);
      const addOnArr: FormArray = new FormArray([]);
      if (s.addOnOpcodes && s.addOnOpcodes.opcodes) {
        s.addOnOpcodes.opcodes.forEach(x => {
          let selectedAddOn = false;
          if (selectedService) {
            selectedAddOn = this.findSelectedService(x.opcodeDetails);
          }
          const addOnServiceItem = this.formBuilder.group({
            selected: [!!selectedAddOn],
            serviceInfo: [x.opcodeDetails],
            addOns: this.formBuilder.array([]),
            comments: null,
            hidden: [false],
            expanded: [false]
          });
          addOnArr.push(addOnServiceItem);
        });
      }
      else if(s.subComplaints && s.subComplaints.complaintCodes && s.subComplaints.complaintCodes.length){
        const onlineSubComplaints=this.getSubComplaints(s);
        onlineSubComplaints.forEach(x=> {
          let selectedAddOn = false;
          if(selectedService){
          selectedAddOn = this.findSelectedsubComplaints(x);
          }    
          const addOnServiceItem = this.formBuilder.group({
            selected: [selectedAddOn],
            serviceInfo: [x],
            addOns: this.formBuilder.array([]),
            comments: null,
            hidden: [false],
            expanded: [false]
          });
          addOnArr.push(addOnServiceItem);
        });
      }
      const serviceItem = this.formBuilder.group({
        selected: [!!selectedService],
        serviceInfo: [s],
        addOns: addOnArr,
         comments: this.formBuilder.control({ value: selectedService && selectedService.comments, disabled: (!s.isMiscellaneous && !s.commentsEnabled && !s.isDmsApptOpcode) }),
        hidden: [false],
        expanded: [false],
        trackingFlag : [true]
      });
      this.subs['allServiceSelectedSub' + index] = (serviceItem.get('selected') as FormControl).valueChanges
        .subscribe(this.setCommentsValidator(serviceItem, s));
      this.allServicesArr.push(serviceItem);
      this.buildAddOnServiceArray(s, !!selectedService);
    });

    this.subs.allServicesChangeSub = this.allServicesArr.valueChanges.subscribe(t => {
      this.regularServicesSelectedCount = this.allServicesArr.value?.filter((item) => item.selected).length;
    });
  }

  private findSelectedService(s) {
    return this.selectedServices.find(md =>(md?.internalCode ) ? ( (md?.internalCode !=="") ? (md.internalCode === s.opcode) : (md.opcode === s.opcode)) : (md.opcode === s.opcode) );  }

  private setCommentsValidator(serviceItem, rs) {
    return checked => {
      const commentsItem = serviceItem.get('comments') as FormControl;
    if (checked && rs.commentsEnabled && rs.commentsRequired) {
        commentsItem.setValidators([Validators.required]);
      } else {
        commentsItem.setValidators([]);
      }
      commentsItem.updateValueAndValidity();
    };
  }

  onVehicleChange(vehicleDetails: IVehicleForm, appointment: any) {
    /* To choose the round off mileage which is chosen during the create mode */
    const mileage = +(appointment?.vehicle?.mileage === vehicleDetails.mileage ? (appointment?.roundOffMileage || vehicleDetails.mileage) : appointment?.vehicle?.mileage) || 0;
    const opcodeRequest: IOpcodeRequest = {
      vin: vehicleDetails.vin && vehicleDetails.vin.length === 17 ? vehicleDetails.vin : undefined,
      mileage,
      model: vehicleDetails.model,
      year: vehicleDetails.year?.toString(),
      make: vehicleDetails.make ? vehicleDetails.make.toUpperCase() : undefined,

    };
    if(opcodeRequest.mileage > 0){
      this.serviceAppointmentState.dispatch(new LoadServiceRequests({ opcodeRequest , countryCode:util.countryCd, languageCode:util.languageCd}));
    }
  }

  fetchVehicleHealthReport(userCd: string, vehicleId: string, vin: string) {
    this.isTelematicsLoading = false;
    if (!vin) {
        this.telematicsLoadedFor = vin;
        this.resetTelematicsVars();
        return;
    }

    vehicleId = vin;
    if (this.telematicsLoadedFor === vin) return;

    this.telematicsLoadedFor = vin;
    this.isTelematicsLoading = true;
    const sub: SubscriptionList = {};

    sub.getVehicleHealthReport = this.vehicleService.getVehicleHealthReport(userCd, vehicleId, vin).subscribe(telematics => {
        this.telematicsAlert = undefined;
        this.recallAlert = undefined;
        this.telematicsInfo = telematics.vhr;

        if (this.telematicsInfo?.vehicleAlerts?.vehicleAlertList?.length > 0) {
            this.telematicsAlert = this.telematicsInfo.vehicleAlerts.vehicleAlertList
                .filter(warning => warning.WNGDesc)
                .map(warning => warning.WNGDesc)
                .join('; ');
        }

        if (this.telematicsInfo?.safetyRecalls?.safetyRecallList?.length > 0) {
            const recallCodes = this.telematicsInfo.safetyRecalls.safetyRecallList.map(recall => recall.DealerReferenceID);
            this.recallAlert = this.telematicsInfo.safetyRecalls.safetyRecallList
                .map(recall => recall.Title)
                .join('; ');

            this.serviceAppointmentState.dispatch(new UpdateRecallCodes({ recallCodes }));
            this.buildRecommendedServicesFormArray();
            this.isTelematicsLoading = false;
            this.unsubscribeSubscriptionsWrapper(sub);
        } else {
            this.getSafetyCampaigns(vin);
        }
    }, error => {
        this.resetTelematicsVars();
        this.getSafetyCampaigns(vin);
    });
  }

  unsubscribeSubscriptionsWrapper(subscriptions: SubscriptionList) {
    util.unsubscribeSubscriptions(subscriptions);
  };

  getSafetyCampaigns(vin: string) {
    const sub: SubscriptionList = {};
    sub.getSpecialServiceCampaign = this.vehicleService.getSpecialServiceCampaign(vin).subscribe(ssc => {
      if (ssc.status.code === '200' && ssc?.vehicleCampaignList?.length > 0) {
        const recallCodes = ssc.vehicleCampaignList.map(campaign => campaign.campaignCode);
        this.recallAlert = ssc.vehicleCampaignList.map(campaign => campaign.campaignTitle).join('; ');
        this.serviceAppointmentState.dispatch(new UpdateRecallCodes({ recallCodes }));
      }
      this.buildRecommendedServicesFormArray();
      this.isTelematicsLoading = false;
      this.unsubscribeSubscriptionsWrapper(sub);
    });
  }

  resetTelematicsVars() {
    this.telematicsAlert = undefined;
    this.recallAlert = undefined;
    this.telematicsInfo = undefined;
    
  }

  getPricing(serviceOpcode: IServiceOpcode) {
    return util.getOpcodePricing(serviceOpcode);
  }

  getPricingText(serviceOpcode: IServiceOpcode) {
    return this.opCodePricingTranslation(util.getOpcodePricingText(serviceOpcode));
  }

  onMileageChange(mileage: number) {
    const opcodeRequest: IOpcodeRequest = {
      vin: this.lastVehicleForm.vin && this.lastVehicleForm.vin.length === 17 ? this.lastVehicleForm.vin : undefined,
      mileage: mileage,
      model: this.lastVehicleForm.model,
      year: this.lastVehicleForm.year?.toString(),
      make: this.lastVehicleForm.make ? this.lastVehicleForm.make.toUpperCase() : undefined,

    };
    this.formService.setSelectedMileage = mileage;
    this.serviceAppointmentState.dispatch(new LoadServiceRequests({ opcodeRequest, countryCode:util.countryCd, languageCode:util.languageCd }));
  }

  private setupStore() {

    this.serviceAppointmentState.select(fromSelectors.selectIsEditMode).subscribe(t => {
      this.isEditMode = t;
    });
    this.subs.userVehicleSub = this.dashboardState.select(fromDashboardSelectors.selectUserVehicles).subscribe(t => {
      /* *this 't' gives back logged in users userVehicles */
      this.serviceAppointmentState.select(fromSelectors.selectVehicleFormVin).pipe(take(1)).subscribe(x => {
        const vehicle = t.find(v => v.vin === x);
        if (vehicle) {
          this.authService.user.subscribe((user) => {
            if (user && !this.authService.isExpired()) {
              this.fetchVehicleHealthReport(user?.extension_tmsguid, vehicle.vehicleId, x);
            }
          });
        } else {
          this.isTelematicsLoading = false;
        }
      });
    });

    this.subs.serviceFormSub = this.serviceAppointmentState.select(fromSelectors.selectServiceFormSelectedServices)
      .subscribe((selectedServices) => {
        this.selectedServices = selectedServices;
      });

    this.subs.vehicleSelectionSub = this.serviceAppointmentState.select(fromSelectors.selectVehicleFormAndOriginalAppointment)
      .pipe(debounceTime(1000))
      .subscribe(appt => {
        const t = appt.vehicleForm;
        // Breaking the unwanted subscribe call when other parts of the form changes
        if (this.lastVehicleForm && _.isEqual(this.lastVehicleForm, t)) {
          return;
        }
        this.lastVehicleForm = t;

        this.authService.user.subscribe((user) => {
          if (user && !this.authService.isExpired()) {
            this.fetchVehicleHealthReport(user?.profile?.extension_tmsguid, t.vehicleId ? t.vehicleId : undefined, t.vin);
          }
        });
        this.onVehicleChange(t, appt.originalAppt);
      });

    this.subs.recommendedServiceRequestsSub = this.serviceAppointmentState.select(fromSelectors.selectRecommendedServiceOpcodes)
      .subscribe((serviceRequestData) => {
        this.recommendedServices = serviceRequestData;
        this.buildRecommendedServicesFormArray();
      });

    this.subs.otherServiceRequestsSub = this.serviceAppointmentState.select(fromSelectors.selectOtherServiceOpcodes)
      .subscribe((serviceRequestData) => {
        this.services = serviceRequestData;
        this.updateAllServiceArray(this.services);
        this.buildAllServicesFormArray();
      });

  }

  updateAllServiceArray(services) {
    /** this part of code is used to add dms appt opcode to normal services array */
    this.selectedServices = this.selectedServices.map(data => {
      const opcode = (data.internalCode) ? data.internalCode : data.opcode;
      const res = services.find(serviceData => serviceData.opcode === opcode);
      if (!res) {
        const newData = {
          ...data,
          details: {
            ...data.details,
            isDmsApptOpcode: true
          }
        };
        this.services = [newData.details, ...this.services];
        return newData;
      }
      return data;
    });
    
    if (this.selectedServices.length > 0) {
      this.serviceAppointmentState.dispatch(new UpdateServiceForm({ path: 'serviceForm', value: { selectedServices: this.selectedServices } }));
    }
  }

  setMiscSelected(service: FormGroup) {
    const isSelected = service.get('selected').value;
    const shouldSelect = !!service.value.comments?.trim().length;
    if (shouldSelect && !isSelected) {
      service.get('selected').setValue(true);
    }
    else if (!shouldSelect && isSelected) {
      service.get('selected').setValue(false);
    }
  }

  opCodePricingTranslation(text){
    if(text){
      text = text.replace('Starts at', this.translate.instant('selectService.startsAt'));
      text = text.replace('Ranges from', this.translate.instant('selectService.rangesFrom'));
    }
    return text;
  }

  getSubComplaints(com)
  {
    return com?.subComplaints?.complaintCodes.filter(x=>x.online);
  }

  /*if selected service has subcomplaints ,compare with their opcodes*/
  findSelectedsubComplaints(subComplaint) {
    let check = false;
    this.selectedServices.forEach(sub => {
      if(sub?.details?.isInternalCode && sub?.subComplaints)
      {
        sub.subComplaints.forEach(x => {
          if (x.opcode === subComplaint.opcode) {
            check = true;
          }
        });
      }
    });
    return check;
  }
}
