import {TestDriveComponent} from './test-drive.component';
import {SharedModule} from './../shared/shared.module';
import {TestDriveRoutingModule} from './test-drive-routing-module';
import {NgModule} from '@angular/core';
import {ConfirmInfoComponent} from './confirm-info/confirm-info.component';
import {VehicleConfiguratorComponent} from './vehicle-configurator/vehicle-configurator.component';
import {VehicleDetailsComponent} from './vehicle-details/vehicle-details.component';
import { SelectedVehicleInfoComponent } from './selected-vehicle-info/selected-vehicle-info.component';

@NgModule({
  declarations: [TestDriveComponent,
    ConfirmInfoComponent,
    VehicleConfiguratorComponent,
    VehicleDetailsComponent, SelectedVehicleInfoComponent],
  imports: [
    TestDriveRoutingModule,
    SharedModule
  ],
  entryComponents: [TestDriveComponent],
  providers: [],
})
export class TestDriveModule { }
