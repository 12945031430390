import {ServiceAppointmentRoutingModule} from './service-appointment-routing.module';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServiceAppointmentComponent} from './service-appointment.component';
import {SelectVehicleComponent} from './select-vehicle/select-vehicle.component';
import {SelectServiceComponent} from './select-service/select-service.component';
import {AppointmentTimeComponent} from './appointment-time/appointment-time.component';
import {YourInformationComponent} from './your-information/your-information.component';
import {SharedModule} from '../shared/shared.module';
import {VehicleCardComponent} from './select-vehicle/vehicle-card/vehicle-card.component';
import {AppointmentFormService} from './appointment-form.service';
import {ConfirmAppointmentComponent} from './confirm-appointment/confirm-appointment.component';
import {ConnectStoreDirective} from './connect-store-directive/connect-store.directive';
import { MileageCarouselComponent } from './select-service/mileage-carousel/mileage-carousel.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    ServiceAppointmentComponent,
    SelectVehicleComponent,
    VehicleCardComponent,
    SelectServiceComponent,
    AppointmentTimeComponent,
    YourInformationComponent,
    ConfirmAppointmentComponent,
    ConnectStoreDirective,
    MileageCarouselComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ServiceAppointmentRoutingModule,
    TranslateModule
  ],
  entryComponents: [],
  exports: [TranslateModule],
  providers: [AppointmentFormService],
})
export class ServiceAppointmentModule { }
