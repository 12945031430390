import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-splitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.scss'],
})
export class SplitterComponent implements OnInit {
  @Input() config: string;

  @Input()direction: string = 'horizontal';
  @Input()size: string = '10px';
  @Input()vOffset: string = '10px';
  @Input()hOffset: string = '10px';
  @Input()hideAt: string = '550px';

  constructor() {}

  ngOnInit(): void {
    this.configTheSplitter();
  }

  configTheSplitter() {
    if (this.config) {
      const configSplit = this.config.split(' ');
      this.direction = configSplit[0];
      this.size = configSplit[1];
      this.hOffset = configSplit[2];
      this.vOffset = configSplit[3];
      this.hideAt = configSplit[4];
    }
  }
}
