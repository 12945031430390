import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import * as util from '../../services/util.service';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationType, ITransportOption } from '@signal/asp-data-commons';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toggle-location',
  templateUrl: './toggle-location.component.html',
  styleUrls: ['./toggle-location.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleLocationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ToggleLocationComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ToggleLocationComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() formControlName: string;
  @Input() allTransportOptions: ITransportOption[] = [];
  @Input() department: string;
  transportSelected: string;
  additionalInfo:any;
  locationType: LocationType;
  isRemote: boolean;
  toggleView: boolean;
  private apptTransportByType: { [p: string]: any[] };
  transportsForSelectedType: any[] = [{ desc: 'test' }];
  selectedTransportByType: any;

  private control: AbstractControl;

  onChange: any = (value) => { };
  onTouched: any = () => { };

  constructor(@Optional() @Host() @SkipSelf()
  private readonly controlContainer: ControlContainer,
    private readonly analyticsService: AnalyticsService,
    private readonly translate: TranslateService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('allTransportOptions') && changes.allTransportOptions.currentValue) {
      this.allTransportOptions = this.allTransportOptions.filter(x => x.isActive);
      this.control = this.controlContainer.control.get(this.formControlName);
      this.toggleView = this.department === 'SERVICE' ? false : !!this.allTransportOptions.find(x => x.category === LocationType.REMOTE);

      this.isRemote = (this.control.value === 'REMOTE');
      if (this.department === 'SERVICE') {
        this.apptTransportByType = { "TransportOptions": this.allTransportOptions };
        if (this.apptTransportByType['TransportOptions']) {
          this.selectedTransportByType = {
            ['TransportOptions']: this.apptTransportByType['TransportOptions'][0]
          }
        }
        if (this.transportSelected) {
          const initialTransport = this.allTransportOptions
            .find(ato => ato.id === this.transportSelected);
          if (initialTransport) {
            this.isRemote = initialTransport.category === LocationType.REMOTE;
            this.locationType = this.isRemote ? LocationType.REMOTE : LocationType.DEALER;
            this.selectedTransportByType[this.locationType] = initialTransport;
          }
        }
        this.transportsForSelectedType = this.apptTransportByType['TransportOptions'];

        this.setTransportSelected(this.control.value || this.selectedTransportByType['TransportOptions'].id);
      }
      else {
        this.apptTransportByType = util.groupBy(this.allTransportOptions, 'category');
        if (this.apptTransportByType[LocationType.REMOTE]) {
          this.selectedTransportByType = {
            [LocationType.REMOTE]: this.apptTransportByType[LocationType.REMOTE][0],
            [LocationType.DEALER]: this.apptTransportByType[LocationType.DEALER][0]
          };
        } else {
          this.selectedTransportByType = {
            [LocationType.DEALER]: this.apptTransportByType[LocationType.DEALER][0]
          };
        }

        this.locationType = this.isRemote ? LocationType.REMOTE : LocationType.DEALER;
        this.transportsForSelectedType = this.apptTransportByType[this.locationType];
        this.setTransportSelected(this.control.value || this.selectedTransportByType[this.locationType].id);
      }
    }
  }



  ngOnInit(): void { }

  toggleLocationType() {
    this.isRemote = !this.isRemote;
    const locationToggleTrackingData = {
      content_section: 'appointment tab',
      link_module: 'transportation',
      link_text: "transportation category changed",
      link_input_field: this.isRemote ? "we come to you" : "you come to us"
    };
    this.analyticsService.dealerData = {
      ...this.analyticsService.dealerData,
      isRemote: this.isRemote
    }
    this.analyticsService.trackLink(locationToggleTrackingData, true);
    this.locationType = this.isRemote ? LocationType.REMOTE : LocationType.DEALER;
    this.transportsForSelectedType = this.apptTransportByType[this.locationType];
    this.setTransportSelected(this.selectedTransportByType[this.locationType].id);

  }

  getTransport(name) {
    if (name) {
      if(name === "Rideshare" && this.translate.currentLang.split("-")[1] === "MX"){
        return this.translate.instant(`transportation.complimentaryTransportation`)
      }
      return this.translate.instant(`transportation.${name}`)
    }
  }
  onSelectingPickupLocation(transport: any) {
    if(this.department === 'SERVICE')
    {
      this.selectedTransportByType['TransportOptions']=transport;
     this.setTransportSelected(transport.id);
     if(transport.id === 'DELIVERY'){
       this.additionalInfo ="Please note, the customer has to visit the dealership to drop off his vehicle"
    }
    else if(transport.id === 'PICKUP')
    {
      this.additionalInfo ="Please note, the customer has to visit the dealership to pickup his vehicle after service"
    }
    else
    {
      this.additionalInfo ='';
    }
    }
    else
    {
    const locationToggleTrackingData = {
      content_section: 'appointment tab',
      link_module: 'transportation',
      link_text: "transportation type changed",
      link_input_field: transport?.label
    };
    this.analyticsService.trackLink(locationToggleTrackingData, true);
    this.selectedTransportByType[this.locationType] = transport;
    this.setTransportSelected(transport.id);
  }
  }

  setTransportSelected(value) {
    this.transportSelected = value;
    this.control.setValue(this.transportSelected);
    this.onChange(this.transportSelected);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.transportSelected = value;
      this.isRemote = (value === 'REMOTE'); // TODO - check type instead of value
      this.locationType = this.isRemote ? LocationType.REMOTE : LocationType.DEALER;
      if (this.apptTransportByType && this.department!=='SERVICE'){
        this.transportsForSelectedType = this.apptTransportByType[this.locationType];
      }
      else if (this.apptTransportByType && this.department==='SERVICE'){
        this.transportsForSelectedType = this.apptTransportByType['TransportOptions'];
      }
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): string {
    return this.control.value;
  }

  set value(value: string) {
    this.transportSelected = value;
    this.onChange(value);
    this.onTouched();
  }

  validate(_: FormControl) {
    return this.control?.valid ? null : { valid: false };
  }
}
