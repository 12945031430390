<div *ngIf="(dealerPdiChecklist$ | async)?.length > 0" class="checklist-container">
    <div class="row mb15">
        <div class="col-md-12">
            <span class="header">{{ 'delivery.checkList' | translate }}</span>
        </div>
    </div>
    <div class="row mb15">
        <div class="col-md-12">
            <div>
                {{ 'delivery.youNeedToBringTheFollowingDocumentsTxt' | translate }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="checklist-items">
                <ol>
                    <li *ngFor="let item of (dealerPdiChecklist$ | async)">{{item}}</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
           <div class='action-con'>
                <div class="action-items">
                    <app-icon name='ic-20-communication-print.svg' color="var(--primary-color)"></app-icon>
                    {{ 'delivery.printChecklist' | translate }}
                </div>
                <div class="action-items">
                    <app-icon name='ic-20-utility-share.svg'  color="var(--primary-color)"></app-icon>
                    {{ 'delivery.download' | translate }}
                </div>
            </div>
        </div>
    </div>
    <app-splitter class='app-splitter'  [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
</div>
