import { Pipe, PipeTransform } from '@angular/core';
import { assetUrl } from '../services/util.service';


@Pipe({ name: 'assetUrl' })
export class AssetUrlPipe implements PipeTransform {
    transform(value: string): string {
        return assetUrl(value);
    }
}
