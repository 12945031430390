import { Component, HostListener, OnInit, OnDestroy, Input } from '@angular/core';
import {AspBannerService} from './asp-banner.service';
import {Subscription, Observable} from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import {assetUrl, getCarImageAbsoluteURL ,setDefaultImg} from '../../services/util.service';
import { Store } from '@ngrx/store';
import { DealerState } from './../../../store/dealer/dealer.reducer';
import * as dealerActions from './../../../store/dealer/dealer.actions';
import { bannerTextsBasedOnDept } from './../../../store/dealer/dealer.selectors';
import { AppointmentType } from '@signal/asp-data-commons';

@Component({
  selector: 'app-asp-banner',
  templateUrl: './asp-banner.component.html',
  styleUrls: ['./asp-banner.component.scss']
})
export class AspBannerComponent implements OnInit, OnDestroy {
  @Input() inDealerSite = false;
  domain = 'toyota';
  banner: any = {};
  showBanner = true;
  showCarImage = true;
  showBannerSub: any;
  updateBannerSub: any;
  bannerImage: any = [];
  staticBannerImage = '';
  setDefaultImg=setDefaultImg;
  private showCarImageSub: Subscription;
  portal$: Observable<{bannerBodyText : string, bannerTitleText: string}>;

  constructor(private readonly aspBannerService: AspBannerService,
              private readonly dealerState: Store<DealerState>,
              private readonly router: Router) {
    this.setUpSubscriptions();
  }

  ngOnInit(): void {
    this.refreshBanner();
    this.refreshBannerText();

    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.refreshBannerText();
      }
    });
  }

  refreshBanner() {
    this.banner.urls = this.staticBannerImage;
  }

  refreshBannerText(){
    const currentRoute = (this.router.url.split('?')[0]).replace(/\//g, '').toLowerCase();
    switch (currentRoute) {
      case ('service'):
      {
        this.dealerState.dispatch(new dealerActions.LoadSettings({departments: 'service'}));
        const props = AppointmentType.SERVICE
        this.portal$ = this.dealerState.select(bannerTextsBasedOnDept,props);
        break;
      }
      case ('testdrive'):
      {
        this.dealerState.dispatch(new dealerActions.LoadSettings({departments: 'sales'}));
        const props = AppointmentType.TEST_DRIVE;
        this.portal$ = this.dealerState.select(bannerTextsBasedOnDept,props);
        break;
      }
      case ('delivery'):
      {
        this.dealerState.dispatch(new dealerActions.LoadSettings({departments: 'sales'}));
        const props = AppointmentType.DELIVERY;
        this.portal$ = this.dealerState.select(bannerTextsBasedOnDept,props);
        break;
      }
      case ('deliveryconfirm'):
      {
        this.dealerState.dispatch(new dealerActions.LoadSettings({departments: 'sales'}));
        const props = AppointmentType.DELIVERY;
        this.portal$ = this.dealerState.select(bannerTextsBasedOnDept,props);
        break;
      }
      case ('learning'):
      {
        this.dealerState.dispatch(new dealerActions.LoadSettings({departments: 'sales'}));
        const props = AppointmentType.LEARNING;
        this.portal$ = this.dealerState.select(bannerTextsBasedOnDept,props);
        break;
      }
      case ('welcome'):
      {
        break;
      }
      default:
        this.dealerState.dispatch(new dealerActions.LoadSettings({departments: ''}));
    }
  }

  isMobile() {
    return window.innerWidth <= 600;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.refreshBanner();
  }

  ngOnDestroy() {
    this.updateBannerSub.unsubscribe();
    this.showBannerSub.unsubscribe();
    this.showCarImageSub.unsubscribe();
  }

  private setUpSubscriptions() {

    this.showBannerSub = this.aspBannerService.showBanner.subscribe(res => {
      this.showBanner = res;
    });

    this.showCarImageSub = this.aspBannerService.showCarImage$.subscribe(res => {
      this.showCarImage = res;
    });

    this.updateBannerSub = this.aspBannerService.updateBanner.subscribe(res => {
      if (res) {
        const imageUrl = getCarImageAbsoluteURL(res);
        this.bannerImage = imageUrl.includes('Sedan_Generic_base_default_34_f_d_490x280.png') ? assetUrl('images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png') : imageUrl;
        this.staticBannerImage = imageUrl.includes('Sedan_Generic_base_default_34_f_d_490x280.png') ? assetUrl('images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png') : imageUrl;
        this.refreshBanner();
      } else {
        this.bannerImage = res;
        this.staticBannerImage = res;
        this.refreshBanner();
      }
    });
  }

}
