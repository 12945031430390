<div
  class="btn-action"
  [class]="type + '-btn'"
  (click)="onButtonClick()"
  [ngClass]="{'disabled': disabled}"
  matRipple
>
  <span class="action">{{ name }}</span>
  <ng-container *ngIf="isInfoButton()">
    <span class="info">{{ info }}</span>
  </ng-container>
</div>
