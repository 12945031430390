// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { environment as devEnvironment } from './environment.dev';
import { environment as testEnvironment } from './environment.test';
import { environment as qaEnvironment } from './environment.qa';
import { environment as stageEnvironment } from './environment.stage';
import { environment as prodEnvironment } from './environment.prod';

const localEnvironment =
{
  production: true,
  brand: "toyota",
  clientId: "8c2022a0-279c-4837-9206-d9d013087175",
  spaHost: "https://localhost:4200/",
  redirectUri: "https://localhost:4200/",
  postLogoutRedirectUri: "https://localhost:4200/",
  b2cSignInPolicy: "B2C_1_SignupOrSigninToyota",
  authority: "https://qa.driverslogin.com",
  tenant: "tmnab2cqa.onmicrosoft.com",
  registerUrl: "https://www.toyota.com/owners/register",
  lexusRegisterUrl: "https://drivers.lexus.com/lexusdrivers/account/register",
  toyotaRegisterUrl: "https://www.toyota.com/owners/register",
  efcApi: "https://asp-catalog-api.dev.dealer.toyota.com/proxy-external/efc/vehiclecontent/v1/",
  dcsb2oApi: "https://asp-catalog-api.dev.dealer.toyota.com/proxy-external/dcs-b2o/",
  dcsb2cApi: "https://asp-appointment-api.dev.dealer.toyota.com/proxy-external/dcs-b2c/",
  aspAppointmentApi: "https://asp-b2c-api.dev.dealer.toyota.com/public/",
  aspAvailabilityApi: "https://asp-b2c-api.dev.dealer.toyota.com/public/",
  aspCatalogApi: "https://asp-b2c-api.dev.dealer.toyota.com/public/",
  aspB2cGuestApi: "https://asp-b2c-guest-api.dev.dealer.toyota.com/",
  xBrand: "T",
  xClient: "TO-WEB",
  xVersion: "1.0",
  frDgId: "7ac6cfd9-dc3d-4a79-8eea-9bae4857a687",
  frClientId: "appointment-portal",
  frLoginUrl: "https://api.stage.dg.toyota.com/garage/v2/auth/login/start",
  frLogoutUrl: "https://api.stage.dg.toyota.com/garage/v2/auth/logout/start",
  loginEnvironment: "tmnab2cqa",
  frEnvironment: "stage",
  analyticsTaggingUrl: "https://nexus.toyota.com/toyotanational/t-scheduler-stage/Bootstrap.js",
  useEOSForDCS: true,
  showDelivery: true
};

export const environmentConst = {
  production: false,
  LOCAL: localEnvironment,
  DEV: devEnvironment,
  TEST: testEnvironment,
  QA: qaEnvironment,
  STAGE: stageEnvironment,
  PROD: prodEnvironment
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
