import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserVehicleService } from '../../../shared/services/user-vehicle.service';
import { IServiceHistory } from '../../../shared/models/dashboard.model';
import { ServiceHistory } from '../../../shared/models/dashboard/service-history.model';
import { DashboardState } from '../../../store/dashboard/dashboard.reducer';
import { Store } from '@ngrx/store';
import { IVehicle } from '@signal/asp-data-commons';
import { Observable, Subscription } from 'rxjs';
import { selectUserVehicles } from '../../../store/dashboard/dashboard.selectors';
import { AspBannerService } from '../../../shared/components/asp-banner/asp-banner.service';
import { ViewportScroller } from '@angular/common';
import { navigateToRoutePromise } from '../../../shared/services/util.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-service-history',
  templateUrl: './service-history.component.html',
  styleUrls: ['./service-history.component.scss']
})
export class ServiceHistoryComponent implements OnInit, OnDestroy {
  vehicleId: string;
  serviceHistory: IServiceHistory[];
  userVehicleDetails$: Observable<IVehicle[]>;
  vehicleDetails: IVehicle;
  sub: Subscription;
  loading = false;
  mileageUnit: string;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly userVehicleService: UserVehicleService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly bannerService: AspBannerService,
    private readonly viewportScroller: ViewportScroller,
    private readonly router: Router,
    private readonly translate: TranslateService) { 
      this.mileageUnit = this.translate.instant(`recommendedService.${this.translate.instant('COUNTRYTRANSLATION.miles')}`);
    }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.bannerService.update('');
    this.route.queryParams.subscribe(params => {
      this.vehicleId = params.id;
      this.fetchServiceHistory();
    });

    this.userVehicleDetails$ = this.dashboardState.select(selectUserVehicles);
    this.sub = this.userVehicleDetails$.subscribe(data => {
      if (data) {
        this.vehicleDetails = data.find(x => `${x.vehicleId}` === `${this.vehicleId}`);
        this.bannerService.update(this.vehicleDetails?.imageUrl);
      }
    });
  }

  fetchServiceHistory() {
    this.loading = true;
    this.userVehicleService.getVehicleServiceHistory(this.vehicleId).subscribe(data => {
      if (data.status.code === '200') {
        const records = data.serviceHistoryRecords;
        this.serviceHistory = ServiceHistory.getSerivceHistoryArray(records);
        this.loading = false;
      }
    },
    error => {
      this.loading = false;
    });
  }

  onVehicleSelect(value) {
    navigateToRoutePromise('/servicehistory', this.router, {
      queryParams: {
        id: value
      }
    }).then(() => {
      this.ngOnInit();
    });

  }

  backToDashboard() {
    navigateToRoutePromise('../', this.router, { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.bannerService.update('');
  }
}
