<div class="container mt" id="service-container">
  <div class="row mb">
    <div class="col-md-12">
      <div class="breadcrumb-con">
        <div class="service-breadcrumb">
          <span class="breadcrumb-steps" *ngFor="let obj of breadcrumbSteps;"
                [class.activetab]="selectedIndex === (obj.id)">
                        <span *ngIf="checkNav(obj.id); else elseBlock" class="step"
                              (click)="switchTabFromBreadcrumbs(obj.id)">{{obj.name | translate}}</span>
                        <ng-template #elseBlock><span class="step inactivetab">{{obj.name | translate}}</span></ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <mat-tab-group animationDuration="1000ms" [selectedIndex]="selectedIndex">
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.chooseVehicle'| translate}}</span>
            <span class="tab-hint">{{'selectService.selectVehicle'| translate}}</span>
          </ng-template>
          <div class="service-content select-vehicle">
            <app-select-vehicle #form1 [stepIndex]="selectedIndex" (toShowFooter)="toShowFooterDetails($event)"></app-select-vehicle>
          </div>

        </mat-tab>
        <mat-tab [disabled]="!form1?.step?.valid">
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.service'| translate}}</span>
            <span class="tab-hint">{{'selectService.selectService'| translate}}</span>
          </ng-template>
          <div class="service-content select-service">
            <app-select-service #form2 [stepIndex]="selectedIndex"></app-select-service>
          </div>

        </mat-tab>
        <mat-tab [disabled]="!form2?.step?.valid">
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.appointmentTime'| translate}}</span>
            <span class="tab-hint">{{'selectService.selectTime'| translate}}</span>
          </ng-template>
          <div class="service-content">
            <app-appointment-time [dealerDetails]='getDealerDetails()' [vin]="vin" [appointmentId]="appointmentId"></app-appointment-time>
          </div>

        </mat-tab>
        <mat-tab [disabled]="true">
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.yourInformation'| translate}}</span>
            <span class="tab-hint">{{'selectService.verifyInfo'| translate}}</span>
          </ng-template>
          <div class="service-content">
            <app-your-information [isEditMode]="isEditMode"></app-your-information>
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div id="service-sticky-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="sticky-breadcrumb">
          <div class="disclaimer"
               *ngIf="selectedIndex === 3"
               (click)="disclaimerToggle = !disclaimerToggle"
               [ngClass]="{'expanded': disclaimerToggle}">
            <div class="disclaimer-text">
              {{getDisclaimerText | translate}}
            </div>
            <mat-icon class="toggle-icon">keyboard_arrow_up</mat-icon>
          </div>
          <div class="action-area">
            <div>
              <button (click)="gotoBack()"
                      [ngClass]="selectedIndex !== 0 && !isSubmitting ? 'btn-enable':''"
                      class="goback-btn"
                      [disabled]="selectedIndex === 0 || isSubmitting">{{'selectService.back'| translate}}
              </button>
            </div>
            <div class="selection-info desktop spacer">
              <div class="footer-summaries">
              <ng-container *ngFor="let fs of serviceAppointmentFooterSummaries$ | async">
                <ng-container *ngIf="toShowFooterAppointment">
                  <div class="footer-summary" *ngIf="fs.value">
                    <div class="key">
                      {{fs.key | translate}}
                      <span class="disclaimer" *ngIf="fs.disclaimer">*</span></div>
                    <div class="value">{{fs.value | translate}}</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="disclaimer-text" *ngIf="(serviceAppointmentFooterSummaries$ | async)?.length > 1 && 
              (serviceAppointmentFooterSummaries$ | async)[1]?.value">
              <span style="color:red">*</span>
              <span style="color: gray">{{'delivery.theActualPriceAndHoursMayVaryBasedOnTheVehicleTypeAndSize' | translate}}</span>
            </div>
          </div>
            <div class="spacer-mobile"></div>
            <div class="continue-btn">
              <button (click)="onConfirmButtonClick()"
                      class="continue" [class.submitting]="isSubmitting"
                      [ngClass]="isValidForm() ? 'btn-enable':'btn-disabled-color'"
                      [disabled]="isSubmitting">
                <span>{{(isSubmitting ? continueBtnText[selectedIndex + 1] : continueBtnText[selectedIndex]) | translate}}</span>
                <app-loading *ngIf="isSubmitting" loadingSymbol="dots" color="var(--dashboard-primary-color)"
                             size=".6"></app-loading>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


