import { AspSideNavComponent } from './components/asp-side-nav/asp-side-nav.component';
import { AssetUrlPipe } from './pipes/asset-url.pipe';
import { Time12HourFormatPipe } from './pipes/time-12hour-format.pipe';
import { PhonePipe } from './pipes/phone-number.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CalendarHeaderComponent } from './components/calendar/calendar-header/calendar-header.component';
import { AspCalendarComponent } from './components/calendar/calendar.component';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MaterialModule } from './modules/material-module';
import { InlineSVGModule } from 'ng-inline-svg';

import { SplitterComponent } from './components/splitter/splitter.component';
import { GsapAnimationService } from './services/gsap-animation.service';
import { LoadingComponent } from './components/loading/loading.component';
import { IconComponent } from './components/icon/icon.component';
import { AspBannerComponent } from './components/asp-banner/asp-banner.component';
import { BrandSwitcherComponent } from './components/brand-switcher/brand-switcher.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { ASPSwiperModule } from './modules/asp-swiper.module';
import { AspFooterComponent } from './components/asp-footer/asp-footer.component';
import { AspHeaderComponent } from './components/asp-header/asp-header.component';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CancelBottomSheetComponent } from './components/cancel-bottom-sheet/cancel-bottom-sheet.component';
import { AddToCalendarComponent } from './components/add-to-calendar/add-to-calendar.component';
import { EfcBaseHttpService } from './services/efc-base-http.service';
import { DcsBaseHttpService } from './services/dcs-base-http.service';
import { UserVehicleService } from './services/user-vehicle.service';
import { VehicleService } from './services/vehicle.service';
import { ToggleLocationComponent } from './components/toggle-location/toggle-location.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { TradeInInfoComponent } from './components/trade-in-info/trade-in-info.component';
import { CustomerInfoComponent } from './components/customer-info/customer-info.component';
import { AvailabilityFormComponent } from './components/availability/availability.component';
import { TimeSlotComponent } from './components/time-slot/time-slot.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ServiceHistoryComponent } from './components/service-history/service-history.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { FactoryServicesDialogComponent } from './components/factory-services-dialog/factory-services-dialog.component';
import { OnofswitchComponent } from './components/onofswitch/onofswitch.component';
import { AddressComponent } from './components/address/address.component';
import { MessageInfoComponent } from './components/message-info/message-info.component'
import { TranslateModule } from '@ngx-translate/core';
import { CustomTranslateFactoryService } from './services/custom-translate-factory.service';
import { SelectLearningVehicleComponent } from './select-learning-vehicle/select-learning-vehicle.component';
import { LearningVehicleCardComponent } from './select-learning-vehicle/learning-vehicle-card/learning-vehicle-card.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { SalesAppointmentTimeComponent } from './components/sales-appointment-time/sales-appointment-time.component';
import { ContactEmailInfoComponent } from './components/contact-email-info/contact-email-info.component';
import { ConfirmSummaryComponent } from './components/confirm-summary/confirm-summary.component';
import { ConfirmedSummaryInfoComponent } from './components/confirmed-summary-info/confirmed-summary-info.component'
@NgModule({
  declarations: [
    InfoButtonComponent,
    SplitterComponent,
    LoadingComponent,
    IconComponent,
    BrandSwitcherComponent,
    AspCalendarComponent,
    CalendarHeaderComponent,
    AspBannerComponent,
    AspHeaderComponent,
    AspSideNavComponent,
    AspFooterComponent,
    ConfirmationComponent,
    CancelBottomSheetComponent,
    AddToCalendarComponent,
    ToggleLocationComponent,
    NumberOnlyDirective,
    TradeInInfoComponent,
    CustomerInfoComponent,
    AssetUrlPipe,
    PhonePipe,
    Time12HourFormatPipe,
    AvailabilityFormComponent,
    TimeSlotComponent,
    ColorPickerComponent,
    ServiceHistoryComponent,
    AlertDialogComponent,
    FactoryServicesDialogComponent,
    OnofswitchComponent,
    AddressComponent,
    MessageInfoComponent,
    SelectLearningVehicleComponent,
    LearningVehicleCardComponent,
    ChecklistComponent,
    SalesAppointmentTimeComponent,
    ContactEmailInfoComponent,
    ConfirmSummaryComponent,
    ConfirmedSummaryInfoComponent
  ],
  exports: [
    InfoButtonComponent,
    SplitterComponent,
    LoadingComponent,
    IconComponent,
    BrandSwitcherComponent,
    AspCalendarComponent,
    MaterialModule,
    CommonModule,
    FormsModule,
    ASPSwiperModule,
    InlineSVGModule,
    AspBannerComponent,
    AspHeaderComponent,
    AspSideNavComponent,
    AspFooterComponent,
    ConfirmationComponent,
    ToggleLocationComponent,
    NumberOnlyDirective,
    TradeInInfoComponent,
    CustomerInfoComponent,
    AssetUrlPipe,
    PhonePipe,
    Time12HourFormatPipe,
    ReactiveFormsModule,
    CurrencyPipe,
    AvailabilityFormComponent,
    TimeSlotComponent,
    ColorPickerComponent,
    ServiceHistoryComponent,
    NgxMatIntlTelInputModule,
    OnofswitchComponent,
    AddressComponent,
    MessageInfoComponent,
    TranslateModule,
    SelectLearningVehicleComponent,
    LearningVehicleCardComponent,
    ChecklistComponent,
    SalesAppointmentTimeComponent,
    ContactEmailInfoComponent,
    ConfirmSummaryComponent,
    ConfirmedSummaryInfoComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ASPSwiperModule,
    NgAddToCalendarModule,
    InlineSVGModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxMatIntlTelInputModule,
    TranslateModule
  ],
  providers: [
    GsapAnimationService,
    EfcBaseHttpService,
    DcsBaseHttpService,
    UserVehicleService,
    VehicleService,
    CurrencyPipe,
    CustomTranslateFactoryService],
})
export class SharedModule {
}
