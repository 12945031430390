<div class='guest-container' [formGroup]='vehicle' >
  <div *ngIf="vehicleDetails.length>0" class='choose-options'>
    <div class='guest-dropdown editMode'>
      <div class='year'>
        <label>{{ 'testDrive.year' | translate}}</label>
        <mat-form-field appearance="outline" >
          <input matInput  formControlName="year" />
        </mat-form-field>
      </div>
      
      <div class='model'>
        <label>{{ 'testDrive.model' | translate}}</label>
        <!-- <div *ngIf="withoutFleetVehicle; then thenBlockModel else elseBlockModel"></div>
          <ng-template #thenBlockModel>
            <mat-form-field appearance="outline" [ngClass]="{'disabled' : !isEditMode}">
            <mat-select formControlName="model" required>
              {{vehicleDetails?.modelList}}
              <mat-option *ngFor="let model of vehicleDetails?.modelList" [value]="model.modelCode">{{model.modelTitle}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </ng-template>
          <ng-template #elseBlockModel> -->
            <mat-form-field appearance="outline" [ngClass]="{'disabled' : !isEditMode}">
              <input matInput formControlName="model" />        
            </mat-form-field>
          <!-- </ng-template>   -->
      </div>
      <div class='trim' *ngIf="vehicle.value.vin">
        <label>{{ 'testDrive.trim' | translate}}</label>
        <mat-form-field appearance="outline" 
        [ngClass]="{'disabled' : !isEditMode }" >

        <!-- <div *ngIf="!!vehicleDetails?.trimList[0].trimTitle; then thenBlockTrim else elseBlockTrim"></div> -->
        <!-- <ng-template #thenBlockTrim>
          <input matInput  [value]="vehicleDetails?.trimList[0].trimTitle || ''" />
        </ng-template> -->
        <!-- <ng-template #elseBlockTrim> -->
          <div *ngIf="!displayMarketingTitle; then thenBlockTrim else elseBlockTrim"></div>
          <ng-template #thenBlockTrim>
            <input matInput formControlName="trim"/>
          </ng-template>
          <ng-template #elseBlockTrim>
          <input matInput [value]="displayMarketingTitle"/>
          </ng-template>
        <!-- </ng-template>    -->
        </mat-form-field>
      </div>

      <!-- <div class='trim' *ngIf="!vehicle.value.vin">
        <mat-label>{{ 'testDrive.trim' | translate}}</mat-label>
        <mat-form-field appearance="outline" 
        [ngClass]="{'disabled' : !isEditMode }" >
        <mat-select  formControlName="trim" required>
          <mat-option *ngFor="let trim of vehicleDetails?.trimList" [value]="trim.trimCode">{{trim.trimTitle}}
          </mat-option>
        </mat-select> 
        </mat-form-field>
      </div> -->
   
      <!-- <div class="vin" *ngIf="vehicle.value.vin">
        <label>VIN</label>
        <mat-form-field appearance="outline" [ngClass]="{'disabled' : !isEditMode }">
        <input matInput  formControlName="vin" />
      </mat-form-field>
      </div> -->

      <!-- <div class='mileage-price' *ngIf="!vehicle.value.vin && vehicleDetails?.mileage && vehicleDetails?.price">
        <div class='mileage'>
          <app-splitter class='app-splitter'  [config]="'vertical 3rem -1rem 0.5rem 550px'"></app-splitter>
          {{vehicleDetails.mileage}}<span class='mileage-notation'>MPG</span>
        </div>
        <div class='price'>{{vehicleDetails.price | currency : ('GLOBAL.CURRENCY' | translate)}}<span class='price-notation'>MSRP</span></div>
      </div> -->
    </div>
   
  </div>
</div>