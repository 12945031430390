import { LayoutModule } from './layout/layout.module';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeModule } from './welcome/welcome.module';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAppStore from './store/app.store';
import { appEffects } from './store/app.effects';
import { stringContains } from './shared/services/util.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptorService } from '../modules/shared/services/auth.interceptor.service';
import { initApp } from './shared/services/commons.service';
import { APP_BASE_HREF } from '@angular/common';
import { CustomTranslateFactoryService } from '../modules/shared/services/custom-translate-factory.service';
import { ResolveGuard } from '../app/guards/resolve.guard';

// AoT requires an exported function for factories
export function translateLoaderFactory(http: HttpClient) {
  return new CustomTranslateFactoryService(http);
}

export const loginRequest: { scopes: string[] } = {
  scopes: ['openid', 'profile'],
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory:  translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    SharedModule,
    WelcomeModule,
    NgAddToCalendarModule,
    StorageServiceModule,
    StoreModule.forRoot(fromAppStore.appReducers),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument(fromAppStore.appDevToolsOptions)
  ],
  providers: [
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [Store, Store]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: APP_BASE_HREF, useValue:
        stringContains('smartpath\.[a-zA-Z]+\.com$', (window as any).location.origin) ? '/scheduling' : ''
    }, 
    ResolveGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
