import {Component, OnInit} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-cancel-bottom-sheet',
  templateUrl: './cancel-bottom-sheet.component.html',
  styleUrls: ['./cancel-bottom-sheet.component.scss']
})
export class CancelBottomSheetComponent implements OnInit {

  constructor(private readonly cancelBottomSheet: MatBottomSheetRef<CancelBottomSheetComponent>) { }

  ngOnInit(): void {
  }

  closeLink(event: MouseEvent, data: string): void {
    this.cancelBottomSheet.dismiss(data);
    event.preventDefault();
  }

}
