<app-loading
  class="loading"
  size=".5"
  *ngIf="loading; else history"
></app-loading>
<ng-template #history>
<div class="container" *ngIf="(userVehicleDetails$ | async).length">
    <section class="vehicle-details">
        <div class="model-year-vin">
            <div class="desc">
                <mat-icon style="cursor: pointer;" (click)="backToDashboard()">arrow_back_ios</mat-icon>
                {{vehicleDetails?.description}}
            </div>
            <div class="model-details">
                <div class="model">{{vehicleDetails?.make}} {{vehicleDetails?.model}} {{vehicleDetails?.year}}</div>
                <div class="vin">VIN {{vehicleDetails?.vin}}</div>
            </div>
        </div>

        <select class="choose-vehicle" (change)="onVehicleSelect($event.target.value)">
            <option disabled selected>{{'dashboardAppointments.selectADifferentVehicle' | translate | uppercase}}</option>
            <option
            *ngFor="let vehicle of (userVehicleDetails$ | async)"
            [value]="vehicle.vehicleId">
            {{vehicle.make}} {{vehicle.model}} {{vehicle.year}}
            </option>
        </select>
    </section>

    <section class="history-details">
        <div *ngIf="serviceHistory?.length > 0; else loadserviceHistory">
        <mat-expansion-panel *ngFor="let history of serviceHistory">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{history.serviceDate | date}}
                </mat-panel-title>
                <mat-panel-description>
                    {{history.mileage}} {{ mileageUnit | lowercase  }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="condition-details">
                <div class="service-details">
                    <span class="ro-number">
                        <span class="header">{{ 'dashboardAppointments.repairOrder' | translate}} #:</span>
                        <span>{{history.repairOrderNumber}}</span>
                    </span>
                    <span class="dealer">
                        <span class="header">{{ 'dashboardAppointments.servicingDealer' | translate}}:</span>
                        <span>{{history.dealerName}} ({{history.dealerNumber}})</span>
                    </span>
                    <span class='ro-open'>
                        <span class="header">R.O. {{ 'dashboardAppointments.openDate' | translate}}:</span>
                        <span>{{!history.repairOrderOpenDate.toString()?.includes('-')?'- : - : -': (history.repairOrderOpenDate|date) }}</span>                  
                    </span>
                    <span class='ro-close'>
                        <span class="header">R.O. {{ 'dashboardAppointments.closeDate' | translate}}:</span>
                        <span>{{history.repairOrderCloseDate | date}}</span>
                    </span>
                    <span class='advisor'>
                        <span class="header">{{ 'transportation.serviceAdvisor' | translate | titlecase}}:</span>
                        <span>{{history.serviceAdvisor}}</span>
                    </span>
                </div>

                <div class="conditions" *ngFor="let condition of history.repairOrderConditions">
                    <span class="condition">{{ 'dashboardAppointments.condition' | translate}} {{condition.conditionNumber}}</span>
                    <span class="operation">
                        <span
                            *ngFor="let operation of condition.operation">{{operation.serviceOperationDescription}}</span>
                    </span>
                </div>
            </div>
        </mat-expansion-panel>
        </div>
        <ng-template #loadserviceHistory>
        <div>
            <span class="not-found">{{ 'dashboardAppointments.serviceHistoryNotFound' | translate}}</span>
        </div>
        </ng-template>
    </section>
</div>
</ng-template>
