import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'asp-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
})
export class InfoButtonComponent implements OnInit {
  /* primary label for the button */
  @Input() name: string;
  /* secondary label for the button */
  @Input() info: string;

  /* type of the button [primary, secondary] default is 'normal' */
  @Input() type = 'primary';

  @Input() disabled = false;

  /* output event when the button is clicked */
  @Output() iClick: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  isInfoButton(): boolean {
    return !!this.info;
  }

  onButtonClick() {
    this.iClick.emit();
  }
}
