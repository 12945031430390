export const dealerAppointmentDetails = {
    openingTime: '7.00 am',
    closingTime: '7.00 pm',
    numberOfSuggestedTimes: 3,
    slotDuration : 30,
    languages : ['English', 'Spanish', 'French'],

    advisors : [{
        id: 0,
        name: 'Any Advisor'
      },
      {
        id: 1,
        name: 'Jane Smith'
      },
      {
        id: 2,
        name: 'Jacob King'
      }],

    availableSlots :  [{
      date: '2020-08-18',
      slots: ['10.30 am', '4.30 pm']
    },
    {
      date: '2020-08-21',
      slots: ['9.30 am', '11.00 am', '12.00 pm', '3.30 pm', '5.00 pm', '6.00 pm']
    },
    {
      date: '2020-08-24',
      slots: ['4.00 pm', '5.00 pm', '6.00 pm']
    }],

    startAt : '2020-08-20',
    minDate : '2020-07-20',
    maxDate : '2020-09-24'
  };
