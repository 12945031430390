<div id="asp-calendar">
  <mat-calendar #tdcalendar
                [headerComponent]="customHeader"
                [(selected)]="selectedDate"
                [dateFilter]="dateFilterInput"
                [(startAt)]="startAt"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [dateClass]="setDateClass()"
                (selectedChange)="dateChange($event)"
                (monthSelected)="monthChange($event)"
                [ngClass]="{'loading': isAvailabilityLoading}"
                class="col-md-12">
  </mat-calendar>
  <div class="calendar-skeleton"
       *ngIf="isAvailabilityLoading"></div>
</div>
