<div class='guest-container'>
  <form [formGroup]='vehicle'>
    <div class='choose-vehicle'>
      {{ 'testDrive.chooseYourVehicle' | translate}}
    </div>
    <div *ngIf="withoutFleetVehicle" class='choose-options'>
      <div class='guest-dropdown'>
        <mat-form-field appearance="outline" class='year'>
          <mat-label>{{ 'testDrive.year' | translate}}</mat-label>
          <mat-select formControlName="year" required>
            <mat-option *ngFor="let year of vehicleDetails?.yearList" [value]="year">{{year}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.year.invalid">{{ 'testDrive.pleaseSelectAYear' | translate}}</mat-error>
          <mat-hint *ngIf="!!f.year.value && !vehicleDetails?.modelList?.length">{{ 'testDrive.noModelsForTheYear' | translate}}</mat-hint>
        </mat-form-field>

          <mat-form-field appearance="outline" class='model'
          [ngClass]="{'disabled' : !isEditMode && !vehicleDetails?.modelList?.length}">
            <mat-label>{{ 'testDrive.model' | translate}}</mat-label>
            <mat-select formControlName="model" required>
              <mat-option *ngFor="let model of vehicleDetails?.modelList" [value]="model.modelCode">{{model.modelTitle}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.model.invalid">{{ 'testDrive.pleaseSelectAModel' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class='trim'
          [ngClass]="{'disabled' : !isEditMode && !vehicleDetails?.trimList?.length}">
            <mat-label>{{ 'testDrive.trim' | translate}}</mat-label>
            <mat-select formControlName="trim" required>
              <mat-option *ngFor="let trim of vehicleDetails?.trimList" [value]="trim.trimCode">{{trim.trimTitle}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.trim.invalid">{{ 'testDrive.pleaseSelectATrim' | translate}}</mat-error>
          </mat-form-field>

        <div *ngIf="isEditMode || f.trim.valid">
          <ng-container *ngIf="!vehicleDetails?.exteriorColors?.length">
            <div class="color-loading">
              <app-loading loadingSymbol="dots" color="var(--dashboard-primary-color)" size="1">
              </app-loading>
            </div>
          </ng-container>
          <asp-color-picker required [exteriorColors]="vehicleDetails?.exteriorColors"
            [formControlName]="'exteriorColor'"></asp-color-picker>
        </div>
      </div>
      <div class='mileage-price' *ngIf="vehicleDetails?.mileage && vehicleDetails?.price">
        <div class='mileage'>
          <app-splitter class='app-splitter' [config]="'vertical 50px -15px 5px 550px'"></app-splitter>
          {{vehicleDetails.mileage}}<span class='mileage-notation'>MPG</span>
        </div>
        <div class='price'>{{vehicleDetails.price | currency}}<span class='price-notation'>MSRP</span></div>
      </div>
    </div>

    <div *ngIf="!withoutFleetVehicle" class='choose-options'>
      <div *ngIf="vehicleDetails?.length>0" class='guest-dropdown'>
        <mat-form-field appearance="outline" class='year'>
          <mat-label>{{ 'testDrive.year' | translate}}</mat-label>
          <mat-select formControlName="year" required>
            <mat-option *ngFor="let tempYear of vehicleDetails" [value]="tempYear.year" (click)="vehiclesForThatYear(tempYear.year)">{{tempYear.year}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.year.invalid">{{'testDrive.pleaseSelectAYear' | translate}}</mat-error>
          <mat-hint *ngIf="!!f.year.value && !(vehicleDetails?.length>0)">{{'testDrive.noModelsForTheYear' | translate}}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class='model'
        [ngClass]="{'disabled' : !isEditMode && !modelList$?.series_List?.length}">
          <mat-label>{{ 'testDrive.model' | translate}}</mat-label>
          <mat-select formControlName="model" required>
            <mat-option *ngFor="let tempModel of modelList$?.series_List" [value]="tempModel.model" (click)="vehiclesForMoodel(tempModel.model)">{{tempModel.model}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.model.invalid">{{'testDrive.pleaseSelectAModel' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class='trim'
        [ngClass]="{'disabled' : !isEditMode && !vehicleList$?.length}">
          <mat-label>{{ 'testDrive.trim' | translate}}</mat-label>
          <mat-select formControlName="trim" required>
            <mat-option *ngFor="let trim of vehicleList$" [value]="trim.trimCode" (click)="vehiclesForTrim(trim.trimCode,trim.marketing_title)"><div [innerHtml]="trimTranslate(trim)"></div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.trim.invalid">{{'testDrive.pleaseSelectATrim' | translate}}</mat-error>
        </mat-form-field>

        <asp-color-picker [required]="countryCode.toUpperCase().includes('US') && value.trim !== 'anytrim'" [exteriorColors]="vehicleListColor$" [formControlName]="'exteriorColor'" (click)="vehicleColorSelected()"></asp-color-picker>
      </div>
    </div>
  </form>
</div>