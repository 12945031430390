<div id="header-banner" *ngIf="!inDealerSite && showBanner && (portal$ | async) && (portal$ | async).bannerTitleText">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="banner-text" [ngClass]="{'dashboard-banner': !(portal$ | async) }">
                    <div class="toyota-scheduling mb15">
                        {{!!(portal$ | async) ? ((portal$ | async).bannerTitleText | translate ) : ('dashboardAppointments.welcomeToTheAppointmentSchedulingPortal' | translate)}}
                    </div>
                    <div class="lorem-ipsum-dolor-si mb15">
                        {{!!(portal$ | async) ? ((portal$ | async).bannerBodyText | translate ) : ('dashboardAppointments.fromHereYoucanScheduleServiceForYourVehiclesText' | translate)}}
                    </div>
                </div>
                <ng-container>
                    <img *ngIf="showCarImage"  [src]="bannerImage" (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="" class="banner-image">
                </ng-container>
            </div>
        </div>
    </div>
</div>
