import {ServiceAppointmentComponent} from './service-appointment.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ConfirmAppointmentComponent} from './confirm-appointment/confirm-appointment.component';
import { ServiceHistoryComponent } from '../shared/components/service-history/service-history.component';

const routes: Routes = [
    {
        path: '',
        component: ServiceAppointmentComponent,
    },
    {
        path: 'confirm',
        component: ConfirmAppointmentComponent
    },
    {
        path: 'servicehistory',
        component: ServiceHistoryComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ServiceAppointmentRoutingModule { }
