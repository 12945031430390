/* List of effects used in the store */

import {DashboardEffects} from './dashboard/dashboard.effects';
import {ServiceAppointmentEffects} from './service-appointment/service-appointment.effects';
import {SalesAppointmentEffects} from './sales-appointment/sales-appointment.effects';
import {DealerEffects} from './dealer/dealer.effects';

export const appEffects: any[] = [
  DashboardEffects,
  ServiceAppointmentEffects,
  SalesAppointmentEffects,
  DealerEffects
];

/* Devtools instrumentation config */
export const appDevToolsOptions = {maxAge: 25, name: 'Appointment Scheduling Portal'};
