import {Directive, ElementRef, HostListener, OnInit, Renderer2,} from '@angular/core';

@Directive({
  selector: '[aspNumberOnly]',
})
export class NumberOnlyDirective implements OnInit {

  constructor(private readonly _elRef: ElementRef, private readonly _renderer: Renderer2) {
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Tab', 'Enter'];
    
    var inputChar = event.key;
    // for browser autofill
    if(event.key === undefined){
      // timeout needed for browser to fill the input field
      setTimeout(() => {
        // filters numbers from the autfill value
        this._elRef.nativeElement.value = this._elRef.nativeElement.value.replace(/[^0-9]/g,'');
      }, 100);
    }

    return /\d/.test(inputChar) || allowedKeys.includes(inputChar);
  }

  @HostListener('paste',['$event'])
  handlePasteEvent(event: ClipboardEvent) { 
    event.preventDefault();
    const clipboardText = event.clipboardData.getData('text');
    const processedText = clipboardText.replace(/[^0-9]/g,'');
    this._elRef.nativeElement.value = processedText;
    return true;
  }

  ngOnInit() {
  }
}
