import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmedSummaryInfoComponent } from '../shared/components/confirmed-summary-info/confirmed-summary-info.component';
import { DeliveryComponent } from '../delivery/delivery.component';

const routes: Routes = [
  {
    path: '',
    component: DeliveryComponent
  },
  {
    path: 'confirm',
    component: ConfirmedSummaryInfoComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryRoutingModule { }
