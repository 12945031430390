import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { assetUrl } from '../../services/util.service';


@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss']
})
export class MessageInfoComponent implements OnInit {

  title: string ;
  iconSource;
  width: string = '2rem';
  height: string = '2rem';

  constructor(
 
    @Inject(MAT_DIALOG_DATA) public data: any) {
        this.iconSource = assetUrl('icons/svg/' + 'ic-20-utility-close-default.svg');
        this.title= this.data.title;
  }

  ngOnInit(){

  }
 
}
