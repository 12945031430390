import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import { ServiceHistoryComponent } from '../shared/components/service-history/service-history.component';


const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
    },
    {
        path: 'servicehistory',
        component: ServiceHistoryComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
