import { Injectable } from "@angular/core";
import { assetUrl, titleCase, countryCd } from "../../services/util.service";
import pdfMake from 'pdfmake/build/pdfmake';
import {CustomVFS} from './print-font'
import { IDCSVehicle } from "@signal/asp-data-commons";
import { CatalogService } from "../../../shared/services/asp/catalog.service";
import { TranslateService } from "@ngx-translate/core";
pdfMake.vfs = CustomVFS.pdfMake;

@Injectable({
    providedIn: 'root'
  })
export class FactoryPrintService {
pdfContent;
base64ToyotaLogo;
base64CarImg;
baseImageUrl='https://www.toyota.com';
defaultImage;

constructor(private readonly catalogservice:CatalogService, private readonly translate: TranslateService){
}
    getImage(){
        this.getBase64ImageFromUrl(assetUrl('icons/png/toyota-logo.png')).then(base64 => {
            this.base64ToyotaLogo = base64;
        })
    }
    makePdfDataForVehicleDetails(vehicleDetails:IDCSVehicle,mileage){
        const imageDetails = this.base64CarImg ? { image :this.base64CarImg} :{ text: `-${this.translate.instant('recommendedService.noImage')}-`};
        this.pdfContent  =[
            {
                style: 'fulltable',
                table: {
                    widths: [50,  '*'],
                    headerRows: 1,
                    body: [
                        [
                            {
                                border: [false, false, false, true],
                                margin:[0,0,0,10],
                                image: this.base64ToyotaLogo, fit:[30,30]
                            },
                            {
                                border: [false, false, false, true],
                                margin:[0,0,0,10],
                                text:[ {text: this.translate.instant('recommendedService.toyota').toUpperCase(),style:'mainheading',fontSize:20,bold: true,color:'#CC0000'},{text:this.translate.instant('recommendedService.owners').toUpperCase(),style:'mainheading',fontSize:20,bold: true,color:'grey'}]
                            }
                        ]
                    ]
                },
                layout:{
                    defaultBorder: false,
        
                    hLineColor:(i,node)=>  { return '#e4e4e4'},
                    vLineColor: (i,node)=> {  return '#e4e4e4'},
            }
            },
        
            {
                text: this.translate.instant('recommendedService.helpEnsureTheHighestLevelsOfSafetyTxt'),
                style: 'heading',
                fontSize:28,
                bold:true,
                margin:[20,20,15,0]
            },
            {
                text: this.translate.instant('recommendedService.hereWhatWeRecommendForYourNextServiceVisit'),
                style: 'sub-heading',
                fontSize:18,
                margin:[20,20,15,10]
            },
            {
                style: 'makemodel',
                margin:[20,0,10,10],
                table: {
                    widths: [250, 200, '*', '*'],
                    body: [
                        [
                            {
                                    colSpan: 2,
                                border: [true, true, true, false],
                                margin:[20,20,0,0],
                                text:[
                                    {
                                        text: vehicleDetails.year+' ',
                                    fontSize:18
                                    },
                                    {
                                        text: vehicleDetails.model,
                                    fontSize:18,
                                    bold:true
                                    },
                                    
                                ]
                            },
                        
                        ''
                        ],
                        [
                            {
                                rowSpan: 3,
                                border: [true, false, false, true],
                                
                                    
                                ...imageDetails,
                                    width: 150,
                                    height: 100,
                                margin:20
                            },
                                {
                                    table: {
                                        body: [
                                            [{	text: this.translate.instant('recommendedService.engineType'),
                                            style:'vehicledetails',
                                            border: [false, false, false, false]
                                            }],
                                            [{	text:[{text:vehicleDetails.engine+'\n\n',style:'vehicledetails',bold:true},
                                            {text:'__________________________',	 color: '#e4e4e4'}],
                                            border: [false, false, false, false]
                                            }]
                                        ]
                                    },
                                    border: [false, false, true, false],
                                    margin:[0,10,0,10]
                                }
                            
                        ],
                        [
                    '',
                            {
                                
                                colSpan: 1,
                                border: [false, false, true, false],
                                text: ''
                            },
                            
                            
                        ],
                        [
                            '',
                            {
                                border: [false, false, true, true],
                                text: '',
                                
                                    table: {
                                        body: [
                                            [
                                                {	
                                                    text: 'MILEAGE',
                                                border: [false, false, false, false],
                                                style:'vehicledetails'
                                                    
                                                },
                                                ],
                                            [
                                                {	text: this.numberWithCommas(mileage),
                                                border: [false, false, false, false],
                                                margin:[0,0,0,20],
                                                style:'vehicledetails',
                                                bold:true
                                                }]
                                    
                            
                                        ]
                                    }
                            },
                        
                        ]
                    ]
                },
                layout: {
                    defaultBorder: false,
                    hLineColor:(i,node)=>  { return '#e4e4e4'},
                    vLineColor: (i,node)=> {  return '#e4e4e4'},
                }
            },
            {
                text:this.numberWithCommas(mileage)+` ${this.translate.instant(`recommendedService.${this.translate.instant('COUNTRYTRANSLATION.miles')}`)}`,
                style: 'sub-heading',
                fontSize:18,
                margin:[20,20,15,10]
            }
        ]
    }
    makePdfDataForFactoryServices(sortedFactoryServices,servItemNotes){
         const sortedservicelength=sortedFactoryServices.length;
         for(const sortedFactoryService of sortedFactoryServices){
            const tableheading ={ text : sortedFactoryService.displayName, style: 'subheader'}
            const length = sortedFactoryService.factoryServices.length;
            let insideTableBody=[];
            const tablelayout={
                style: 'fulltable',
                table:{
                    widths: [ '*', '*'],
                    heights: 20,
                    body: [],
                },
            layout: {
                hLineColor:(i,node)=> { return '#e4e4e4'},
                vLineColor: (i,node)=> { return '#e4e4e4'},
             }
            }    
            for(let i=0;i<length;i++){
                const noteid =(sortedFactoryService.factoryServices[i].notesId ?
                    {text:'['+sortedFactoryService.factoryServices[i].notesId+']',style:'noteid'}:'');
                const textname =[sortedFactoryService.factoryServices[i].name," ",noteid ]
                const tableContent = {
                    text : textname,
                    lineHeight: 2,
                    fontSize: 10,
                    margin:[10,5,10,3]
                }
                
                if(i % 2 !== 0){
                    insideTableBody.push(tableContent);
                    tablelayout.table.body.push(insideTableBody);
                }
                else{
                    insideTableBody=[];
                    insideTableBody.push(tableContent);
                    if(i===length-1){
                        const tableContent = {
                            text : "",
                            alignment:'center',
                            border: [true, false, false, false]
                        }
                        insideTableBody.push(tableContent);
                        tablelayout.table.body.push(insideTableBody);
                    }
                }
            }
            if(sortedservicelength>=1 && sortedFactoryService?.displayName===sortedFactoryServices[1]?.displayName){
                const drivingImpactHeading=
                 
                        {
                            text:this.translate.instant('recommendedService.whereAndHowYouDrivingImpactsYourServicingNeeds'),
                            fontSize:12,
                            bold:true,
                            margin: [20, 10, 0, 10]
                        }
                 const  drivingImpactSubHeading=       {
                            text: this.translate.instant('recommendedService.ServiceOperatingConditionReqText'),
                            fontSize:10,
                            margin: [20, 10, 0, 10]
                        }
                    
                
                this.pdfContent.push(drivingImpactHeading);
                this.pdfContent.push(drivingImpactSubHeading);
            }
            this.pdfContent.push(tableheading,tablelayout)
           }
          
           for(const servItemNote of servItemNotes){
            const text=servItemNote.id+". "+servItemNote.name;
           const paraContent= {
                text: text,
                style: 'paracontent'
            }
            this.pdfContent.push(paraContent)
           }    
    }

    printPDF(isLexusBrand){
        const pageMargin = isLexusBrand ? 25 : null;
        const customFont = {
            normal: 'OpenSans-Regular.ttf',
            bold: 'OpenSans-Bold.ttf'
        }
        if(isLexusBrand){
            customFont.normal = 'nobel-regular.ttf';
            customFont.bold = 'nobel-bold.woff';
        }

       pdfMake.fonts = {
        ThemeFont: customFont
       }
        var docDefinition = {
            pageMargins: pageMargin,
            content:this.pdfContent,
            defaultStyle: {
                font: 'ThemeFont'
              },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [20, 0, 0, 10]
                },
                subheader: {
                    fontSize: 10,
                    bold: true,
                    margin: [20, 10, 0, 10]
                },
                fulltable: {
                        margin: [20, 0, 0, 10],
                },
                fulltablelexus: {
                    margin: [2, 0, 0, 2],
                },
                fulltablelexusvehicles:{
                    margin: [2, 0, 0, 2],
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                paracontent:{
                    margin: [20, 0, 0, 10],
                    fontSize:10
                },
                noteid:{
                    color:'#CC0000'
                },
                rightme:
                {   
                    alignment: 'right'
                },
                mainheading:{
                    fontSize:24
                },
                vehicledetails:{
                    fontSize:10
                },
                aligncenter:{
                    alignment:'center'
                },
                left:{
                    alignment:'left'
                },
                noBorder:{
                    border: [ false, false, false, false],
                    margin:[0,0,0,0],
                },
                serviceHeading:{
                    fontSize:12,
                    border:[false, false, true, false]
                }
            }
        };
  
        pdfMake.createPdf(docDefinition).download('Maintenance_Schedule.pdf');
    }
   
    async getBase64ImageFromUrl(imageUrl) {
        var res = await fetch(imageUrl);
        var blob = await res.blob();
        return new Promise((resolve, reject) => {
          var reader  = new FileReader();
          reader.addEventListener("load", function () {
              resolve(reader.result);
          }, false);
          reader.onerror = () => {
            return reject(this);
          };
          reader.readAsDataURL(blob);
        })
      }
 
    makePdfDataForLexusVehicleDetails(vehicleDetails:IDCSVehicle,mileage){
  
        const vehicledetails = vehicleDetails.make ? vehicleDetails.year+' '+titleCase(vehicleDetails.make)+' '+vehicleDetails.model : vehicleDetails.vin;
        const engineDetails = vehicleDetails.engine ? vehicleDetails.engine + " ,"+this.numberWithCommas(mileage) : '';
        const lexusVehicleHeader = [
            [{
                text: vehicledetails,
                style:'left',
                border: [false, false, false, false],
                fontSize: 20
            }],
            [
                {
                    text:engineDetails,
                    style:'left',
                   border:[false, false, false, true],
                   margin: [0,-5,0,10],
                fontSize:12
                }]
        ]
        const lexusVehicleDetails = {
            style: 'fulltablelexusvehicles',
            table: {
                widths: [ '*' ],
                body: [
                    [{
                        text: vehicledetails,
                        style:'left',
                        border: [false, false, false, false],
                        fontSize: 20
                    }],
                    [
                        {
                            text:engineDetails,
                            style:'left',
                           border:[false, false, false, true],
                           margin: [0,-5,0,10],
                        fontSize:12
                        }]
                ]
            },
            layout: '',
        }
        if(!countryCd.toUpperCase().includes('US')){
            const mileages = {
                text:this.numberWithCommas(mileage)+` ${this.translate.instant(`recommendedService.${this.translate.instant('COUNTRYTRANSLATION.miles')}`)}`,
                style: 'sub-heading',
                fontSize:18,
                margin:[20,20,15,10]
        }
            this.pdfContent=[lexusVehicleDetails,mileages];
        }
        else{
            const lexusTable  =[
                {
                    style: 'fulltablelexus',
                    table: {
                        widths: [ '*' ],
                        body: [
                        ...lexusVehicleHeader
                        ]
                    }
                },
                {
                    style: 'fulltablelexus',
                    table: {
                        widths:['*','*','*'],
                        body: [
                            []
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                        hLineColor:(i,node)=>  { return '#e4e4e4'},
                        vLineColor: (i,node)=> {  return '#e4e4e4'},
                    }
                } 
            ]
            this.pdfContent=[lexusTable,{...lexusVehicleDetails, pageBreak:'before'}];
        }
    }
    makePdfDataForLexusFactoryServices(sortedFactoryServices,index,mileage){
        let displayName = '';
        let displayMileage;
        switch(index){
            case 0:{
                displayName = this.translate.instant('recommendedService.previousService');
                displayMileage = mileage===1000 ? 0:mileage===5000?1000: parseInt(mileage)-5000;
                break;
            }
            case 1:{
                displayName = this.translate.instant('recommendedService.currentService');
                displayMileage = mileage;
                break;
            }
            case 2:{
                displayName = this.translate.instant('recommendedService.nextService');
                displayMileage = mileage === 150000 ? 0 : parseInt(mileage)+5000;
                break;
            }
        }
        const serviceHeading =   {
            border:[false, false, false, false],
            text: [{text:`${displayName}\n`,style: 'header',fontSize: 13} ,{text:`${this.numberWithCommas(displayMileage)} Miles`}], 
            style: 'serviceHeading'
        }
        const tableStyleName = index===0 ? 'noBorder' : 'table';
        const tablelayout={
            style: tableStyleName,
            table:{
                body: [],
            }
        }  
        tablelayout.table.body.push([serviceHeading]);
        tablelayout.table.body.push([{"text":"___________________________","color":"#969696","margin":[0,-12,0,-5], border:[false, false, false, false]}]);
        let i=0;
        for(let sortedFactoryService of sortedFactoryServices){
         if(i===1 && index===1){
            const tableserviceheadinglayout =  {
                text: this.translate.instant('recommendedService.specialDrivingConditions'), 
                style: '',
                fontSize:13,
                 border:[false, false, false, false],
                 margin: [0, 0, 0, -5],
            };
            tablelayout.table.body.push([tableserviceheadinglayout]);
         }
         const displayName =(i===1 )? (index===1)?this.translate.instant('recommendedService.when')+ sortedFactoryService.displayName.replace(this.translate.instant('recommendedService.alsoRecommended'),''):('') : (sortedFactoryService.displayName.includes(this.translate.instant('recommendedService.maintenance'))?(sortedFactoryService.displayName.replace(this.translate.instant('recommendedService.maintenance'),this.translate.instant('recommendedService.service'))):sortedFactoryService.displayName);
          if(displayName) { 
              const tableheading ={ text : displayName, style: '', border:[false, false, false, false],
            margin: [0, 0, 0, -5],
            fontSize:13,}
            tablelayout.table.body.push([tableheading]);
            }
            const unorderedlistLayout={
                border:[false, false, false, false],
                margin: [2, 0, 0, 2],
			    ul: [],
                fontSize:11
            }
            const length = sortedFactoryService.factoryServices.length;
            const unorderdedListFactoryServices = []
            for(let i=0;i<length;i++){
                unorderdedListFactoryServices.push(sortedFactoryService.factoryServices[i].name);
            }
            unorderedlistLayout.ul = unorderdedListFactoryServices;
         
            tablelayout.table.body.push([unorderedlistLayout]);
            i++;
        }
        tablelayout.table.body.push([{text:"....................................................................",color:"#969696", border:[false, false, false, false],margin:[0,-5,0,0]}]);
        this.pdfContent[1].table.body[0].push({   
            "border": [false,false,true,false],
            "stack":[tablelayout]
        });
 }
    setUrl(imageurl){
        this.base64CarImg = this.defaultImage;
        if(imageurl){
            this.catalogservice.getBase64ImageData(imageurl).subscribe(response=>{
                if(response.status.code === 200){
                    this.base64CarImg = response.data;
                }
            })
        }
    }
 
    setDefaultImage(){
        this.getBase64ImageFromUrl(assetUrl('images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')).then(base64 =>
            {
              this.defaultImage = base64;
              this.base64CarImg = this.defaultImage;
            })
    }
    setDataForLexusAllMileages(){
        const tablelayout={
            style: 'fulltable',
            table:{
                widths: [ '*', '*','*'],
                heights: 20,
                body: [],
            },
            layout: 'noBorders'
        }  
        let firstRowMileage = 0;
        let secondRowMileage = 45000;
        let thirdRowMileage = 100000;
     
        for(let i = 0; i< 10; i++){
            firstRowMileage = (i===0) ? 1000 : (i===1)? 0+5000 : firstRowMileage+5000;
            secondRowMileage = secondRowMileage+5000;
            thirdRowMileage = thirdRowMileage+5000;
            const underlineText = {"text":'........................................................................',"color":'#969696'};
            const thirdRowMileageDetails = thirdRowMileage===150000 ? []: [{"text":`${this.numberWithCommas(thirdRowMileage)} Miles \n`},underlineText];
            const mileageData = [
            {
                "margin":[0,7,0,-7],
                "text":[{"text":`${this.numberWithCommas(firstRowMileage)} Miles\n`},underlineText]
            },
            {
                "margin":[0,7,0,-7],
                "text":[{"text":`${this.numberWithCommas(secondRowMileage)} Miles \n`},underlineText]
            },
            {
                "margin":[0,7,0,-7],
                "text":[...thirdRowMileageDetails]
            }
            ]
            tablelayout.table.body.push(mileageData);
            const mileageDescription = this.translate.instant('recommendedService.mileFactoryRecommendedServiceWithSpace');
            const thirdrow = thirdRowMileage===150000 ? '':`${this.numberWithCommas(thirdRowMileage)}${mileageDescription}`
            tablelayout.table.body.push([
                `${this.numberWithCommas(firstRowMileage)}${mileageDescription}`,
                `${this.numberWithCommas(secondRowMileage)}${mileageDescription}`,
                `${thirdrow}`
            ])
        }

        this.pdfContent.push(tablelayout);
    }
    numberWithCommas(number) {
        return parseInt(number).toLocaleString();
    }
}
