import produce from 'immer';

import * as fromActions from './dashboard.actions';
import * as fromAdapter from './dashboard.adapter';
import {IAppointment, IVehicle} from '@signal/asp-data-commons';
import {upsert} from '../../shared/services/util.service';

export interface DashboardState {
  userVehicles: IVehicle[];
  userVehiclesLoading: boolean;
  userVehiclesLoaded: boolean;
  appointments: IAppointment[];
  appointmentsforvin: IAppointment[];
  appointmentsLoading: boolean;
  appointmentsLoaded: boolean;
  error: string;
}

const initialState: DashboardState = {
  userVehicles: [],
  userVehiclesLoading: false,
  userVehiclesLoaded: false,
  appointments: [],
  appointmentsforvin:[],
  appointmentsLoading: false,
  appointmentsLoaded: false,
  error: ''
};

function updateMileageOfVehicleId(payload) {
  return uv => uv.vehicleId === payload.vehicleId ? {
    ...uv,
    mileage: payload.mileage
  } : uv;
}

export function dashboardReducer(
  state: DashboardState = initialState,
  action: fromActions.DashboardActions): DashboardState {

  switch (action.type) {
    case fromActions.DashboardActionTypes.LOAD_USER_VEHICLES: {
      return produce(state, draftState => {
        draftState.userVehicles = [];
        draftState.userVehiclesLoaded = false;
        draftState.userVehiclesLoading = true;
        draftState.error = '';
      });
    }

    case fromActions.DashboardActionTypes.LOAD_USER_VEHICLES_SUCCESS: {
      return produce(state, draftState => {
        draftState.userVehicles = action instanceof fromActions.LoadUserVehiclesSuccess ? action.payload.vehicles : [];
        draftState.userVehiclesLoaded = true;
        draftState.userVehiclesLoading = false;
        draftState.error = '';
      });
    }

    case fromActions.DashboardActionTypes.LOAD_USER_VEHICLES_FAILURE: {
      return produce(state, draftState => {
        draftState.userVehicles = [];
        draftState.userVehiclesLoaded = false;
        draftState.userVehiclesLoading = false;
        draftState.error = action instanceof fromActions.LoadUserVehiclesFail ? action.payload.error : '';
      });
    }

    case fromActions.DashboardActionTypes.LOAD_APPOINTMENTS: {
      return produce(state, draftState => {
        draftState.appointments = [];
        draftState.appointmentsLoaded = false;
        draftState.appointmentsLoading = true;
        draftState.error = '';
      });
    }
    case fromActions.DashboardActionTypes.LOAD_APPOINTMENTS_FOR_VIN: {
      return produce(state, draftState => {
        draftState.appointmentsforvin = [];
        draftState.appointmentsLoaded = false;
        draftState.appointmentsLoading = true;
        draftState.error = '';
      });
    }

    case fromActions.DashboardActionTypes.LOAD_APPOINTMENTS_SUCCESS: {
      return produce(state, draftState => {
        draftState.appointments = action instanceof fromActions.LoadAppointmentsSuccess ? action.payload.appointments : [];
        draftState.appointmentsLoaded = true;
        draftState.appointmentsLoading = false;
        draftState.error = '';
      });
    }
    case fromActions.DashboardActionTypes.LOAD_APPOINTMENTS_SUCCESS_FOR_VIN: {
      return produce(state, draftState => {
        draftState.appointmentsforvin = action instanceof fromActions.LoadAppointmentsSuccessForVin ? action.payload.appointments : [];
        draftState.appointmentsLoaded = true;
        draftState.appointmentsLoading = false;
        draftState.error = '';
      });
    }
    case fromActions.DashboardActionTypes.LOAD_APPOINTMENTS_FAILURE: {
      return produce(state, draftState => {
        draftState.appointments = [];
        draftState.appointmentsLoaded = false;
        draftState.appointmentsLoading = false;
        draftState.error = action instanceof fromActions.LoadAppointmentsFail ? action.payload.error : '';
      });
    }

    case fromActions.DashboardActionTypes.UPSERT_APPOINTMENT: {
      return produce(state, draftState => {
        const appointment = action instanceof fromActions.UpsertAppointment ? action.payload.appointment : undefined;
        draftState.appointments = upsert([...state.appointments], appointment, appt => appt.appointmentId === appointment.appointmentId);
      });
    }

    case fromActions.DashboardActionTypes.CANCEL_APPOINTMENT_FROM_DASHBOARD:
    case fromActions.DashboardActionTypes.CANCEL_APPOINTMENT_FROM_CONFIRM: {
      return produce(state, draftState => {
        draftState.appointments = draftState.appointments
          .map(fromAdapter.updateAppointmentAsCancelling(action));
      });
    }

    case fromActions.DashboardActionTypes.CANCEL_APPOINTMENT_SUCCESS: {
      return produce(state, draftState => {
        const payloadAppointmentId = action instanceof fromActions.CancelAppointmentSuccess ? action.payload.appointmentId : '';
        draftState.appointments = draftState.appointments
          .filter(a => a.appointmentId !== payloadAppointmentId);
      });
    }

    case fromActions.DashboardActionTypes.CANCEL_APPOINTMENT_FAILURE: {
      return produce(state, draftState => {
        draftState.error = action instanceof fromActions.CancelAppointmentFail ? action.payload.error : '';
      });
    }

    default:
      return state;
  }
}

