import { assetUrl } from './../../services/util.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf
} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ITimeSlot, PartOfTheDay} from '@signal/asp-data-commons';
import { AnalyticsService } from './../../../shared/services/analytics.service';
@Component({
  selector: 'asp-time-slot',
  templateUrl: './time-slot.component.html',
  styleUrls: ['./time-slot.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSlotComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeSlotComponent),
      multi: true
    }
  ]
})
export class TimeSlotComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() formControlName: string;
  @Input() timeSlots: ITimeSlot[] = [];
  @Input() isAvailabilityLoading: boolean;
  @Input() isTimeSlotDisabled:boolean;
  @Output() timeSlotValidator: EventEmitter<boolean> = new EventEmitter();

  filteredTimeSlots: ITimeSlot[] = [];
  selectedPartOfDay: string;

  public selectedTimeSlot: string;

  private control: AbstractControl;

  emptyBgImg = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .9)), url('${assetUrl('icons/svg/ic-20-utility-time-clock.svg')}')`);

  constructor(@Optional() @Host() @SkipSelf()
  private readonly controlContainer: ControlContainer,
    private readonly sanitizer: DomSanitizer,
    private readonly analyticsService : AnalyticsService) { }

  ngOnInit(): void {
    this.changePartOfTheDay(PartOfTheDay.MORNING);
    this.control = this.controlContainer.control.get(this.formControlName);
  }

  onChange: any = () => {
    this.filteredTimeSlots = this.timeSlots?.filter(x => x.partOfTheDay === this.selectedPartOfDay);
  }

  onTouched: any = () => { };

  onPartOfDayChange(part: string) {
    this.changePartOfTheDay(part);
    const changeDayTimeTracking = {
      content_section: 'appointment tab',
      link_module : 'appointment availability',
      link_text : 'suggested time slot selected',
      link_input_field : this.selectedPartOfDay
    }
    this.analyticsService.trackLink(changeDayTimeTracking,true);
  }
  changePartOfTheDay(part){
    this.selectedPartOfDay = part;
    this.filteredTimeSlots = this.timeSlots?.filter(x => x.partOfTheDay === part);
  }

  onSlotChange(slot: ITimeSlot) {
    const slotTrackingData = {
      content_section : 'appointment tab',
      link_module: 'appointment availability',
      link_text : 'time slot selected',
      link_input_field : slot.time
    };
    this.analyticsService.trackLink(slotTrackingData,true);
    this.selectedTimeSlot = (slot.timeSlotId);
    this.control.setValue(this.selectedTimeSlot);
    this.onChange(this.selectedTimeSlot);
    this.onTouched();
    this.timeSlotValidator.emit(false);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.selectedTimeSlot = value;
      this.changePartOfTheDay(this.timeSlots?.find(x => x.timeSlotId === value)?.partOfTheDay);
    }

    if (value === null) {
      this.control.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): string {
    return this.control.value;
  }

  set value(value: string) {
    this.selectedTimeSlot = value;
    this.onChange(value);
    this.onTouched();
  }

  get PartOfTheDay(){
    return PartOfTheDay;
  }

  validate(_: FormControl) {
    return this.control.valid ? null : { valid: false } ;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty(('timeSlots')) && changes.timeSlots.currentValue) {
      this.selectedPartOfDay = this.timeSlots?.find(x => x.timeSlotId === this.selectedTimeSlot)?.partOfTheDay;
      this.filteredTimeSlots = this.timeSlots?.filter(x => x.partOfTheDay === this.selectedPartOfDay);
    }else{
      this.selectedPartOfDay = PartOfTheDay.MORNING;
      this.filteredTimeSlots = [];
    }
  }
}
