import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private readonly authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.user.pipe(
            switchMap(user => {
                if (user && !!this.authService.getToken()) {
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${this.authService.getToken()}`
                        }
                    });
                }
                return next.handle(req);
            })
        );
    }
}
