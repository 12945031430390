import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': ''
  })
};

@Injectable()
export class EfcBaseHttpService {

  constructor(private readonly http: HttpClient) {

  }


  get(path: string): Observable<any> {
    return this.http.get(`${path}`, httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${path}`, body, httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${path}`, body, httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }

  delete(path: string): Observable<any> {
    return this.http.delete(`${path}`, httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(this.handleError)
      );
  }


  private log(data) {
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
