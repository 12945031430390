import { IServiceHistory, IRepairOrderCondition, IOperation } from '../dashboard.model';
import { ServiceHistoryRecord, RepairOrderCondition, Operation, ServiceManager } from './service-history';

export class ServiceHistory implements IServiceHistory {
  vin: string;
  serviceDate: number;
  mileage: number;
  repairOrderNumber: string;
  repairOrderOpenDate: number;
  repairOrderCloseDate: number;
  totalCost: string;
  serviceAdvisor: string;
  dealerNumber: string;
  dealerName: string;
  repairOrderConditions: IRepairOrderCondition[];

  constructor(vin: string,
    serviceDate: number,
    mileage: number,
    repairOrderNumber: string,
    repairOrderOpenDate: number,
    repairOrderCloseDate: number,
    totalCost: string,
    serviceAdvisor: string,
    dealerNumber: string,
    dealerName: string,
    repairOrderConditions: IRepairOrderCondition[] = []) {

    this.vin = vin;
    this.serviceDate = serviceDate;
    this.mileage = mileage;
    this.repairOrderNumber = repairOrderNumber;
    this.repairOrderOpenDate = repairOrderOpenDate;
    this.repairOrderCloseDate = repairOrderCloseDate;
    this.totalCost = totalCost;
    this.serviceAdvisor = serviceAdvisor;
    this.dealerNumber = dealerNumber;
    this.dealerName = dealerName;
    this.repairOrderConditions = repairOrderConditions;
  }

  static getSerivceHistoryArray(serviceHistoryRecords: ServiceHistoryRecord[]) {
    const serviceHistoryArray = [];
    serviceHistoryRecords.forEach(record => {
      // record.openDate?.toString()?.includes('-')
      serviceHistoryArray.push(new ServiceHistory(
        record.vin,
        record.postedDate,
        record.miles ? record.miles : record.mileage,
        record.repairOrderNumber,
        record.openDate,
        record.closeDate,
        record.totalCost,
        this.getServiceAdvisor(record.serviceManager),
        record.dealer?.dealerNumber,
        record.dealer?.dealerName,
        this.getRepairOrderCondition(record.repairOrderConditions)
      ));
    });
    return serviceHistoryArray;
  }

  static getRepairOrderCondition(repairOrderConditions: RepairOrderCondition[]): IRepairOrderCondition[] {
    const conditions: IRepairOrderCondition[] = [];
    if (repairOrderConditions && repairOrderConditions.length) {
      repairOrderConditions.forEach(element => {
        conditions.push(new RepairConditions(
          element.conditionNumber,
          this.getServiceOperation(element.operation)
        ));
      });
    }
    return conditions;
  }

  static getServiceOperation(operations: Operation[]): IOperation[] {
    const serviceOperation: IOperation[] = [];
    if (operations && operations.length) {
      operations.forEach(operation => {
        serviceOperation.push(new ServiceOperation(
          operation.opCodeNumber,
          operation.opCodeDescription,
          operation.serviceOperationDescription,
          operation.opCodeHours
        ));
      });
    }
    return serviceOperation;
  }

  static getServiceAdvisor(serviceManager: ServiceManager): string {
    if (serviceManager) {
      return `${serviceManager.firstName} ${serviceManager.middleInitial} ${serviceManager.lastName}`;
    } else {
      return;
    }
  }
}

export class RepairConditions implements IRepairOrderCondition {
  conditionNumber: string;
  operation: IOperation[];

  constructor(
    conditionNumber: string,
    operation: IOperation[] = []
  ) {
    this.conditionNumber = conditionNumber;
    this.operation = operation;
  }
}

export class ServiceOperation implements IOperation {
  opCodeNumber: string;
  opCodeDescription: string;
  serviceOperationDescription: string;
  opCodeHours: string;

  constructor(opCodeNumber: string,
    opCodeDescription: string,
    serviceOperationDescription: string,
    opCodeHours: string) {
    this.opCodeNumber = opCodeNumber;
    this.opCodeDescription = opCodeDescription;
    this.serviceOperationDescription = serviceOperationDescription;
    this.opCodeHours = opCodeHours;
  }
}
