import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ResolveGuard } from '../app/guards/resolve.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
    resolve: {data: ResolveGuard}
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
    resolve: {data: ResolveGuard}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
