<div class="shadowed-rule"></div>
<div class="container">
    <div class="row dealer-details no-gutters flex-column flex-md-row mb-2">
        <div class="d-flex flex-wrap col-md-4 p-2 font-weight-bold justify-content-center">
            <img class="logo-image my-auto" [src]="logoPath" alt="Logo image"
                [ngClass]="brand === 'lexus' ? 'p-1' : ''">
            <div class="d-flex flex-wrap my-auto">
                <span class="d-flex my-auto" style="font-size: 1rem;">{{ dealerName$ |
                    async }}</span>
                <div class="w-100 d-none d-md-flex"></div>
                <span class="menu-underline always-show d-flex my-auto ml-2 ml-md-0" style="cursor: pointer;"
                    (click)="redirectToT3()">{{ 'welcome.home' | translate }}</span>
            </div>
        </div>
        <div class="d-flex flex-wrap col-md-4 p-2 my-auto">
            <span *ngIf="dealerAddress$ | async"
                class="justify-content-center font-weight-normal text-center text-md-left m-auto m-md-0">
                {{ (dealerAddress$ | async).addressLine1 + ',' }}
                <br />
                {{ (dealerAddress$ | async).city + ', ' +
                (dealerAddress$ | async).state + ' ' + (dealerAddress$ | async).zip}}
            </span>
            <div class="w-100"></div>
            <div class="d-flex m-auto m-md-0">
                <span class="d-flex m-auto menu-underline always-show font-weight-bold" (click)="onNavigateToGmap()"
                    style="cursor: pointer;">{{ 'welcome.getDirections' | translate }}</span>
            </div>

        </div>
        <div class="d-flex flex-wrap col-md-4 p-2 my-auto">
            <span *ngIf="serviceHoursOfOperation$ | async"
                class="d-flex justify-content-center justify-content-md-start font-weight-normal text-center m-auto m-md-0 text-nowrap">
                <strong>{{ 'welcome.service' | translate }}:&nbsp;</strong>{{ ('appointmentInformation.openFrom' | translate ) + (serviceHoursOfOperation$ | async)}}</span>
            <div *ngIf="serviceHoursOfOperation$ | async" class="w-100"></div>
            <span *ngIf="salesHoursOfOperation$ | async"
                class="d-flex justify-content-center justify-content-md-start font-weight-normal text-center m-auto m-md-0 text-nowrap">
                <strong>{{ 'welcome.sales' | translate }}:&nbsp;</strong>{{ ('appointmentInformation.openFrom' | translate ) + (salesHoursOfOperation$ | async) }}</span>
            <div *ngIf="salesHoursOfOperation$ | async" class="w-100"></div>
            <span *ngIf="dealerPhone" (click)="callPhone()"
                class="d-flex justify-content-center font-weight-bold text-center m-auto m-md-0 menu-underline always-show"
                style="cursor: pointer;">{{ dealerPhone | phone }}</span>
        </div>
    </div>
    <div class="offset-md-4">
        <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px'"></app-splitter>
        <div id="asp-footer-con">
            <div class="container p-0">
                <div class="col p-0">
                    <div id="footer-section">
                        <div class="footer-title d-flex flex-row justify-content-center justify-content-md-start">
                            <a href="https://www.toyota.com/support/privacy-rights/" class="pl-md-2 pr-2">
                                {{'welcome.privacyPolicy' | translate}}
                            </a>
                            <app-splitter class='app-splitter' [config]="'vertical 16px 0 4px 0px'"></app-splitter>
                            <a href="https://www.toyota.com/support/california-privacy/" class="px-2">
                                {{'welcome.californiaPrivacy' | translate}}
                            </a>
                            <app-splitter class='app-splitter' [config]="'vertical 16px 0 4px 0px'"></app-splitter>
                            <a href="https://www.toyota.com/support/legal-terms/" class="pr-md-2 pl-2">
                                {{'welcome.legalTerms' | translate}}
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div
            class="footer-copy-right pt-2 pb-4 pr-md-3 pl-md-2 justify-content-center justify-content-md-start m-auto text-center text-md-left">
            &#169; 2006-{{ currentYear }} Toyota Motor Sales, U.S.A.,
            Inc. {{ 'welcome.allInformationContainedAppliesToUSVehiclesOnly' | translate}}
        </div>
    </div>

</div>