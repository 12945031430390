import {Injectable} from '@angular/core';
import {gsap, Power2, TimelineMax} from 'gsap/all';

@Injectable({
  providedIn: 'root',
})
export class GsapAnimationService {
  /*---=| GSAP v3 Animation Engine |=---*/

  public enterFromDown(element, duration, initialOpacity, initialOffset, stagger = .15) {
    return gsap.fromTo(element, {
      opacity: initialOpacity,
      y: initialOffset,
    }, {
      duration: duration,
      opacity: 1,
      y: 0,
      stagger: stagger,
      ease: Power2,
    });
  }

  vehicleTypeSwitcher() {
    return gsap.fromTo('#vehicle-type-switcher',
      {opacity: 0, x: '-=8'},
      {duration: .5, opacity: 1, x: 0, ease: Power2});
  }

  enterDifferentVehicleContainer() {
    setTimeout(() => {
      const tl = new TimelineMax();
      tl.add(this.enterFromDown('#select-vehicle-container .anime-element.anime-prime', .2, 0, '+=20', .15))
        .add(this.enterFromDown('#different-vehicle-container .anime-element', .2, 0, '+=20', .075))
        .add(this.vehicleTypeSwitcher());
    }, 0);
  }

  animateYearSeriesInput() {
    setTimeout(() => {
      const tl = new TimelineMax();
      tl.add(this.enterFromDown('#different-vehicle-container .anime-element.anime-sec', .2, 0, '+=20', .075));
    }, 0);
  }

  animateOwnVehicleContainer() {
    setTimeout(() => {
      const tl = new TimelineMax();
      tl.add(this.enterFromDown('#select-vehicle-container .anime-element.anime-prime', .2, 0, '+=20', .15))
        .add(this.enterFromDown('#own-vehicles-container .anime-element', .2, 0, '+=20', .15))
        .add(this.vehicleTypeSwitcher());
    }, 0);
  }

  animateOwnVehicleCards() {
    setTimeout(() => {
      const tl = new TimelineMax();
      tl.add(this.enterFromDown('#own-vehicle-container .anime-element', .2, 0, '+=20', .15));
    }, 0);
  }

}
