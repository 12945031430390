import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {EfcBaseHttpService} from './efc-base-http.service';
import { switchMap, map} from 'rxjs/operators';
import * as util from '../../shared/services/util.service';
import {SeriesData} from '../models/service-appointments/series-data';
import {VehicleImage} from '../models/vehicle-image';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '@signal/asp-data-commons';
import { environment } from '../../shared/services/util.service';
import { Store } from '@ngrx/store'; 
import { DealerState } from '../../../modules/store/dealer/dealer.reducer';
import { selectDealerCode } from '../../../modules/store/dealer/dealer.selectors';

@Injectable()
export class VehicleService {
  currentYear: number = new Date().getFullYear();
  testDriveModelYearStart = 1950;
  testDriveModelYearEnd: number = this.currentYear;
  serviceModelYearStart = 1950;
  serviceModelYearEnd: number = this.currentYear;
  efcApi: string;
  appointmentapi:string;
  seriesData:SeriesData;
  private readonly aspB2cGuestApi: string;

  constructor(private readonly efcBaseHttpService: EfcBaseHttpService,private readonly http: HttpClient,private readonly dealerState: Store<DealerState>) {
    this.efcApi = util.environment.efcApi;
    this.appointmentapi = util.environment.aspAppointmentApi;
    this.aspB2cGuestApi = util.environment.aspB2cGuestApi;
  }
  getVehicleContent(): Observable<any> {
    return this.efcBaseHttpService.get(this.efcApi).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  /**
   * Returns the model years used for test drive appointment screen as number array
   *
   * @returns {Observable<number[]>}
   */
  getTestDriveModelYears(): Observable<number[]> {
    return of(
      util.range(this.testDriveModelYearStart, this.testDriveModelYearEnd)
    );
  }

  /**
   * Returns the model years used for service appointment screen as number array
   *
   * @returns {Observable<number[]>}
   */
  getServiceModelYears(): Observable<number[]> {
    return of(util.range(this.serviceModelYearStart, this.serviceModelYearEnd));
  }
  /**
   * Return list of years and series.
   * @param brand
   * brandbrand must be 3 characters Ex: LEX
   */
  getSeriesData(brand: string): Observable<SeriesData> {
    if(this.seriesData && this.seriesData.data && this.seriesData.data.length > 0){
      return new Observable(observer => {
        observer.next(this.seriesData)
        observer.complete();
      })
    }else{
    const url = this.efcApi + `${brand}/NATIONAL/EN/series`;
    return this.efcBaseHttpService.get(url).pipe(
      map(
        (data: any) => {
          this.seriesData = data;
          return data;
        }
      )
    );
    }
  }

  getInventoryVehicles():Observable<IResponse<any>>{
    return this.dealerState.select(selectDealerCode).pipe(switchMap(dealerCode => {
      return this.http.get(`${environment.aspB2cGuestApi}vehicle-inventory/${dealerCode}/allVehicles`).pipe(
          map((data: IResponse<any>) => {
            return data;
          })
        )
      }));
  }

  getSeriesByBrandYear(brand: string, year: string): Observable<any> {
    const url = this.efcApi + `${brand}/NATIONAL/EN/series?year=${year}`;
    return this.efcBaseHttpService.get(url).pipe(
      map(
        (data: any) => {
          return data;
        }
      )
    );
  }


  getVehicleColors(brand: string, year: string, series: string, trim: string): Observable<VehicleImage> {
    const url = this.efcApi + `${brand}/NATIONAL/EN/colors?year=${year}&series=${series}&trim=${trim}`;
    return this.efcBaseHttpService.get(url).pipe(
      map(
        (data: any) => {
          return data;
        }
      )
    );
  }

  //This method is to get the vehicle image or build data based on the 'isTestDriveVehicle' value
  getVehicleColorForLexus(value,isTestDriveVehicle:boolean){
    const opCodeRequest = value.vin && value.vin.length === 17 ? {"vin": value.vin} : (value.make ? {"mileage":value.mileage,"model":value.model,"year":value.year,"make":value.make}:null)
    if(opCodeRequest){
     const url =  `${this.aspB2cGuestApi}vehicle/20096/vehicledetails/${isTestDriveVehicle}`;
     return this.http.post(url, opCodeRequest).pipe(
       map((data: IResponse<any>) => {
           return data;
         }
       )
     ); 
    }
   }
   
  getVehicleUsingVinFromSource(dealerCode, vehicle) {
   if(vehicle.vin){
    const url =  `${this.appointmentapi}api/vehicle/${dealerCode}/vehicledata/testdrive`;
    return this.http.post(url, vehicle).pipe(
      map((data: IResponse<any>) => {
          return data;
        }
      )
    ); 
   }
  }

}
