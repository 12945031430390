import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GsapAnimationService } from '../../shared/services/gsap-animation.service';
import { Router } from '@angular/router';
import { AppointmentService } from '../../shared/services/asp/appointment.service';
import { IDashboardVehicle } from '../../shared/models/dashboard.model';
import { DateTime } from 'luxon';
import * as util from '../../shared/services/util.service';
import { Observable } from 'rxjs';
import { DashboardState } from '../../store/dashboard/dashboard.reducer';
import { Store } from '@ngrx/store';
import { selectIsBeingCancelledByAppointmentId } from '../../store/dashboard/dashboard.selectors';
import { AppointmentType, LocationType,ISalesInfo } from '@signal/asp-data-commons';
import { DealerState } from '../../store/dealer/dealer.reducer';
import * as fromSelectors from '../../store/dealer/dealer.selectors';
import { Clipboard } from '@angular/cdk/clipboard';
import { AnalyticsService } from './../../shared/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-card',
  templateUrl: './sales-card.component.html',
  styleUrls: ['./sales-card.component.scss'],
})
export class SalesCardComponent implements OnInit {
  @Input() vehicle: IDashboardVehicle;
  @Output() appointmentCancel: EventEmitter<void> = new EventEmitter<void>();

  setDefaultImg = util.setDefaultImg;
  vinDisplayText: string;
  appointmentExists: boolean;
  appointmentDateTime: string;
  carImageAbsoluteURL: string;
  carNameDisplayText: string;
  appointmentLocationType: string;
  appointmentLocationAddress: string;
  enableDrive:boolean;
  enableLearning:boolean;
  buttonText: string;
  buttonTextCancel: string;
  flipCancelCard = false;
  isBeingCancelled$: Observable<boolean>;

  private readonly imageBaseURL = 'http://toyota.com/';

  hoursOfOperation$: Observable<string>;
  dealerName$: Observable<string>;
  dealerPhone$: Observable<string>;
  dealerAddress$: Observable<string>;
  dealerLatitude$: Observable<number>;
  dealerLongitude$: Observable<number>;
  salesSettings$:Observable<ISalesInfo>;

  constructor(private readonly gsap: GsapAnimationService,
   
    private readonly router: Router,
    private readonly appointmentService: AppointmentService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly dealerState: Store<DealerState>,
    private readonly copy: Clipboard,
    private readonly analyticsService: AnalyticsService,
    private readonly translate: TranslateService) {
    this.getDealerStateValue();
  }

  ngOnInit(): void {
    this.vinDisplayText = `VIN ${this.vehicle.vin || this.translate.instant('dashboardAppointments.notAvailable')}`;
    this.carNameDisplayText = this.vehicle?.description && this.vehicle.appointment.appointmentType !== "Learning" ? this.vehicle?.description : `${this.vehicle?.make} ${this.vehicle?.model}`;
    this.appointmentExists = !!this.vehicle.appointment;
    this.appointmentDateTime = this.appointmentExists ? this.getAppointmentDateTime() : '';
    this.getLocationType();

    // TODO - Store generic image URL in prop/env
    this.carImageAbsoluteURL = util.getCarImageAbsoluteURL(this.vehicle.imageUrl);
    this.buttonText = this.vehicle.appointment.appointmentType === "Service" ? 'dashboardAppointments.editServiceAppointment' : 
    this.vehicle.appointment.appointmentType === "Test Drive" ? 'dashboardAppointments.editTestDriveAppointment' : 
    (this.vehicle.appointment.appointmentType === "Learning" ? 'dashboardAppointments.editLearningAppointment': 'dashboardAppointments.editDeliveryAppointment')
    this.buttonTextCancel = this.vehicle.appointment.appointmentType === "Service" ? 'dashboardAppointments.cancelServiceAppointment' : 
    this.vehicle.appointment.appointmentType === "Test Drive" ? 'dashboardAppointments.cancelTestDriveAppointment' : 
    (this.vehicle.appointment.appointmentType === "Learning" ? 'dashboardAppointments.cancelLearningAppointment': 'dashboardAppointments.cancelDeliveryAppointment')
    this.salesSettings$?.subscribe(item=> {     
      this.enableDrive= this.vehicle.appointment.appointmentType === "Test Drive" ? item.enableTestDrive : 
      (this.vehicle.appointment.appointmentType === "Learning" ? item.enableLearning : item.enableDelivery )
    })
    if (this.vehicle.appointment) {
      this.isBeingCancelled$ = this.dashboardState
        .select(selectIsBeingCancelledByAppointmentId,
          { appointmentId: this.vehicle.appointment.appointmentId });
    }
  }

  getLocationType() {
    if (this.vehicle.appointment) {
      this.appointmentLocationType = this.vehicle.appointment.transportation.category;
      this.getDealerAddress();
    }
  }

  getDealerAddress() {
    if (this.appointmentLocationType) {
      if (LocationType.DEALER === this.appointmentLocationType) {
        const dealer = this.vehicle.appointment.dealer;
        this.appointmentLocationAddress = `${dealer.address.addressLine1}, ${dealer.address.city}, ${dealer.address.state} ${dealer.address.zip}`;
      } else {
        const customer = this.vehicle.appointment.dropOffAddress ?
        this.vehicle.appointment.dropOffAddress : this.vehicle.appointment.customer.address;
        this.appointmentLocationAddress = customer.addressLine1 + ', ' + (customer.addressLine2 ? customer.addressLine2 +
          ', ' : '') + customer.city + ', ' + customer.state + ' - ' + customer.zip;
      }
    }
  }

  onHoverDialer() {
    
  }

  callDealer() {
    const linkTrackingData = {
      content_section: 'scheduled services and test drives',
      link_module: 'test drive module',
      link_text: 'contact dealer clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true);
  }

  onEditAppointment() {
    const linkTrackingData = {
      content_section: 'scheduled services and test drives',
      link_module: 'test drive module',
      link_text: 'edit appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true)
    if (this.vehicle.appointment.appointmentType === AppointmentType.TEST_DRIVE) {
      util.navigateToRoute('testdrive', '/testdrive', this.router, {
        queryParams: {
          id: this.vehicle.appointment.appointmentId,
          email: this.vehicle.appointment.emailAddress
        }
      });
    } else if (this.vehicle.appointment.appointmentType === AppointmentType.DELIVERY) {
      util.navigateToRoute('delivery', '/delivery', this.router, {
        queryParams: {
          id: this.vehicle.appointment.appointmentId,
          email: this.vehicle.appointment.emailAddress
        }
      });
    } else if (this.vehicle.appointment.appointmentType === AppointmentType.LEARNING) {
      util.navigateToRoute('learning', '/learning', this.router, {
        queryParams: {
          id: this.vehicle.appointment.appointmentId,
          email: this.vehicle.appointment.emailAddress
        }
      });
    }
  }

  onCancelFlip() {
    const linkTrackingData = {
      content_section: 'scheduled services and test drives',
      link_module: 'test drive module',
      link_text: 'cancel appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true)
    this.flipCancelCard = !this.flipCancelCard;
  }

  onCancel() {
    this.appointmentCancel.emit(undefined);
  }

  getDealerStateValue() {
    this.dealerName$ = this.dealerState.select(fromSelectors.selectDealerName);
    this.dealerPhone$ = this.dealerState.select(fromSelectors.selectDealerPhoneNumber);
    this.dealerAddress$ = this.dealerState.select(fromSelectors.selectDealerAddress);
    this.hoursOfOperation$ = this.dealerState.select(fromSelectors.selectSalesOperationalHours);
    this.dealerLatitude$ = this.dealerState.select(fromSelectors.selectLatitude);
    this.dealerLongitude$ = this.dealerState.select(fromSelectors.selectLongitude);
    this.salesSettings$ = this.dealerState.select(fromSelectors.selectSalesDetails);
  }

  getAppointmentDateTime(): string {
    return DateTime.fromISO(this.vehicle.appointment.appointmentStartDate)
      .toFormat('MMM dd') + ' at ' + this.vehicle.appointment.timeSlotId;
  }

  onNavigateToGmap() {
    const linkTrackingData = {
      content_section: 'scheduled services and test drives',
      link_module: 'test drive module',
      link_text: 'at dealership clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true)
    if (this.vehicle.appointment.dealer && this.isAtDealership) {
      const link = `https://www.google.com/maps?z=12&t=m&q=loc:${this.vehicle.appointment.dealer.latitude}+${this.vehicle.appointment.dealer.longitude}`;
      util.navigateToRoutePromise(undefined, this.router)
        .then(result => {
          window.open(link, '_blank');
        });
    }
  }

  get isAtDealership() {
    return this.appointmentLocationType === LocationType.DEALER;
  }

  convertToText() {
    let copyText = '';
    copyText += `${util.getMakeModelYearText(this.vehicle)}\n`;
    copyText += `VIN ${this.vehicle.vin || this.translate.instant('dashboardAppointments.notAvailable')}`;
    if (this.vehicle.appointment) {
      copyText += `\n${this.translate.instant(this.vehicle.appointment.appointmentType === AppointmentType.TEST_DRIVE ?
        'testDrive.testDriveAppointmentIsScheduledFor' : 'testDrive.deliveryAppointmentIsScheduledFor')} ${this.appointmentDateTime}`;
      copyText += `\n${this.translate.instant((this.appointmentLocationType === 'At Dealership' ?
        'dashboardAppointments.atDealership' : 'dashboardAppointments.atRemote'))}: ${this.appointmentLocationAddress}`;
    }
    return copyText;
  }

  onCopyDetails() {
    const linkTrackingData = {
      content_section: 'scheduled services and test drives',
      link_module: 'test drive module',
      link_text: 'copy details clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true);
    this.copy.copy(this.convertToText());
  }
}
