<div class="vehicle-card"
     [ngClass]="signedIn ? 'signed-in' : 'guest-user'"
     [class.flip-card]="isFlipCard">
  <div class="front">
    <div class="car-image">
      <img [src]="carImageAbsoluteURL"  (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="Vehicle image"/>
      <ng-container [ngTemplateOutlet] = "menuCard"></ng-container>
    </div>

    <div class="car-name" *ngIf="(vehicle?.make && vehicle?.model);else descriptionPlaceholder">{{ vehicle.make |titlecase }} {{vehicle.model}}</div>


    <div class="car-info">
      <span>{{ makeModelYearText }}</span>
      <span>{{ vinDisplayText }}</span>
    </div>

    <div class='telematics-location-info-container' [ngClass]="signedIn ? 'signed-in' : 'guest-user'">
    <!-- Telematics and NSH details are only for signed in users own vehicle -->
      <ng-container *ngIf="signedIn && vehicle.isOwnVehicle; else emptyDiv">
        <ng-container *ngIf="!isTelematicsLoading; else loading">
          <!-- Telematics metrics -->
          <div class="telematics">
            <span [title]="getTranslation(telematics?.smartKeyBatteryDegradationStatusHover)">
              <app-icon name="icon-battery.svg" color="inherit"></app-icon>
              <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow === '--'">{{ telematics?.smartKeyBatteryDegradationStatusToShow }}</span>
              <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow.toUpperCase() === 'OK'">
                <ng-template [ngTemplateOutlet]="ok"></ng-template>
              </span>
              <span *ngIf="telematics?.smartKeyBatteryDegradationStatusToShow.toUpperCase() === 'BAD'">
                <ng-template [ngTemplateOutlet]="bad"></ng-template>
              </span>
            </span>
            <span [title]="getTranslation(telematics?.milesToEmptyDescriptionHover)">
              <app-icon name="ic-20-vin-fuel.svg" color="inherit"></app-icon>
              <span>{{ telematics?.milesToEmptyDescription }}</span>
            </span>
            <span [title]="getTranslation(telematics?.odometerReadingHover)">
              <app-icon name="ic-20-vin-odometer.svg" color="inherit"></app-icon>
              <span>{{ telematics?.odometerReading }}</span>
            </span>
            <span [title]="getTranslation(telematics?.engineOilLevelStatusHover)">
              <app-icon name="icon-engine-oil.svg" color="inherit"></app-icon>
              <span *ngIf="telematics?.engineOilLevelStatus === '--'">
                {{ telematics?.engineOilLevelStatusToShow }}
              </span>
              <span *ngIf="telematics?.engineOilLevelStatus === '3'">
                <ng-template [ngTemplateOutlet]="ok"></ng-template>
              </span>
              <span *ngIf="telematics?.engineOilLevelStatus === '2'">
                <ng-template [ngTemplateOutlet]="bad"></ng-template>
              </span>
            </span>
            <span [title]="getTranslation(telematics?.tirePressureStatusHover)">
              <app-icon name="ic-20-vin-tire.svg" path="svg/"  color="inherit"></app-icon>
              <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === '--';">
                {{ telematics?.tirePressureStatus }}
              </span>
              <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === 'ok';">
                <ng-template [ngTemplateOutlet]="ok"></ng-template>
              </span>
              <span *ngIf="telematics?.tirePressureStatus?.toLowerCase() === 'bad';">
                <ng-template [ngTemplateOutlet]="bad"></ng-template>
              </span>
            </span>
          </div>
          <!-- end: Telematics metrics -->

          <!-- Telematics alerts -->
          <div class="alert-history-con">
            <div
              *ngIf="telematics?.issueExists(); else noIssue"
              class="telematic-alerts issues-exists"
              matRipple
              (click)="doFlipCard()">
              <span>
                <app-icon
                  class="telematics-alert"
                  name="ic-20-alert-error.svg"
                  color="var(--dashboard-telematics-alert-icon-clr)"></app-icon>
                <span class="count"> {{getAlert(telematics?.getIssueCountDesc())}}</span>
              </span>
              <span id="issue-viewer" class="view">{{ 'dashboardAppointments.view' | translate }}</span>
            </div>
            <ng-template #noIssue>
              <div class="telematic-alerts">
                <span>
                  <app-icon name="ic-20-alert-tick.svg"></app-icon>
                  <span class="count">
                    {{getAlert(telematics?.getIssueCountDesc())}}</span>
                </span>
              </div>
            </ng-template>
            <div class="link-button service-history" *ngIf="vehicle.vehicleId" (click)="onHistoryClicked()">
              <app-icon name="ic-20-utility-time-clock.svg"
                        class="icon"
                        color="var(--dashboard-telematics-alert-icon-clr)"></app-icon>
              {{ 'dashboardAppointments.serviceHistory' | translate }}</div>
          </div>
          <!-- end: Telematics alerts -->

        </ng-container>
        <ng-template #loading>
          <app-loading class="telematics-loading"
                       loadingSymbol="dots"
                       text="{{ 'dashboardAppointments.loadingTelematics' | translate }}"
                       color="var(--dashboard-primary-color)">
          </app-loading>
        </ng-template>
      </ng-container>
      <ng-template #emptyDiv>
        <div class="empty-div">
        </div>
      </ng-template>
      <!-- end: Telematics and NSH details -->

      <!-- Location details -->
      <div class="location-info" *ngIf="appointmentsCount == 1;else locationPlaceHolder">
        <ng-container *ngIf="appointmentLocationType && appointmentLocationAddress" #addressContainer>
          <div class="type"
            title="{{isAtDealership ? ('dashboardAppointments.openInMaps' | translate ) : ''}}"
            [ngStyle]="{'cursor': isAtDealership ? 'pointer' : 'auto'}"
            (click)="onNavigateToGmap()">
            <app-icon class='map-icon' name="location_on-24px.svg" color="var(--primary-color)"></app-icon>
            {{ (appointmentLocationType === 'At Dealership' ? ('dashboardAppointments.atDealership') : ('dashboardAppointments.atRemote')) | translate}}
          </div>
          <div class="address" [title]="appointmentLocationAddress">
            {{ appointmentLocationAddress }}
          </div>
        </ng-container>
      </div>
      <!-- end: Location details -->
    </div>

    <ng-template #locationPlaceHolder>
      <div class="location-placeholder">

      </div>
    </ng-template>

    <ng-template [ngTemplateOutlet]="cardActionBtn"></ng-template>
    <ng-container [ngTemplateOutlet]="dealerData"></ng-container>

  </div>

  <div class="back issue-card">
    <!-- Telematics alerts more info -->
    <ng-container *ngIf="flipTo == 'issue'">
      <div
        class="telematic-alerts issues-exists"
        matRipple
        (click)="doFlipCard()"
      >
        <span>
          <app-icon name="arrow_left.svg"></app-icon>
          <span class="count">
            {{getAlert(telematics?.getIssueCountDesc())}}</span
          >
        </span>
        <span id="issue-closer" class="view">
          {{ 'selectService.back' | translate}}
        </span>
      </div>
      <div class="issue-desc">
        <swiper class="swiper-container" [config]="config">
          <div
            *ngFor="let alert of getTelematicsAlerts()"
            class="swiper-slide alert-msg"
          >
            <div>
              {{ alert.message }}
            </div>
          </div>
        </swiper>

        <div class="swiper-pagination"></div>
      </div>
      <div class="disclaimer">
        <span class="heading">{{ 'welcome.disclaimer' | translate }}</span> {{disclaimerText | translate}}
      </div>
      <ng-template [ngTemplateOutlet]="cardActionBtn"></ng-template>
    </ng-container>
    <!-- end: Telematics alerts more info -->

    <ng-container *ngIf="flipTo == 'cancel'">
      <div [class.flip-card]="flipCancelCard">
        <div class="back cancel-card">
          <div class="cancel-loading" *ngIf="isBeingCancelled$ | async; else cancelConfirmation">
            <app-loading loadingSymbol="dots"
                         text="{{ 'dashboardAppointments.cancelling' | translate}}"
                         color="var(--dashboard-primary-color)"
                         size="1.6">
            </app-loading>
          </div>
          <ng-template #cancelConfirmation>
            <div class="cancel-desc"
            [ngClass]="signedIn ? 'signed-in' : 'guest-user'">{{ 'dashboardAppointments.areYouSureYouWantToCancel' | translate }}</div>
            <asp-info-button name="{{ 'dashboardAppointments.yes' | translate }}" type="primary" (click)="onCancel()">
            </asp-info-button>

            <asp-info-button
              name="{{ 'dashboardAppointments.no' | translate }}"
              type="secondary"
              (click)="onCancelFlip()"
            ></asp-info-button>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="flipTo == 'all-appointments' || flipTo == 'editcancel-appointments'">
      <div class="all-appointments-card">
        <span class="back-arrow" (click)="cancelAppointmentFlip()"><app-icon name="arrow_left.svg" height="30px" width="30px"></app-icon></span>
        <span class="menu-card"> <ng-container [ngTemplateOutlet] = "menuCard"></ng-container> </span>
        <div class="car-name">
          <span *ngIf="vehicle.make && vehicle.model">{{this.vehicle.make | titlecase}} {{this.vehicle.model}}</span>
        <span *ngIf="vehicle.vin && !vehicle.make && !vehicle.model">VIN {{vehicle.vin}}</span></div>
        <div class="appointment-location">
          <div title="{{isAtDealership ? ('dashboardAppointments.openInMaps' | translate) : ''}}" class="heading"
          [ngStyle]="{'cursor': isAtDealership ? 'pointer' : 'auto'}"
          (click)="onNavigateToGmap()">
          <app-icon class='map-icon' name="location_on-24px.svg" color="var(--primary-color)"></app-icon>
          {{ (appointmentLocationType === 'At Dealership' ? ('dashboardAppointments.atDealership') : ('dashboardAppointments.atRemote')) | translate}}
        </div>
        <div class="address" [title]="appointmentLocationAddress">
          {{ appointmentLocationAddress }}
        </div></div>
        <div class="appointment-time">
          <span class="heading">{{'selectService.time' | translate}}</span>
          {{getAppointmentDateTime()}}
        </div>
        <div class="controls-container">
          <div class="arrow-controls" *ngIf="flipTo == 'all-appointments'">
            <span class="left-arrow" (click)="decrementIndex()">
              <app-icon *ngIf="index > 0" name="ic-left-red.svg" height="40px" width="40px"></app-icon>
              <app-icon *ngIf="index < 1" name="ic-left-grey.svg" height="40px" width="40px"></app-icon>
            </span>
            <span class="index">{{index+1}}/{{appointmentsCount}}</span>
            <span class="right-arrow" (click)="incrementIndex()">
              <app-icon *ngIf="index < appointmentsCount-1" name="ic-right-red.svg" height="40px" width="40px"></app-icon>
              <app-icon *ngIf="index > appointmentsCount-2" name="ic-right-grey.svg" height="40px" width="40px"></app-icon>
            </span>
          </div>
        </div>
        <div class="action-buttons">
          <asp-info-button
            [name]="getEditBtnText() | translate"
            [info]="getAppointmentDateTime()"
            type="secondary"
            (click)="onEditAppointment()"
          ></asp-info-button>
          <asp-info-button
            name="{{ 'confirm.cancelAppointment' | translate }}"
            (click)="onCancelAppointment()"
          ></asp-info-button>
        </div>
        <ng-container [ngTemplateOutlet]="dealerData"></ng-container>
      </div>
    </ng-container>
  </div>

  <ng-template #menuCard>
    <app-card-menu
    hOffset="-14px"
    [vehicle]="vehicle"
    (cancelEvent)="onCancelAppointment()"
    (copyEvent)="onCopyDetails()"></app-card-menu>
  </ng-template>

  <ng-template #cardActionBtn>
    <ng-container *ngIf="appointmentExists && appointmentsCount == 1">
      <asp-info-button
        [name]="getEditCancelBtnText() | translate"
        [info]="getAppointmentDateTime()"
        type="secondary"
        (click)="onEditCancelAppointment()"
      ></asp-info-button>
    </ng-container>
    <ng-container *ngIf="appointmentsCount == 0" [ngTemplateOutlet] = "editButtonPlaceholder"></ng-container>
    <ng-container *ngIf="appointmentExists && appointmentsCount > 1">
      <asp-info-button
        [name]="seeAllAppointmentsBtnText()"
        [info]="appointmentDateTime"
        type="secondary"
        (click)="seeAllApppointments()"
      ></asp-info-button>
    </ng-container>
    <ng-container *ngIf="(appointmentExists)">
      <asp-info-button
        name="{{ 'dashboardAppointments.bookAnotherServiceAppointment' | translate }}"
        (click)="onCreateAppointment()"
      ></asp-info-button>
    </ng-container>
    <ng-container *ngIf="(!appointmentExists)">
      <asp-info-button
        name="{{ 'dashboardAppointments.bookServiceAppointment' | translate }}"
        type="secondary"
        (click)="onCreateAppointment()"
      ></asp-info-button>
    </ng-container>
    <ng-container *ngIf="(enableDrive && !appointmentExists)">
      <asp-info-button
      [name]="('dashboardAppointments.bookLearningAppointment' | translate)"
        (click)="onCreateLearningAppointment()"
      ></asp-info-button>
    </ng-container>
  </ng-template>
  <ng-template #dealerData>
      <div class="call-dealer" (click)="callDealer()">
      <ng-container *ngIf="dealerName && dealerPhone">
        <a [href]="'tel:' + dealerPhone">
          <app-icon name="phone-dialer-solid.svg" width="12px"></app-icon>
          <span class="dealer-info">{{ dealerName + ' • ' + (dealerPhone | phone) }}</span>
        </a>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #descriptionPlaceholder>
    <div style="height: 30px;" class="description-placeholder"></div>
  </ng-template>
  <ng-template #editButtonPlaceholder>
    <div *ngIf="!ownedVehicle" class="editbtn-placeholder" style="height: 85px;"></div>
  </ng-template>
</div>
<ng-template #bad>
  <span class="icon-button">
    <app-icon name="ic-red-cross.svg"
    path="svg/"
    class="app-icon"
    color="inherit"
    width="1rem"
    height="1rem"></app-icon>
  </span>
</ng-template>
<ng-template #ok>
  <span class="icon-button">
    <app-icon name="ic-green-tick.svg"
    path="svg/"
    class="app-icon"
    color="inherit"
    width="1rem"
    height="1rem"></app-icon>
  </span>
</ng-template>
