import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './dealer.reducer';
import * as fromAdapter from './dealer.adapter';
import { FeatureKeys } from '../app.store';
import { AppointmentType, Department, IDealerInfo, IPortal, ISalesInfo, IServiceInfo } from '@signal/asp-data-commons';

const selectDealerFeature = createFeatureSelector<fromReducer.DealerState>(
  FeatureKeys.DEALER
);

export const dealerDetails = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.dealerInfo
);

export const dealerDetailsData = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.dealerInfo.data
);

export const selectDealerCode = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.dealerCode
);

export const selectServiceDetails = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.service
);

export const selectSalesDetails = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.sales
);

export const selectDealerName = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.dealerName
);

export const selectDealerAddress = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.addressShort
);

export const selectDealerAddressDetails = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.address
);

export const selectDealerPhoneNumber = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.phoneNumber
);

export const selectDealerWebsiteLink = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.websiteLink
);

export const selectSalesOperationalHours = createSelector(
  selectSalesDetails,
  (sales: ISalesInfo) => sales?.hoursOfOperation.find(x => x.day.toUpperCase() === fromAdapter.dayOfWeek()).operation
);

export const selectServiceOperationalHours = createSelector(
  selectServiceDetails,
  (service: IServiceInfo) => service?.hoursOfOperation.find(x => x.day.toUpperCase() === fromAdapter.dayOfWeek()).operation
);

export const selectSalesAmenities = createSelector(
  selectSalesDetails,
  (sales: ISalesInfo) => sales?.amenities
);

export const selectServiceAmenities = createSelector(
  selectServiceDetails,
  (service: IServiceInfo) => service?.amenities
);

export const selectLatitude = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.latitude
);

export const selectLongitude = createSelector(
  dealerDetailsData,
  (dealerInfo: IDealerInfo) => dealerInfo?.longitude
);

export const selectPdiChecklist = createSelector(
  selectSalesDetails,
  (sales: ISalesInfo) => sales?.pdiChecklist
);

export const selectServiceShowPricing = createSelector(
  selectServiceDetails,
  (service: IServiceInfo) => service?.showPricing
);

export const selectServiceShowDuration = createSelector(
  selectServiceDetails,
  (service: IServiceInfo) => service?.showEstimatedTime
);

export const serviceSettings = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.settings
);

export const time = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.settings?.time
);

export const bannerTexts = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.settings?.portal
);

export const selectStates = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.states.data
);

export const selectCities = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.cities.data
);

export const selectUpsellText = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.settings?.portal?.upsellText
);

export const selectSlotDuration = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.settings?.time?.slotDuration
);
export const bannerTextsBasedOnDept = createSelector(
  bannerTexts,
  (state: IPortal, props: AppointmentType) => {
    if (props === AppointmentType.SERVICE && state) {
      return { bannerBodyText: state.bannerBodyText, bannerTitleText: state.bannerTitleText }
    }
    else if (props === AppointmentType.DELIVERY && state) {
      return { bannerBodyText: state.deliveryBannerBodyText, bannerTitleText: state.deliveryBannerTitleText }
    }
    else if (props === AppointmentType.TEST_DRIVE && state) {
      return { bannerBodyText: state.testDriveBannerBodyText, bannerTitleText: state.testDriveBannerTitleText }
    }
    else {
      return { bannerBodyText: 'dashboardAppointments.fromHereYoucanScheduleServiceForYourVehiclesText', bannerTitleText: 'dashboardAppointments.welcomeToTheAppointmentSchedulingPortal' }
    }
  }
);
export const transportOptRemote = createSelector(
  selectDealerFeature,
  (state: fromReducer.DealerState) => state.settings?.department === Department.SERVICE && state.settings?.transport?.transportOptions?.find(transportOpt => transportOpt?.id === "RENTAL") || {}
);
