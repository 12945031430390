<button
  mat-icon-button
  [style.--h-offset]="hOffset"
  [style.--v-offset]="vOffset"
  aria-label="icon-button with a menu"
  class="card-menu-trigger-btn"
  matTooltipPosition="above"
  [matTooltip]="getCopyDetailstext()"
  (click)="onCopyClick()"
>
  <mat-icon>content_copy</mat-icon>
</button>
