import * as fromReducer from './sales-appointment.reducer';
import * as fromAdapter from './sales-appointment.adapter';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../app.store';
import { IAvailabilityOptions, IAsyncData, IAvailability, IChecklistRequest, IVehicle, IDealerInfo } from '@signal/asp-data-commons';
import { ITransport } from '@signal/asp-data-commons/lib/interfaces/general-setttings';
import { dealerDetailsData, time } from '../dealer/dealer.selectors';

const selectSalesAppointmentFeature = createFeatureSelector<fromReducer.SalesAppointmentState>(
    FeatureKeys.SALES_APPOINTMENT
);

export const stateDetails = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state
);

export const editDetails = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.editDetails
);

export const appointmentDetails = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => fromAdapter.formatAppointmentDetails(state.appointment)
);

export const inquiryAppointmentDetails = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => fromAdapter.inquiryVehicleAppointmentDetails(state.appointment)
);

export const getAppointmentStartDate = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => appointment.availability.appointmentStartDate
);

export const availabilityOptions = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.availabilityOptions
);

export const getAvailabilityOptions = createSelector(
    availabilityOptions,
    (availabilityOptions: IAsyncData<IAvailabilityOptions>) => availabilityOptions.data
);

export const getAvailabilityOptionsLoading = createSelector(
    availabilityOptions,
    (availabilityOptions: IAsyncData<IAvailabilityOptions>) => availabilityOptions.loading
);

export const availability = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.availability
);

export const getAvailability = createSelector(
    availability,
    (availability: IAsyncData<IAvailability>) => availability.data
);

export const getAvailabilityLoading = createSelector(
    availability,
    (availability: IAsyncData<IAvailability>) => availability.loading
);

export const getAvailabilityFormAdvisorId = createSelector(
    appointmentDetails,
    (appointmentDetails: fromReducer.ISalesAppointmentForm) => appointmentDetails.availability.advisorId
);

export const getTransport = createSelector(
    getAvailabilityOptions,
    (availabilityOptions: IAvailabilityOptions) => availabilityOptions.transport
);

export const getTransportOptions = createSelector(
    getTransport,
    (transport: ITransport) => transport?.transportOptions
);

export const getPMAZipCodes = createSelector(
    getTransport,
    (transport: ITransport) => transport?.includedRemoteZipCodes
);

export const getYearList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => fromAdapter.getYearList()
);

export const getSeriesList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState, props: { model?: string, year: number }) =>
        fromAdapter.getSeriesList(state.seriesDetails, props.year)
);

export const getTrimList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState, props: { model: string, year: number }) =>
        fromAdapter.getTrimList(state.seriesDetails, props.year, props.model)
);

export const getAllVehicleInventoryList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => {
        return fromAdapter.transformVehicleInventory(state.vehicleInventory.data, true)
    }
  )

  export const selectAllVehiclesFromInventory = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.vehicleInventory.data
  )

  export const getValidVehicleInventoryList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => {
        return fromAdapter.transformVehicleInventory(state.vehicleInventory.data, false)
    }
  )

/* Used in learning appointment to check whether edit details got editmode or undefined */
export const isEditModeInEditDetails = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state?.editDetails?.isEditMode?true:false
);

/* To select vehicle from edit learning appointment form */
export const editAppointmentVehicle = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => [appointment.vehicle]
)

export const getExteriorColorsList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.exteriorColors.data
);

export const getMileage = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState, props: { model: string, year: number }) =>
        fromAdapter.getMileage(state.seriesDetails, props.year, props.model)
);

export const getPrice = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState, props: { model: string, year: number }) =>
        fromAdapter.getPrice(state.seriesDetails, props.year, props.model)
);

export const getVehicleDetails = createSelector(
    getYearList,
    getSeriesList,
    getTrimList,
    getExteriorColorsList,
    getMileage,
    getPrice,
    (yearList, seriesList, trimList, exteriorColors, mileage, price, props: { model: string, year: number }) =>
        fromAdapter.getVehicleDetails(yearList, seriesList, trimList, exteriorColors, mileage, price)
);

export const getOtherVehiclesSeriesList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState, props: { model?: string, year: number }) =>
        fromAdapter.getSeriesList(state.seriesDetails, props.year)
);

export const getOtherVehiclesTrimList = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState, props: { model: string, year: number }) =>
        fromAdapter.getTrimList(state.seriesDetails, props.year, props.model)
);

export const getAvailabilityDetails = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => 
    ({
        transport: appointment.transportationTypeCode, 
        advisorId: appointment.availability.advisorId,
        appointmentStartDate: appointment.availability.appointmentStartDate
    })
);
export const getVehicleDetailsForVin = createSelector(
    selectSalesAppointmentFeature,
    getExteriorColorsList,
    appointmentDetails,
    getMileage,
    getPrice,
    ( state: fromReducer.SalesAppointmentState, exteriorColors,appointment: fromReducer.ISalesAppointmentForm,mileage,price, props: { model: string, year: number }) => (
        fromAdapter.getVehicleDetailsForVinTestDrive( state,exteriorColors,appointment?.vehicle,mileage,price)
    )
)

export const selectOnlineChecklist = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.allChecklist.data.filter(x => x.status)
  );

export const selectChecklistForm = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => appointment.checklistRequest
);

export const selectAllChecklistLoading = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.allChecklist.loading
);

export const selectAppointmentAvailability = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => appointment.availability
)

/* --------------- Footer summary --------------------------------- */
/* To select the appointment vehicle details and form vehicle summary */
export const selectAppointmentVehicleSummary = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => fromAdapter.appointmentVehicleFormToSummaryProjector(appointment.vehicle)
);

/* To select the appointment checklist requests and form checklist request summary */
export const appointmentChecklistRequestFormToSummaryProjector = createSelector(
    time,
    (time:any) =>
        fromAdapter.appointmentChecklistRequestFormToSummaryProjector(time)
);

/* To select the appointment details and form footer summary array */
export const selectSalesAppointmentFooterSummaries = createSelector(
    selectAppointmentVehicleSummary,
    appointmentChecklistRequestFormToSummaryProjector,
    (vehicleSummary: fromReducer.IFooterSummary[], checklistRequestSummary: fromReducer.IFooterSummary[]) => [...vehicleSummary, ...checklistRequestSummary]
);

/* To select the currentStep from the sales appointment state */
export const selectCurrentStep = createSelector(
    selectSalesAppointmentFeature,
    (state: fromReducer.SalesAppointmentState) => state.currentStep
);

export const selectChecklistDurationForAvailability = createSelector(
    selectChecklistForm,
    (checklistRequest: IChecklistRequest[]) => fromAdapter.getChecklistDurationForAvailability(checklistRequest)
);

export const selectVehicleForm = createSelector(
    appointmentDetails,
    (appointment: fromReducer.ISalesAppointmentForm) => appointment.vehicle
);

export const selectVehicleFormImageUrl = createSelector(
    selectVehicleForm,
    (vehicleForm: IVehicle) => vehicleForm.imageUrl
);

export const selectVehicleFormMakeModelYearText = createSelector(
    selectVehicleForm,
    (vehicleForm: IVehicle) => fromAdapter.vehicleToMakeModelYearTextProjector(vehicleForm)
);

export const selectAppointmentFormAndLookUpData = createSelector(
    appointmentDetails,
    getAvailabilityOptions,
    (appointmentForm: fromReducer.ISalesAppointmentForm, availabilityOptions: IAvailabilityOptions) =>
        ({ appointmentForm, availabilityOptions })
);

/* To select appointment form and derive detailMap */
export const selectAppointmentFormDetailMap = createSelector(
    selectAppointmentFormAndLookUpData,
    dealerDetailsData,
    (data: { appointmentForm: fromReducer.ISalesAppointmentForm, availabilityOptions: IAvailabilityOptions }, dealerDetails: IDealerInfo) =>
        fromAdapter.appointmentFormToDetailMap(data, dealerDetails)
);

export const selectAppointmentStatus = createSelector(
    editDetails,
    (details: fromReducer.IEditDetails) => details?.appointmentStatus
)


