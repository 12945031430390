import {Injectable} from '@angular/core';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserVehicleService} from '../../shared/services/user-vehicle.service';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {UserVehicle} from '../../shared/models/service-appointments/myvehicle';
import * as fromAdapter from './dashboard.adapter';
import * as fromActions from './dashboard.actions';
import {AppointmentService} from '../../shared/services/asp/appointment.service';
import {IAppointment, IResponse, IVehicle} from '@signal/asp-data-commons';

@Injectable()
export class DashboardEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly userVehicleService: UserVehicleService,
    private readonly appointmentService: AppointmentService
  ) {
  }

  @Effect()
  loadUserVehicles: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadUserVehicles>(
      fromActions.DashboardActionTypes.LOAD_USER_VEHICLES
    ),
    mergeMap((action: fromActions.LoadUserVehicles) =>
      this.userVehicleService.getVehicle().pipe(
        map((userVehicle: UserVehicle) => fromAdapter.toVehiclesProjector(userVehicle)),
        map((vehicles: IVehicle[]) => new fromActions.LoadUserVehiclesSuccess({vehicles})),
        catchError(err => of(new fromActions.LoadUserVehiclesFail({error: err})))
      )
    )
  );

  @Effect()
  loadAppointments: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadAppointments>(
      fromActions.DashboardActionTypes.LOAD_APPOINTMENTS
    ),
    switchMap((action: fromActions.LoadAppointments) =>
      this.appointmentService.search(action.payload.queryText).pipe(
        switchMap((appointments: IResponse<IAppointment[]>) => fromAdapter.toAppointmentsProjector(appointments)),
        map((appointments: IAppointment[]) => new fromActions.LoadAppointmentsSuccess({appointments})),
        catchError(err => of(new fromActions.LoadUserVehiclesFail({error: err})))
      )
    )
  );

  @Effect()
  loadAppointmentsforvin: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadAppointmentsForVin>(
      fromActions.DashboardActionTypes.LOAD_APPOINTMENTS_FOR_VIN
    ),
    mergeMap((action: fromActions.LoadAppointmentsForVin) =>
      this.appointmentService.search(action.payload.queryText).pipe(
        switchMap((appointments: IResponse<IAppointment[]>) => fromAdapter.toAppointmentsProjector(appointments)),
        map((appointments: IAppointment[]) => new fromActions.LoadAppointmentsSuccessForVin({appointments})),
        catchError(err => of(new fromActions.LoadUserVehiclesFail({error: err})))
      )
    )
  );

  @Effect()
  cancelAppointment: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.CancelAppointmentFromDashboard | fromActions.CancelAppointmentFromConfirm>(
      fromActions.DashboardActionTypes.CANCEL_APPOINTMENT_FROM_DASHBOARD,
      fromActions.DashboardActionTypes.CANCEL_APPOINTMENT_FROM_CONFIRM
    ),
    mergeMap((action: fromActions.CancelAppointment) =>
      this.appointmentService.cancel(action.payload.appointment).pipe(
        map(() => new fromActions.CancelAppointmentSuccess({appointmentId: action.payload.appointment.appointmentId})),
        catchError(err => of(new fromActions.CancelAppointmentFail({error: err})))
      )
    )
  );
}
