import {Component, Input, OnInit} from '@angular/core';
import { assetUrl } from '../../../../modules/shared/services/util.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() name: string;
  @Input() desc: string = "icon";
  @Input() width: string = "20px";
  @Input() height: string = "20px";
  @Input() color: string = "grey";

  iconSource: string;
  constructor() {}

  ngOnInit(): void {
    this.iconSource = assetUrl("icons/svg/" + this.name);
  }
}
