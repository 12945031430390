<div class="choose-vehicle-con">
    <div class="row">
        <div class="col-md-12">
            <span class="choose-vehicle-header">{{ 'delivery.selectedVehicle' | translate }}</span>
        </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="selected-vehicle">
          <div class="yearMakeModel vehicle-label">
            <span>{{vehicleDetails.yearMakeModel}}</span>
          </div>
          <div class="vin vehicle-label">
            <span class="vin-label">
              VIN
            </span>
            <span class="vin-value">
                {{vehicleDetails.vin}}
            </span>
          </div>
          <div class="vin vehicle-label">
            <span class="vin-label">
              {{ 'delivery.StockNo' | translate}}
            </span>
            <span class="vin-value">
                {{vehicleDetails.stockno}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-splitter class='app-splitter'  [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
</div>

