import { AspSideNavService } from './shared/components/asp-side-nav/asp-side-nav.service';
import { SubscriptionList } from './shared/models/asp.types';
import { AnalyticsService } from './shared/services/analytics.service';
import { AuthService } from './shared/services/auth.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { DashboardState } from './store/dashboard/dashboard.reducer';
import { LoadUserVehicles } from './store/dashboard/dashboard.actions';
import { assetUrl, environment, unsubscribeSubscriptions, languageCd, countryCd, setCurrentLanguageLocale, setCurrentCurrency } from './shared/services/util.service';
import { MatDrawer } from '@angular/material/sidenav';
import * as fromDealerActions from '../modules/store/dealer/dealer.actions';
import * as dashboardActions from '../modules/store/dashboard/dashboard.actions';
import { DealerState } from './store/dealer/dealer.reducer';


@Component({
  selector: 'asp-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  dynamicCSSUrl;
  isProdEnv: boolean;
  signedIn: boolean;
  analyticsScriptLoaded: boolean = false;
  @ViewChild('drawer', { static: true }) public drawer: MatDrawer;
  private subs: SubscriptionList = {};

  constructor(public translate: TranslateService,
    public sanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dashboardStore: Store<DashboardState>,
    private readonly dealerState: Store<DealerState>,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private readonly aspSideNavService: AspSideNavService
  ) {
    const languageCode = languageCd || (window as any).ASPHub?.languageCd;
    this.setLanguage(translate, languageCode);
    this.setTheme(environment.brand);

    this.authService.setSignedInUser();
    this.authService.setExpired();
    const scriptLoader = this.analyticsService.setupAnalytics();
    scriptLoader.onload = () => {
      this.analyticsScriptLoaded = true;
      this.analyticsService.scriptLoadSuccess = true;
    };
    scriptLoader.onerror = () => {
      this.analyticsScriptLoaded = true;
    };
    this.dealerState.dispatch(new fromDealerActions.LoadStatesDetails());
    this.dealerState.dispatch(new fromDealerActions.LoadCityDetails());
  }

  ngOnInit() {
    this.isProdEnv = environment.production;
    if (window.location.href.startsWith("https://dev.scheduling.toyota.com")) {
      this.isProdEnv = false;
    }
    this.subs.toggleDrawer = this.aspSideNavService.toggleSideNav.subscribe(() => {
      this.drawer.toggle();
    });

    this.subs.user = this.authService.user.subscribe(user => {
      this.signedIn = user && !this.authService.isExpired();
      if (this.signedIn) {
        this.dashboardStore.dispatch(new LoadUserVehicles());
        let payload: { queryText: string };
        payload = {
          queryText: user.email,
        };
        this.dashboardStore.dispatch(new dashboardActions.LoadAppointments(payload));
      }
      // else {
      //   this.subs.searchText = this.route.queryParams.subscribe(params => {
      //     const queryText = params.searchText;
      //     if (queryText) {
      //       this.dashboardStore.dispatch(new dashboardActions.LoadAppointments({ queryText }));
      //     }
      //   });
      // }
    });
    setTimeout(() => {
      const languageLocale = this.translate.instant('GLOBAL.LOCALE');
      const currency = this.translate.instant('GLOBAL.CURRENCY');
      setCurrentLanguageLocale(languageLocale && !languageLocale.includes('.') ? languageLocale : 'en-US')
      setCurrentCurrency(currency && !currency.includes('.') ? currency : 'USD');
    }, 100)
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
  }

  setLanguage(translateService, languageCode) {
    const languageList = ['en-US', 'es-US', 'en-MX', 'es-MX'];
    translateService.addLangs(languageList);
    translateService.setDefaultLang('en-US');
    const browserLang = translateService.getBrowserLang();
    translateService.use('en-US');
    if ((countryCd && languageCode && languageList.indexOf(`${languageCode}-${countryCd}`) > -1) && (`${languageCode}-${countryCd}`) !== browserLang)
    {
      translateService.use(`${languageCode}-${countryCd}`);
    }
  }

  setTheme(brand: string) {
    this.dynamicCSSUrl = this.sanitizer.bypassSecurityTrustResourceUrl(assetUrl(`styles/${brand}.css`));
  }

  brandChange(brand: string) {
    this.setTheme(brand);
  }

  private setupStore() {
    /* Dispatching store actions */
    if (this.signedIn) {
      this.dashboardStore.dispatch(new LoadUserVehicles());
    }
  }
}
