    <div class="confirmation-card">
        <div class="car-details" *ngIf="view == 'confirm' && !isFromMail" >
            <!-- <img *ngIf="carImage" [src]='baseImageUrl+carImage' (error)="setDefaultImg($event,'images/toyota/Sedan_Generic_base_default_34_f_d_490x280.png')" alt="Vehicle image not available"> -->
            <!-- <div class="model">{{makeModelYear}}</div> -->
            <div *ngIf="view == 'confirm' && !isFromMail" class="confirm-alert">{{getAppointmentMsg(appointment?.appointmentType, appointment?.appointmentStatus)}}</div>
            <div *ngIf="view == 'confirm' && !isFromMail && !isConfirm" class="email-message">{{'confirm.youWillReceiveAConfirmationEmailFromTheDealer'| translate}}</div>
        </div>
        <div class='confirmation-details'>
            <div class='summary-heading'>{{getAppointmentSummaryMsg(appointment?.appointmentType, appointment?.appointmentStatus)}}</div>
            <div class="summary-details"
                *ngFor="let item of getDetailKeys()"
                [ngStyle]="{'display': view == 'confirm' ? 'grid' : 'block'}">
                <div
                class='key'
                [ngStyle]="{'padding': view == 'confirm' ? '10px' : '0px'}">{{item | translate | titlecase}}</div>
                <div
                class='value'
                [ngStyle]="{'padding': view == 'confirm' ? '10px' : '0px', 'font-size': item === 'selectService.time' && view == 'confirm' ?
                '14px' : '12px',  'font-weight': item === 'selectService.time' && view == 'confirm' ?
                'bold' : '' }">{{getTranslation(item)}}
                    <app-icon name="ic-20-feedback-info.svg" *ngIf="item == 'Coupons'"></app-icon>
                    <div *ngIf="view == 'confirm' && item == 'Coupons'" class='coupon'>
                        <div class='coupon-warning'>
                            <app-icon name="ic-20-feedback-alert-error.svg"></app-icon>
                            {{'confirm.mustBringCouponsToAppointment'| translate}}</div>
                          
                    </div>
                </div>
            </div>
            <div *ngIf="view == 'confirm' && !signedIn && isLoginVisible" class="create-account">
              {{'confirm.saveAndBookAppointmentsQuickly'| translate}}
                <a class="create-link" href="{{registerUrl}}" target="blank" (click)="createAccount()">{{'welcome.createAccount'| translate}}</a>
                <app-icon name="ic-20-arrow-half-right.svg"></app-icon>
            </div>
            <div class="d-flex">
                <div *ngIf="view == 'confirm'" class="cancel-edit" (click)="onEdit()">{{'confirm.editAppointment'| translate}}</div>
                <div *ngIf="view == 'confirm'" class="cancel-edit" (click)="onCancel()">{{'confirm.cancelAppointment'| translate}}</div>
            </div>
            <div *ngIf="view == 'confirm'" class="d-flex">
                <div class='add-to-calendar' (click)="onAddToCalendar()">{{'confirm.addToCalendar'| translate}}</div>
                <div class='add-to-calendar' (click)="redirectToT3()">{{'confirm.dealershipHome'| translate}}</div>
            </div>
            <div *ngIf="view == 'confirm' && signedIn">
                <div class='manage' (click)="onManage()">{{'confirm.viewAndManageAppointments'| translate}}</div>
            </div>
            <div *ngIf="view == 'confirm'" class='dealer-details'>
              <a [href]="'tel:' + (dealerPhone$ | async)">
                <app-icon name="phone-dialer-solid.svg" width="12px"></app-icon>
                <span class="dealer-info" (click)="dealerInfoClick()">{{ (dealerName$ | async) + ' • ' + ((dealerPhone$ | async) | phone) }}</span>
              </a>
            </div>
        </div>

    </div>
