import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AspSideNavService {

    toggleSideNav = new Subject<void>();

    constructor() { }

    toggle() {
        this.toggleSideNav.next();
    }

}
