import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DeliveryFormComponent} from './delivery-form/delivery-form.component';
import {DeliveryConfirmComponent} from './delivery-confirm/delivery-confirm.component';

const routes: Routes = [
  {
      path: '',
      component: DeliveryFormComponent
  },
  {
    path: 'confirm',
    component: DeliveryConfirmComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryFormRoutingModule { }
