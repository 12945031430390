<div class="row d-flex service-header px-4">
    <span class="col-12 service-name">{{data.displayName}}</span>
    <mat-icon class="close-dialog-btn" (click)="close()">{{'recommendedService.close'| translate}}</mat-icon>    
</div>
<div>
    <app-loading
    class="py-2 px-5 col-12 loader"
  size=".5"
  *ngIf="loading; else showfactoryrecommendedservice"
></app-loading>
</div>
<ng-template #showfactoryrecommendedservice>
    <div class="row task-list d-flex py-2 px-5">
        <ng-container *ngFor="let task of sortedFactoryServices; let i=index">
            <ng-container *ngIf="i===1 && showData">
                <div class="col-12 service-description driving-impacts-title">{{drivingImpactsTitle}}</div>
                <div class="col-12 service-description driving-impacts-subtitle" *ngIf="data.factoryServices[0].vehicleDetails.make?.toLowerCase() !=='lexus'">{{ 'recommendedService.ServiceOperatingConditionReqText' | translate}}</div>
            </ng-container>
            <div class="p-0 col-md-12" >
                <span class="service-description" [ngClass]="i===0 ? 'col-md-8':'col-md-12'">
                    <span *ngIf="(!showData && i===0) || (showData && i >= 0)">{{task.displayName}}</span>
                    <button class="mat-badge" *ngIf="task.notesId && showData" (click)="openDisclaimer(task)">
                        <sup> {{task.notesId}}</sup>
                     </button>
                </span>
                <ng-container *ngIf="i===0 ">
                    <span class="col-md-3 pb-1 engineType" *ngIf="vehicleBrand === 'toyota'">
                        <mat-form-field class="row d-flex">
                            <mat-label [innerHTML]="engineTypeLabelText"></mat-label>
                            <mat-select [(value)]="selectedEngine" [required]="engineList && engineList.length > 1">
                                <mat-option *ngFor="let engineDetail of engineList" [value]="engineDetail.name"
                                (click)="changeRecommendedServiceDetails(engineDetail)">
                                  {{engineDetail.name}}
                                </mat-option>
                              </mat-select>
                              <sub class="error-text" *ngIf="!showData">{{engineTypeMandatoryText}}</sub>
                        </mat-form-field>
                       
                        </span>
                    <span *ngIf="vehicleBrand === 'lexus' && engineList && engineList.length > 0" class="col-md-8" style="float: right;">
                        <span class="col-md-12 enginedetail">
                            <span class="col-md-4"><strong>{{'recommendedService.engine' | translate | uppercase}}</strong></span>
                            <span class="col-md-4"><strong>{{'recommendedService.transmission' | translate | uppercase}}</strong></span>
                            <span class="col-md-4"><strong>{{'recommendedService.drive' | translate | uppercase}}</strong></span>
                        </span>
                        <span class="col-md-12 enginedetail">
                            <span class="col-md-4">{{engineList[0].engine}}</span>
                            <span class="col-md-4">{{engineList[0].transmission}}</span>
                            <span class="col-md-4">{{engineList[0].driveType}}</span>
                        </span>
                    </span>
                
                    <div  class="col-md-12 pb-3 pl-0">
                        <button class="btn pdf-btn mr-5" (click)="printPDF()">
                            <span>{{ 'recommendedService.downloadPdf' | translate}}</span>
                        </button>
                    </div>
                </ng-container>
            </div>
          
        <ng-container *ngIf="showData">
            <div class="col-md-6 task-container d-flex" *ngFor="let factoryService of task.factoryServices">
               
                <span class="task-item d-flex my-auto" >
                    {{factoryService.name}}
                    <button class="mat-badge" *ngIf="factoryService.notesId" (click)="openDisclaimer(factoryService)">
                       <sup> {{factoryService.notesId}}</sup>
                    </button>
                </span>
            </div>
        </ng-container>
     
        </ng-container>
    </div>
    <div *ngIf="showNotes && showData">
        <ol class="py-2 px-5 shownotes">
            <li *ngFor="let servItemNote of servItemNotes">
                {{servItemNote.name}}
            </li>
        </ol>
    </div>
    <div class="row d-flex" *ngIf="showData">
        <button class="btn close-btn mr-5" (click)="close()">
            <span>{{ 'recommendedService.close'| translate | titlecase}}</span>
        </button>
    </div>
</ng-template>
