<div>
    <div class="appointment">
        <div [ngClass]="from === 'sales' ? 'address' : 'customer-dealer-details'">
            <form class='customer-form' [formGroup]='customerInfo'  [ngStyle]="{'display' : from === 'sales' ? 'block' : 'grid'} ">

                <ng-template [ngIf]="location=='DEALER' && from === 'sales'">
                    <div class="dealer-address">
                        <h5>{{dealerAddressForRadio.dealerName}}</h5>
                        <div style="font-weight: bold;">{{dealerAddressForRadio.address.addressLine1}} {{dealerAddressForRadio.address.addressLine2}} {{dealerAddressForRadio.address.city}}, {{dealerAddressForRadio.address.state}} {{dealerAddressForRadio.address.zip}}</div>
                        <div style="font-weight: bold;">{{dealerPhoneNumber}}</div>
                    </div>
                </ng-template>

                <ng-template [ngIf]="location=='REMOTE'">
                    <div class='location-details' [ngStyle]="{'display' : from === 'sales' ? 'block' : 'grid'} ">
                        <app-shared-address [form]="customerInfo" 
                        [zipCodes]="remoteValidZipcodes" 
                        (zipChange)="zipChange($event)"
                        [formStates]="formStates['address'].states"
                        [formcities]="formcities['address'].cities.statecities"
                        [remotelocation]="location=='REMOTE'"
                        (addressChange)="updateAddressFields($event,'pickUpAddress')"
                        [isZipValid]="isZipValid"
                        >
                        </app-shared-address>
                      </div>
                </ng-template>
            </form>

            <!-- <div class='dont-forget'>
                <div *ngIf="(amenities$ | async)?.length > 0" class='please-note'>
                    <div class='header'>{{ 'appointmentInformation.amenities' | translate }}</div>
                    <div class='content'>
                        <ol class="pl-4">
                            <li *ngFor="let amenity of amenities$ | async">{{amenity}}</li>
                        </ol>
                    </div>
                </div>
                <div class='contact-us'>
                    <div class='header'>{{ 'appointmentInformation.contactUs' | translate }}</div>
                    <div class='content'>
                        <div>{{ dealerName$ | async }}</div>
                        <div>{{ dealerAddress$ | async }}</div>
                        <div>
                            {{ ('appointmentInformation.openFrom' | translate) + (hoursOfOperation$ | async) + ' • ' + ((dealerPhone$ | async) | phone) }}
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
