<div class='location-container' *ngIf="department">
  <div class='transport'>
    <section class='transport-container'>
      <!-- Button to toggle location types -->
      <ng-container  *ngIf="toggleView">
        <div class="location-btn">
          <div class='btn'
               [ngClass]="{'btn-selected' : !isRemote}"
               (click)="toggleLocationType()">{{'transportation.youComeToUs'| translate}}
          </div>
          <div class='btn'
               [ngClass]="{'btn-selected' : isRemote}"
               (click)="toggleLocationType()">{{'transportation.weComeToYou'| translate}}
          </div>
        </div>
      </ng-container>

      <!-- Pick up options for each location type -->
      <div [ngClass]= "department === 'SERVICE' ? 'pickup-options-transport'  : 'pickup-options'">

        <!-- Multi view for location type with more than one pickup option -->
        <ng-container *ngIf="transportsForSelectedType?.length >= 1; else singleTransportView">
          <mat-radio-group aria-label="Select an option" name="TransportationRadio">
            <span class="TransportationSpan" *ngFor='let transport of transportsForSelectedType' [title]="transport.comments">
                <mat-radio-button disableRipple class='pickup-option' [value]="transport.id"
                              [checked]="transportSelected === transport.id"
                              (change)='onSelectingPickupLocation(transport)'>
                  {{getTransport(transport?.label)}}
                </mat-radio-button>
            </span>
          </mat-radio-group>
          <div style="text-align: center; font-size:1.1rem ;">{{additionalInfo}}</div>
          <br/>
        </ng-container>

        <!-- Single view for location type with only one pickup option -->
        <ng-template #singleTransportView>
          <div class='remoteInfo' *ngIf="toggleView">
            {{transportsForSelectedType[0]?.comments}}
          </div>
        </ng-template>

      </div>
      <!-- End: Pick up options -->
    </section>
    <!--  End: transport-container  -->
  </div>
  <!-- End: transport -->
</div>
<!-- End: location-container -->
