import {dashboardReducer, DashboardState} from './dashboard/dashboard.reducer';
import {Action, ActionReducerMap} from '@ngrx/store';
import {serviceAppointmentReducer, ServiceAppointmentState} from './service-appointment/service-appointment.reducer';
import {salesAppointmentReducer, SalesAppointmentState} from './sales-appointment/sales-appointment.reducer';
import {dealerReducer, DealerState} from './dealer/dealer.reducer';

/* Feature keys - This key should be used for creating feature selector */
export enum FeatureKeys {
  DASHBOARD = 'dashboardState',
  SERVICE_APPOINTMENT = 'serviceAppointmentState',
  SALES_APPOINTMENT = 'salesAppointmentState',
  DEALER = 'dealerState'
}

/* App Reducer */
export interface AppState {
  [FeatureKeys.DASHBOARD]: DashboardState;
  [FeatureKeys.SERVICE_APPOINTMENT]: ServiceAppointmentState;
  [FeatureKeys.SALES_APPOINTMENT]: SalesAppointmentState;
  [FeatureKeys.DEALER]: DealerState;
}

export const appReducers: ActionReducerMap<AppState> = {
  dashboardState: dashboardReducer,
  serviceAppointmentState: serviceAppointmentReducer,
  salesAppointmentState : salesAppointmentReducer,
  dealerState : dealerReducer
};

/* Actions */
export enum AppStoreTypes {
  StartAppInitializer = '[AppStore] Start App Initializer',
  FinishAppInitializer = '[AppStore] Finish App Initializer',
}

export class StartAppInitializer implements Action {
  public readonly type = AppStoreTypes.StartAppInitializer;
}

export class FinishAppInitializer implements Action {
  public readonly type = AppStoreTypes.FinishAppInitializer;
}

/* Devtools instrumentation config */
export const appDevToolsOptions = {maxAge: 25, name: 'Appointment Scheduling Portal'};

export type AppActions = StartAppInitializer | FinishAppInitializer;
